import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getMatchTypeOptions, sponsorshipTypes } from '../../utils'
import { Card } from 'react-bootstrap'

export const SponsorshipInquiryDetailView = ({ API_ENDPOINT, ...props }) => {
	const navigate = useNavigate()

	const { sponsorshipId } = useParams()

	const [sponsorship, setSponsorship] = useState({})

	useEffect(() => {
		// TODO : sponsorship 정보 불러오기
		fetchSponsorship()
	}, [])

	const fetchSponsorship = async () => {
		await fetch(`${API_ENDPOINT}/admin/sponsor/${sponsorshipId}`)
			.then(async (response) => {
				const result = await response.json()

				setSponsorship(result)
			})
			.catch((errors) => console.log(errors))
	}

	const destroySponsorship = async () => {
		await fetch(`${API_ENDPOINT}/sponsorship/delete/${sponsorshipId}`, {
			method: 'DELETE',
		})
			.then(async (response) => {
				navigate(`/sponsorship`)
			})
			.catch((errors) => console.log(errors))
	}

	return (
		<div className="wrap traning_page form_page">
			<div className="content_area">
				<div className="container tpContainer">
					<p className="topTitleText">스폰서쉽</p>
					<p className="topSubTitleText">
						스폰서십 소개글 입력영역 입니다. 적용될 내용을 알려주시면 반영하도록
						하겠습니다. 스폰서십 소개글 입력영역 입니다. 적용될 내용을 알려주 시면
						반영하도록 하겠습니다. 스폰서십 소개글 입력영역 입니다. 적용될 내용을
						알려주시면 반영하도록 하겠습니다.
					</p>

					<Card className="mb-5">
						<Card.Body>
							<div className="row align-items-center cmb-20">
								<div className="col-12 col-md-6">
									<div className="d-flex w-100">
										<span className="form_subTitle me-3 fontSize-18 color-greyish-brown-two">
											작성자
										</span>
										<span>{sponsorship?.name || '-'}</span>
									</div>
								</div>
								<div className="col-12 col-md-6">
									<div className="d-flex w-100">
										<span className="form_subTitle me-3 fontSize-18 color-greyish-brown-two">
											연락처
										</span>

										<span>{sponsorship?.phone || '-'}</span>
									</div>
								</div>
							</div>

							<div className="row align-items-center cmb-20">
								<div className="col-12 col-md-6">
									<div className="d-flex w-100">
										<span className="form_subTitle me-3 fontSize-18 color-greyish-brown-two">
											이메일
										</span>
										<span>{sponsorship?.email || '-'}</span>
									</div>
								</div>
							</div>

							<div className="row align-items-center cmb-20">
								<div className="col-12 col-md-6">
									<div className="d-flex w-100">
										<span className="form_subTitle me-3 fontSize-18 color-greyish-brown-two">
											스폰서쉽 문의대상
										</span>
										<span>{sponsorship?.proName || '-'}</span>
									</div>
								</div>
								<div className="col-12 col-md-6">
									<div className="d-flex w-100">
										<span className="form_subTitle me-3 fontSize-18 color-greyish-brown-two">
											스폰서쉽 유형
										</span>
										<span>
											{getMatchTypeOptions(
												sponsorshipTypes,
												sponsorship?.type,
											) || '-'}
										</span>
									</div>
								</div>
							</div>

							<div className="row align-items-center cmb-20">
								<div className="col-12 col-md-6">
									<div className="d-flex w-100">
										<span className="form_subTitle me-3 fontSize-18 color-greyish-brown-two">
											사업자 등록증
										</span>
										<span>{sponsorship?.business_license || '-'}</span>
									</div>
								</div>
							</div>

							<div className="border-bottom cmb-20" />

							<div className="row align-items-center cmb-20">
								<div className="col-12">
									<div className="d-flex w-100">
										<div className="form_subTitle me-3 fontSize-18 color-greyish-brown-two">
											제목
										</div>
										<div>{sponsorship?.title || '-'}</div>
									</div>
								</div>
							</div>

							<div className="row align-items-center cmb-20">
								<div className="col-12">
									<div className="d-flex w-100">
										<div className="form_subTitle me-3 fontSize-18 color-greyish-brown-two">
											문의 내용
										</div>
										<div style={{ whiteSpace: 'pre' }}>
											{sponsorship?.detail || '-'}
										</div>
									</div>
								</div>
							</div>

							{sponsorship?.is_answer && (
								<div className="row align-items-center cmb-20">
									<div className="col-12">
										<div className="d-flex w-100">
											<div className="form_subTitle me-3 fontSize-18 color-greyish-brown-two">
												답변 내용
											</div>
											<div
												dangerouslySetInnerHTML={{
													__html: sponsorship?.answer_detail || '-',
												}}
												style={{ whiteSpace: 'pre' }}
											/>
										</div>
									</div>
								</div>
							)}
						</Card.Body>
					</Card>

					<div className="row justify-content-end align-items-center cmb-20">
						{!sponsorship?.is_answer && (
							<React.Fragment>
								<div className="col-4 col-md-2 d-flex align-items-center cmb-10 mb_btn_size">
									<Link
										to={`/sponsorship/update/${sponsorshipId}`}
										className="btn btn-spruce pb-3 pt-3"
										style={{ width: '100%' }}>
										수정
									</Link>
								</div>

								<div className="col-4 col-md-2 d-flex align-items-center cmb-10 mb_btn_size">
									<button
										type="submit"
										className="btn btn-danger pb-3 pt-3"
										style={{ width: '100%' }}
										onClick={destroySponsorship}>
										삭제
									</button>
								</div>
							</React.Fragment>
						)}

						<div className="col-4 col-md-2 d-flex align-items-center cmb-10 mb_btn_size">
							<Link
								to={'/sponsorship'}
								className="btn btn-white-three pb-3 pt-3"
								style={{ width: '100%' }}>
								이전
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
