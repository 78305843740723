import React from 'react'
import { useNavigate } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'

export const ModalCompleteAcceptProposal = ({ modalVisible, onClose, ...props }) => {
	const navigate = useNavigate()
	return (
		<Modal
			id="completeAcceptProposal"
			show={modalVisible}
			size="md"
			backdrop="static"
			tabIndex={-1}
			centered
			aria-labelledby="completeAcceptProposal"
			aria-hidden="true"
			className="wrap register_page form_page re_form">
			<Modal.Header className="border-0">
				<button type="button" className="btn-close" aria-label="Close" onClick={onClose} />
			</Modal.Header>
			<Modal.Body>
				<div className="text-center">
					<div className="cmb-30">
						<p
							className="fontSize-28 font-L00 color-greyish-brown me-2"
							style={{ wordBreak: 'keep-all' }}>
							제안을 <span className="font-M00">수락</span>했습니다.
						</p>
					</div>

					<div className="cmb-30">
						<p
							className="fontSize-24 font-M00 color-greyish-brown me-2 cmb-10"
							style={{ wordBreak: 'keep-all' }}>
							스포이음 서비스를 이용해주셔서 감사합니다.
						</p>

						<p className="fontSize-20 font-L00 color-greyish-brown">
							결제 후 계약이 마무리 됩니다.
						</p>
					</div>

					<div className="fontSize-20 font-L00 color-greyish-brown cmb-30">
						<p>
							<span className="font-M00">'계약관리 > 계약 진행 상황'</span>에서
						</p>
						<p>자세한 정보를 확인할 수 있습니다.</p>
					</div>

					<div className="row">
						<div className="col-12">
							<button
								className="btn btn-dark pb-4 pt-4 w-100 cmb-10"
								onClick={() => navigate(`/mypages/contract-process`)}>
								<span className="fontSize-20">계약 진행 상황 바로가기</span>
							</button>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
}
