import cookie from 'js-cookie'

const DEFAULT_SESSION_COOKIE_NAME = 'session_id'
const DEFAULT_USER_SESSION_COOKIE_NAME = 'user'

const setCookie = (key, value, expires = 1) => {
	cookie.set(key, value, {
		expires,
		path: '/',
	})
}

const removeCookie = (key) => {
	cookie.remove(key, {
		expires: 1,
	})
}

const getCookie = (key) => {
	return cookie.get(key)
}

export {
	DEFAULT_SESSION_COOKIE_NAME,
	DEFAULT_USER_SESSION_COOKIE_NAME,
	getCookie,
	setCookie,
	removeCookie,
}
