import React, { useState, useEffect } from 'react'
import { MessageModal } from '../../components'
import { formattingPrice, getPaymentOption, isEmpty, SETTLE_PG, useAuthStatus } from '../../utils'

export const PaymentView = ({ API_ENDPOINT, CDN_ENDPOINT, ...props }) => {
	const [auth, onChangeAuth] = useAuthStatus()

	const [paymentTotal, setPaymentTotal] = useState('0')
	const [paymentMethod, setPaymentMethod] = useState(null)
	const [checkedAgree, setCheckedAgree] = useState(false)
	const [messageModalVisible, setMessageModalVisible] = useState(false)
	const [messageModalContent, setMessageModalContent] = useState('')

	useEffect(() => {
		fetchPaymentTotal()
	}, [auth])

	const fetchPaymentTotal = async () => {
		await fetch(`${API_ENDPOINT}/payment/tour/payment/${auth?.user?.id}`)
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) throw result

				const total = result?.reduce((total, item, index) => {
					total += item.amount

					return total
				}, 0)

				setPaymentTotal(total.toString())
			})
			.catch((errors) => setPaymentTotal('0'))
	}

	const onClickPayment = async () => {
		if (isEmpty(paymentMethod)) {
			handleMessageModalOpen('결제 수단을 선택해주세요.')
		} else if (!checkedAgree) {
			handleMessageModalOpen('결제 내용을 동의 하셔야합니다.')
		} else {
			// const hash = getPktHash(
			// 	{
			// 		mchtId: 'spoeum',
			// 		method: paymentMethod,
			// 		mchtTrdNo: current,
			// 		trdDt,
			// 		trdTm,
			// 		trdAmt: '2200000',
			// 	},
			// 	PAYMENT_HASH_KEY,
			// )
			//
			// const options = {
			// 	env: PAYMENT_URL,
			// 	mchtId: 'spoeum',
			// 	method: paymentMethod,
			// 	mchtName: '스포이음',
			// 	mchtEName: 'SPOEUM',
			// 	trdDt,
			// 	trdTm,
			// 	mchtTrdNo: current,
			// 	mchtCustNm: encryptAES(auth?.user?.name, PAYMENT_AES256_KEY),
			// 	email: encryptAES(auth?.user?.email, PAYMENT_AES256_KEY),
			// 	pmtPrdtNm: '서비스 수수료',
			// 	trdAmt: encryptAES('2200000', PAYMENT_AES256_KEY),
			// 	notiUrl: `${API_ENDPOINT}/payment/tour/noti`,
			// 	nextUrl: `${CDN_ENDPOINT}/payment/completed`,
			// 	cancUrl: `${CDN_ENDPOINT}/payment/fail`,
			// 	pktHash: hash,
			// 	ui: {
			// 		type: 'self',
			// 	},
			// }

			const options = getPaymentOption({
				userId: auth?.user?.id,
				name: auth?.user?.name,
				email: auth?.user?.email,
				method: paymentMethod,
				price: paymentTotal,
			})

			SETTLE_PG.pay(options, function (rsp) {
				console.log(rsp)
			})
		}
	}

	const onClickPaymentMethod = (event) => {
		setPaymentMethod(event.target.value)
	}

	const onCheckAgree = () => {
		setCheckedAgree(!checkedAgree)
	}

	const handleMessageModalClose = () => {
		setMessageModalContent('')
		setMessageModalVisible(false)
	}
	const handleMessageModalOpen = (message) => {
		setMessageModalContent(message)
		setMessageModalVisible(true)
	}

	return (
		<div className="wrap form_page">
			<div className="content_area">
				<div className="bgWrap">
					<div style={{ width: '662px' }}>
						<div className="container">
							<div className="fontSize-25 font-M00 color-greyish-brown cmb-30 text-center">
								<span>수수료 결제</span>
							</div>

							<div className="card">
								<div className="card-body">
									<p className="fontSize-18 font-M00 color-greyish-brown-two cmt-30 cmb-30">
										결제 금액 : {formattingPrice(paymentTotal)}원
									</p>
									<div className="border-bottom border-d8d8d8 w-100 cmb-30"></div>

									<div className="cmb-30">
										<label htmlFor="name" className="form-label">
											* 이름
										</label>
										<div className="d-flex">
											<div id="name" className="form-control underbarInput">
												{auth?.user?.name}
											</div>
										</div>
									</div>

									<div className="cmb-30">
										<label htmlFor="email" className="form-label">
											* 이메일
										</label>
										<div className="d-flex">
											<div id="email" className="form-control underbarInput">
												{auth?.user?.email}
											</div>
										</div>
									</div>

									<div className="row cmb-20 align-items-center">
										<label htmlFor="method" className="form-label cmb-10">
											* 결제 수단
										</label>
										<div className="col cmb-10">
											<input
												type="radio"
												id="method_card"
												className="btn-check"
												autoComplete="off"
												value="card"
												checked={paymentMethod === 'card'}
												onClick={onClickPaymentMethod}
											/>
											<label
												className="btn btn-light btn-whiteShadow"
												htmlFor="method_card">
												신용카드
											</label>
										</div>
										<div className="col cmb-10">
											<input
												type="radio"
												id="method_bank"
												className="btn-check"
												autoComplete="off"
												value="bank"
												checked={paymentMethod === 'bank'}
												onClick={onClickPaymentMethod}
											/>
											<label
												className="btn btn-light btn-whiteShadow"
												htmlFor="method_bank">
												계좌이체
											</label>
										</div>
									</div>

									<div className="row justify-content-between align-items-center cmb-30">
										<div className="col-auto">
											<label className="regInfoText" htmlFor="agree">
												결제 내용을 확인하고 동의 합니다.
											</label>
										</div>

										<div className="col-auto">
											<input
												type="checkbox"
												name="agree_tos"
												id="agree"
												className="d-none"
												checked={checkedAgree}
												onChange={onCheckAgree}
											/>
											<img
												src={`/images/${
													checkedAgree
														? 'register-pro-001-checkbox-bk.png'
														: 'register-pro-001-checkbox-gray.png'
												}`}
												alt="serviceAgreement"
												id="rg002_checkServiceAgreeImg"
												onClick={onCheckAgree}
											/>
										</div>
									</div>

									<button
										disabled={parseInt(paymentTotal) === 0}
										className="btn btn-dark nextPageButton cmb-30"
										onClick={onClickPayment}>
										결제하기
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<MessageModal
				modalVisible={messageModalVisible}
				content={messageModalContent}
				onClose={handleMessageModalClose}
			/>
		</div>
	)
}
