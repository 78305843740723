import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import Modal from 'react-bootstrap/Modal'
import { REG_PASSWORD } from '../../utils'
import { MessageModal } from '../MessageModal'

export const ModalPasswordChange = ({ modalVisible, changePassword, onClose, ...props }) => {
	const { register, getValues, setError, reset, handleSubmit } = useForm({
		mode: 'onBlur',
	})

	const onSubmit = async (data) => {
		const response = await changePassword(data)

		if (response.success === false) {
			setError('originPassword', {
				type: 'custom',
				message: '기존 비밀번호 오류',
			})
		} else {
			onClose()
			reset()
		}
	}

	const onInvalid = (errors) => {
		if (!!errors.originPassword) {
			handleMessageModalOpen(errors.originPassword.message)
		} else if (!!errors.password) {
			handleMessageModalOpen(errors.password.message)
		} else if (!!errors.checkedPassword) {
			handleMessageModalOpen(errors.checkedPassword.message)
		}
	}

	// modal
	const [messageModalVisible, setMessageModalVisible] = useState(false)
	const [messageModalContent, setMessageModalContent] = useState('')

	const handleMessageModalClose = () => {
		setMessageModalContent('')
		setMessageModalVisible(false)
	}
	const handleMessageModalOpen = (message) => {
		console.log(message)
		setMessageModalContent(message)
		setMessageModalVisible(true)
	}

	return (
		<React.Fragment>
			<Modal
				id="changepwModal"
				show={modalVisible}
				backdrop="static"
				tabIndex={-1}
				centered
				aria-labelledby="changepwModalLabel"
				aria-hidden="true">
				<Modal.Header>
					<p className="modal-title w-100 text-center font-bold" id="staticBackdropLabel">
						비밀번호 변경
					</p>
					<button
						type="button"
						className="btn-close"
						aria-label="Close"
						onClick={onClose}
					/>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleSubmit(onSubmit, onInvalid)}>
						<p className="form_title cmb-10">기존 비밀번호</p>
						<div className="d-flex justify-content-between align-items-end cmb-30 w-100">
							<div className="w-100 me-2">
								<div className="d-flex align-items-center inputText pe-0">
									<input
										type="text"
										name="prePw"
										className="form-control underbarInput"
										id="prePw"
										placeholder="기존 비밀번호를 입력해 주세요."
										{...register('originPassword', {
											required: '비밀번호는 6~16글자로 입력해 주세요.',
											pattern: {
												value: REG_PASSWORD,
												message: '비밀번호는 6~16글자로 입력해 주세요.',
											},
										})}
									/>
								</div>
							</div>
						</div>

						<p className="form_title cmb-10">새 비밀번호</p>
						<div className="d-flex justify-content-between align-items-end cmb-30 w-100">
							<div className="w-100 me-2">
								<div className="d-flex align-items-center inputText pe-0">
									<input
										type="text"
										className="form-control underbarInput"
										id="newPw1"
										placeholder="새 비밀번호를 입력해 주세요."
										{...register('password', {
											required: '비밀번호는 6~16글자로 입력해 주세요.',
											pattern: {
												value: REG_PASSWORD,
												message: '비밀번호는 6~16글자로 입력해 주세요.',
											},
											validate: (password) => {
												if (!getValues('originPassword')) return
												return (
													getValues('originPassword') === password ||
													'비밀번호를 다시 입력해 주세요.'
												)
											},
										})}
									/>
								</div>
							</div>
						</div>

						<p className="form_title cmb-10">새 비밀번호 확인</p>
						<div className="d-flex justify-content-between align-items-end cmb-30 w-100">
							<div className="w-100 me-2">
								<div className="d-flex align-items-center inputText pe-0">
									<input
										type="text"
										name="newPw2"
										className="form-control underbarInput"
										id="newPw2"
										placeholder="새 비밀번호를 한번 더 입력해 주세요."
										{...register('checkedPassword', {
											required: '비밀번호는 6~16글자로 입력해 주세요.',
											pattern: {
												value: REG_PASSWORD,
												message: '비밀번호는 6~16글자로 입력해 주세요.',
											},
											validate: (checkedPassword) => {
												if (!getValues('password')) return
												return (
													getValues('password') === checkedPassword ||
													'비밀번호가 일치하지 않습니다.'
												)
											},
											onBlur: (event) =>
												getValues('password') !== event.target.value &&
												handleMessageModalOpen(
													'비밀번호가 일치하지 않습니다.',
												),
										})}
									/>
								</div>
							</div>
						</div>

						<button type="submit" className="btn btn-dark nextPageButton">
							저장하기
						</button>
					</form>
				</Modal.Body>
			</Modal>

			<MessageModal
				modalVisible={messageModalVisible}
				content={messageModalContent}
				onClose={handleMessageModalClose}
			/>
		</React.Fragment>
	)
}
