import React from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import { contractTypes, formattingPrice, getMatchTypeOptions } from '../../utils'
import dayjs from 'dayjs'

export const MatchingOfferList = ({ offers, ...props }) => {
	return (
		<React.Fragment>
			<div id="matching-list" className="row align-items-center cmb-10">
				<div className="col-12 col-md">
					<p className="neoM00_25_greyish-brown">구인공고 리스트</p>
				</div>
			</div>

			<Card className="cmb-30">
				<Card.Body>
					{offers?.length > 0 ? (
						offers?.map((offer) => (
							<Link to={`/recruits/${offer?.offer?.id}`} key={offer?.offer?.id}>
								<div className="row cmb-10">
									<div className="col-12 col-md-8 cmb-10">
										<p className="fontSize-14 font-R00 color-greyish-two">
											{dayjs(offer?.offer?.created_at).format('YYYY-MM-DD')}
										</p>
										<p className="fontSize-14 font-R00 color-greyish-two">
											{offer?.user?.name}
										</p>
										<p className="fontSize-16 font-M00">
											{offer?.offer?.post_title}
										</p>
										{/*<p className="fontSize-14 font-R00 color-greyish-two">*/}
										{/*	메뉴톡 KPGA 오픈*/}
										{/*</p>*/}
									</div>
									<div className="col-12 col-md-4 d-flex justify-content-end align-items-center cmb-10 cumbjc">
										<p
											className="fontSize-16 font-M00 color-greyish-two"
											style={{ textAlign: 'right' }}>
											{getMatchTypeOptions(
												contractTypes,
												offer?.offer?.contract_type,
											)}
											<br />
											<span className="fontSize-16 font-SB00 color-warm-pink">
												{['single', 'long_term'].includes(
													offer?.offer?.contract_type,
												) && (
													<React.Fragment>
														컷통과{' '}
														{formattingPrice(
															offer?.offer?.weekly_salary,
														)}{' '}
														원<br />
														컷탈락{' '}
														{formattingPrice(
															offer?.offer?.weekly_salary_f,
														)}{' '}
														원
													</React.Fragment>
												)}

												{['year_first_half', 'year_second_half'].includes(
													offer?.offer?.contract_type,
												) &&
													`${formattingPrice(
														offer?.offer?.weekly_salary,
													)} 원`}
											</span>
										</p>
									</div>

									<div className="w-100 border-bottom"></div>
								</div>
							</Link>
						))
					) : (
						<div className="text-center">공고가 없습니다.</div>
					)}
				</Card.Body>
			</Card>
		</React.Fragment>
	)
}
