import React from 'react'
import { Carousel } from 'react-bootstrap'

export const MatchingListAd = ({ ...props }) => {
	return (
		<React.Fragment>
			<Carousel controls={false} indicators={false} className="slide cmb-50 onlyPc">
				<Carousel.Item>
					<img src={`/images/ti.png`} className="d-block w-100" alt="img01" />
				</Carousel.Item>
				<Carousel.Item>
					<img
						src={`/images/ti02.jpg`}
						className="d-block w-100"
						style={{ height: '162px' }}
						alt="img02"
					/>
				</Carousel.Item>
			</Carousel>

			<Carousel controls={false} indicators={false} className="slide cmb-30 onlyMobile">
				<Carousel.Item>
					<img
						src={`/images/ti.png`}
						className="d-block w-100"
						style={{ height: '110px' }}
						alt="img01"
					/>
				</Carousel.Item>
				<Carousel.Item>
					<img
						src={`/images/ti02.jpg`}
						className="d-block w-100"
						style={{ height: '110px' }}
						alt="img02"
					/>
				</Carousel.Item>
			</Carousel>
		</React.Fragment>
	)
}
