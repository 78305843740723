import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {
	useAuthStatus,
	REG_EMAIL,
	REG_PASSWORD,
	DEFAULT_USER_SESSION_COOKIE_NAME,
	setCookie,
} from '../../utils'
import { MessageModal } from '../../components'

export const LoginView = ({ API_ENDPOINT, ...props }) => {
	const [auth, onChangeAuth] = useAuthStatus()

	const navigate = useNavigate()
	const location = useLocation()

	const { register, handleSubmit } = useForm({ mode: 'onBlur' })

	if (auth.isAuthentication) {
		navigate(location.state)
	}

	const onSubmit = async (data) => {
		const input = { ...data }

		// TODO: after login action
		await fetch(`${API_ENDPOINT}/auth/signin`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(input),
		})
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) {
					throw new Error('로그인 실패')
				} else {
					onChangeAuth(JSON.stringify(result.user))
					navigate('/matching')
				}
			})
			.catch((errors) => {
				// console.log(errors)
				handleMessageModalOpen('아이디와 패스워드를 확인해 주세요.')
			})
	}

	// modal
	const [messageModalVisible, setMessageModalVisible] = useState(false)
	const [messageModalContent, setMessageModalContent] = useState('')

	const handleMessageModalClose = () => {
		setMessageModalContent('')
		setMessageModalVisible(false)
	}
	const handleMessageModalOpen = (message) => {
		setMessageModalContent(message)
		setMessageModalVisible(true)
	}

	return (
		<div className="wrap login_page">
			<div className="content_area">
				<div className="bgWrap">
					<div className="container">
						<div className="loginLogoBox">
							<img src={`/images/splash_001_logo.png`} alt="SPOEUM" />
						</div>
						<form onSubmit={handleSubmit(onSubmit)}>
							<input type="hidden" name="myToken" id="myToken" />
							<div className="row justify-content-center">
								<div className="col-12">
									<label htmlFor="login_id" className="form-label">
										아이디
									</label>
									<input
										type="email"
										className="form-control underbarInput"
										id="login_id"
										placeholder="아이디를 입력해 주세요."
										{...register('email', {
											required: true,
											pattern: {
												value: REG_EMAIL,
											},
										})}
									/>
								</div>
								<div className="col-12">
									<label htmlFor="login_password" className="form-label">
										비밀번호
									</label>
									<input
										type="password"
										className="form-control underbarInput"
										id="login_password"
										placeholder="비밀번호를 입력해 주세요."
										{...register('password', {
											required: true,
											pattern: {
												value: REG_PASSWORD,
											},
										})}
									/>
								</div>
							</div>
							<div className="d-flex justify-content-center">
								{/*<a href="./id_find_001.php" className="findLink"><u>아이디 찾기</u></a>*/}
								<Link to={'/find-id'} as={'/find-id'} className="findLink">
									<u>아이디 / 비밀번호 찾기</u>
								</Link>
							</div>
							<button
								type="submit"
								className="btn btn-dark loginButton"
								id="loginBtn">
								스포이음 로그인
							</button>
							<div className="d-flex justify-content-center">
								<Link to={'/register'} as={'/register'} className="makeAccountLink">
									<u>계정이 없으신가요?</u>
								</Link>
							</div>
						</form>
					</div>
				</div>
			</div>

			<MessageModal
				modalVisible={messageModalVisible}
				content={messageModalContent}
				onClose={handleMessageModalClose}
			/>
		</div>
	)
}
