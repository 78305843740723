import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStateMachine } from 'little-state-machine'
import { updateFormInput } from '../../utils'
import { SuggestionAnnualTypeSelectForm } from '../../components'

export const ProposalAnnualTypeSelectView = ({ ...props }) => {
	const navigate = useNavigate()
	const { actions, state } = useStateMachine({ updateFormInput })

	const onSubmit = (data) => {
		const payload = {
			contractInput: { ...state.contractInput, ...data },
		}

		actions.updateFormInput(payload)
		navigate('/proposal/create/annual-info')
	}

	return (
		<div className="wrap form_page">
			<div className="content_area">
				<div className="bgWrap">
					<SuggestionAnnualTypeSelectForm onSubmit={onSubmit} />
				</div>
			</div>
		</div>
	)
}
