import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'

export const ModalContractCancelModal = ({
	modalVisible,
	userType,
	contractStatus,
	contractId,
	cancelRequestContract,
	cancelContract,
	onClose,
	...props
}) => {
	const [updatedStatus, setUpdatedStatus] = useState('')

	useEffect(() => {
		if (modalVisible) {
			if (contractStatus === 'PROCEEDING') setUpdatedStatus('CANCEL_REQUEST')
			else if (contractStatus === 'CANCEL_REQUEST') setUpdatedStatus('CANCEL')
		}
	}, [modalVisible])

	const onCancelContract = () => {
		if (contractStatus === 'PROCEEDING') cancelRequestContract(contractId, updatedStatus)
		else if (contractStatus === 'CANCEL_REQUEST') cancelContract(contractId, updatedStatus)

		onClose()
	}

	return (
		<Modal
			id="challengeCancelModal"
			backdrop="static"
			centered={true}
			tabIndex="-1"
			aria-labelledby="refusalModalLabel"
			aria-hidden="true"
			className="modal-dialog-centered"
			show={modalVisible}
			onHide={onClose}>
			<Modal.Header className="border-0">
				{contractStatus === 'PROCEEDING' && (
					<p
						id="staticBackdropLabel"
						className="modal-title w-100 fontSize-25 color-greyish-brown font-M00 text-center">
						계약 취소 하기
					</p>
				)}

				{contractStatus === 'CANCEL_REQUEST' && (
					<p
						id="staticBackdropLabel"
						className="modal-title w-100 fontSize-25 color-greyish-brown font-M00 text-center">
						계약 취소 요청 확인
					</p>
				)}
				<button type="button" className="btn-close" onClick={onClose} />
			</Modal.Header>

			<Modal.Body className="text-center">
				<div>
					{contractStatus !== 'CANCEL_REQUEST' && (
						<p className="fontSize-20 color-greyish-brown font-L00  cmb-10">
							계약 취소를 원할 경우 계약된 상대방의 동의가 필요합니다. 또한 남은 계약
							대회에 대한 위약금이 청구 될 수 있습니다.
						</p>
					)}
					<p className="fontSize-20 color-greyish-brown font-L00  cmb-30">
						계약 취소를 진행 하시겠습니까?
					</p>
					{/*<p className="fontSize-20 color-greyish-brown font-L00  cmb-30">*/}
					{/*	(취소 위약금: nnn,nnn 원)*/}
					{/*</p>*/}
				</div>

				{/*{['tourCaddie', 'houseCaddie'].includes(userType) && (*/}
				{/*	<div>*/}
				{/*		<p className="fontSize-20 color-greyish-brown font-L00  cmb-10">*/}
				{/*			계약을 취소하시려면 계약된 프로의 확인이 필요합니다.*/}
				{/*		</p>*/}
				{/*		<p className="fontSize-20 color-greyish-brown font-L00  cmb-30">*/}
				{/*			계약 파기를 진행 하시겠습니까?*/}
				{/*		</p>*/}
				{/*	</div>*/}
				{/*)}*/}

				{/*<p className="fontSize-20 color-greyish-brown font-L00">*/}
				{/*    계약을 취소할 경우, 약관의 취소/환불 정책에 의거하여*/}
				{/*</p>*/}
				{/*<p className="fontSize-20 color-greyish-brown font-L00">*/}
				{/*    일정 수준의 위약금이 발생할 수 있으며 상대방은 계약이 제외된*/}
				{/*</p>*/}
				{/*<p className="fontSize-20 color-greyish-brown font-L00">*/}
				{/*    기간 동안 계약자 외의 활동을 할 수 있습니다.*/}
				{/*</p>*/}
				{/*<p className="fontSize-20 color-greyish-brown font-L00">*/}
				{/*    원활한 진행을 위해 취소 전 계약 상대방과*/}
				{/*</p>*/}
				{/*<p className="fontSize-20 color-greyish-brown font-L00">*/}
				{/*    충분한 논의를 권장하며, 계약 파기 및 환불에 대한 부분은*/}
				{/*</p>*/}
				{/*<p className="fontSize-20 color-greyish-brown font-L00 cmb-30">*/}
				{/*    <Link to="/terms">*/}
				{/*		<span className="fontSize-20 color-greyish-brown font-M00">*/}
				{/*			<u>계약파기 및 환불 관련 안내</u>*/}
				{/*		</span>*/}
				{/*    </Link>*/}
				{/*    를 참고 바랍니다.*/}
				{/*</p>*/}

				<button
					type="button"
					id="canclebtn"
					className="btn btn-danger pb-2 pt-2 w-100 cmb-20"
					onClick={onCancelContract}>
					예, 계약을 취소 합니다.
				</button>
				<button
					type="button"
					className="btn btn-dark pb-2 pt-2 w-100 registerNextButton"
					onClick={onClose}>
					아니오, 취소하지 않습니다
				</button>
			</Modal.Body>
		</Modal>
	)
}
