import React, { useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import { Link, useSearchParams } from 'react-router-dom'
import { ProposalCard } from '../../../components'
import { contractTypes, useAuthStatus } from '../../../utils'

export const ProposalListView = ({ API_ENDPOINT, PROFILE_BUCKET, ...props }) => {
	const [auth, onChangeAuth] = useAuthStatus()

	const [searchParams] = useSearchParams()

	const [sentProposals, setSentProposals] = useState([])
	const [receivedProposal, setReceivedProposal] = useState([])
	const [tabKey, setTabKey] = useState(searchParams.get('tab') || 'RECEIVED')

	useEffect(() => {
		fetchProposals()
	}, [auth])

	const fetchProposals = async (contractType = 'ALL') => {
		await fetch(`${API_ENDPOINT}/contract/contract/${auth?.user?.id}/PRE`)
			.then(async (response) => {
				const result = await response.json()

				const proposals =
					contractType === 'ALL'
						? result
						: result?.filter((item) => item?.contract_type === contractType)

				const { sentProposals, receivedProposal } = proposals?.reduce(
					({ sentProposals, receivedProposal }, item, index) => {
						if (item?.user?.id === auth?.user?.id) {
							sentProposals.push(item)
						} else if (item?.targetUser?.id === auth?.user?.id) {
							receivedProposal.push(item)
						}

						return { sentProposals, receivedProposal }
					},
					{
						sentProposals: [],
						receivedProposal: [],
					},
				)

				setSentProposals(sentProposals)
				setReceivedProposal(receivedProposal)
			})
			.catch((errors) => {
				console.log(errors)
			})
	}

	const handleTabSelect = async (key) => {
		await fetchProposals()

		setTabKey(key)
	}

	const handleTypeSelect = async (event) => {
		await fetchProposals(event.target.value)
	}

	return (
		<div className="wrap pro_form form_page">
			<div className="content_area">
				<div className="container mainWrap">
					<div className="row cmb-30">
						<div className="col-auto col-md-6">
							<div className="col-auto cmb-10 contract_topTitleText">
								내 제안/회신 내역
							</div>
							<div className="col-auto contract_topSubTitleText">
								받은 제안,보낸 제안을 확인해보세요
							</div>
						</div>
						<div className="col-6 col-md-3 d-flex align-items-center cmb-10 mb_btn_size">
							<Link
								to={'/matching'}
								className="btn btn-spruce pb-4 pt-4"
								style={{ width: '100%' }}>
								인재 검색 바로가기 &gt;
							</Link>
						</div>

						<div className="col-6 col-md-3 d-flex align-items-center cmb-10 mb_btn_size">
							<Link
								to={'/recruits'}
								className="btn btn-white-three pb-4 pt-4"
								style={{ width: '100%' }}>
								구인공고 바로가기 &gt;
							</Link>
						</div>
					</div>

					<div className="col-auto cmb-30">
						<select
							className="form-select rounded-pill"
							style={{ width: '7rem', height: '45px' }}
							aria-label="Default select example"
							defaultValue="ALL"
							onChange={handleTypeSelect}>
							<option value="ALL">전체</option>
							{contractTypes?.map((item) => (
								<option value={item?.value}>{item?.label}</option>
							))}
						</select>
					</div>

					<Tab.Container defaultActiveKey={tabKey} onSelect={handleTabSelect}>
						<Nav className="nav-tabs nav-tabs-camel" id="myTab" role="tablist">
							<Nav.Item className="w-50" role="presentation">
								<Nav.Link eventKey={'RECEIVED'} className="cumbw-100 tab-camel">
									받은 제안서 보기
								</Nav.Link>
							</Nav.Item>

							<Nav.Item className="w-50" role="presentation">
								<Nav.Link eventKey={'SENT'} className="cumbw-100 tab-camel">
									보낸 제안서 보기
								</Nav.Link>
							</Nav.Item>
						</Nav>

						<Tab.Content className="tab-content" id="myTabContent">
							<Tab.Pane eventKey={'RECEIVED'} className="py-3">
								{receivedProposal?.length > 0 ? (
									receivedProposal?.map((proposal) => (
										<ProposalCard
											PROFILE_BUCKET={PROFILE_BUCKET}
											tabKey={tabKey}
											proposal={proposal}
										/>
									))
								) : (
									<div className="card">
										<div className="card-body">받은 제안이 없습니다.</div>
									</div>
								)}
							</Tab.Pane>

							<Tab.Pane eventKey={'SENT'} className="py-3">
								{sentProposals?.length > 0 ? (
									sentProposals?.map((proposal) => (
										<ProposalCard
											PROFILE_BUCKET={PROFILE_BUCKET}
											tabKey={tabKey}
											proposal={proposal}
										/>
									))
								) : (
									<div className="card">
										<div className="card-body">보낸 제안이 없습니다.</div>
									</div>
								)}
							</Tab.Pane>
						</Tab.Content>
					</Tab.Container>
				</div>
			</div>
		</div>
	)
}
