import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

export const FindIdView = ({ API_ENDPOINT, ...props }) => {
	const navigate = useNavigate()
	const { register, handleSubmit } = useForm({ mode: 'onBlur' })

	const [certificationVisible, setIsCertificationVisible] = useState(false)

	const onSubmit = async (data) => {
		console.log(data)

		// TODO: fine id
		await fetch(`${API_ENDPOINT}/auth/findmyid`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then(async (response) => {
				const result = await response.json()

				console.log(result)
			})
			.catch((errors) => {})
	}

	const onClickCertification = () => {
		setIsCertificationVisible(true)
	}

	return (
		<div className="wrap find_info form_page">
			<div className="content_area">
				<div className="bgWrap">
					<div className="container">
						<form onSubmit={handleSubmit(onSubmit)}>
							<p className="form_topTitle">아이디 / 비밀번호 찾기</p>
							<div className="card">
								<div className="card-body">
									<input type="hidden" name="userType" value="" />

									<div className="row">
										<div className="col cmt-30">
											<div className="findId_inputBox cmb-30">
												<label
													htmlFor="rg002_name"
													className="form-label cmb-10">
													이름
												</label>

												<input
													id="rg002_name"
													type="text"
													className="form-control underbarInput"
													placeholder="이름을 입력해 주세요."
													{...register('name', {
														required: true,
													})}
												/>
											</div>
										</div>
									</div>

									<div className="findId_inputBox cmb-20">
										<label
											htmlFor="findId_phoneNumber"
											className="form-label cmb-10">
											전화번호
										</label>
										<div className="d-flex justify-content-between cmb-10 w-100">
											<div className="w-100 me-2">
												<div className="d-flex align-items-start pe-0">
													<input type="hidden" id="cNum2" />
													<input
														id="phoneNumber"
														type="tel"
														className="form-control underbarInput px-2"
														placeholder="휴대폰 번호를 입력해 주세요."
														{...register('phone', {
															required: true,
														})}
													/>
												</div>
											</div>
											<button
												id="sendCerificationNumber"
												type="button"
												name="sendCerificationNumber"
												className="btn btn-outline-dark findId_certButton text-nowrap"
												onClick={onClickCertification}>
												인증번호 전송
											</button>
										</div>

										{certificationVisible && (
											<React.Fragment>
												<div className="d-flex justify-content-between cmb-10 w-100">
													<div className="w-100 me-2">
														<div className="d-flex align-items-start pe-0">
															<input
																type="text"
																name="certificationNum"
																className="form-control underbarInput px-2"
																id="certification"
																placeholder="인증번호를 입력해 주세요."
																required=""
															/>
														</div>
													</div>
													<button
														id="checkCertification"
														type="button"
														name="checkCertification"
														className="btn btn-outline-dark findId_certButton text-nowrap">
														인증번호 확인
													</button>
												</div>

												<div className="d-flex mt-2 justify-content-between certificationCheckBox">
													<div className="certificationTime col-auto">
														3:00
													</div>
													<div className="certificationText col-auto">
														<span className="findIdTextFix">
															<img
																src={`/images/id_find_001_exicn01.png`}
																alt="img"
															/>
															인증번호를 다시 입력해 주세요.
														</span>
													</div>
												</div>
											</React.Fragment>
										)}
									</div>

									<div>
										<button
											type="submit"
											name="changepwBtn"
											className="btn btn-dark findAccountButton"
											id="changePw_nextButton"
											style={{ marginBottom: '2.19rem' }}>
											본인 확인
										</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}
