import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStateMachine } from 'little-state-machine'
import { TourSuggestionInfoForm } from '../../components'
import {
	FETCH_HEADER_OPTIONS,
	parsePriceToNumber,
	parseStringToBool,
	useAuthStatus,
} from '../../utils'

export const TourProposalInfoFormView = ({ API_ENDPOINT, ...props }) => {
	const [auth, onChangeAuth] = useAuthStatus()

	const [serviceFeePercentages, setServiceFeePercentages] = useState([])

	useEffect(() => {
		Promise.all([fetchServiceFeePercentages()]).then(([percentages]) => {
			setServiceFeePercentages(percentages)
		})
	}, [auth])

	const createContract = async ({ incentives, ...input }) => {
		await fetch(`${API_ENDPOINT}/contract/contract`, {
			method: 'POST',
			headers: FETCH_HEADER_OPTIONS,
			body: JSON.stringify({ ...input, isOffer: false }),
		})
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) throw result

				if (incentives?.length > 0) {
					const incentivesInput = incentives?.map((item) => {
						return {
							...item,
							contractId: result?.id,
							contractGroupId: result?.contract_group_id,
						}
					})

					await createContractIncentives(incentivesInput)
				}
			})
			.catch((error) => {
				// TODO : 에러메세지 관련 안내창 출력
				console.log(error)
			})
	}

	const createContractIncentives = async (input) => {
		await fetch(`${API_ENDPOINT}/contract/contract/incentive`, {
			method: 'POST',
			headers: FETCH_HEADER_OPTIONS,
			body: JSON.stringify(input),
		})
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) throw result
			})
			.catch((error) => {
				// TODO : 에러메세지 관련 안내창 출력
				console.log(error)
			})
	}

	const fetchServiceFeePercentages = async () => {
		return await fetch(`${API_ENDPOINT}/admin/payment-manage/pro/incentive`)
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) throw result

				return result
			})
			.catch((errors) => {
				console.log(errors)
			})
	}

	return (
		<div className="wrap register_page form_page">
			<div className="content_area">
				<div className="bgWrap">
					<TourSuggestionInfoForm
						serviceFeePercentages={serviceFeePercentages}
						createContract={createContract}
					/>
				</div>
			</div>
		</div>
	)
}
