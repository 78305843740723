import React, { useState, useEffect } from 'react'
import { Collapse } from '../../components'
import { useAuthStatus } from '../../utils'

export const NoticeListView = ({ API_ENDPOINT, ...props }) => {
	const [auth, onChangeAuthStatus] = useAuthStatus()

	const [noticeList, setNoticeList] = useState([])
	const [searchKeyword, setSearchKeyword] = useState('')

	useEffect(() => {
		fetchNotices()
	}, [])

	const fetchNotices = async () =>
		await fetch(`${API_ENDPOINT}/boards/notice`).then(async (response) => {
			const result = await response.json()
			setNoticeList(result)
		})

	const onChangeSearchKeyword = (event) => {
		setSearchKeyword(event.target.value)
	}

	const searchNotice = async (event) => {
		await fetch(`${API_ENDPOINT}/boards/notice/search?keyword=${searchKeyword}`).then(
			async (response) => {
				const result = await response.json()

				if (result.success) {
					setNoticeList(result.data || [])
				}
			},
		)
	}

	return (
		<div className="wrap notice_page">
			<div className="content_area">
				<div className="container mainWrap">
					<div className="cmb-30" style={{ fontSize: '3rem' }}>
						공지사항
					</div>

					<div className="row cmb-30">
						<div className="col-12">
							<div className="card py-0">
								<div className="card-body" style={{ padding: '1rem' }}>
									<div className="row align-items-center">
										<div className="col">
											<input
												type="text"
												name=""
												id="pm001_input_searchcaddie"
												className="border-0 p-0 w-100"
												placeholder="검색어를 입력해 주세요."
												onChange={onChangeSearchKeyword}
											/>
										</div>
										<div className="col-auto" onClick={searchNotice}>
											<i
												className="fas fa-search color-white-three"
												aria-hidden="true"></i>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<p className="fontSize-16 color-greyish-two font-M00 cmb-30">
						공지사항 <span id="noticecnt">{noticeList?.length || 0}</span>개
					</p>

					<Collapse isAuthentication={auth.isAuthentication} dataSource={noticeList} />

					<div className="pboardcount" id="pboardcount">
						<a href="javascript: void(0)" className="selected">
							1
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}
