import React, { useEffect, useState } from 'react'
import { contractTypes, formattingPrice, getMatchTypeOptions } from '../../utils'

export const SingleContractDocs = ({ contract, contractIncentives, serviceConfig, ...props }) => {
	return (
		<React.Fragment>
			<p className="mb-5">
				<span>{contract?.pro?.name}</span>(계약자 값)(이하 '선수'라 한다)와/
				<span>{contract?.caddie?.name}</span>(이하 '캐디'라 한다)은/는 선수가 캐디에게
				주식회사 스포이음(이하 '회사'라 한다)이 운영하는 플랫폼을 통하여 위탁한 캐디
				업무(이하 ‘본건 업무’라 한다)를 이행함에 이어 아래와 같이 계약(이하 ‘본 계약’이라
				한다)을 체결한다.
			</p>

			<p className="title">제 1조 (계약의 목적)</p>
			<p className="ms-3 mb-5">
				본 계약은 선수가 캐디에게 의뢰한 본건 업무를 제공하는 것과 관련하여 상호 간에 권리 ·
				의무, 책임사항 및 기타 필요한 사항을 규정함으로써 이를 기준으로 상호 신의성실의
				원칙에 따라 본 계약을 이행함에 목적이 있다.
			</p>

			<p className="title">제 2조 (본건 업무의 내용)</p>
			<p className="ms-3 mb-3">본 계약에서 본건 업무의 구체적인 내용은 아래와 같다.</p>
			<ol>
				<li>
					<b>본건 업무의 대상:</b> {contract?.tour?.name} 대회(이하 ‘대회’라 한다.
					장기계약, 연계약의 경우 2개 이상의 대회가 포함될 수 있으며, 제3조 본건 업무의
					제공기간 내에 개최되는 대회가 모두 포함된다). 원칙적으로 공식연습일(본 대회가
					시작되기 전에 해당 대회에 참가하는 선수들이 대회가 열리는 골프장 코스의 라운드를
					하는 날을 의미)과 예비일(천재지변 등의 불가항력 사유로 인하여 대회가 축소될 경우
					추가적으로 시합이 이루어지는 날을 의미)이 대회의 범위에 포함된다. 또한 대회가
					공식대회로 인정되지 않고 축소되어 진행되는 경우도 동일하다.
				</li>
				<li>
					<b>본건 업무의 내용:</b> 대회에서 선수의 전속 개인 캐디로서 수행하는 업무(선수의
					경기를 보조하면서 골프채가 들어 있는 골프가방 운반, 선수의 요구에 응하여
					골프채를 꺼내어 전달하고, 선수가 골프채를 휘두를 때 발생하는 잔디 파손 부분을
					손질, 코스매니지먼트 등 선수가 대회에 참가하여 치루는 경기를 보조하는 업무를
					총괄하여 의미한다.) 다만, 구체적인 업무의 범위는 선수와 캐디 간에 협의로 조정할
					수 있다.
				</li>
				<li className="mb-5">
					<b>본건 업무의 이행장소:</b> 대회가 주최되는 장소(다만, 상황에 따라 변경될 수
					있으며, 선수는 대회가 주최되는 장소가 변경된 경우에는 해당 사실을 알게 된 즉시
					캐디에게 통지한다. 만일 변경된 장소가 지역구의 변동으로 인하여 캐디의 본건
					업무의 이행이 어려운 경우, 캐디는 추가비용을 요청하거나 해당 대회에서 제공해야
					하는 본건 업무의 이행을 거절할 수 있다. 후자의 경우, 해당 대회에 한해서 효력이
					없다.)
				</li>
			</ol>

			<p className="title">제 3조 (본건 업무의 제공기간)</p>
			<p className="ms-3 mb-3">
				<b>
					&lt; {getMatchTypeOptions(contractTypes, contract?.contract_type)} - 단일대회
					&gt;
				</b>
			</p>
			<p className="ms-3 mb-3">
				캐디는 본 계약에 따라 제2조에 명시된 대회의 공식연습일로부터 대회가 종료할 때까지
				본건 업무를 선수에게 제공해야 한다. 단, 캐디는 선수와의 계약 기간 중에 선수가 시합에
				참가를 하지 않거나, 해당 협회 시합이 없는 경우에는 타 선수의 캐디 업무를 할 수 있다.
			</p>
			<p className="ms-3 mb-5">
				<b>대회명:</b> {contract?.tour?.name}
			</p>

			<p className="title">제 4조 (본건 업무에 대한 비용 지급)</p>
			<ol>
				<li>
					선수는 본 계약에 따라 아래와 같이 대회 별 용역비용을 캐디에게 지급해야 한다.
				</li>
				<div className="ms-5 mb-3 d-flex">
					<p>
						<b>용역비용:</b> {formattingPrice(contract?.weekly_salary)} 원 (플랫폼 상의
						계약완료 된 세금처리 방식을 따른다)
					</p>
				</div>
				<li>
					선수는 본 계약에 따라 본건업무에 대한 대가로 캐디에게 용역비용과 인센티브를
					아래와 같이 정해진 시기에 ‘회사’가 지정하는 계좌로 지급해야 한다.
				</li>
				<table className="table table-bordered liTable ms-3">
					<tbody>
						<tr>
							<td rowSpan="2">
								<b>단기계약</b>
							</td>
							<td>
								<b>용역비용</b>
							</td>
							<td rowSpan="2">대회 종료일로 부터 10일 이내</td>
						</tr>
						<tr>
							<td>
								<b>인센티브</b>
							</td>
						</tr>
						<tr>
							<td rowSpan="2">
								<b>장기계약</b>
							</td>
							<td>
								<b>용역비용</b>
							</td>
							<td rowSpan="2">대회 종료일로 부터 10일 이내</td>
						</tr>
						<tr>
							<td>
								<b>인센티브</b>
							</td>
						</tr>
						<tr>
							<td rowSpan="2">
								<b>년 계약</b>
							</td>
							<td>
								<b>용역비용</b>
							</td>
							<td rowSpan="2">계약 완료 후 10일 이내</td>
						</tr>
						<tr>
							<td>
								<b>인센티브</b>
							</td>
						</tr>
					</tbody>
				</table>
				<li>제 1항에서 ‘회사’가 지정하는 계좌에 대한 정보는 아래와 같다.</li>
				<p className="ms-5 mb-3">
					<b>계좌정보:</b> (하나은행 149-910020-89604, 예금주: ㈜스포이음 )
				</p>
				<li>
					본 계약에 따라 선수가 캐디에게 지급하는 인센티브는 대회의 공식 상금에 대하여
					아래 표를 기준으로 한 비율에 해당하는 금액을 의미한다. 여기서 인센티브 책정의
					기준이 되는 공식 상금의 액수는 세금처리 이후의 금액을 기준으로 하는 것을
					원칙으로 한다. 다만, 당사자 간의 협의에 따라 세금처리 이전의 금액을 기준으로 할
					수 있다. 공식상금은 각 협회(KPGA, KLPGA 등)에서 공시되는 공식 상금을 의미한다.
					플랫폼 상에서 계약 완료된 상금 표기법을 따른다.(상금 표시액 or 상금 실수령액)
				</li>
				<li>상금 인센티브 요율</li>
				<table className="table table-bordered liTable ms-3">
					<thead>
						<tr>
							{contractIncentives?.map((item) => {
								if (item?.endRank === 1) {
									return <th>우승(1위)</th>
								}

								return (
									<th>
										{item?.startRank}위 ~{' '}
										{item?.endRank === 0 ? 'CUT' : `${item?.endRank}위`}
									</th>
								)
							})}
						</tr>
					</thead>
					<tbody>
						<tr>
							{contractIncentives?.map((item) => (
								<td>{item?.percentage}%</td>
							))}
						</tr>
					</tbody>
				</table>
				<li>
					선수는 회사에 대하여 회사와 약정한 아래의 표를 기준으로 한 매칭 수수료 및
					수수료를 지급하여야 한다. 선수가 회사에 매칭 수수료 또는 수수료를 지급하지 않은
					경우에는 본 계약을 체결한 캐디는 다른 선수와 용역계약을 체결할 수 있고, 캐디의
					해지 통지(전자적 방법에 의해서도 가능)로 본 계약은 종료된다. 이로 인하여
					발생하는 모든 불이익은 선수가 부담한다.
				</li>
				{/* TODO */}
				<li>매칭 수수료</li>
				<table className="table table-bordered liTable ms-3">
					<thead>
						<tr>
							<th>구분</th>
							<th>매칭 수수료</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<b>단기계약</b>
							</td>
							<td>
								용역비용의 {serviceConfig?.proServiceFees[0]?.percentage || '00'}%
							</td>
						</tr>
						<tr>
							<td>
								<b>장기계약</b>
							</td>
							<td>용역비용의 00%</td>
						</tr>
						<tr>
							<td>
								<b>년 계약</b>
							</td>
							<td>용역비용의 00%</td>
						</tr>
					</tbody>
				</table>
				<li>
					캐디는 선수가 제4항의 내용을 위반한 경우, 본 계약을 해지로 종료하는 것에
					동의하며, 회사의 매칭 업무 운영에 대하여 적극적으로 협력하는 것에 동의한다.
				</li>
				<li>
					본 조에 따른 비용의 지급일자, 방법 등 지급과 관련된 내용은 상호 협의하여 변경할
					수 있다.
				</li>
				<li className="mb-5">
					본 조에 명시한 내용을 포함하여 관련 내용은 회사의 서비스 이용약관에 따른다.
				</li>
			</ol>

			<p className="title">제 5조 (초상권, 성명권 등의 이용허락)</p>
			<p className="ms-3 mb-5">
				선수가 대회에 참가하는 과정에서 촬영된 사진, 영상 등을 사용함에 있어서 본 계약의
				체결로 인하여 대회 참여와 관련된 범위 내에서는 캐디로부터 캐디의 초상권, 성명권에
				대한 이용허락을 받은 것으로 본다. 따라서 캐디는 선수의 이용에 대하여 추가적인 비용을
				청구하거나 이의를 제기할 수 없다.
			</p>

			<p className="title">제 6조 (캐디의 계약 상 의무)</p>
			<ol>
				<li>
					캐디는 본 계약에 따라 선수가 원활하게 대회에 참가하여 선수로서 실력을 발휘할 수
					있도록 본건 업무를 성실히 이행할 의무를 부담한다.
				</li>
				<li>
					캐디는 선수와 사전에 협의하여 선수의 스폰서가 제공하는 모자 또는 선수가 지정하는
					모자를 착용할 수 있다. 이 경우 선수는 캐디가 구단에 소속된 경우 구단과의 계약을
					위반하여 문제가 발생하지 않도록 협력해야 한다.
				</li>
				<li>
					캐디는 본 계약에 따른 본건 업무를 수행하지 못하거나 지체가 발생한 경우, 해당
					의무 위반에 대하여 캐디의 비용과 책임으로 불이익을 부담하여야 한다. 단, 사전에
					선수에게 양해를 구하거나 업무의 진행이 어려울 경우에는 선수에게 사전 양해 후
					본건 업무의 이행 예정일로부터 최소 3일 전까지 캐디와 동일한 성별과 동등한 능력을
					가진 자로 대체자를 구하여야 하며, 대체자에 대한 신상 및 경력정보를 선수에게 즉시
					알려야 한다.
				</li>
				<li className="mb-5">
					제 3항 단서에 해당하는 사유가 발생하였음에도 불구하고, 캐디가 대체자를 구하지
					못하거나 선수로부터 대체자에 대한 승인을 얻지 못한 경우에는 선수와 캐디는 본
					계약을 합의하여 해지하기로 하며, 캐디는 선수에게 본 계약상의 비용을 청구할 수
					없다.
				</li>
			</ol>

			<p className="title">제 7조 (대회 축소 또는 축소로 인한 선수의 비용지급)</p>
			<ol>
				<li>
					선수는 자신의 개인적인 사정으로 본 계약을 이행할 수 없는 경우에는 이를 즉시
					캐디에게 통지하여야 하며, 캐디에게 지급하기로 약정한 용역비용을 기준으로 아래의
					표에 기재된 비율을 곱한 금액을 캐디에게 지급한다. 여기서 본 계약의 이행은
					공식연습일부터 시작하며, 공식연습일이 없는 경우 본 대회 시작일을 기준으로 한다.
				</li>
				{/* TODO */}
				<li>취소위약금</li>
				<table className="table table-bordered liTable mb-3">
					<thead>
						<tr>
							<th>구분</th>
							<th>취소 위약금</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<b>단기계약</b>
							</td>
							<td rowSpan="3">
								총 주급 수수료의 {serviceConfig?.refund || '00'}%
								<br />
								(이행한 대회 제외)
							</td>
						</tr>
						<tr>
							<td>
								<b>장기계약</b>
							</td>
						</tr>
						<tr>
							<td>
								<b>년 계약</b>
							</td>
						</tr>
					</tbody>
				</table>
				<li>
					선수는 제14조의 사유로 인하여 대회가 축소되어 정규 대회에 포함되지 않는 경우라도
					해당 대회를 주최한 협회로부터 공식 상금을 수령한 경우에는 제4조 제3항에 따른
					금액을 캐디에게 지급해야 한다.
				</li>
				<li className="mb-5">
					캐디는 제14조의 사유로 인하여 대회가 취소된 경우, 해당 대회의 비용청구권을
					포기한다. 다만, 연봉제 계약이 진행된 캐디의 경우에는 그러하지 아니하다.
				</li>
			</ol>

			<p className="title">제 8조 (비밀유지의무)</p>
			<ol>
				<li>
					선수와 캐디는 상대방의 업무상 · 기술상 관련된 비밀정보를 상대방의 서면(문자,
					메시지, 이메일 등 전자적 형태로 작성, 송수신, 저장된 정보 일체(전자문서)를 포함,
					이하 본 계약에서 동일) 동의 없이 본 계약의 이행을 위한 목적 외로 사용하거나
					제3자에게 누설, 제공 또는 공표하여서는 아니 된다. 만약, 당사자 일방이 본 업무의
					이행을 위하여 상대방의 비밀정보를 제3자에게 제공하고자 할 때에는 사전에
					상대방으로부터 서면에 의한 동의를 받아야 한다.
				</li>
				<li>
					본 조에 따라 보호되는 비밀정보란, 쌍방이 본 계약에 따른 업무를 이행하는 과정에서
					스스로 알게 되거나, 본 업무를 수행하기 위하여 상대방으로부터 제공받아 알게 된
					일체의 기술상 혹은 경영상의 정보 및 이를 기초로 새롭게 발생한 일체의 기술상 혹은
					경영상의 정보를 말한다.
				</li>
				<li>
					제2항의 비밀정보는 본 계약의 내용을 포함하여, 서면(전자문서를 포함), 구두 혹은
					기타 방법으로 제공되는 모든 노하우, 공정, 도면, 설계, 실험결과, 샘플, 사양,
					데이터, 공식, 제법, 프로그램, 가격표, 수수료, 거래명세서, 생산단가, 아이디어 등
					모든 기술상 혹은 경영상의 정보와 그러한 정보가 수록된 물건 또는 장비 등을 모두
					포함한다.
				</li>
				<li className="mb-5">
					선수와 캐디는 계약기간 중 또는 계약기간의 만료 또는 계약의 해제 · 해지 이후에도
					본 조에 따른 비밀유지의무를 부담하며, 당사자 일방이 이에 위반하여 상대방에게
					손해를 입힌 경우에는 이로 인한 손해를 배상할 책임을 진다.
				</li>
			</ol>

			<p className="title">제 9조 (통지의무)</p>
			<p className="ms-3 mb-5">
				본 계약의 이행과 관련하여 변동 사항이 발생한 경우, 상대방에게 변동 사항에 대하여
				즉시 통지하여야 한다. 통지하지 않음으로써 발생한 불이익에 대해서는 통지의무를 위반한
				자의 책임으로 한다.
			</p>

			<p className="title">제 10조 (소통의무)</p>
			<p className="ms-3 mb-5">
				선수와 캐디는 성공적인 대회를 이루어 내기 위하여 상대방과 원활한 의사소통을 할
				의무를 부담한다. 계약의 일방 당사자가 상대방에게 스케줄 및 대회 참석을 위한 제반
				사항에 대한 문의를 한 경우, 상대방은 원칙적으로 24시간 이내에 성실하게 답변하여야
				하며, 응답을 하지 아니하거나 불분명하거나 모호한 답변을 통해 성실한 소통의무를
				다하지 않은 경우, 본 조의 의무를 위반한 것으로 본다.
			</p>

			<p className="title">제 11조 (계약의 해지)</p>
			<ol>
				<li>
					선수와 캐디는 서면(전자서면 포함)으로 상호 합의하여 본 계약을 해지할 수 있다.
				</li>
				<li>
					선수와 캐디는 상대방이 본 계약의 내용을 이행하지 않을 경우, 상당한 기간(7일)을
					정하여 그 이행을 서면으로 최고할 수 있고, 이행 최고 시 정한 기간의 종료일까지
					이행하지 않을 경우 이행을 최고한 당사자는 서면을 통하여 본 계약을 즉시 해지할 수
					있다.
				</li>
				<li className="mb-1">
					선수와 캐디는 다음 각 호의 사유가 발생한 경우, 별도의 최고절차 없이 상대방에
					대한 서면통보로써 본 계약을 즉시 해지할 수 있다.
				</li>
				<ul>
					<li>본 계약 제4조 제4항에 따른 매칭 수수료 지급 기한이 경과된 경우</li>
					<li>
						계약 당사자 일방의 부도, 파산신청, 회생절차 개시 신청 또는 영업정지/취소 등
						경영상 중대한 사유가 발생하여 업무를 중지하거나 폐업 등 본 계약의 정상적
						이행이 어려운 사정이 발생하는 경우
					</li>
					<li>
						계약 당사자의 질병 등 건강상의 이유로 본 계약의 정상적인 이행이 어려운
						사정이 발생하는 경우. 다만, 이 경우 상대방이 이에 대한 증빙자료를 요청할
						경우, 이를 제공하여야 한다.
					</li>
					<li className="mb-3">
						계약의 당사자가 계약 내용을 위반함으로 인하여 계약의 목적달성이 어렵다고
						인정되는 경우
					</li>
				</ul>
				<li className="mb-5">
					본 계약의 종료는 종료 이전에 발생한 양 당사자의 권리, 의무 또는 손해배상청구권에
					영향을 미치지 않는다.
				</li>
			</ol>

			<p className="title">제 12조 (손해배상책임)</p>
			<ol>
				<li>
					본 계약 당사자의 불법적인 행위로 인하여 문제(민사소송 제기, 형사고소, 고발 및
					민원 등 포함)가 발생되었을 경우 불법적인 행위를 한 당사자는 이에 대한 모든
					책임을 지며, 이로 인하여 상대방 또는 제3자에게 손해가 발생한 경우 이를
					배상하여야 한다.
				</li>
				<li className="mb-5">
					선수의 귀책사유로 인하여 대회참가가 어려운 경우, 캐디는 본건 업무를 수행하지
					못하는 대회에서 다른 선수의 캐디로서 본건 업무를 수행할 수 있다. 캐디는 이를
					이유로 하여 다른 대회에 대한 본건 업무의 이행을 거절할 수 없다.
				</li>
			</ol>

			<p className="title">제 13조 (불가항력)</p>
			<ol>
				<li>
					계약의 당사자는 천재지변, 국가비상사태, 코로나 19 등과 같은 불가항력적인 사유
					또는 각 당사자의 책임질 수 없는 사유로 인하여 본 계약의 이행을 지체하거나 이행할
					수 없는 경우에는 이에 대한 책임을 지지 아니한다. 다만, 해당 사유가 발생하였다는
					점은 이를 주장하는 자가 입증하여야 하며, 서면으로 상대방에게 통지하여야 한다.
				</li>
				<li className="mb-5">
					본 조에 따라 대회가 취소되는 경우, 캐디는 해당 대회의 비용을 선수에게 청구할 수
					없다. 단, 연봉제 캐디의 경우에는 이에 해당하지 않는다.
				</li>
			</ol>

			<p className="title">제 14조 (계약내용의 변경)</p>
			<p className="ms-3 mb-5">
				본 계약의 내용을 변경할 필요가 있는 경우, 상호 합의하여 별도의 서면(전자서면
				포함)으로 변경하여야 한다.
			</p>

			<p className="title">제 15조 (분쟁의 해결)</p>
			<p className="ms-3 mb-5">
				본 계약과 관련하여 분쟁이 발생하는 경우, 상호 원만한 합의를 통하여 해결하도록
				노력하여야 한다. 그럼에도 불구하고 관련하여 소송이 발생하는 경우 서울중앙지방법원을
				합의관할 법원으로 한다.
			</p>

			<p className="title">제 16조 (계약의 효력)</p>
			<ol>
				<li>
					본 계약은 선수와 캐디가 상호 서명 또는 기명 날인함으로써 성립함과 동시에 그
					효력이 발생하며, 회사가 운영하는 플랫폼 내에서 전자계약의 형태로 이루어지는 것에
					상호 동의한다.
				</li>
				<li>본 계약과 관련하여 서면으로 합의한 사항은 본 계약과 동일한 효력을 갖는다.</li>
				<li className="mb-5">
					선수와 회사, 캐디와 회사 간의 각 거래관계에 대하여는 본 계약에서 명시하지 않은
					내용은 회사의 서비스 이용약관에 따르며, 양자가 충돌하는 범위 내에서 본 계약의
					효력이 우선한다.
				</li>
			</ol>

			<p className="title">제 17조 (기타)</p>
			<p className="ms-3 mb-5">
				본 계약에서 정하지 않은 사항에 대하여는 상호 협의하여 결정하기로 한다.
			</p>

			<p className="mb-5">
				선수와 캐디는 본 계약의 성립을 증명하기 위하여 본 계약서 2부를 작성하여 각각
				서명(또는 기명날인)한 후 각자 1부씩 보관한다.
			</p>
		</React.Fragment>
	)
}
