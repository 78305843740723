import React, { useEffect, useState } from 'react'
import { useAuthStatus } from '../../utils'
import { useNavigate } from 'react-router-dom'

export const AgencyManagementView = ({ API_ENDPOINT, ...props }) => {
	const [auth, onChangeAuth] = useAuthStatus()
	const navigate = useNavigate()

	const [agencies, setAgencies] = useState([])

	useEffect(() => {
		if (auth.user.user_type !== 'tourPro') {
			navigate('/')
		}
	}, [])

	const cancelContract = () => {}

	return (
		<div className="wrap form_page register_page">
			<div className="content_area">
				<div className="container mainWrap">
					<div className="row cmb-20">
						<div className="col-auto">
							<div className="col-auto fontSize-30 fw-bold cmb-10">에이전시 관리</div>
						</div>
					</div>

					<div className="row cmb-20">
						<div className="col-auto">
							<div className="col-auto fontSize-20 fw-bold cmb-10">소속 에이전시</div>
							<div className="col-auto fontSize-15 fw-bold cmb-10">스포츠인</div>
						</div>
					</div>

					<div className="row cmb-20">
						<div className="col-auto">
							<div className="col-auto fontSize-20 fw-bold cmb-10">
								에이전시 계약 상태
							</div>
						</div>
					</div>

					<div className="d-flex w-100">
						<table
							className="table table-hover text-center rgAgen001_table"
							id="memberTable">
							<thead>
								<tr>
									<th>에이전시명</th>
									<th>에이전시ID</th>
									<th>에이전시 연락처</th>
									<th>계약 상태</th>
									<th>정회원번호</th>
									<th>관리</th>
								</tr>
							</thead>
							<tbody className="fontSize-16 font-M00 color-black proTbody">
								{agencies.map((agency) => (
									<tr className="tableRow">
										<td className="pt-3">{agency.name}</td>
										<td className="pt-3 aId">{agency.email}</td>
										<td className="pt-3">{agency.phone}</td>
										<td className="pt-3">계약 중</td>
										<td className="pt-3 mem_num">0875</td>
										<td>
											<button
												type="button"
												className="btn btn-danger rounded-pill me-2"
												onClick={cancelContract}>
												계약 취소 요청
											</button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	)
}
