import React from 'react'
import { useStateMachine } from 'little-state-machine'
import { useForm } from 'react-hook-form'
import { contractTypes, formattingPrice, getMatchTypeOptions, updateFormInput } from '../../utils'
import dayjs from 'dayjs'

export const AnnualSuggestionInfoForm = ({ serviceConfig, onSubmit, ...props }) => {
	const { state } = useStateMachine({ updateFormInput })

	const { register, setValue, handleSubmit } = useForm({
		defaultValues: {
			isTaxIncluded: 'true',
		},
		mode: 'onBlur',
	})

	const onKeyupPrice = (key) => (event) => {
		const price = formattingPrice(event.target.value)

		setValue(key, price)
	}

	return (
		<div className="container form_mw-662">
			<p className="form_topTitle fw-bold">
				제안 조건을{' '}
				<span className="text-nowrap" style={{ fontWeight: 'normal' }}>
					입력해 주세요.
				</span>
			</p>
			<p className="form_progressTitle cmt-30">항목을 선택하시면 제안서가 작성됩니다. 3/3</p>

			<div className="progress">
				<div
					className="progress-bar"
					role="progressbar"
					style={{ width: '100%' }}
					aria-valuenow="100"
					aria-valuemin="0"
					aria-valuemax="100"
				/>
			</div>

			<form
				id="form_matchingPro"
				noValidate
				className="needs-validation"
				onSubmit={handleSubmit(onSubmit)}>
				<div className="card">
					<div className="card-body">
						<p className="form_title cmb-10">계약 기간</p>
						<div className="d-flex justify-content-between align-items-end cmb-30 w-100">
							<div className="border-bottom w-100 me-2">
								<div
									id="contractTotal"
									className="d-flex align-items-center form_inputText pe-0">
									<p style={{ wordBreak: 'keep-all' }}>
										{getMatchTypeOptions(
											contractTypes,
											state?.contractInput?.contractType,
										)}
									</p>
									<p>
										<span className="text-nowrap me-1">
											(계약일 ~{' '}
											{state?.contractInput?.contractType ===
												'year_first_half' &&
												dayjs(serviceConfig?.yearDate).format('YYYY.MM.DD')}
											{state?.contractInput?.contractType ===
												'year_second_half' &&
												dayjs(
													new Date(new Date().getFullYear(), 11, 31),
												).format('YYYY.MM.DD')}
											)
										</span>
									</p>
								</div>
							</div>
						</div>

						<p className="form_title">
							* 임금 세금 포함 여부
							<span
								style={{
									marginLeft: '0.625rem',
									fontSize: '0.75rem',
									color: '#aeaeae',
								}}>
								원천징수 3.3%
							</span>
						</p>
						<div className="row cmb-30">
							<div className="col">
								<input
									type="radio"
									className="btn-check requiredc"
									id="mPro_paymentTax_1"
									autoComplete="off"
									value="true"
									{...register('isTaxIncluded', {
										required: true,
									})}
								/>
								<label
									className="btn btn-light btn-whiteShadow input-group-target"
									htmlFor="mPro_paymentTax_1">
									세전
								</label>
							</div>
							<div className="col">
								<input
									type="radio"
									className="btn-check"
									name="paymentTax"
									id="mPro_paymentTax_2"
									autoComplete="off"
									value="false"
									{...register('isTaxIncluded', {
										required: true,
									})}
								/>
								<label
									className="btn btn-light btn-whiteShadow input-group-target"
									htmlFor="mPro_paymentTax_2">
									세후
								</label>
							</div>
						</div>

						<p className="form_title">임금 지급 방식</p>
						<div className="row cmb-30">
							<div className="col-6">
								<input
									type="radio"
									className="btn-check requiredc"
									id="mPro_paymentTypeAnnual"
									autoComplete="off"
									checked
								/>
								<label
									className="btn btn-light btn-whiteShadow input-group-target"
									htmlFor="mPro_paymentTypeAnnual">
									연봉(
									{getMatchTypeOptions(
										contractTypes,
										state?.contractInput?.contractType,
									)}
									)
								</label>
							</div>
						</div>

						<p className="form_title">* 연봉</p>
						<div className="row cmb-30 align-items-center">
							<div className="col">
								<div className="d-flex justify-content-end align-items-center underbarInput">
									<input
										id="weeklySalary"
										className="border-0 w-100 form-control no-focus form_inputText regUnderbarInput"
										placeholder="금액을 입력해 주세요."
										{...register('weeklySalary', {
											required: true,
											onChange: onKeyupPrice('weeklySalary'),
										})}
									/>
									원
								</div>
							</div>
						</div>

						<p className="form_title">글 내용</p>
						<textarea
							id="mPro_noticeComment"
							className="w-100 cmb-30"
							rows="7"
							placeholder="본문 내용을 입력해주세요"
							{...register('etc', {
								required: true,
							})}
						/>

						<button type="submit" className="btn btn-dark nextPageButton">
							제안하기
						</button>
					</div>
				</div>
			</form>
		</div>
	)
}
