import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

export const SuggestionTypeForm = ({ onSubmit, ...props }) => {
	const { register, watch, handleSubmit } = useForm({
		mode: 'onBlur',
	})

	return (
		<div className="container register002">
			<p className="form_topTitle fw-bold">
				계약 유형을{' '}
				<span className="text-nowrap" style={{ fontWeight: 'normal' }}>
					선택해 주세요.
				</span>
			</p>
			<div className="card">
				<div className="card-body">
					<form
						id="form_recruit"
						className="needs-validation form_mw-662 mx-auto"
						noValidate
						onSubmit={handleSubmit(onSubmit)}>
						<p className="form_progressTitle cmt-30">
							항목을 선택하시면 제안서가 작성됩니다. 1/3
						</p>
						<div className="progress">
							<div
								className="progress-bar"
								role="progressbar"
								style={{ width: '33.3333%' }}
								aria-valuenow="33.33333"
								aria-valuemin="0"
								aria-valuemax="100"></div>
						</div>

						<div className="row cmb-20 input-group-box align-items-stretch">
							<div className="col-4 cmb-10">
								<label
									className={`form-check-label partner_radioBox w-100 h-100 ${
										watch('contractType') === 'single' ? 'actived' : null
									}`}
									htmlFor="contract_1">
									<input
										type="radio"
										id="contract_1"
										className="form-check-input mt-0"
										name="contract"
										value="single"
										{...register('contractType', {
											required: true,
										})}
									/>
									<div className="partner_labelBox w-100">
										<p
											className="text-center"
											style={{ wordBreak: 'keep-all' }}>
											단일 계약(대회별)
										</p>
									</div>
								</label>
							</div>
							<div className="col-4 cmb-10">
								<label
									className={`form-check-label partner_radioBox w-100 h-100 ${
										watch('contractType') === 'long_term' ? 'actived' : null
									}`}
									htmlFor="contract_2">
									<input
										type="radio"
										id="contract_2"
										className="form-check-input mt-0"
										value="long_term"
										{...register('contractType', {
											required: true,
										})}
									/>
									<div className="partner_labelBox w-100">
										<p className="text-center">장기 계약</p>
									</div>
								</label>
							</div>
							<div className="col-4 cmb-10">
								<label
									className={`form-check-label partner_radioBox w-100 h-100 ${
										watch('contractType') === 'year' ? 'actived' : null
									}`}
									htmlFor="contract_3">
									<input
										type="radio"
										id="contract_3"
										className="form-check-input mt-0"
										value="year"
										{...register('contractType', {
											required: true,
										})}
									/>
									<div className="partner_labelBox w-100">
										<p className="text-center">연간 계약</p>
									</div>
								</label>
							</div>
						</div>

						<button type="submit" className="btn btn-dark nextPageButton">
							다음 단계로
						</button>
					</form>
				</div>
			</div>
		</div>
	)
}
