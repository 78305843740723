import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Tab, Nav, Modal, Card } from 'react-bootstrap'
import dayjs from 'dayjs'
import { isEmpty, REG_PHONE } from '../../utils'
import { MessageModal } from '../../components'

export const SponsorshipListView = ({ API_ENDPOINT, ...props }) => {
	const navigate = useNavigate()

	const [sponsorships, setSponsorships] = useState([])

	const [tabKey, setTabKey] = useState('LIST')
	const [searchKeyword, setSearchKeyword] = useState('')
	const [selectedSponsorship, setSelectedSponsorship] = useState({})
	const [confirmFormVisible, setConfirmFormVisible] = useState(false)
	const [messageModalVisible, setMessageModalVisible] = useState(false)
	const [messageModalContent, setMessageModalContent] = useState('')

	useEffect(() => {
		fetchSponsorships()
	}, [])

	const fetchSponsorships = async () => {
		await fetch(`${API_ENDPOINT}/admin/sponsor/list`)
			.then(async (response) => {
				const result = await response.json()

				setSponsorships(result)
			})
			.catch((errors) => setSponsorships([]))
	}

	const confirmSponsorship = async ({ phone }) => {
		await fetch(`${API_ENDPOINT}/admin/sponsor/${selectedSponsorship?.id}/${phone}`)
			.then(async (response) => {
				const result = await response.json()

				if (result === true) {
					handleConfirmModalClose()
					navigate(`/sponsorship/${selectedSponsorship?.id}`)
				} else if (result === false) {
					handleConfirmModalClose()
					handleMessageModalOpen(
						<span>
							문의 글에 입력된 연락처와 일치하지 않습니다.
							<br /> 확인 후, 다시 시도해 주세요.
						</span>,
					)
				}
			})
			.catch((errors) => {})
	}

	const searchSponsorship = async () => {
		await fetch(`${API_ENDPOINT}/admin/sponsor/search/${searchKeyword}`)
			.then(async (response) => {
				const result = await response.json()

				setSponsorships(result)
			})
			.catch((errors) => setSponsorships([]))
	}

	const handleSponsorshipTabSelect = async (key) => {
		if (key === 'LIST') {
			await fetchSponsorships()
		}

		setTabKey(key)
	}

	const onChangeSearchKeyword = (event) => {
		setSearchKeyword(event.target.value)
	}

	const handleConfirmModalOpen = (sponsorship) => (event) => {
		setSelectedSponsorship(sponsorship)
		setConfirmFormVisible(true)
	}
	const handleConfirmModalClose = () => {
		setSelectedSponsorship({})
		setConfirmFormVisible(false)
	}

	const handleMessageModalOpen = (message) => {
		setMessageModalContent(message)
		setMessageModalVisible(true)
	}

	const handleMessageModalClose = () => {
		setMessageModalContent('')
		setMessageModalVisible(false)
	}

	const maskingName = (name) => {
		if (isEmpty(name)) {
			return ''
		}

		if (name?.length <= 2) {
			return name?.replace(name?.substring(0, 1), '*')
		} else {
			return (
				name?.[0] +
				'*'.repeat(name?.substring(1, name?.length - 1)?.length) +
				name[name?.length - 1]
			)
		}
	}

	const maskingPhone = (phone) => {
		if (isEmpty(phone)) {
			return ''
		}

		const values = phone?.split('-')
		values[1] = '*'.repeat(values?.[1]?.length)

		return values.join('-')
	}

	return (
		<div className="wrap traning_page">
			<div className="content_area">
				<div className="container tpContainer">
					<p className="topTitleText">스폰서쉽</p>
					<p className="topSubTitleText">
						스폰서십 소개글 입력영역 입니다. 적용될 내용을 알려주시면 반영하도록
						하겠습니다. 스폰서십 소개글 입력영역 입니다. 적용될 내용을 알려주 시면
						반영하도록 하겠습니다. 스폰서십 소개글 입력영역 입니다. 적용될 내용을
						알려주시면 반영하도록 하겠습니다.
					</p>

					<Tab.Container defaultActiveKey={tabKey} onSelect={handleSponsorshipTabSelect}>
						<Nav className="nav-tabs nav-tabs-camel" id="myTab" role="tablist">
							<Nav.Item className="w-50" role="presentation">
								<Nav.Link eventKey={'SERVICE'} className="cumbw-100 tab-camel">
									서비스 소개
								</Nav.Link>
							</Nav.Item>

							<Nav.Item className="w-50" role="presentation">
								<Nav.Link eventKey={'LIST'} className="cumbw-100 tab-camel">
									문의 내용
								</Nav.Link>
							</Nav.Item>
						</Nav>

						<Tab.Content className="tab-content" id="myTabContent">
							<Tab.Pane eventKey={'SERVICE'} className="py-3"></Tab.Pane>

							<Tab.Pane eventKey={'LIST'} className="py-3">
								<div className="row align-items-center cmb-30">
									<div className="col-12 col-md-2">
										<Link to="/sponsorship/create">
											<button className="btn btn-lg btn-secondary btnDownload">
												문의 접수
											</button>
										</Link>
									</div>

									<div className="col-12 col-md-6 offset-md-4">
										<Card className="py-0">
											<Card.Body style={{ padding: '1rem' }}>
												<div className="row align-items-center">
													<div className="col">
														<input
															type="text"
															name=""
															id="pm001_input_searchcaddie"
															className="border-0 p-0 w-100"
															placeholder="검색어를 입력해 주세요."
															onChange={onChangeSearchKeyword}
														/>
													</div>
													<div className="col-auto">
														<i
															className="fas fa-search color-white-three"
															aria-hidden="true"
															onClick={searchSponsorship}
														/>
													</div>
												</div>
											</Card.Body>
										</Card>
									</div>
								</div>

								<div className="row align-items-center cmb-30">
									<table
										className="table table-hover text-center"
										id="zero-config">
										<thead>
											<tr>
												<th>No.</th>
												<th>작성자</th>
												<th>연락처</th>
												<th>답변 여부</th>
												<th>문의 내용</th>
												<th>작성일</th>
											</tr>
										</thead>
										<tbody id="sponsorshipTableBody">
											{sponsorships?.map((item) => (
												<tr onClick={handleConfirmModalOpen(item)}>
													<th>{item?.id}</th>
													<th>{maskingName(item?.name)}</th>
													<th>{maskingPhone(item?.phone)}</th>
													<th>
														{item?.is_answer
															? '답변 완료'
															: '답변 필요'}
													</th>
													<th>{item?.title}</th>
													<th>
														{dayjs(item?.created_at).format(
															'YYYY-MM-DD HH:mm:ss',
														)}
													</th>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</Tab.Pane>
						</Tab.Content>
					</Tab.Container>
				</div>
			</div>

			<ModalConfirmPhone
				modalVisible={confirmFormVisible}
				confirmSponsorship={confirmSponsorship}
				onClose={handleConfirmModalClose}
			/>

			<MessageModal
				modalVisible={messageModalVisible}
				content={messageModalContent}
				onClose={handleMessageModalClose}
			/>
		</div>
	)
}

const ModalConfirmPhone = ({ modalVisible, confirmSponsorship, onClose, ...props }) => {
	const { register, reset, handleSubmit } = useForm({
		mode: 'onBlur',
	})

	const onSubmit = async (data) => {
		confirmSponsorship(data)
		onCloseModal()
	}

	const onCloseModal = () => {
		onClose()
		reset()
	}

	return (
		<Modal
			id="payModal"
			backdrop="static"
			centered={true}
			tabIndex="-1"
			aria-labelledby="payModalLabel"
			aria-hidden="true"
			show={modalVisible}
			onHide={onCloseModal}>
			<Modal.Header className="border-0">
				<p className="modal-title w-100 text-center font-bold" id="staticBackdropLabel">
					연락처 확인
				</p>
				<button
					type="button"
					className="btn-close"
					aria-label="Close"
					onClick={onCloseModal}
				/>
			</Modal.Header>
			<Modal.Body>
				<p className="fontSize-20 font-L00 color-greyish-brown cmb-20">
					문의 글을 등록 시 입력한 연락처의 전체를 입력해주세요.
				</p>

				<div>
					<form onSubmit={handleSubmit(onSubmit)}>
						<label htmlFor="phoneNumber" className="form-label">
							* 휴대폰 번호
						</label>
						<div className="d-flex justify-content-between align-items-end cmb-30 w-100">
							<div className="w-100 me-2">
								<div className="d-flex align-items-center inputText pe-0">
									<input
										type="tel"
										id="phoneNumber"
										name="prePw"
										className="form-control underbarInput"
										{...register('phone', {
											required: true,
											pattern: {
												value: REG_PHONE,
												message: '휴대폰 번호를 정확히 입력하세요.',
											},
											onChange: (event) => {
												event.target.value = event.target.value
													.replace(/[^0-9]/g, '')
													.replace(
														/^(\d{0,3})(\d{0,4})(\d{0,4})$/g,
														'$1-$2-$3',
													)
													.replace(/(\-{1,2})$/g, '')
											},
										})}
									/>
								</div>
							</div>
						</div>

						<div className="row cmb-20">
							<div className="col-6">
								<button
									type="button"
									aria-label="Close"
									className="btn btn-danger registerNextButton w-100 pb-3 pt-3 cmb-10"
									onClick={onCloseModal}>
									취소
								</button>
							</div>
							<div className="col-6">
								<button
									type="submit"
									className="btn btn-dark registerNextButton w-100 pb-3 pt-3 cmb-10">
									확인
								</button>
							</div>
						</div>
					</form>
				</div>
			</Modal.Body>
		</Modal>
	)
}
