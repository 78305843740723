import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthStatus } from '../../utils'

export const RegisterHomeView = ({ ...props }) => {
	const [auth, onChangeAuthStatus] = useAuthStatus()

	const navigate = useNavigate()
	const userTypeRadioRef = useRef()

	const [selectedUserType, setSelectedUserType] = useState(null)

	useEffect(() => {
		if (auth.isAuthentication) {
			navigate('/')
		}

		const userTypeRadioGroup = userTypeRadioRef.current

		const radioItems = userTypeRadioGroup.querySelectorAll('input[name=userType]')

		!!radioItems &&
			radioItems.forEach((_radio) => {
				_radio.addEventListener('click', () => {
					radioItems.forEach((element) => {
						let radioBox = element.closest('.rg001_radioBox'),
							label = element.nextElementSibling,
							label_p = label.querySelector('p'),
							img = label.querySelector('img'),
							imgSrc = img.getAttribute('src')

						if (element === _radio) {
							if (element.checked) {
								radioBox.style.borderColor = 'var(--camel)'
								label_p.style.color = 'var(--camel)'
								if (imgSrc.substr(-6) !== '-1.png') {
									img.setAttribute('src', imgSrc.replace('.png', '-1.png'))
								}

								setSelectedUserType(radioBox.querySelector('input').value)
							} else {
								radioBox.style.borderColor = 'var(--white-four)'
								label_p.style.color = 'var(--greyish-two)'
								imgSrc = imgSrc.replace('-1', '')
								img.setAttribute('src', imgSrc)
							}
						} else {
							radioBox.style.borderColor = 'var(--white-four)'
							label_p.style.color = 'var(--greyish-two)'
							imgSrc = imgSrc.replace('-1', '')
							img.setAttribute('src', imgSrc)
						}
					})
				})
			})

		return (
			!!radioItems &&
			radioItems?.forEach((_radio) => {
				_radio.removeEventListener('click', () => {
					radioItems.forEach((element) => {
						let radioBox = element.closest('.rg001_radioBox'),
							label = element.nextElementSibling,
							label_p = label.querySelector('p'),
							img = label.querySelector('img'),
							imgSrc = img.getAttribute('src')

						if (element === _radio) {
							if (element.checked) {
								radioBox.style.borderColor = 'var(--camel)'
								label_p.style.color = 'var(--camel)'
								if (imgSrc.substr(-6) !== '-1.png') {
									img.setAttribute('src', imgSrc.replace('.png', '-1.png'))
								}

								setSelectedUserType(radioBox.querySelector('input').value)
							} else {
								radioBox.style.borderColor = 'var(--white-four)'
								label_p.style.color = 'var(--greyish-two)'
								imgSrc = imgSrc.replace('-1', '')
								img.setAttribute('src', imgSrc)
							}
						} else {
							radioBox.style.borderColor = 'var(--white-four)'
							label_p.style.color = 'var(--greyish-two)'
							imgSrc = imgSrc.replace('-1', '')
							img.setAttribute('src', imgSrc)
						}
					})
				})
			})
		)
	}, [])

	const onSubmit = () => {
		localStorage.setItem('userType', `${selectedUserType}`)

		navigate(`/register/main`)
	}

	return (
		<div className="wrap register_page">
			<div className="content_area">
				<div className="bgWrap">
					<div className="container">
						<div className="loginLogoBox">
							<img src={`/images/splash_001_logo.png`} alt="SPOEUM" />
						</div>
						<p className="rg001_title_1">국내 유일 매칭 서비스</p>
						<p className="rg001_title_2">
							골퍼를 위한 플랫폼, <strong>스포이음</strong> 입니다.
						</p>
						<p className="rg001_radioTitle">회원 분류를 선택해 주세요.</p>
						<div className="row" ref={userTypeRadioRef}>
							<div className="col-6">
								<div className="rg001_radioBox">
									<input
										className="form-check-input"
										type="radio"
										name="userType"
										id="userType1"
										value="tourPro"
									/>
									<label className="form-check-label" htmlFor="userType1">
										<div className="rg001_labelBox">
											<img
												src={`/images/register_001_icn01.png`}
												alt="tourPro"
											/>
											<p>투어 프로</p>
										</div>
									</label>
								</div>
							</div>
							<div className="col-6">
								<div className="rg001_radioBox">
									<input
										className="form-check-input"
										type="radio"
										name="userType"
										id="userType3"
										value="tourCaddie"
									/>
									<label className="form-check-label" htmlFor="userType3">
										<div className="rg001_labelBox">
											<img
												src={`/images/register_001_icn03.png`}
												alt="tourCaddie"
											/>
											<p>투어 캐디</p>
										</div>
									</label>
								</div>
							</div>
							<div className="col-6">
								<div className="rg001_radioBox">
									<input
										className="form-check-input"
										type="radio"
										name="userType"
										id="userType4"
										value="houseCaddie"
									/>
									<label className="form-check-label" htmlFor="userType4">
										<div className="rg001_labelBox">
											<img
												src={`/images/register_001_icn04.png`}
												alt="housecaddie"
											/>
											<p>하우스 캐디</p>
										</div>
									</label>
								</div>
							</div>
							<div className="col-6">
								<div className="rg001_radioBox">
									<input
										className="form-check-input"
										type="radio"
										name="userType"
										id="userType5"
										value="agency"
									/>
									<label className="form-check-label" htmlFor="userType5">
										<div className="rg001_labelBox">
											<img
												src={`/images/register_001_icn05.png`}
												alt="agency"
											/>
											<p>에이전시</p>
										</div>
									</label>
								</div>
							</div>
							<div className="col-12">
								<button
									type="button"
									id="rg001_nextButton"
									className="btn btn-dark registerNextButton"
									onClick={onSubmit}>
									다음 단계로
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
