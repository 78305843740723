import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { SponsorshipForm } from '../../components'
import { FETCH_HEADER_OPTIONS, parseFields } from '../../utils'

export const SponsorshipInquiryUpdateView = ({ API_ENDPOINT, ...props }) => {
	const navigate = useNavigate()

	const { sponsorshipId } = useParams()

	const [sponsorship, setSponsorship] = useState({})
	const [fields, setFields] = useState({})

	useEffect(() => {
		// TODO : sponsorship 정보 불러오기
		fetchSponsorship()
	}, [])

	const fetchSponsorship = async () => {
		await fetch(`${API_ENDPOINT}/admin/sponsor/${sponsorshipId}`)
			.then(async (response) => {
				const result = await response.json()
				const fields = parseFields(result)

				setFields(fields)
			})
			.catch((errors) => console.log(errors))
	}

	const updateSponsorship = async ({ business_license, ...data }) => {
		const businessLicenseFileName = business_license?.[0]?.name || ''

		const input = { ...data, business_license: businessLicenseFileName }

		console.log(input)

		await fetch(`${API_ENDPOINT}/admin/sponsor/update/${sponsorshipId}`, {
			method: 'PATCH',
			headers: FETCH_HEADER_OPTIONS,
			body: JSON.stringify(input),
		})
			.then(async (response) => {
				const result = await response.json()

				// await uploadS3(result?.id, 'business', business_license)

				navigate(`/sponsorship/${sponsorshipId}`)
			})
			.catch((errors) => console.log(errors))
	}

	return (
		<div className="wrap traning_page form_page">
			<div className="content_area">
				<div className="container tpContainer">
					<p className="topTitleText">스폰서쉽 문의하기</p>
					<p className="topSubTitleText">
						스폰서십 소개글 입력영역 입니다. 적용될 내용을 알려주시면 반영하도록
						하겠습니다. 스폰서십 소개글 입력영역 입니다. 적용될 내용을 알려주 시면
						반영하도록 하겠습니다. 스폰서십 소개글 입력영역 입니다. 적용될 내용을
						알려주시면 반영하도록 하겠습니다.
					</p>

					<SponsorshipForm fields={fields} onSubmitHandle={updateSponsorship} />
				</div>
			</div>
		</div>
	)
}
