import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAuthStatus } from '../../utils'
import { useNavigate } from 'react-router-dom'

export const ProManagementView = ({ cndEndpoint, ...props }) => {
	const [auth, onChangeAuth] = useAuthStatus()
	const navigate = useNavigate()

	const { register, handleSubmit } = useForm({ mode: 'onBlur' })

	const [players, setPlayers] = useState([])

	useEffect(() => {
		if (auth.user.user_type !== 'agency') {
			navigate('/')
		}
	}, [])

	const onSubmit = (data) => {}

	const cancelContract = (id) => (event) => {}
	const destroyPlayer = (id) => (event) => {}

	return (
		<div className="wrap form_page register_page">
			<div className="content_area">
				<div className="container mainWrap">
					<div className="row cmb-20">
						<div className="col-auto">
							<div className="col-auto fontSize-30 fw-bold cmb-10">
								소속 프로 관리
							</div>
						</div>
					</div>

					<div className="row align-items-center w-100 cmb-30">
						<form noValidate onSubmit={handleSubmit(onSubmit)}>
							<div className="col-auto pe-3">
								<span>프로추가</span>
							</div>
							<div className="col-auto" style={{ paddingTop: '3px' }}>
								<select
									className="rgAgen001_memberAddSelect"
									id="tourType"
									{...register('tourType', {
										required: true,
									})}>
									{/*onchange="changeStyleColor(this, 'var(--greyish-brown');"*/}
									<option value="KPGA" className="text-greyish-brown" selected>
										KPGA
									</option>
									<option value="KLPGA" className="text-greyish-brown">
										KLPGA
									</option>
								</select>
							</div>
							<div className="col-12 col-md-7">
								<div className="d-flex">
									<input
										type="text"
										id="name"
										className="form-control underbarInput rgAgen001_name me-2"
										placeholder="이름"
										{...register('name', {
											required: true,
										})}
									/>
									<input
										type="text"
										id="memNum"
										className="form-control underbarInput rgAgen001_memNum me-2"
										onKeyUp="checkNumber(this)"
										placeholder="정회원 번호"
										{...register('name', {
											required: true,
										})}
									/>
									<button
										type="submit"
										id="memberAddButton"
										className="btn btn-dark rgAgen001_memberAddBtn">
										제안서 발송
									</button>
								</div>
							</div>
						</form>
					</div>

					<div className="d-flex w-100">
						<table
							className="table table-hover text-center rgAgen001_table"
							id="memberTable">
							<thead>
								<tr>
									<th>투어</th>
									<th>이름</th>
									<th>정회원번호</th>
									<th>계약상태</th>
									<th>관리</th>
								</tr>
							</thead>
							<tbody className="fontSize-16 font-M00 color-black proTbody">
								{players.map((player) => (
									<tr className="tableRow">
										<td className="pt-3">{player.tourType}</td>
										<td className="pt-3">{player.name}</td>
										<td className="pt-3 mem_num">0611</td>
										<td className="pt-3">제안서 발송</td>
										<td>
											<button
												type="button"
												className="btn btn-danger rounded-pill me-2"
												style={{ width: '117px' }}
												onClick={cancelContract}>
												제안서 취소
											</button>
										</td>
									</tr>
								))}

								{/*<tr className="tableRow">*/}
								{/*	<td className="pt-3">KPGA</td>*/}
								{/*	<td className="pt-3">김진성</td>*/}
								{/*	<td className="pt-3 mem_num">0875</td>*/}
								{/*	<td className="pt-3">계약 중</td>*/}
								{/*	<td>*/}
								{/*		<button*/}
								{/*			type="button"*/}
								{/*			className="btn btn-danger rounded-pill me-2"*/}
								{/*			onClick={destroyPlayer}>*/}
								{/*			계약해지 요청*/}
								{/*		</button>*/}
								{/*	</td>*/}
								{/*</tr>*/}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	)
}
