import React, { useEffect, useState } from 'react'
import {
	DEFAULT_USER_SESSION_COOKIE_NAME,
	FETCH_HEADER_OPTIONS,
	formattingPrice,
	getCookie,
	getCurrentYear,
	getMatchTypeOptions,
	useAuthStatus,
	userTypes,
} from '../../utils'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'

export const InterestedHumanResourcesView = ({ API_ENDPOINT, PROFILE_BUCKET, ...props }) => {
	const [auth, onChangeAuthStatus] = useAuthStatus()
	const [hrList, setHrList] = useState([])

	const user = getCookie(DEFAULT_USER_SESSION_COOKIE_NAME)

	useEffect(() => {
		fetchHRList()
	}, [auth])

	const fetchHRList = async () => {
		await fetch(`${API_ENDPOINT}/users/my-page/${auth?.user?.id}/favored`)
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) throw new Error(result.massage)

				setHrList(result)
			})
			.catch((errors) => setHrList([]))
	}

	const unlikeUser = (favoredUserId) => async (event) => {
		await fetch(`${API_ENDPOINT}/users/my-page/${auth?.user?.id}/favored`, {
			method: 'DELETE',
			headers: FETCH_HEADER_OPTIONS,
			body: JSON.stringify({ favoredUserId: favoredUserId }),
		}).then(async (response) => {
			const result = await response.json

			if (result.success === false) {
				throw new Error('관심인재 삭제 실패')
			}

			await fetchHRList()
		})
	}

	console.log(hrList)

	return (
		<div className="wrap pro_form form_page">
			<div className="content_area">
				<div className="container mainWrap">
					<div className="cmb-30 pro_my_title_text">관심 인재</div>

					<div className="row cmb-30">
						<div className="col-auto cmb-10">
							<select
								className="form-select rounded-pill"
								style={{ width: '7rem' }}
								aria-label="Default select example">
								<option selected="">전체</option>
								<option value="1">경력순</option>
								<option value="2">성적순</option>
								<option value="3">주급순</option>
							</select>
						</div>

						<div className="col-auto cmb-10">
							<select
								className="form-select rounded-pill"
								style={{ width: '7rem' }}
								aria-label="Default select example">
								<option selected="">캐디전체</option>
								<option value="houseCaddie">하우스 캐디</option>
								<option value="tourCaddie">투어 캐디</option>
							</select>
						</div>

						<div className="col-auto cmb-10">
							<input
								type="checkbox"
								className="btn-check"
								name="contract"
								id="contractcheck"
								autoComplete="off"
							/>
							<label
								className="d-flex justify-content-center btn btn-light btn-whiteShadow rounded-pill"
								style={{
									width: '7rem',
									height: '38px',
									alignItems: 'center',
									padding: 'unset',
								}}
								htmlFor="contractcheck">
								계약중
							</label>
						</div>
					</div>

					<div className="row align-items-center cmb-30" id="caddieList">
						{hrList?.map((item) => (
							<React.Fragment>
								<div
									key={`pc-${item?.favored?.id}`}
									className={`col-12 col-md-6 cmb-10 onlyPc`}>
									<Card style={{ cursor: 'pointer' }}>
										<div className="d-flex">
											<Link
												to={`/matching/${item?.favored?.id}`}
												className="cardImage_caddie">
												<Card.Img
													src={
														item?.favored?.profile_type
															? PROFILE_BUCKET +
															  item?.favored?.id +
															  '.' +
															  item?.favored?.profile_type
															: `/images/noImg.png`
													}
													alt={user?.name}
												/>
											</Link>

											<Card.Body>
												<div className="d-flex justify-content-between">
													<Link to={`/matching/${item?.favored?.id}`}>
														<p className="font-R00 fontSize-13 color-greyish-two mb-1">
															{getMatchTypeOptions(
																userTypes,
																item?.favored?.user_type,
															)}
														</p>
														<p className="font-SB00 fontSize-17 color-black mb-2">
															{item?.favored?.name}
														</p>

														{renderInfoByType(item?.favored)}
													</Link>
													<div>
														<img
															src={`/images/pro-main-001-like-02.png`}
															alt="thumbup"
															className="button_thumup"
															id="thumb-6"
															onClick={unlikeUser(item?.favored?.id)}
														/>
													</div>
												</div>
											</Card.Body>
										</div>
									</Card>
								</div>

								<div
									key={`mobile-${item?.favored?.id}`}
									className={`col-12 col-md-6 cmb-10 onlyMobile`}>
									<Card style={{ cursor: 'pointer' }}>
										<div className="d-flex">
											<Link
												to={`/matching/${item?.favored?.id}`}
												className="cardImage_caddie">
												<Card.Img
													src={
														item?.favored?.profile_type
															? PROFILE_BUCKET +
															  item?.favored?.id +
															  '.' +
															  item?.favored?.profile_type
															: `/images/noImg.png`
													}
													alt={user?.name}
												/>
											</Link>

											<Card.Body>
												<div className="d-flex justify-content-between">
													<Link to={`/matching/${item?.favored?.id}`}>
														<p className="font-R00 fontSize-13 color-greyish-two mb-1">
															{getMatchTypeOptions(
																userTypes,
																item?.favored?.user_type,
															)}
														</p>
														<p className="font-SB00 fontSize-17 color-black mb-2">
															{item?.favored?.name}
														</p>

														{renderInfoByType(item?.favored)}
													</Link>
													<div>
														<img
															src={`/images/pro-main-001-like-02.png`}
															alt="thumbup"
															className="button_thumup"
															id="thumb-6"
															onClick={unlikeUser(item?.favored?.id)}
														/>
													</div>
												</div>
											</Card.Body>
										</div>
									</Card>
								</div>
							</React.Fragment>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

const renderInfoByType = (user) => {
	const userType = user?.user_type

	const currentYear = getCurrentYear()

	switch (userType) {
		case 'tourPro':
			return (
				<React.Fragment>
					<p className="font-L00 fontSize-15 color-greyish-two">
						메인스폰서
						<span className="color-greyish-brown ms-2 me-3">
							{user?.user_tour_pro?.main_sponsor}
						</span>
					</p>
					<p className="font-L00 fontSize-15 color-greyish-two">
						에이전시
						<span className="color-greyish-brown ms-2 me-3">
							{user?.user_tour_pro?.agency_name}
						</span>
					</p>
				</React.Fragment>
			)
		case 'tourCaddie':
			return (
				<React.Fragment>
					<p className="font-L00 fontSize-15 color-greyish-two">
						경력
						<span className="color-greyish-brown ms-2 me-3">
							{currentYear - user.user_tour_caddie?.employment_year + 1} 년차
						</span>
					</p>
					<p className="font-L00 fontSize-15 color-greyish-two cmb-10">
						구단
						<span className="color-greyish-brown ms-2">
							{user.user_tour_caddie?.club || '-'}
						</span>
					</p>
					{!!user?.user_tour_caddie?.weekly_salary_for_KPGA && (
						<button className="btn btn-light form_titleSubButton radius-5">
							KPGA / {formattingPrice(user?.user_tour_caddie?.weekly_salary_for_KPGA)}{' '}
							원
						</button>
					)}
					{!!user?.user_tour_caddie?.weekly_salary_for_KLPGA && (
						<button className="btn btn-light form_titleSubButton radius-5">
							KLPGA /{' '}
							{formattingPrice(user?.user_tour_caddie?.weekly_salary_for_KLPGA)} 원
						</button>
					)}
				</React.Fragment>
			)
		case 'houseCaddie':
			return (
				<React.Fragment>
					<p className="font-L00 fontSize-15 color-greyish-two">
						소속
						<span className="color-greyish-brown ms-2">
							{user.user_house_caddie?.club || '-'}
						</span>
					</p>
				</React.Fragment>
			)
		case 'agency':
			return (
				<React.Fragment>
					<p className="font-L00 fontSize-15 color-greyish-two">
						법인명
						<span className="color-greyish-brown ms-2">
							{user?.user_agency?.corporate_name}
						</span>
					</p>
					<p className="font-L00 fontSize-15 color-greyish-two">
						현재 소속 프로
						<span className="color-greyish-brown ms-2">
							{user?.user_agency?.players?.length}명
						</span>
					</p>
				</React.Fragment>
			)
		default:
			return
	}
}
