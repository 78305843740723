import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MatchingAgencyInfo, MatchingCaddieInfo, MatchingProInfo } from '../../components'
import { useStateMachine } from 'little-state-machine'
import {
	contractTypes,
	FETCH_HEADER_OPTIONS,
	getMatchTypeOptions,
	updateFormInput,
	useAuthStatus,
} from '../../utils'
import dayjs from 'dayjs'

export const MatchingHumanResourceDetailView = ({ API_ENDPOINT, PROFILE_BUCKET, ...props }) => {
	const [auth, onChangeAuthStatus] = useAuthStatus()

	const navigate = useNavigate()
	const { userId } = useParams()
	const { actions, state } = useStateMachine({ updateFormInput })

	const [user, setUser] = useState({})
	const [favoredUserIds, setFavoredUserIds] = useState([])
	const [serviceConfig, setServiceConfig] = useState({})

	useEffect(() => {
		fetchUserInfo()
		fetchFavoredUserIds()
		fetchSettingContract()
	}, [auth])

	const fetchUserInfo = async () => {
		return await fetch(`${API_ENDPOINT}/users/my-page/${userId}`)
			.then(async (response) => {
				const result = await response.json()

				setUser(result)
			})
			.catch((errors) => {
				console.log(errors)
				// setError('checkId', { type: 'custom', message: '사용중인 이메일 입니다.' })
			})
	}

	const fetchFavoredUserIds = async () => {
		await fetch(`${API_ENDPOINT}/users/my-page/${auth?.user?.id}/favored`)
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) throw new Error(result.massage)

				const ids = result.map((item) => item.favored_id)

				setFavoredUserIds(ids)
			})
			.catch((errors) => setFavoredUserIds([]))
	}

	const fetchContractsByStatus = async (status) => {
		return await fetch(`${API_ENDPOINT}/contract/contract/${userId}/${status}`)
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) throw result

				const color = (type) => {
					if (type === 'KPGA') return '#6495ed'
					else if (type === 'KLPGA') return '#ecdae3'
					else return '#aeaeae'
				}

				const schedules = result?.map((item) => {
					if (['single', 'long_term'].includes(item?.contract?.contract_type)) {
						const startedAt = item?.tour?.started_at?.substring(0, 10)
						const endedAt = item?.tour?.ended_at?.substring(0, 10)

						return {
							id: item?.contract?.id,
							seq: item?.contract?.id,
							title: item?.tour?.name,
							start: dayjs(startedAt).format('YYYY-MM-DD'),
							end: dayjs(endedAt).add(1, 'days').format('YYYY-MM-DD'),
							color: color(item?.tour?.type),
							allDay: true,
						}
					} else if (
						['year_first_half', 'year_second_half'].includes(
							item?.contract?.contract_type,
						)
					) {
						return {
							id: item?.contract?.id,
							seq: item?.contract?.id,
							title: getMatchTypeOptions(
								contractTypes,
								item?.contract?.contract_type,
							),
							start: dayjs(item?.contracted_at).format('YYYY-MM-DD'),
							end:
								item?.contract?.contract_type === 'year_first_half'
									? dayjs(serviceConfig?.yearDate)
											.add(1, 'days')
											.format('YYYY-MM-DD')
									: dayjs(new Date(new Date().getFullYear(), 11, 31))
											.add(1, 'days')
											.format('YYYY년 MM월 DD일'),
							color: color(item?.tour?.type),
							allDay: true,
						}
					}
				})

				return schedules
			})
			.catch((errors) => console.log(errors))
	}

	const fetchSettingContract = async () => {
		return await fetch(`${API_ENDPOINT}/admin/payment-manage/admin/setting`)
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) throw result

				setServiceConfig(result)
			})
			.catch((errors) => console.log(errors))
	}

	const onClickFavored = (favoredUserId) => async (event) => {
		const isFavored = favoredUserIds.includes(favoredUserId)
		const apiMethod = isFavored ? `DELETE` : 'POST'

		await fetch(`${API_ENDPOINT}/users/my-page/${auth?.user?.id}/favored`, {
			method: apiMethod,
			headers: FETCH_HEADER_OPTIONS,
			body: JSON.stringify({ favoredUserId: favoredUserId }),
		})
			.then(async (response) => {
				const result = await response.json

				if (result.success === false) {
					throw new Error('관심인재 등록/삭제 실패')
				}

				fetchFavoredUserIds()
			})
			.catch((errors) => {
				console.log(errors)
			})
	}

	const onClickProposal = () => {
		const payload = {
			contractInput: {
				...state?.contractInput,
				userId: auth?.user?.id,
				targetUserId: userId,
			},
		}

		actions.updateFormInput(payload)

		navigate('/proposal/create/type')
	}

	return (
		<div className="wrap">
			<div className="content_area">
				<div className="container mainWrap">
					{user?.user_type === 'tourPro' && (
						<MatchingProInfo
							API_ENDPOINT={API_ENDPOINT}
							PROFILE_BUCKET={PROFILE_BUCKET}
							user={user}
							favoredUserIds={favoredUserIds}
							fetchContractsByStatus={fetchContractsByStatus}
							onClickFavored={onClickFavored}
							onClickProposal={onClickProposal}
						/>
					)}

					{['tourCaddie', 'houseCaddie'].includes(user?.user_type) && (
						<MatchingCaddieInfo
							API_ENDPOINT={API_ENDPOINT}
							PROFILE_BUCKET={PROFILE_BUCKET}
							user={user}
							favoredUserIds={favoredUserIds}
							fetchContractsByStatus={fetchContractsByStatus}
							onClickFavored={onClickFavored}
							onClickProposal={onClickProposal}
						/>
					)}

					{user?.user_type === 'agency' && (
						<MatchingAgencyInfo
							API_ENDPOINT={API_ENDPOINT}
							PROFILE_BUCKET={PROFILE_BUCKET}
							user={user}
							favoredUserIds={favoredUserIds}
							fetchContractsByStatus={fetchContractsByStatus}
							onClickFavored={onClickFavored}
							onClickProposal={onClickProposal}
						/>
					)}
				</div>
			</div>
		</div>
	)
}
