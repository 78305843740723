import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FETCH_HEADER_OPTIONS, isEmpty, useAuthStatus } from '../../utils'
import { MessageModal } from '../../components'

export const MyAccountView = ({ API_ENDPOINT, ...props }) => {
	const [auth, onChangeAuth] = useAuthStatus()

	const { register, setValue, handleSubmit } = useForm({
		mode: 'onBlur',
	})
	const [account, setAccount] = useState(null)

	useEffect(() => {
		fetchAccount()
	}, [])

	const fetchAccount = async () => {
		await fetch(`${API_ENDPOINT}/users/my-page/${auth.user.id}/bank-account`)
			.then(async (response) => {
				const result = await response.json()

				if (result.length > 0) {
					setValue('bankName', result[0].bank_name)
					setValue('ownerName', result[0].owner_name)
					setValue('accountNumber', result[0].account_number)

					setAccount(result[0])
				}
			})
			.catch((errors) => console.log(errors))
	}

	const onSubmit = async (data) => {
		const input = { ...data, id: isEmpty(account) ? '0' : account.id }

		await fetch(`${API_ENDPOINT}/users/my-page/${auth.user.id}/bank-account`, {
			method: 'PATCH',
			headers: FETCH_HEADER_OPTIONS,
			body: JSON.stringify(input),
		})
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) {
					throw new Error(result.message)
				}

				handleMessageModalOpen('저장되었습니다.')
				setAccount(result)
			})
			.catch((errors) => {
				handleMessageModalOpen('저장에 실패했습니다.')
			})
	}

	// modal
	const [messageModalVisible, setMessageModalVisible] = useState(false)
	const [messageModalContent, setMessageModalContent] = useState('')

	const handleMessageModalClose = () => {
		setMessageModalContent('')
		setMessageModalVisible(false)
	}
	const handleMessageModalOpen = (message) => {
		setMessageModalContent(message)
		setMessageModalVisible(true)
	}

	return (
		<div className="wrap">
			<div className="content_area">
				<div className="container mainWrap">
					<div className="cmb-30" style={{ fontSize: '3rem' }}>
						결제 관리
					</div>

					<div className="card cmb-30">
						<div className="card-body">
							<p className="color-greyish-brown fontSize-25 font-L00 cmb-10">
								계좌 정보
							</p>
							<p className="color-greyish-brown fontSize-22 font-L00 cmb-10">
								계좌 정보는 계약 대상에게 정보 전달용으로만 사용되며, 스포이음은
								이를 따로 활용하지 않습니다.
							</p>

							<div className="row align-items-end">
								<div className="col-auto cmb-10">
									<select
										id="bankSelect"
										defaultValue={'0'}
										aria-label="Default select example"
										className="form-select rounded-pill"
										style={{ width: '7rem' }}
										{...register('bankName', {
											required: true,
											validate: (bankName) => bankName !== '0',
										})}>
										<option value="0">은행 선택</option>
										<option value="경남은행">경남은행</option>
										<option value="광주은행">광주은행</option>
										<option value="국민은행">국민은행</option>
										<option value="기업은행">기업은행</option>
										<option value="농협중앙회">농협중앙회</option>
										<option value="농협회원조합">농협회원조합</option>
										<option value="대구은행">대구은행</option>
										<option value="도이치은행">도이치은행</option>
										<option value="부산은행">부산은행</option>
										<option value="산업은행">산업은행</option>
										<option value="상호저축은행">상호저축은행</option>
										<option value="새마을금고">새마을금고</option>
										<option value="수협중앙회">수협중앙회</option>
										<option value="신한금융투자">신한금융투자</option>
										<option value="신한은행">신한은행</option>
										<option value="신협중앙회">신협중앙회</option>
										<option value="외환은행">외환은행</option>
										<option value="우리은행">우리은행</option>
										<option value="우체국">우체국</option>
										<option value="전북은행">전북은행</option>
										<option value="제주은행">제주은행</option>
										<option value="카카오뱅크">카카오뱅크</option>
										<option value="케이뱅크">케이뱅크</option>
										<option value="하나은행">하나은행</option>
										<option value="한국씨티은행">한국씨티은행</option>
										<option value="HSBC은행">HSBC은행</option>
										<option value="SC제일은행">SC제일은행</option>
									</select>
								</div>

								<div className="col-auto cmb-10">
									<input
										type="text"
										className="underbarInput"
										id="reg_bank_onername"
										placeholder="예금주 입력"
										{...register('ownerName', {
											required: true,
										})}
									/>
								</div>

								<div className="col-12 col-md-5 cmb-10">
									<input
										type="text"
										className="underbarInput"
										style={{ width: '80%' }}
										id="reg_bank_num"
										placeholder="계좌번호 -없이 숫자만 입력해 주세요"
										{...register('accountNumber', {
											required: true,
										})}
									/>
								</div>

								<div
									className="col-12 col-md-auto btn btn-dark border border-greyish-brown font-R00 fontSize-20 color-white pl-2 pr-2"
									onClick={handleSubmit(onSubmit)}>
									확인
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<MessageModal
				modalVisible={messageModalVisible}
				content={messageModalContent}
				onClose={handleMessageModalClose}
			/>
		</div>
	)
}
