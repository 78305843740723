export const AboutView = ({ ...props }) => {
	return (
		<div className="wrap">
			<div className="content_area">
				<div className="container mainWrap">
					<div
						className="font-SB00 color-greyish-brown cmb-30 text-center"
						style={{ fontSize: '3rem' }}>
						스포이음 소개
					</div>

					<div className="row align-items-stretch">
						<div className="col-12 col-md-4 cmb-20">
							<div className="card h-100">
								<div style={{ paddingTop: '3.5rem', paddingBottom: '3.5rem' }}>
									<div className="d-flex justify-content-center cmb-10">
										<img
											style={{ width: '58px', justifyContent: 'center' }}
											src={`/images/aboutus_icn001.png`}
											alt="SPOEUMAbout1"
										/>
									</div>
									<p className="fontSize-20 font-M00 color-greyish-brown text-center cmb-20">
										1. 대한민국 골프시장의 기준
									</p>
									<p className="fontSize-14 font-L00 color-greyish-brown text-center">
										- 대한민국 최초의 투어프로에게 필요한 전문인력 1:1 매칭
										서비스를
									</p>
									<p className="fontSize-14 font-L00 color-greyish-brown text-center">
										제공함으로써 세계적으로 성장하고 있는
									</p>
									<p className="fontSize-14 font-L00 color-greyish-brown text-center">
										대한민국 골프시장에선의 기준을 제시
									</p>
								</div>
							</div>
						</div>

						<div className="col-12 col-md-4 cmb-20">
							<div className="card h-100">
								<div style={{ padding: '3.5rem 0' }}>
									<div className="d-flex justify-content-center cmb-10">
										<img
											style={{ width: '58px', justifyContent: 'center' }}
											src={`/images/aboutus_icn002.png`}
											alt="SPOEUMAbout2"
										/>
									</div>
									<p className="fontSize-20 font-M00 color-greyish-brown text-center cmb-20">
										2. 직업 정보 제공
									</p>
									<p className="fontSize-14 font-L00 color-greyish-brown text-center">
										- KPGA, KLPGA 투어프로에게 전문인력의 정확한 정보를
									</p>
									<p className="fontSize-14 font-L00 color-greyish-brown text-center">
										제공함으로써 시장의 투명성 및 공정성 제공과 동시에 한정 된
									</p>
									<p className="fontSize-14 font-L00 color-greyish-brown text-center">
										정보 안에서만 이루어졌던 구인구직의 탈피를 목적
									</p>
								</div>
							</div>
						</div>

						<div className="col-12 col-md-4 cmb-20">
							<div className="card h-100">
								<div style={{ padding: '3.5rem 0' }}>
									<div className="d-flex justify-content-center cmb-10">
										<img
											style={{ width: '58px', justifyContent: 'center' }}
											src={`/images/aboutus_icn003.png`}
											alt="SPOEUMAbout3"
										/>
									</div>
									<p className="fontSize-20 font-M00 color-greyish-brown text-center cmb-20">
										3. 표준 계약서
									</p>
									<p className="fontSize-14 font-L00 color-greyish-brown text-center">
										- 표준 계약서 작성을 통한 투어프로 및 전문인력을
									</p>
									<p className="fontSize-14 font-L00 color-greyish-brown text-center">
										법적 보호를 받을 수 있게 함과 동시에 전문인력의 추가
									</p>
									<p className="fontSize-14 font-L00 color-greyish-brown text-center">
										- 근로를 통한 처우 개선 가능
									</p>
									<p className="fontSize-14 font-L00 color-greyish-brown text-center">
										- 엄선된 캐디만을 회원으로 가입. 대한민국 골프투어의
									</p>
									<p className="fontSize-14 font-L00 color-greyish-brown text-center">
										질적 향상 도모
									</p>
								</div>
							</div>
						</div>

						<div className="col-12 col-md-4 cmb-20">
							<div className="card h-100">
								<div style={{ padding: '3.5rem 0' }}>
									<div className="d-flex justify-content-center cmb-10">
										<img
											style={{ width: '58px', justifyContent: 'center' }}
											src={`/images/aboutus_icn004.png`}
											alt="SPOEUMAbout4"
										/>
									</div>
									<p className="fontSize-20 font-M00 color-greyish-brown text-center cmb-20">
										4. 에이전시 기능 강화
									</p>
									<p className="fontSize-14 font-L00 color-greyish-brown text-center">
										- 투어프로의 권한을 위임 받아 소속 투어프로에게 필요한
									</p>
									<p className="fontSize-14 font-L00 color-greyish-brown text-center">
										전문인력을 1:1 매칭 서비스를 제공
									</p>
									<p className="fontSize-14 font-L00 color-greyish-brown text-center">
										- 투어프로 및 에이전시간의 효율적인 업무 프로세스 제공
									</p>
								</div>
							</div>
						</div>

						<div className="col-12 col-md-4 cmb-20">
							<div className="card h-100">
								<div style={{ padding: '3.5rem 0' }}>
									<div className="d-flex justify-content-center cmb-10">
										<img
											style={{ width: '58px', justifyContent: 'center' }}
											src={`/images/aboutus_icn005.png`}
											alt="SPOEUMAbout5"
										/>
									</div>
									<p className="fontSize-20 font-M00 color-greyish-brown text-center cmb-20">
										5. 세무처리에 필요한 자료 제공
									</p>
									<p className="fontSize-14 font-L00 color-greyish-brown text-center">
										- 매 시합 수작업을 통해 지출했던 경비 내역을 체크하던 방법을
									</p>
									<p className="fontSize-14 font-L00 color-greyish-brown text-center">
										탈피하여 언제든지 클릭 한번으로 본인의 지출 내역을
									</p>
									<p className="fontSize-14 font-L00 color-greyish-brown text-center">
										확인할 수 있는 서비스 제공
									</p>
								</div>
							</div>
						</div>

						<div className="col-12 col-md-4 cmb-20">
							<div className="card h-100">
								<div style={{ padding: '3.5rem 0' }}>
									<div className="d-flex justify-content-center cmb-10">
										<img
											style={{ width: '58px', justifyContent: 'center' }}
											src={`/images/aboutus_icn006.png`}
											alt="SPOEUMAbout6"
										/>
									</div>
									<p className="fontSize-20 font-M00 color-greyish-brown text-center cmb-20">
										6. 양자간의 계약 조건 협상 기능
									</p>
									<p className="fontSize-14 font-L00 color-greyish-brown text-center">
										- 대면으로 양자간의 의사 전달이 어려웠던 사항들을
									</p>
									<p className="fontSize-14 font-L00 color-greyish-brown text-center">
										디대면 플랫폼을 통해 서로의 계약 조건을
									</p>
									<p className="fontSize-14 font-L00 color-greyish-brown text-center">
										명확하게 전달
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
