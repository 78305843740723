import React, { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import Modal from 'react-bootstrap/Modal'
import { formattingPrice, parsePriceToNumber, parseStringToBool } from '../../utils'

export const ModalSuggestionForm = ({
	modalVisible,
	modalTitle,
	fieldValues,
	contractType,
	createContractList,
	onClose,
	...props
}) => {
	const { control, register, getValues, setValue, watch, handleSubmit } = useForm({
		values: fieldValues,
		mode: 'onBlur',
	})
	const { fields, remove, insert } = useFieldArray({
		control,
		name: 'incentives',
		rules: { maxLength: 10 },
	})

	const [expenseReimbursementJejuVisible, setExpenseReimbursementJejuVisible] = useState(false)

	useEffect(() => {
		setExpenseReimbursementJejuVisible(parseInt(fieldValues?.expenseReimbursementJeju) > 0)
	}, [modalVisible])

	const onSubmit = async (data) => {
		const input = {
			...data,
			isTaxIncluded: parseStringToBool(data?.isTaxIncluded),
			isIncentiveTaxIncluded: parseStringToBool(data?.isIncentiveTaxIncluded),
			wearSponsoredHat: parseStringToBool(data?.wearSponsoredHat),
			weeklySalary: parsePriceToNumber(data?.weeklySalary),
			weeklySalaryF: parsePriceToNumber(data?.weeklySalaryF),
			expenseReimbursementJeju: parsePriceToNumber(data?.expenseReimbursementJeju),
		}

		await createContractList(input)
	}

	const onKeyupPrice = (key) => (event) => {
		const price = formattingPrice(event.target.value)

		setValue(key, price)
	}

	const addIncentive = (index) => (event) => {
		event.preventDefault()

		const prevIncentive = getValues('incentives')[index - 1]
		insert(index, { startRank: prevIncentive?.endRank + 1, percentage: 1 })
	}

	const removeIncentive = (index) => (event) => {
		event.preventDefault()
		remove(index)
	}

	const onClickExpenseReimbursementJeju = (value) => (event) => {
		setValue('expenseReimbursementJeju', '0')
		setExpenseReimbursementJejuVisible(value)
	}

	return (
		<Modal
			id="anoterSuggestionModal"
			show={modalVisible}
			size="lg"
			backdrop="static"
			tabIndex={-1}
			centered
			aria-labelledby="anoterSuggestionModalLabel"
			aria-hidden="true"
			className="wrap register_page form_page re_form">
			<Modal.Header className="border-0">
				<p id="staticBackdropLabel" className="modal-title w-100 text-center font-bold">
					{modalTitle}
				</p>
				<button type="button" className="btn-close" aria-label="Close" onClick={onClose} />
			</Modal.Header>
			<Modal.Body>
				<form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
					<span className="form_title pe-1">임금 세금포함 여부</span>
					<span className="regTaxText">원천징수 3.3%</span>
					<div className="row cmt-20 cmb-20">
						<div className="col cmb-10">
							<input
								id="suggestion_paymentTax_1"
								type="radio"
								className="btn-check"
								autoComplete="off"
								value="true"
								{...register('isTaxIncluded', {
									required: true,
								})}
							/>
							<label
								className="btn btn-light btn-whiteShadow"
								htmlFor="suggestion_paymentTax_1">
								세전
							</label>
						</div>
						<div className="col cmb-10">
							<input
								id="suggestion_paymentTax_2"
								type="radio"
								className="btn-check"
								autoComplete="off"
								value="false"
								{...register('isTaxIncluded', {
									required: true,
								})}
							/>
							<label
								className="btn btn-light btn-whiteShadow"
								htmlFor="suggestion_paymentTax_2">
								세후
							</label>
						</div>
					</div>

					<p className="form_title">
						{contractType === 'single' && (
							<React.Fragment>
								단일계약(주급)
								<span className="regTaxText">
									컷통과와 컷탈락의 금액을 동일하게 설정하길 희망할 경우 동일한
									금액을 입력해 주세요.
								</span>
							</React.Fragment>
						)}
						{contractType === 'long_term' && (
							<React.Fragment>
								장기계약(주급)
								<span className="regTaxText">
									컷통과와 컷탈락의 금액을 동일하게 설정하길 희망할 경우 동일한
									금액을 입력해 주세요.
								</span>
							</React.Fragment>
						)}
						{contractType === 'year_first_half' && '연봉(상반기)'}
						{contractType === 'year_second_half' && '연봉(하반기)'}
					</p>
					{['single', 'long_term'].includes(contractType) && (
						<React.Fragment>
							<div className="row align-items-end cmt-10 cmb-30">
								<div className="col-12 col-md-6 cmb-10">
									<div className="row align-items-center">
										<div className="col-auto reg_subTitle text-end">컷통과</div>
										<div className="col">
											<div className="d-flex justify-content-end align-items-center underbarInput">
												<input
													id="weeklySalary"
													className="border-0 w-100 form-control no-focus form_inputText regUnderbarInput"
													placeholder="금액을 입력해 주세요."
													{...register('weeklySalary', {
														required: true,
														onChange: onKeyupPrice('weeklySalary'),
													})}
												/>
												원
											</div>
										</div>
									</div>
								</div>

								<div className="col-12 col-md-6 cmb-10">
									<div className="row align-items-center">
										<div className="col-auto reg_subTitle text-end">컷탈락</div>
										<div className="col">
											<div className="d-flex justify-content-end align-items-center underbarInput">
												<input
													id="weeklySalaryF"
													className="border-0 w-100 form-control no-focus form_inputText regUnderbarInput"
													placeholder="금액을 입력해 주세요."
													{...register('weeklySalaryF', {
														required: true,
														onChange: onKeyupPrice('weeklySalaryF'),
													})}
												/>
												원
											</div>
										</div>
									</div>
								</div>
							</div>
						</React.Fragment>
					)}

					{['year_first_half', 'year_second_half'].includes(contractType) && (
						<div className="row align-items-end cmt-10 cmb-30">
							<div className="col">
								<div className="d-flex justify-content-end align-items-center underbarInput">
									<input
										id="weeklySalary"
										className="border-0 w-100 form-control no-focus form_inputText regUnderbarInput"
										placeholder="금액을 입력해 주세요."
										{...register('weeklySalary', {
											required: true,
											onChange: onKeyupPrice('weeklySalary'),
										})}
									/>
									원
								</div>
							</div>
						</div>
					)}

					{['single', 'long_term'].includes(contractType) && (
						<React.Fragment>
							<p className="form_title">인센티브 방식</p>
							<div className="row cmb-30">
								<div className="col">
									<input
										id="suggestion_incentiveTax_1"
										type="radio"
										className="btn-check"
										name="radioTax2"
										autoComplete="off"
										value="true"
										{...register('isIncentiveTaxIncluded')}
									/>
									<label
										className="btn btn-light btn-whiteShadow"
										htmlFor="suggestion_incentiveTax_1">
										상금 표기액
									</label>
								</div>
								<div className="col">
									<input
										id="suggestion_incentiveTax_2"
										type="radio"
										className="btn-check"
										autoComplete="off"
										value="false"
										{...register('isIncentiveTaxIncluded')}
									/>
									<label
										className="btn btn-light btn-whiteShadow"
										htmlFor="suggestion_incentiveTax_2">
										상금 실수령액
									</label>
								</div>
							</div>

							<p className="form_title">인센티브</p>
							<div className="row" id="incenBox">
								{fields.map((field, index) => {
									return (
										<div className="col-12 cmb-10" key={field?.id}>
											<div className="row flex-nowrap align-items-center">
												<div className="col-1 fontSize-16 font-SB00 text-end incenNum">
													{index + 1}
												</div>
												<div className="col fontSize-16 font-SB00 text-end">
													<div
														className="row justify-content-end pb-2"
														style={{
															display: index === 0 ? 'none' : 'auto',
															flexWrap: 'nowrap',
														}}>
														<input
															type="number"
															className="border-0 border-bottom no-focus text-end p-0"
															placeholder="등수"
															style={{
																width: '40px',
															}}
															{...register(
																`incentives.${index}.startRank`,
																{
																	required: true,
																	valueAsNumber: true,
																	min: 0,
																},
															)}
														/>
														~
														<input
															type="number"
															className="border-0 border-bottom no-focus text-end p-0"
															placeholder="등수"
															style={{
																display:
																	field?.endRank === 0
																		? 'none'
																		: 'auto',
																width: '40px',
															}}
															{...register(
																`incentives.${index}.endRank`,
																{
																	required: true,
																	valueAsNumber: true,
																	min: 0,
																},
															)}
														/>
														{field?.endRank === 0 && ' CUT'}
													</div>
													{index === 0 && '우승'}
												</div>
												<div className="col-md-4 col-3 d-flex">
													<div className="incentiveItemInput underbarInput">
														<input
															type="number"
															id="incen_win"
															className="rgPro003_noBorderInput rgPro003_incentiveInput p-right"
															key={field?.id}
															placeholder="0"
															{...register(
																`incentives.${index}.percentage`,
																{
																	required: true,
																	valueAsNumber: true,
																	min:
																		field?.endRank === 0
																			? 0
																			: 1,
																	max: 100,
																},
															)}
														/>
														%
													</div>
												</div>

												<div className="col-auto ps-0">
													<button
														type="button"
														className="btn btn-danger mb-2 onlyPc"
														style={{
															visibility:
																index === 0 ||
																index === fields?.length - 1
																	? 'hidden'
																	: 'auto',
														}}
														onClick={removeIncentive(index)}>
														삭제
													</button>
													<button
														type="button"
														className="btn btn-danger mb-2 onlyMobile"
														style={{
															padding: '0.4rem',
															lineHeight: '0.8',
															visibility:
																index === 0 ||
																index === fields?.length - 1
																	? 'hidden'
																	: 'auto',
														}}
														onClick={removeIncentive(index)}>
														X
													</button>
												</div>
											</div>
										</div>
									)
								})}
							</div>
							<button
								type="button"
								className="btn btn-light rgPro003_addIncentive"
								disabled={fields?.length >= 9}
								onClick={addIncentive(fields?.length - 1)}>
								+ 추가하기
							</button>

							<div className="row align-items-center cmb-10">
								<div className="col-auto regNWExpensesText text-end">
									제주대회 출전 시
								</div>
								{expenseReimbursementJejuVisible && (
									<div className="col">
										<div className="d-flex justify-content-end">
											<div className="col justify-content-end regUnderbarInput">
												<input
													className="border-0 no-focus text-end"
													id="expenseReimbursementJeju"
													placeholder="희망 금액을 입력해 주세요."
													{...register('expenseReimbursementJeju', {
														required: expenseReimbursementJejuVisible,
														onChange: onKeyupPrice(
															'expenseReimbursementJeju',
														),
													})}
												/>
												원
											</div>
										</div>
									</div>
								)}
							</div>
							<div className="row cmb-30">
								<div className="col">
									<input
										type="radio"
										className="btn-check"
										id="suggestion_jeju_1"
										autoComplete="off"
										checked={expenseReimbursementJejuVisible}
										onClick={onClickExpenseReimbursementJeju(true)}
									/>
									<label
										className="btn btn-light btn-whiteShadow"
										htmlFor="suggestion_jeju_1">
										지원
									</label>
								</div>
								<div className="col">
									<input
										type="radio"
										className="btn-check"
										id="suggestion_jeju_2"
										autoComplete="off"
										checked={!expenseReimbursementJejuVisible}
										onClick={onClickExpenseReimbursementJeju(false)}
									/>
									<label
										className="btn btn-light btn-whiteShadow"
										htmlFor="suggestion_jeju_2">
										미지원
									</label>
								</div>
							</div>

							<p className="form_title">선수 스폰서 모자 착용 가능 여부</p>
							<div className="row cmb-30">
								<div className="col">
									<input
										id="suggestion_wearing"
										type="radio"
										className="btn-check"
										autoComplete="off"
										value="true"
										{...register('wearSponsoredHat')}
									/>
									<label
										className="btn btn-light btn-whiteShadow"
										htmlFor="suggestion_wearing">
										착용
									</label>
								</div>
								<div className="col">
									<input
										id="suggestion_notWorn"
										type="radio"
										className="btn-check"
										autoComplete="off"
										value="false"
										{...register('wearSponsoredHat')}
									/>
									<label
										className="btn btn-light btn-whiteShadow"
										htmlFor="suggestion_notWorn">
										미착용
									</label>
								</div>
							</div>
						</React.Fragment>
					)}

					<p className="form_title">기타사항</p>
					<div className="row cmb-30">
						<div className="col">
							<textarea
								id="suggestion_memo"
								className="w-100"
								rows="3"
								placeholder="기타 내용을 입력해 주세요."
								{...register('etc', {
									required: true,
								})}
							/>
						</div>
					</div>

					<button
						type="submit"
						id="suggestion_submitBtn"
						className="btn btn-dark nextPageButton">
						제안하기
					</button>
				</form>
			</Modal.Body>
		</Modal>
	)
}
