export const ModalPP = () => {
	return (
		<div
			className="modal fade"
			id="ppModal"
			data-bs-backdrop="static"
			tabIndex="-1"
			aria-labelledby="ppModalLabel"
			aria-hidden="true">
			<div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">개인정보취급방침</h5>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"></button>
					</div>
					<div className="modal-body">
						<div className="WordSection1">
							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '22pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										개인정보처리방침
									</span>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										&nbsp;
									</span>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										주식회사 스포이음
									</span>
								</b>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									(
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									이하 “회사”<span lang="EN-US">)</span>은 『정보통신망 이용촉진
									및 정보보호 등에 관한 법률』<span lang="EN-US">, </span>
									『통신비밀보호법』<span lang="EN-US">,</span>『전기통신사업법』
									<span lang="EN-US">, </span>『개인정보보호법』 등
									정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호
									규정을 준수하며<span lang="EN-US">, </span>관련 법령에 의거한
									개인정보처리방침을 정하여 이용자의 권익 보호에 최선을 다하고
									있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '12pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									&nbsp;
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									본 개인정보처리방침은 이용자가 회사가 관리 · 운영하는 스포이음
									서비스 플랫폼<span lang="EN-US">(</span>웹
									<span lang="EN-US">, </span>모바일 웹 · 앱 포함
									<span lang="EN-US">,</span>이하 <span lang="EN-US">‘</span>
									플랫폼<span lang="EN-US">’</span>이라 합니다
									<span lang="EN-US">)</span>에서 제공하는 서비스
									<span lang="EN-US">(</span>이하 <span lang="EN-US">‘</span>
									서비스라 합니다<span lang="EN-US">)</span>를 이용함에 적용되며
									<span lang="EN-US">, </span>다음과 같은 내용을 담고 있습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">1</span>조<span lang="EN-US"> (</span>
										수집하는 개인정보 항목 및 수집방법
										<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: "'HY신명조', serif",
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 이용자의 회원가입 및 관리<span lang="EN-US">,</span>
									원활한 고충처리<span lang="EN-US">, </span>각종 서비스의 제공을
									위해 다양한 소셜 로그인을 지원하며<span lang="EN-US">, </span>이
									과정에서 이용자로부터 다음의 개인정보를 수집하고 있습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										marginLeft: '18pt',
										textIndent: '-18pt',
										lineHeight: '150%',
									}}>
									> ②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									제<span lang="EN-US">1</span>항의 개인정보 항목 외에 서비스 이용
									과정이나 앱 실행 과정에서<span lang="EN-US"> IP Address, </span>
									쿠키<span lang="EN-US">, </span>방문일시
									<span lang="EN-US">, </span>서비스 이용기록
									<span lang="EN-US">, </span>불량 이용기록
									<span lang="EN-US">, </span>기기정보<span lang="EN-US">, </span>
									위치정보<span lang="EN-US">(</span>위치기반서비스약관 동의 시
									<span lang="EN-US">)</span>가 수집될 수 있으며
									<span lang="EN-US">, </span>고객 문의 접수 및 처리 과정에서
									이메일 주소나 전화번호가 수집될 수도 있습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: "'HY신명조', serif",
									}}>
									③
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사가 제공하는 서비스 이용과정에서 이용자의 식별 및 본인여부
									<span lang="EN-US">, </span>연령 확인이 필요한 경우 이름
									<span lang="EN-US">, </span>생년월일<span lang="EN-US">,</span>
									성별<span lang="EN-US">, </span>내외국인정보
									<span lang="EN-US">, </span>휴대폰번호 또는 아이핀번호
									<span lang="EN-US">, </span>암호화된 동일인 식별연계정보
									<span lang="EN-US">(CI), </span>중복가입확인정보
									<span lang="EN-US">(DI).</span>미성년자의 경우 법정대리인정보가
									자동으로 생성 및 추가되어 수집될 수 있습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: "'HY신명조', serif",
									}}>
									④
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 이용자의 기본적 인권 침해 우려가 있는 민감한 개인정보
									<span lang="EN-US">(</span>인종<span lang="EN-US">, </span>사상
									<span lang="EN-US">, </span>신조<span lang="EN-US">, </span>
									정치적 성향<span lang="EN-US">, </span>범죄기록
									<span lang="EN-US">, </span>의료 정보 등
									<span lang="EN-US">)</span>는 수집하지 않으며
									<span lang="EN-US">, </span>
									<b>
										이용자가 선택 항목의 정보를 입력하지 않은 경우에도 회사가
										제공하는 서비스 이용에 제한은 없습니다
										<span lang="EN-US">.</span>
									</b>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: "'HY신명조', serif",
									}}>
									⑤
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 수집한 개인정보를 수집 목적 이외의 용도로 사용되지 않으며
									<b>
										수집 및 이용 목적이 변경될 시에는 이용자로부터 별도의
										사전동의를 구합니다<span lang="EN-US">.</span>
									</b>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: "'HY신명조', serif",
									}}>
									⑥
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 다음과 같은 방법으로 이용자의 개인정보를 수집할 수
									있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									-{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									홈페이지<span lang="EN-US">,</span>모바일기기
									<span lang="EN-US">, </span>서면양식<span lang="EN-US">, </span>
									팩스<span lang="EN-US">,</span>전화<span lang="EN-US">, </span>
									상담 게시판<span lang="EN-US">, </span>이메일
									<span lang="EN-US">,</span>이벤트 응모
									<span lang="EN-US">, </span>배송요청
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									-{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									생성정보 수집 툴을 통한 수집
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">2</span>조<span lang="EN-US">(</span>
										수집한 개인정보의 이용목적<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 이용자로부터 수집한 정보를 다음과 같은 목적을 위해
									활용합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									-{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회원가입<span lang="EN-US">, </span>원활한 고객상담
									<span lang="EN-US">, </span>이용자와 약속한 서비스 제공
									<span lang="EN-US">, </span>서비스 제공에 따른 본인인증
									<span lang="EN-US">, </span>구매 및 요금결제
									<span lang="EN-US">, </span>회사의 서비스 이용내역 제공
									<span lang="EN-US">,</span>결제 수단의 등록 등을 위하여
									개인정보를 이용합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									-{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회원 가입 의사의 확인<span lang="EN-US">,</span>연령 확인 및
									법정대리인 동의 진행<span lang="EN-US">, </span>이용자 및
									법정대리인의 본인 확인<span lang="EN-US">, </span>이용자 식별
									<span lang="EN-US">, </span>회원탈퇴 의사의 확인
									<span lang="EN-US">, </span>문의사항 또는 불만처리 등{' '}
									<u>회원관리</u>를 위하여 개인정보를 이용합니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									-{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									법령 및 회사가 정한 정책<span lang="EN-US">(</span>서비스
									이용약관 등 포함<span lang="EN-US">)</span>을 위반하는 이용자에
									대한 이용 제한 조치<span lang="EN-US">, </span>부정 이용 행위를
									포함하여 서비스의 원활한 운영에 지장을 주는 행위 및 비인가
									행위에 대한 방지 및 제재<span lang="EN-US">,</span>계정도용 및
									부정거래 방지<span lang="EN-US">, </span>고지사항 전달
									<span lang="EN-US">, </span>분쟁 조정을 위한 기록보존 등
									<u>이용자 보호 및 서비스 이용환경을 확인하고 안정적인 운영</u>을
									위하여 개인정보를 이용합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									-{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									인구통계학적 특성에 따른 서비스 제공<span lang="EN-US">,</span>
									접속 빈도 분석<span lang="EN-US">, </span>기능개선
									<span lang="EN-US">, </span>서비스 이용에 대한 통계
									<span lang="EN-US">, </span>서비스 분석 및 통계에 기반하여
									이용자의 상품 구매 및 서비스 이용 성향
									<span lang="EN-US">, </span>관심<span lang="EN-US">, </span>
									이용기록 분석 등을 반영한 신규 서비스 제공
									<span lang="EN-US">(</span>개인 맞춤형 상품 추천 서비스 등 포함
									<span lang="EN-US">)</span>등에 개인정보를 이용합니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									-{' '}
								</span>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										이벤트 정보<span lang="EN-US">, </span>광고성 정보 제공 등
										이벤트 및 프로모션의 목적 등
									</span>
								</b>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									에 개인정보를 이용합니다<span lang="EN-US">(</span>마케팅
									<span lang="EN-US">, </span>광고성 정보 제공 동의 시
									<span lang="EN-US">).</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">3</span>조<span lang="EN-US">(</span>
										개인정보 보유 및 이용기간<span lang="EN-US">) </span>
									</span>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 원칙적으로 이용자의 개인정보를 회원 탈퇴 시까지
									보유합니다<span lang="EN-US">. </span>다만
									<span lang="EN-US">, </span>회사는 거래 관련 분쟁 방지 등을
									위하여 다음과 같은 경우에는 회원 탈퇴 이후에도 명시한 기간 동안
									보유합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										가<span lang="EN-US">.</span>
									</span>
								</b>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사 내부 방침에 의한 개인정보 보존 사유
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										나<span lang="EN-US">.</span>
									</span>
								</b>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									이용자에 대하여 관계 법령 위반에 따른 수사 · 조사 등이 진행중인
									경우<span lang="EN-US">: </span>해당 수사 · 조사 종료 시까지{' '}
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										다<span lang="EN-US">.</span>
									</span>
								</b>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사와 이용자 사이에 채권 · 채무관계가 잔존하는 경우
									<span lang="EN-US">: </span>해당 채권 · 채무관계 정산 완료
									시까지{' '}
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										라<span lang="EN-US">.</span>
									</span>
								</b>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									상법<span lang="EN-US">, </span>『전자상거래 등에서의
									소비자보호에 관한 법률』 등 관계 법령의 규정에 따라 보존할
									필요가 있는 경우 회사는 관계 법령에서 정한 일정한 기간 동안
									이용자의 정보를 보존합니다<span lang="EN-US">.</span>이 경우
									회사는 보존하는 정보를 그 보존의 목적으로만 사용하며 보존기간은
									아래와 같습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<u>
										<span
											lang="EN-US"
											style={{
												fontSize: '11pt',
												lineHeight: '150%',
												fontFamily: '나눔스퀘어',
											}}>
											-
										</span>
									</u>
								</b>
								<b>
									<u>
										<span
											style={{
												fontSize: '11pt',
												lineHeight: '150%',
												fontFamily: '나눔스퀘어',
											}}>
											소비자의 불만 또는 분쟁처리에 관한 기록
										</span>
									</u>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									보존이유<span lang="EN-US">: </span>전자상거래 등에서의
									소비자보호에 관한 법률
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									보존기간<span lang="EN-US">: 3</span>년
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<u>
										<span
											lang="EN-US"
											style={{
												fontSize: '11pt',
												lineHeight: '150%',
												fontFamily: '나눔스퀘어',
											}}>
											-
										</span>
									</u>
								</b>
								<b>
									<u>
										<span
											style={{
												fontSize: '11pt',
												lineHeight: '150%',
												fontFamily: '나눔스퀘어',
											}}>
											계약 또는 청약철회 등에 관한 기록
										</span>
									</u>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									보존이유<span lang="EN-US">: </span>전자상거래 등에서의
									소비자보호에 관한 법률
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									보존기간<span lang="EN-US">: 5</span>년
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<u>
										<span
											lang="EN-US"
											style={{
												fontSize: '11pt',
												lineHeight: '150%',
												fontFamily: '나눔스퀘어',
											}}>
											-
										</span>
									</u>
								</b>
								<b>
									<u>
										<span
											style={{
												fontSize: '11pt',
												lineHeight: '150%',
												fontFamily: '나눔스퀘어',
											}}>
											대금결제 및 재화 등의 공급에 관한 기록
										</span>
									</u>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									보존이유<span lang="EN-US">: </span>전자상거래 등에서의
									소비자보호에 관한 법률
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									보존기간<span lang="EN-US">: 5</span>년
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<u>
										<span
											lang="EN-US"
											style={{
												fontSize: '11pt',
												lineHeight: '150%',
												fontFamily: '나눔스퀘어',
											}}>
											-
										</span>
									</u>
								</b>
								<b>
									<u>
										<span
											style={{
												fontSize: '11pt',
												lineHeight: '150%',
												fontFamily: '나눔스퀘어',
											}}>
											건당 거래금액이<span lang="EN-US"> 1</span>만원을
											초과하는 전자금융거래에 관한 기록
										</span>
									</u>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									보존이유<span lang="EN-US">:</span>전자금융거래법
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									보존기간<span lang="EN-US">: 5</span>년
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<u>
										<span
											lang="EN-US"
											style={{
												fontSize: '11pt',
												lineHeight: '150%',
												fontFamily: '나눔스퀘어',
											}}>
											-
										</span>
									</u>
								</b>
								<b>
									<u>
										<span
											style={{
												fontSize: '11pt',
												lineHeight: '150%',
												fontFamily: '나눔스퀘어',
											}}>
											서비스 이용에 관한 로그기록
										</span>
									</u>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									보존이유<span lang="EN-US">:</span>통신비밀보호법
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									보존기간<span lang="EN-US">: 3</span>개월
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">4</span>조<span lang="EN-US">(</span>
										이용자의 권리 및 의무<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									이용자는 아래와 같은 권리를 행사할 수 있습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									1.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									이용자는 회사에 대해 언제든지 개인정보 열람
									<span lang="EN-US">, </span>정정<span lang="EN-US">, </span>삭제
									<span lang="EN-US">, </span>처리정지 요구 등의 권리를 행사할 수
									있으며<span lang="EN-US">, </span>회원 탈퇴 절차를 통하여
									개인정보 이용에 대한 동의를 철회할 수 있습니다
									<span lang="EN-US">. </span>이 때 회사는 위의 요구를 한 자가
									본인이거나 정당한 대리인인지를 확인합니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									2.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									위 <span lang="EN-US">1.</span>에 따른 권리 행사는 회사에 대해
									서면<span lang="EN-US">, </span>전자우편
									<span lang="EN-US">, </span>모사전송
									<span lang="EN-US">(FAX)</span>등을 통하여 하실 수 있으며 회사는
									이에 대해 지체 없이 조치하겠습니다<span lang="EN-US">. </span>
									다만 아이디<span lang="EN-US">(ID),</span>성명
									<span lang="EN-US">, </span>주민등록번호
									<span lang="EN-US">, </span>외국인등록번호는 정정하실 수 없으며
									<span lang="EN-US">, </span>개명으로 인한 성명 변경 및 행정상의
									문제로 인한 주민<span lang="EN-US">(</span>사업자
									<span lang="EN-US">)</span>등록번호 변경은 예외적으로 허용될 수
									있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									3.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 이용자의 개인정보 처리 정지 요구가 있는 경우에도 아래에
									해당하는 경우에는 처리정지 요구를 거절할 수 있습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									-{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여
									불가피한 경우
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									-{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									다른 사람의 생명<span lang="EN-US">·</span>신체를 해할 우려가
									있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가
									있는 경우
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									-{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									개인정보를 처리하지 아니하면 정보주체와 약정한 서비스를 제공하지
									못하는 등 계약의 이행이 곤란한 경우로서 정보주체가 그 계약의
									해지 의사를 명확하게 밝히지 아니한 경우
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									4.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									위<span lang="EN-US"> 1.</span>에 따른 권리 행사는 이용자의
									법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수
									있습니다<span lang="EN-US">. </span>이 경우 개인정보 보호법
									시행규칙 별지 제<span lang="EN-US">11</span>호 서식에 따른
									위임장을 제출하여야 합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									5.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									개인정보 열람 및 처리정지 요구는 관련법령에 의하여 이용자의
									권리가 제한될 수 있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									6.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집
									대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다
									<span lang="EN-US">. </span>또한 개인정보 오류에 대한 정정
									요청한 경우에는 다른 법률에 따라 개인정보의 제공을 요청 받는
									경우가 아닌 한 정정을 완료하기 전까지 당해 개인정보를 이용 또는
									제공하지 않습니다<span lang="EN-US">. </span>만약 잘못된
									개인정보를 이미 제공한 경우에는 정정 처리 결과를 제
									<span lang="EN-US">3</span>자에게 통지하여 정정이 이루어지도록
									하겠습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									7.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<u>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										회사는 아동의 개인정보를 보호하기 위하여
										<span lang="EN-US">, </span>만<span lang="EN-US"> 14</span>
										세 미만의 아동의 경우 신규회원 가입을 제한하고 있습니다
										<span lang="EN-US">.</span>
									</span>
								</u>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									② 이용자는 아래의 내용에 따라 개인정보의 열람 및 정정
									<span lang="EN-US">, </span>동의의 철회
									<span lang="EN-US">, </span>동의에 대한 거부권 행사
									<span lang="EN-US">, </span>탈퇴에 관한 권리를 행사할 수
									있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										1.
										<span style={{ font: '7pt "Times New Roman"' }}>
											&nbsp;&nbsp;&nbsp;{' '}
										</span>
									</span>
								</b>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										개인정보의 열람 및 정정 범위
									</span>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									-{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사가 보유하고 있는 이용자의 개인정보
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									-{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사가 이용하거나 제<span lang="EN-US">3</span>자에 제공한
									개인정보
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									-{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									개인정보의 수집<span lang="EN-US">,</span>이용
									<span lang="EN-US">, </span>제공 등의 동의 현황
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										2.
										<span style={{ font: '7pt "Times New Roman"' }}>
											&nbsp;&nbsp;&nbsp;{' '}
										</span>
									</span>
								</b>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										개인정보의 열람 · 정정 및 동의 철회
										<span lang="EN-US">, </span>광고성 정보 전송에 대한 동의
										거부권 행사 방법
									</span>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									-{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									이용자는 플랫폼 또는 회사의 고객센터에 연락하여 동의 거부권을
									행사할 수 있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										3.
										<span style={{ font: '7pt "Times New Roman"' }}>
											&nbsp;&nbsp;&nbsp;{' '}
										</span>
									</span>
								</b>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										회원 탈퇴
									</span>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									-{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									이용자는 플랫폼 내에서 회사가 정한 절차에 따라 회원탈퇴를 할 수
									있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									③ 이용자는 개인정보주체로서 아래와 같이 개인정보를 스스로 보호할
									의무가 있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									1.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									이용자는 개인정보를 항상 최신의 상태로 유지해야 하며
									<span lang="EN-US">, </span>고객이 입력한 부정확한 정보로 인하여
									발생한 문제의 책임은 이용자 자신에게 있습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									2.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사의 귀책사유가 없이 아이지<span lang="EN-US">(ID),</span>
									비밀번호<span lang="EN-US">, </span>접근매체 등의 양도
									<span lang="EN-US">·</span>대여<span lang="EN-US">·</span>
									분실이나 로그인 상태에서 이석 등 이용자 본인의 부주의나
									관계법령에 의한 보안조치로 차단할 수 없는 방법이나 기술을 사용한
									해킹 등 회사가 상당한 주의를 기울였음에도 불구하고 통제할 수
									없는 인터넷 상의 문제 등으로 개인정보가 유출되어 발생한 문제에
									대해 회사는 책임을 지지 않습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									3.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									이용자는 아이디<span lang="EN-US">(ID),</span>비밀번호
									<span lang="EN-US">, </span>접근매체 등 개인정보가 유출되지
									않도록 주의하고 제<span lang="EN-US">3</span>자에게 양도하거나
									대여할 수 없습니다<span lang="EN-US">. </span>이용자 본인의
									부주의로 인하여 발생한 손해에 대해서는 회사가 책임을 지지
									않습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									4.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									타인의 개인정보를 도용한 회원가입 또는 서비스 이용 시 이용자
									자격 상실과 함께 관련 법령에 따라 처벌될 수 있습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									5.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									이용자는 회사의 개인정보보호정책에 따라 보안을 위해 비밀번호의
									주기적 변경에 협조할 의무가 있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									6.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									이용자는 개인정보에 관한 관련 법령을 준수하여야 합니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">5</span>조<span lang="EN-US">(</span>
										개인정보의 제<span lang="EN-US">3</span>자 제공
										<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									① 회사는 원칙적으로 이용자의 개인정보를 수집 및 이용목적 범위를
									넘어 제<span lang="EN-US">3</span>자에게 제공하거나 외부에
									공개하지 않습니다<span lang="EN-US">. </span>다만
									<span lang="EN-US">,</span>보다 나은 서비스 제공을 위하여
									<span lang="EN-US">(</span>예를 들어<span lang="EN-US">, </span>
									회사의 서비스 제공에 따른 요금정산을 위해 필요한 경우 등
									<span lang="EN-US">) </span>이용자의 개인정보를 협력업체 등과
									공유할 필요가 있는 경우에는
									<b>
										제공받는 자<span lang="EN-US">, </span>제공 목적
										<span lang="EN-US">, </span>제공 정보항목
										<span lang="EN-US">,</span>이용 및 보유기간 등을 이용자에게
										고지하여 동의를 구합니다<span lang="EN-US">.</span>
									</b>
									<span lang="EN-US"> </span>또한 법령의 규정에 의거하거나
									<span lang="EN-US">, </span>수사목적으로 법령에서 정해진 절차와
									방법에 따라 수사기관의 요구가 있는 경우에는 이용자의 개인정보를
									제공할 수 있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									② 회사는 이용자의 개인정보를 회사가 제공하는 서비스 외의 용도로
									사용하거나 이용자의 동의 없이 제<span lang="EN-US">3</span>
									자에게 제공하지 않습니다<span lang="EN-US">. </span>
									<b>
										필요에 의하여 제<span lang="EN-US">3</span>자에게 제공할
										경우에는 이용자에게 알리고 별도의 동의를 받습니다
										<span lang="EN-US">.</span>
									</b>
									<span lang="EN-US"> </span>다만<span lang="EN-US">, </span>다음
									각 호의 경우에는 예외로 합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										가<span lang="EN-US">.</span>
									</span>
								</b>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									이용자가 회사의 이용약관을 위배하거나 서비스를 이용하여 타인에게
									피해를 주거나 미풍양속을 해치는 위법행위를 한 경우
									<span lang="EN-US">, </span>법적인 조치를 취하기 위하여
									개인정보를 공개해야 한다고 판단되는 경우
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										나<span lang="EN-US">.</span>
									</span>
								</b>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									법령의 규정에 의거하거나<span lang="EN-US">,</span>수사목적으로
									법령에서 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										다<span lang="EN-US">.</span>
									</span>
								</b>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									통계작성<span lang="EN-US">, </span>학술연구
									<span lang="EN-US">, </span>시장조사<span lang="EN-US">, </span>
									정보제공 및 공지 안내 메일 발송의 경우로서 특정 개인을 식별할 수
									없는 형태로 제공되는 경우
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">6</span>조<span lang="EN-US">(</span>
										개인정보의 처리위탁<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 이용자에게 원활한 서비스 제공과 서비스 품질의 향상을
									위하여 이와 관련된 업무 일부를 외부업체에 위탁하여 수행하고
									있습니다<span lang="EN-US">. </span>회사는 외부업체에게 업무
									위탁 시 계약서 등을 통하여 개인정보보호 관련 법규의 준수
									<span lang="EN-US">, </span>개인정보에 관한 비밀유지
									<span lang="EN-US">, </span>제<span lang="EN-US">3</span>자
									제공에 대한 금지<span lang="EN-US">, </span>사고시의 책임 부담
									<span lang="EN-US">, </span>위탁기간<span lang="EN-US">,</span>
									처리 종료 후의 개인정보의 파기 의무 등을 규정하고
									<span lang="EN-US">, </span>이를 준수하도록 관리감독하고
									있습니다<span lang="EN-US">. </span>회사는 서비스 향상과
									효과적인 업무 처리를 위하여 다음과 같이 개인정보를 처리 위탁하고
									있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 개인정보 처리위탁업무의 내용이나 수탁자가 변경될 경우
									<span lang="EN-US">, </span>지체 없이 본 개인정보처리방침을
									통하여 공개하고<span lang="EN-US">, </span>이로써 위탁에 대한
									동의로 갈음합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">7</span>조<span lang="EN-US"> (</span>
										개인정보의 파기 절차 및 방법<span lang="EN-US">) </span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 원칙적으로 이용자의 개인정보를 그 수집 및 이용 목적이
									달성되면 지체 없이 파기합니다<span lang="EN-US">. </span>단
									<span lang="EN-US">, </span>회사는{' '}
									<b>
										<span lang="EN-US">‘</span>개인정보 유효 기간제
										<span lang="EN-US">’</span>
									</b>
									에 따라 <span lang="EN-US">1</span>년 간 서비스를 이용하지 않는
									이용자의 개인정보를 파기 또는 별도로 분리 보관하여 관리합니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사의 개인정보 파기의 절차 및 방법은 다음과 같습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<u>
										<span
											style={{
												fontSize: '11pt',
												lineHeight: '150%',
												fontFamily: '나눔스퀘어',
											}}>
											가<span lang="EN-US">. </span>파기 절차
										</span>
									</u>
								</b>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										:
									</span>
								</b>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후
									별도의<span lang="EN-US"> DB</span>로 옮겨져
									<span lang="EN-US">(</span>종이의 경우 별도의 서류함
									<span lang="EN-US">)</span>내부 방침 및 기타 관련 법령에 의한
									정보보호 사유에 따라<span lang="EN-US">(</span>보유 및 이용기간
									참조<span lang="EN-US">)</span>일정 기간 저장된 후 파기됩니다
									<span lang="EN-US">. </span>동 개인정보는 법률에 의한 경우가
									아닌 한 보유 및 보존되는 이외의 다른 목적으로 이용되지 않습니다
									<span lang="EN-US">. </span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<u>
										<span
											style={{
												fontSize: '11pt',
												lineHeight: '150%',
												fontFamily: '나눔스퀘어',
											}}>
											나<span lang="EN-US">. </span>파기 방법
										</span>
									</u>
								</b>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										:
									</span>
								</b>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									종이에 기록<span lang="EN-US">, </span>저장된 개인정보는
									분쇄기로 분쇄하거나 소각을 통하여 파기하며
									<span lang="EN-US">, </span>전자적 파일 형태로 저장된 개인정보는
									기록을 재생할 수 없도록 기술적 방법을 사용하여 삭제합니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">8</span>조<span lang="EN-US"> (</span>
										이용자 및 법정대리인의 권리와 행사방법
										<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									이용자 및 법정대리인은 언제든지 등록되어 있는 자신 혹은 만{' '}
									<span lang="EN-US">14</span>세 미만 아동의 개인정보를 조회하거나
									수정할 수 있으며<span lang="EN-US">, </span>언제든지 회원탈퇴
									등을 통해 개인정보의 수집 및 이용 동의를 철회할 수 있습니다
									<span lang="EN-US">. </span>다만<span lang="EN-US">, </span>
									그러한 경우 서비스의 전부 또는 일부의 이용이 어려울 수 있습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									&nbsp;
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									이용자 또는 만 <span lang="EN-US">14</span>세 미만 아동의
									개인정보 조회 및 수정을 위해서는 <span lang="EN-US">‘</span>
									개인정보변경<span lang="EN-US">’(</span>또는{' '}
									<span lang="EN-US">‘</span>회원정보 수정
									<span lang="EN-US">’ </span>등<span lang="EN-US">)</span>을
									<span lang="EN-US">, </span>가입해지<span lang="EN-US">(</span>
									동의철회<span lang="EN-US">)</span>를 위해서는{' '}
									<span lang="EN-US">‘</span>회원탈퇴<span lang="EN-US">’</span>를
									통하여 본인확인 절차를 거친 후에 직접 열람
									<span lang="EN-US">,</span>정정 또는 탈퇴가 가능합니다
									<span lang="EN-US">. </span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									③
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 이용자가 개인정보의 오류에 대한 정정을 요청한 경우
									<span lang="EN-US">, </span>정정을 완료하기 전까지 해당
									개인정보를 이용 또는 제공하지 않습니다
									<span lang="EN-US">. </span>또한 잘못된 개인정보를 제
									<span lang="EN-US">3</span>자에게 이미 제공한 경우에는 정정
									처리결과를 제<span lang="EN-US">3</span>자에게 지체없이 통지하여
									정정이 이루어지도록 하겠습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									④
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 이용자 혹은 법정대리인의 요청에 의해 해지 또는 삭제된
									개인정보를 본 개인정보처리방침에 명시된{' '}
									<span lang="EN-US">‘</span>개인정보의 보유 및 이용기간
									<span lang="EN-US">’</span>에 명시된 바에 따라 처리하고
									<span lang="EN-US">, </span>그 외의 용도로 열람 또는 이용할 수
									없도록 처리하고 있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									⑤
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									만<span lang="EN-US"> 14</span>세 미만 아동의 개인정보를 수집
									<span lang="EN-US">, </span>이용하거나 제
									<span lang="EN-US">3</span>자에게 제공하기 위하여 법정대리인의
									동의를 받고자 하는 경우에는 전화<span lang="EN-US">, </span>팩스
									<span lang="EN-US">, </span>우편<span lang="EN-US">, </span>
									아동으로 하여금 통지 내용을 법정대리인에게 제출하게 하는 방법
									<span lang="EN-US">, </span>법정대리인에게 이메일을 보내 본
									개인정보 처리방침을 볼 수 있도록 연결<span lang="EN-US">(</span>
									하이퍼링크<span lang="EN-US">)</span>시키는 방법 또는 기타
									법정대리인에게 고지 내용이 도달할 수 있는 합리적인 방법을 통하여
									법정대리인에게 고지합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									⑥
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									유선 또는 서면을 통하여 만<span lang="EN-US">14</span>세 미만
									아동인 회원의 법정대리인이 열람<span lang="EN-US">, </span>
									증명을 요구하는 경우<span lang="EN-US">, </span>회사는
									대리관계를 증명하는 위임장 및 인감증명서
									<span lang="EN-US">, </span>요청인 및 대리인의 신분증 사본 등의
									증표를 제시 받아 해당 요구를 하는 자가 진정한 법정대리인인지
									여부를 확인합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">9</span>조<span lang="EN-US">(</span>
										개인정보 자동 수집 장치의 설치<span lang="EN-US">, </span>
										운영 및 거부에 관한 사항<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 이용자에게 원활한 서비스 제공을 위하여 이용자들의 정보를
									저장하고<span lang="EN-US">, </span>수시로 불러내는 찾아내는{' '}
									<span lang="EN-US">‘</span>쿠키
									<span lang="EN-US">(cookie)’</span>등을 운용합니다
									<span lang="EN-US">. </span>쿠키란 웹사이트를 운영하는 데
									이용되는 서버가 회원의 브라우저에 보내는 아주 작은 텍스트
									파일로서 이용자의 컴퓨터 하드디스크에 저장되기도 합니다
									<span lang="EN-US">(</span>쿠키의 의미
									<span lang="EN-US">).</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									&nbsp;
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 회원과 비회원의 접속 빈도나 방문 시간 등을 분석
									<span lang="EN-US">, </span>회원의 취향과 관심분야를 파악 및
									자취 추적<span lang="EN-US">, </span>각종 이벤트 참여 정도 및
									방문 횟수 파악 등을 통한 타깃 마케팅 및 개인 맞춤 서비스 제공
									등의 목적으로 쿠키를 사용합니다<span lang="EN-US">(</span>쿠키의
									사용 목적<span lang="EN-US">).</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									③
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									&nbsp;
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회원은 쿠키 설치에 대한 선택권을 가지고 있습니다
									<span lang="EN-US">. </span>따라서<span lang="EN-US">, </span>
									회원은 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를
									허용하거나<span lang="EN-US">, </span>쿠키가 저장될 때마다
									확인을 거치거나<span lang="EN-US">, </span>아니면 모든 쿠키의
									저장을 거부할 수도 있습니다<span lang="EN-US">(</span>쿠키의
									설치<span lang="EN-US">, </span>운영 및 거부
									<span lang="EN-US">). </span>다만<span lang="EN-US">, </span>
									이용자가 쿠키 설치를 거부하는 경우 로그인이 필요한 일부 서비스
									이용에 어려움이 있을 수 있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									④
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									&nbsp;
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									제<span lang="EN-US">3</span>항에 따라 쿠키 설정을 거부하는
									방법으로<span lang="EN-US">, </span>회원은 사용하는 웹
									브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를
									저장할 때마다 확인을 거치거나<span lang="EN-US">, </span>모든
									쿠키의 저장을 거부할 수 있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									⑤
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는<span lang="EN-US"> PC </span>환경과 동일
									<span lang="EN-US">·</span>유사한 인터넷 환경을 모바일
									서비스에서도 제공하기 위해 모바일 기기
									<span lang="EN-US"> (</span>예<span lang="EN-US">: </span>스마트
									폰<span lang="EN-US">, </span>태블릿
									<span lang="EN-US"> PC)</span>에서도<span lang="EN-US"> '</span>
									쿠키<span lang="EN-US">(cookie)'</span>를 사용할 수 있습니다
									<span lang="EN-US">. </span>회사는 관련 법령 및 규제기관의
									가이드라인에 따라 쿠키를 수집하며 단<span lang="EN-US">, </span>
									이용자의 개인정보를 포함하여 그 자체로 개인을 식별할 수 있는
									쿠키는 이용자의 사전 동의 없이 임의로 수집하지 않습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									⑥
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									모바일 기기에서도 웹브라우저 설정 기능에서 쿠키 허용 여부를
									선택할 수 있습니다<span lang="EN-US">. </span>모바일 기기의
									운영체제 및 웹 브라우저 종류에 따라 다소 상이할 수 있지만
									<span lang="EN-US">,</span>대부분의 경우 모바일 웹브라우저의
									환경 설정을 통해 쿠키허용 여부를 결정하거나
									<span lang="EN-US">, </span>기존의 쿠키 일체를 삭제할 수
									있습니다<span lang="EN-US">. </span>다만 쿠키의 저장을 거부할
									경우<span lang="EN-US">, </span>로그인이 필요한 일부 서비스의
									이용에 불편함이 있을 수 있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">10</span>조<span lang="EN-US"> (</span>
										개인정보보호를 위한 회사의 노력<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: "'HY신명조', serif",
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 이용자들의 개인정보를 처리함에 있어 개인정보가 분실
									<span lang="EN-US">, </span>도난<span lang="EN-US">, </span>유출
									<span lang="EN-US">, </span>변조 또는 훼손되지 않도록 안전성
									확보를 위하여 내부관리계획을 수립<span lang="EN-US">/</span>
									시행하고 접근통제장치를 설치 및 운영하며
									<span lang="EN-US">, </span>접속기록의 위
									<span lang="EN-US">/</span>변조 방지를 위해 보안기능을 사용하는
									등 기술적<span lang="EN-US">/</span>관리적 대책을 강구하고
									있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{ marginLeft: '18pt', lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										가<span lang="EN-US">.</span>
									</span>
								</b>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									{' '}
								</span>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										개인정보 암호화<span lang="EN-US">:</span>
									</span>
								</b>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 비밀번호<span lang="EN-US">, </span>결제수단 등의
									개인정보를 법령에서 요구하는 기준에 따라 암호화 처리하고 있으며
									<span lang="EN-US">, </span>중요한 데이터는 파일 및 전송
									데이터를 암호화하는 등 별도의 보안 조치를 취하여 이용자의
									개인정보를 보호하고 있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{ marginLeft: '18pt', lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										나<span lang="EN-US">.</span>
									</span>
								</b>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									{' '}
								</span>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										해킹 등에 대비한 대책<span lang="EN-US">:</span>
									</span>
								</b>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가
									유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다
									<span lang="EN-US">. </span>개인정보의 훼손에 대비해서 자료를
									수시로 백업하고 있고<span lang="EN-US">,</span>최신
									백신프로그램을 이용하여 이용자 들의 개인정보나 자료가 유출되거나
									손상되지 않도록 방지하고 있으며<span lang="EN-US">, </span>
									암호화통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할
									수 있도록 하고 있습니다<span lang="EN-US">. </span>그리고
									침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고
									있으며<span lang="EN-US">, </span>기타 시스템적으로 보안성을
									확보하기 위한 가능한 모든 기술적 장치를 갖추기 위하여 노력하고
									있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{ marginLeft: '18pt', lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										다<span lang="EN-US">. </span>처리 직원의 최소화 및 교육
										<span lang="EN-US">: </span>
									</span>
								</b>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사의 개인정보관련 처리 직원은 담당자에 한정시키고 있고
									<span lang="EN-US">, </span>이를 위한 별도의 비밀번호를 부여하여
									정기적으로 갱신하고 있으며<span lang="EN-US">, </span>담당자에
									대한 수시 교육을 통하여 회사 개인정보처리방침의 준수를 항상
									강조함으로써 개인정보 보호가 실질적으로 이루어질 수 있도록
									계속적으로 노력하고 있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{ marginLeft: '18pt', lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										라<span lang="EN-US">.</span>
									</span>
								</b>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									{' '}
								</span>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										개인정보보호전담기구의 운영<span lang="EN-US">:</span>
									</span>
								</b>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 사내 개인정보보호 업무를 수행하는 부서 등을 통하여 회사
									개인정보처리방침의 이행사항 및 담당자의 준수여부를 확인하여
									문제가 발견될 경우 즉시 수정하고 바로잡을 수 있도록 노력하고
									있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: "'HY신명조', serif",
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									제<span lang="EN-US">1</span>항에도 불구하고 이용자 본인의 고의
									또는 부주의나 인터넷상의 문제로 아이디
									<span lang="EN-US">(ID), </span>비밀번호
									<span lang="EN-US">, </span>주민등록번호 등 개인정보가 유
									<span lang="EN-US">·</span>노출되어 발생한 문제에 대해 회사는
									일체의 책임을 지지 않습니다<span lang="EN-US">. </span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">11</span>조<span lang="EN-US"> (</span>
										개인정보보호책임자 및 연락처<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 개인정보에 대한 의견 수렴 및 불만 처리를 위하여 이를
									담당하는 개인정보보호책임자를 아래와 같이 지정하고 있습니다
									<span lang="EN-US">. </span>이용자가 회사의 서비스를 이용하면서
									발생하는 모든 개인정보보호에 대한 문의
									<span lang="EN-US">, </span>불만처리<span lang="EN-US">, </span>
									피해구제 등 관련 민원을 개인정보보호책임자 혹은 담당부서에
									신고하실 수 있습니다<span lang="EN-US">. </span>회사는
									이용자들의 문의 및 신고에 대해 신속하게 답변 및 처리해드릴
									것입니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<u>
										<span
											style={{
												fontSize: '11pt',
												lineHeight: '150%',
												fontFamily: '나눔스퀘어',
											}}>
											개인정보보호책임자
										</span>
									</u>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										성명<span lang="EN-US">:</span>조지영
										<span lang="EN-US">&nbsp;&nbsp;&nbsp; </span>
									</span>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										소속<span lang="EN-US">/</span>직책
										<span lang="EN-US">:</span>
									</span>
								</b>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									대표이상
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										이메일<span lang="EN-US">: master@spoeum.com</span>
									</span>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래의
									기관에 문의하시기 바랍니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '38pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔고딕',
									}}>
									-
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										개인정보침해신고센터
									</span>
								</b>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									{' '}
									( http://privacy.kisa.or.kr /
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									국번없이<span lang="EN-US"> 118 )</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '38pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔고딕',
									}}>
									-
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										대검찰청 사이버수사과
									</span>
								</b>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									{' '}
									( http://www.spo.go.kr /
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									국번없이<span lang="EN-US"> 1301 )</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '38pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔고딕',
									}}>
									-
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										경찰청 사이버안전국
									</span>
								</b>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									{' '}
									( http://cyberbureau.police.go.kr /
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									국번없이<span lang="EN-US"> 182 )</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">12</span>조<span lang="EN-US"> (</span>
										고지의무<span lang="EN-US">) </span>
									</span>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 현행 개인정보처리방침에 대한 내용 추가
									<span lang="EN-US">, </span>삭제 및 수정이 있을 시에는{' '}
									<b>
										개정 최소<span lang="EN-US"> 7</span>일 전부터
									</b>{' '}
									플랫폼을 통해 고지할 것입니다<span lang="EN-US">. </span>다만
									<span lang="EN-US">, </span>개인정보의 수집 및 활용
									<span lang="EN-US">,</span>제<span lang="EN-US">3</span>자 제공
									등과 같이 회원의 권리의 중요한 변경이 있을 경우에는 최소
									<span lang="EN-US"> 30</span>일 전에 고지합니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">13</span>조<span lang="EN-US">(</span>
										기타<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사가 제공하는 서비스에 링크되어 있는 웹페이지들이 개인정보를
									수집하는 행위에 대해서는 본 회사의 개인정보처리방침이 적용되지
									않습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									&nbsp;
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										시행일자<span lang="EN-US">:</span>
									</span>
								</b>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									{' '}
									2021
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									년 <span lang="EN-US">3</span>
									<span>
										월<span lang="EN-US">26</span>일
									</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									&nbsp;
								</span>
							</p>
						</div>
					</div>
					<div className="modal-footer py-1">
						<button
							type="button"
							className="btn btn-warning btn-sm"
							data-bs-dismiss="modal">
							확인
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}
