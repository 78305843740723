import React from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import { formattingPrice, getCurrentYear, getMatchTypeOptions, userTypes } from '../../utils'

export const MatchingUserCard = ({
	PROFILE_BUCKET,
	user,
	favoredUserIds,
	onClickFavored,
	...props
}) => {
	return (
		<React.Fragment>
			<div key={`pc-${user?.id}`} className="col-12 col-md-6 cmb-10 onlyPc">
				<Card style={{ cursor: 'pointer', height: '100%' }}>
					<div className="d-flex">
						<Link to={`/matching/${user?.id}`} className="cardImage_caddie">
							<Card.Img
								src={
									user?.profile_type
										? PROFILE_BUCKET + user?.id + '.' + user?.profile_type
										: `/images/noImg.png`
								}
								alt={user?.name}
							/>
						</Link>
						<Card.Body>
							<div className="d-flex justify-content-between">
								<Link to={`/matching/${user?.id}`}>
									<div>
										<p className="font-R00 fontSize-13 color-greyish-two">
											{getMatchTypeOptions(userTypes, user?.user_type)}
										</p>
										<p className="font-SB00 fontSize-17 color-black mb-2">
											{user?.name}
										</p>

										{renderUserInfoByType(user)}
									</div>
								</Link>

								<div>
									<img
										src={`/images/${
											favoredUserIds.includes(user?.id)
												? 'pro-main-001-like-02.png'
												: 'pro-main-001-like-01.png'
										}`}
										alt="thumbup"
										className="button_thumup"
										id="thumb-130"
										onClick={onClickFavored(user?.id)}
									/>
								</div>
							</div>
						</Card.Body>
					</div>
				</Card>
			</div>
			<div key={`mobile-${user?.id}`} className="col-12 col-md-6 cmb-10 onlyMobile">
				<Card style={{ cursor: 'pointer', height: '100%' }}>
					<div className="d-flex">
						<Link to={`/matching/${user?.id}`} className="cardImage_caddie_mb">
							<Card.Img
								src={
									user?.profile_type
										? PROFILE_BUCKET + user?.id + '.' + user?.profile_type
										: `/images/noImg.png`
								}
								alt={user?.name}
							/>
						</Link>

						<Card.Body className="mobile-card-body">
							<div className="d-flex justify-content-between">
								<Link to={`/matching/${user?.id}`}>
									<div>
										<p className="font-R00 fontSize-13 color-greyish-two">
											{getMatchTypeOptions(userTypes, user?.user_type)}
										</p>
										<p className="font-SB00 fontSize-17 color-black mb-2">
											{user?.name}
										</p>

										{renderUserInfoByType(user)}
									</div>
								</Link>
								<div>
									<img
										src={`/images/${
											favoredUserIds.includes(user?.id)
												? 'pro-main-001-like-02.png'
												: 'pro-main-001-like-01.png'
										}`}
										alt="thumbup"
										className="button_thumup"
										id="thumb-130"
										onClick={onClickFavored(user?.id)}
									/>
								</div>
							</div>
						</Card.Body>
					</div>
				</Card>
			</div>
		</React.Fragment>
	)
}

const renderUserInfoByType = (user) => {
	const userType = user?.user_type

	const currentYear = getCurrentYear()

	switch (userType) {
		case 'tourPro':
			return (
				<React.Fragment>
					<p className="font-L00 fontSize-15 color-greyish-two">
						메인스폰서
						<span className="color-greyish-brown ms-2 me-3">
							{user?.user_tour_pro?.main_sponsor || '-'}
						</span>
					</p>
					<p className="font-L00 fontSize-15 color-greyish-two">
						에이전시
						<span className="color-greyish-brown ms-2 me-3">
							{user?.user_tour_pro?.agency_name || '-'}
						</span>
					</p>
				</React.Fragment>
			)
		case 'tourCaddie':
			return (
				<React.Fragment>
					<p className="font-L00 fontSize-15 color-greyish-two">
						경력
						<span className="color-greyish-brown ms-2 me-3">
							{currentYear - user.user_tour_caddie?.employment_year + 1} 년차
						</span>
					</p>
					<p className="font-L00 fontSize-15 color-greyish-two cmb-10">
						구단
						<span className="color-greyish-brown ms-2">{user.club_name || '-'}</span>
					</p>
					{!!user?.user_tour_caddie?.weekly_salary_for_KPGA && (
						<button className="btn btn-light form_titleSubButton radius-5">
							KPGA / {formattingPrice(user?.user_tour_caddie?.weekly_salary_for_KPGA)}{' '}
							원
						</button>
					)}
					{!!user?.user_tour_caddie?.weekly_salary_for_KLPGA && (
						<button className="btn btn-light form_titleSubButton radius-5">
							KLPGA /{' '}
							{formattingPrice(user?.user_tour_caddie?.weekly_salary_for_KLPGA)} 원
						</button>
					)}
				</React.Fragment>
			)
		case 'houseCaddie':
			return (
				<React.Fragment>
					<p className="font-L00 fontSize-15 color-greyish-two">
						소속
						<span className="color-greyish-brown ms-2">{user?.club_name || '-'}</span>
					</p>
				</React.Fragment>
			)
		case 'agency':
			return (
				<React.Fragment>
					<p className="font-L00 fontSize-15 color-greyish-two">
						법인명
						<span className="color-greyish-brown ms-2">
							{user?.user_agency?.corporate_name}
						</span>
					</p>
					<p className="font-L00 fontSize-15 color-greyish-two">
						현재 소속 프로
						<span className="color-greyish-brown ms-2">
							{user?.user_agency?.players?.length}명
						</span>
					</p>
				</React.Fragment>
			)
		default:
			return
	}
}
