import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
	Calendar,
	MatchingListMyInfo,
	MatchingListAd,
	MatchingOfferList,
	MatchingUserCard,
} from '../../components'
import { FETCH_HEADER_OPTIONS, getCurrentYear, isEmpty, useAuthStatus } from '../../utils'
import { Card } from 'react-bootstrap'
import dayjs from 'dayjs'

export const MatchingIndexView = ({ API_ENDPOINT, PROFILE_BUCKET, ...props }) => {
	const [auth, onChangeAuthStatus] = useAuthStatus()
	const [user, setUser] = useState(null)
	const [players, setPlayers] = useState([])
	const [caddies, setCaddies] = useState([])
	const [favoredUserIds, setFavoredUserIds] = useState([])
	const [tours, setTours] = useState([])
	const [offers, setOffers] = useState([])

	const [selectedTour, setSelectedTour] = useState()
	const [contractByTour, setContractByTour] = useState(null)

	const [searchKeyword, setSearchKeyword] = useState('')

	useEffect(() => {
		fetchUserInfo()

		if (['tourPro', 'agency'].includes(auth?.user?.userType)) searchCaddies(searchKeyword)
		if (['tourCaddie', 'houseCaddie'].includes(auth?.user?.userType))
			searchPlayers(searchKeyword)

		Promise.all([fetchFavoredUserIds(), fetchTours(), fetchOffers()])
	}, [auth])

	const fetchUserInfo = async () => {
		return await fetch(`${API_ENDPOINT}/users/my-page/${auth?.user?.id}`)
			.then(async (response) => {
				const result = await response.json()

				setUser(result)
			})
			.catch((errors) => {
				console.log(errors)
			})
	}

	const fetchContractByTourId = async (tourId) => {
		await fetch(`${API_ENDPOINT}/contract/search/${auth?.user?.id}/${tourId}`)
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) throw result

				const selectedTour = tours?.find((item) => item?.id === tourId)

				setContractByTour(result)
				setSelectedTour(selectedTour)
			})
			.catch((errors) => {
				console.log(errors)
			})
	}

	const searchPlayers = async (keyword) => {
		await fetch(`${API_ENDPOINT}/users/user/pro/search${isEmpty(keyword) ? '' : `/${keyword}`}`)
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) throw new Error(result.massage)

				setPlayers(result)
			})
			.catch((errors) => setPlayers([]))
	}

	const searchCaddies = async (keyword) => {
		await fetch(
			`${API_ENDPOINT}/users/user/caddie/search/${isEmpty(keyword) ? '' : `/${keyword}`}`,
		)
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) throw new Error(result.massage)

				setCaddies(result)
			})
			.catch((errors) => setCaddies([]))
	}

	const fetchFavoredUserIds = async () => {
		await fetch(`${API_ENDPOINT}/users/my-page/${auth?.user?.id}/favored`)
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) throw new Error(result.massage)

				const ids = result.map((item) => item.favored_id)

				setFavoredUserIds(ids)
			})
			.catch((errors) => setFavoredUserIds([]))
	}

	const fetchTours = async () =>
		await fetch(`${API_ENDPOINT}/admin/db-manage/tours`)
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) {
					throw new Error(result.message)
				}

				const color = (type) => {
					if (type === 'KPGA') return '#6495ed'
					else if (type === 'KLPGA') return '#ecdae3'
				}

				const tours = result.map((item) => {
					return {
						id: item.id,
						seq: item.id,
						title: item.name,
						start: dayjs(item.startedAt).format('YYYY-MM-DD'),
						end: dayjs(item.endedAt).add(1, 'days').format('YYYY-MM-DD'),
						color: color(item.type),
						allDay: true,
					}
				})

				setTours(tours)
			})
			.catch((errors) => console.log(errors))

	const fetchOffers = async (contractType = 'ALL') => {
		await fetch(`${API_ENDPOINT}/contract/offer/list`)
			.then(async (response) => {
				const result = await response.json()

				setOffers(result)
			})
			.catch((errors) => {
				console.log(errors)
				setOffers([])
			})
	}

	const onClickTour = async (info) => {
		const selectedEventId = info?.event?.id

		await fetchContractByTourId(selectedEventId)
	}

	const searchUser = () => {
		if (['tourPro', 'agency'].includes(auth?.user?.userType)) searchCaddies(searchKeyword)
		if (['tourCaddie', 'houseCaddie'].includes(auth?.user?.userType))
			searchPlayers(searchKeyword)
	}

	const onChangeKeyword = async (event) => {
		const keyword = event.target.value

		setSearchKeyword(keyword)
	}

	const onClickFavored = (favoredUserId) => async (event) => {
		const isFavored = favoredUserIds.includes(favoredUserId)
		const apiMethod = isFavored ? `DELETE` : 'POST'

		await fetch(`${API_ENDPOINT}/users/my-page/${auth?.user?.id}/favored`, {
			method: apiMethod,
			headers: FETCH_HEADER_OPTIONS,
			body: JSON.stringify({ favoredUserId: favoredUserId }),
		})
			.then(async (response) => {
				const result = await response.json

				if (result.success === false) {
					throw new Error('관심인재 등록/삭제 실패')
				}

				fetchFavoredUserIds()
			})
			.catch((errors) => {
				console.log(errors)
			})
	}

	return (
		<div className="wrap matching form_page">
			<div className="content_area">
				<div className="container mainWrap">
					<div className="row align-items-stretch">
						<div className="col-12 col-md-6 cmb-40">
							<MatchingListMyInfo
								PROFILE_BUCKET={PROFILE_BUCKET}
								user={user}
								contract={contractByTour}
								selectedTour={selectedTour}
							/>
						</div>

						<div className="col-12 col-md-6 cmb-40">
							{/*full calendar*/}
							<div className="card card-boxShadow h-100">
								<div className="card-body">
									<Calendar events={tours} onClickEvent={onClickTour} />
								</div>
							</div>
						</div>
					</div>

					{/* 검색창 */}
					<div className="row cmb-40">
						<div className="col-12">
							<div className="card py-0">
								<div className="card-body">
									<div className="row align-items-center">
										<div className="col">
											<input
												type="text"
												name=""
												id="pm001_input_searchPeople"
												className="border-0 p-0 w-100"
												placeholder="찾고 싶은 사람을 키워드로 검색해 보세요."
												onChange={onChangeKeyword}
											/>
										</div>
										<div className="col-auto">
											<i
												className="fas fa-search color-white-three"
												aria-hidden="true"
												onClick={searchUser}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row cmb-30">
						<div className="col-12 col-md-6 cmb-10">
							<Card className="card-link">
								<Card.Body>
									<div className="row justify-content-center align-items-center">
										<div className="col-auto">
											<div className="iconBox_circle">
												<img
													src={`/images/pro_main_001_bticn01.png`}
													alt="인재검색"
												/>
											</div>
										</div>
										<div className="col-auto me-4">
											<p className="neoSB00_20_greyish-brown mb-2">
												{['tourPro', 'agency'].includes(
													auth?.user?.userType,
												) && '캐디'}
												{['tourCaddie', 'houseCaddie'].includes(
													auth?.user?.userType,
												) && '프로'}
												검색
											</p>
											<p className="neoL00_18_greyish-brown mb-2">
												딱 맞는 파트너를
												<br />
												찾는 최고의 방법
											</p>
										</div>
										<div className="col-auto ms-4">
											<a href="#matching-list">
												<button
													type="button"
													className="btn btn-sm btn-dark form_titleSubButton px-4">
													바로가기 &gt;
												</button>
											</a>
										</div>
									</div>
								</Card.Body>
							</Card>
						</div>
						<div className="col-12 col-md-6 cmb-10">
							<Card className="card-link">
								<Card.Body>
									<div className="row align-items-center">
										<div className="row justify-content-center align-items-center">
											<div className="col-auto">
												<div className="iconBox_circle">
													<img
														src={`/images/pro_main_001_bticn02.png`}
														alt="구인공고"
													/>
												</div>
											</div>
											<div className="col-auto me-4">
												<p className="neoSB00_20_greyish-brown mb-2">
													구인공고
												</p>
												<p className="neoL00_18_greyish-brown mb-2">
													역제안 방식으로
													<br />
													합리적인 계약
												</p>
											</div>
											<div className="col-auto ms-4">
												<Link to={'/recruits'}>
													<button
														type="button"
														className="btn btn-sm btn-dark form_titleSubButton px-4">
														바로가기 &gt;
													</button>
												</Link>
											</div>
										</div>
									</div>
								</Card.Body>
							</Card>
						</div>
					</div>

					{/* 광고 */}
					<MatchingListAd />

					<div id="matching-list" className="row align-items-center cmb-10">
						<div className="col-12 col-md">
							<p className="neoM00_25_greyish-brown">
								아직{' '}
								{['tourPro', 'agency'].includes(auth?.user?.userType) && '캐디'}
								{['tourCaddie', 'houseCaddie'].includes(auth?.user?.userType) &&
									'프로'}
								를 구하지 못했다면?{' '}
								<span className="neoR00_18_rouge mobiletxt">지금 계약 가능!</span>
							</p>
						</div>
					</div>

					<div className="row align-items-stretch cmb-30" id="caddieList">
						{['tourPro', 'agency'].includes(auth?.user?.userType) &&
							(caddies.length > 0 ? (
								caddies.map((caddie) => (
									<MatchingUserCard
										PROFILE_BUCKET={PROFILE_BUCKET}
										user={caddie}
										favoredUserIds={favoredUserIds}
										onClickFavored={onClickFavored}
									/>
								))
							) : (
								<Card className="cmb-30">
									<Card.Body>
										<div className="text-center">캐디가 없습니다.</div>
									</Card.Body>
								</Card>
							))}

						{['tourCaddie', 'houseCaddie'].includes(auth?.user?.userType) &&
							(players.length > 0 ? (
								players.map((player) => (
									<MatchingUserCard
										PROFILE_BUCKET={PROFILE_BUCKET}
										user={player}
										favoredUserIds={favoredUserIds}
										onClickFavored={onClickFavored}
									/>
								))
							) : (
								<Card className="cmb-30">
									<Card.Body>
										<div className="text-center">프로가 없습니다.</div>
									</Card.Body>
								</Card>
							))}
					</div>

					{/*구인공고 리스트*/}
					<MatchingOfferList offers={offers} />

					<div className="row cmb-30">
						<div className="col-12 col-md-6 cmb-10">
							<Link to={'/about'}>
								<div className="bannerWrap cmb-10">
									<img
										className="w-100"
										src={`/images/pro_main_001_banner01.png`}
										alt="pro_main_001_banner01"
									/>
								</div>
							</Link>
						</div>
						<div className="col-12 col-md-6 cmb-10">
							<Link to={'/about'}>
								<div className="bannerWrap cmb-10">
									<img
										className="w-100"
										src={`/images/pro_main_001_banner02.png`}
										alt="pro_main_001_banner02"
									/>
								</div>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
