import { useState } from 'react'
import { Link } from 'react-router-dom'
import sanitizeHtml from 'sanitize-html'

export const Collapse = ({ isAuthentication, dataSource = [], ...props }) => {
	return (
		<div className="pboard" id="pboard">
			{dataSource?.map((item) => (
				<CollapseItem key={item.id} isAuthentication={isAuthentication} data={item} />
			))}
		</div>
	)
}

const CollapseItem = ({ isAuthentication, data, ...props }) => {
	const [toggle, setToggle] = useState(false)

	const handleToggle = (event) => {
		setToggle(!toggle)
	}

	return (
		<div className="pboard-item" onClick={handleToggle}>
			<div className="item-left">
				<p className="title">{data?.subject}</p>
				<p className="subtitle">{data?.createdAt}</p>
			</div>
			<div className="item-right">
				<button className="updownbtn">
					<img
						className={toggle ? 'down' : 'up'}
						alt="down icon"
						src={`/images/down.png`}
					/>
				</button>
			</div>
			<div className={toggle ? `pboard-subitem itemnone` : `pboard-subitem`}>
				<p>
					{!isAuthentication ? (
						<Link to={'/login'}>상세 내용확인은 로그인이 필요합니다</Link>
					) : (
						<div dangerouslySetInnerHTML={{ __html: sanitizeHtml(data?.content) }} />
					)}
				</p>
			</div>
		</div>
	)
}
