import React, { useState } from 'react'
import { ModalContractCancelModal } from './ModalContractCancelModal'
import {
	contractTypes,
	formattingPrice,
	getCurrentYear,
	getMatchTypeOptions,
	getToday,
	isEmpty,
} from '../../utils'
import dayjs from 'dayjs'

export const ScheduleInfo = ({
	serviceConfig,
	userId,
	userType,
	contract,
	incentives,
	cancelRequestContract,
	cancelContract,
	...props
}) => {
	const [contractCancelModelVisible, setContractCancelModelVisible] = useState(false)

	const currentYear = getCurrentYear()

	const onOpenCancelModal = () => {
		setContractCancelModelVisible(true)
	}
	const onCloseCancelModal = () => {
		setContractCancelModelVisible(false)
	}

	const isStartedTour = ['single', 'long_term'].includes(contract?.contract?.contract_type)
		? dayjs(getToday()).isBefore(contract?.tour?.started_at)
		: dayjs(getToday()).isBefore(serviceConfig?.yearDate)

	return (
		<div className="card">
			<div className="card-body">
				<div className="card cmb-30">
					<div className="d-flex">
						<div className="cardImage_caddie">
							<img src={`/images/noImg02.png`} alt="profileImage" />
						</div>
						<div className="card-body">
							<div className="d-flex justify-content-between">
								<div>
									<p className="font-R00 fontSize-13 color-greyish-two mb-1"></p>
									<p className="font-SB00 fontSize-17 color-black mb-2">
										{contract?.targetUser?.name}
									</p>
								</div>
							</div>
							<p className="font-L00 fontSize-15 color-greyish-two">
								경력
								<span className="color-greyish-brown ms-2 me-3">
									{currentYear - contract?.targetUser?.employment_year + 1 || 0}{' '}
									년
								</span>
							</p>
							<p className="font-L00 fontSize-15 color-greyish-two">
								구단
								<span className="color-greyish-brown ms-2">
									{contract?.targetUser?.club?.name}
								</span>
							</p>
							{/*<a href="tel:" className="btn btn-spruce form_titleSubButton radius-5">*/}
							{/*	<img src={`/images/calendar_002_call_icn.png`} alt="call-icon" />*/}
							{/*	통화*/}
							{/*</a>*/}
						</div>
					</div>
				</div>

				<p className="fontSize-20 color-greyish-brown cmd-30">계약 정보</p>

				<div className="w-100 border-bottom cmb-30" />

				<div className="d-flex align-items-start cmb-15">
					<p className="fontSize-16 neoL00_16_greyish-two">계약유형</p>
					<p className="neoL00_16_greyish-brown ms-4">
						{getMatchTypeOptions(contractTypes, contract?.contract?.contract_type)}
					</p>
				</div>

				<div className="d-flex align-items-start cmb-30">
					<p className="neoL00_16_greyish-two text-nowrap">계약기간</p>
					{['single', 'long_term'].includes(contract?.contract?.contract_type) && (
						<p className="neoM00_16_greyish-brown ms-4">
							{dayjs(contract?.tour?.started_at).format('YYYY-MM-DD')} ~{' '}
							{dayjs(contract?.tour?.ended_at).format('YYYY-MM-DD')}
						</p>
					)}
					{['year_first_half', 'year_second_half'].includes(
						contract?.contract?.contract_type,
					) && (
						<p className="neoM00_16_greyish-brown ms-4">
							{dayjs(contract?.contract?.contracted_at).format('YYYY-MM-DD')} ~{' '}
							{contract?.contract?.contract_type === 'year_first_half'
								? dayjs(serviceConfig?.yearDate).format('YYYY-MM-DD')
								: dayjs(new Date(new Date().getFullYear(), 11, 31)).format(
										'YYYY년 MM월 DD일',
								  )}
						</p>
					)}
				</div>

				<div className="d-flex align-items-start cmb-15">
					<p className="fontSize-16 neoL00_16_greyish-two">계약임금</p>
					<p className="neoL00_16_greyish-brown ms-4">
						{['single', 'long_term'].includes(contract?.contract?.contract_type) && (
							<React.Fragment>
								<div className="row mbcmb-10">
									<div className="col-auto">(주급)</div>
									<div className="col-auto text-lg-end text-sm-left">
										컷통과: {formattingPrice(contract?.contract?.weekly_salary)}{' '}
										원 ({contract?.contract?.is_tax_included ? '세전' : '세후'})
										<br />
										컷탈락:{' '}
										{formattingPrice(contract?.contract?.weekly_salary_f)} 원 (
										{contract?.contract?.is_tax_included ? '세전' : '세후'})
									</div>
								</div>
							</React.Fragment>
						)}
						{['year_first_half', 'year_second_half'].includes(
							contract?.contract?.contract_type,
						) && (
							<React.Fragment>
								<div className="row mbcmb-10">
									<div className="col-auto">
										(
										{contract?.contract?.contract_type === 'year_first_half' &&
											'상반기 연봉'}
										{contract?.contract?.contract_type === 'year_second_half' &&
											'하반기 연봉'}
										)
									</div>
									<div className="col-auto text-lg-end text-sm-left">
										{formattingPrice(contract?.contract?.weekly_salary)} 원 (
										{contract?.contract?.is_tax_included ? '세전' : '세후'})
									</div>
								</div>
							</React.Fragment>
						)}
					</p>
				</div>
				<div className="d-flex align-items-start cmb-15">
					<p className="fontSize-16 neoL00_16_greyish-two">임금지급기간</p>
					<p className="neoL00_16_greyish-brown ms-4">대회 종료 날짜 10일 이내</p>
				</div>

				{['single', 'long_term'].includes(contract?.contract?.contract_type) && (
					<React.Fragment>
						<div className="d-flex align-items-start cmb-15">
							<p className="fontSize-16 neoL00_16_greyish-two">인센티브</p>
							<p className="neoL00_16_greyish-brown ms-4">
								{incentives?.length > 0
									? incentives
											?.sort((a, b) => {
												return a?.startRank - b?.startRank
											})
											?.map((item, index) => {
												if (item?.endRank === 1) {
													return `우승: ${item?.percentage}%`
												} else {
													return `TOP ${item?.startRank}~${
														item?.endRank || 'CUT'
													}: ${item?.percentage}%`
												}
											})
											?.join(', ')
									: '-'}
							</p>
						</div>

						<div className="d-flex align-items-start cmb-30">
							<p className="neoL00_16_greyish-two text-nowrap">계약대회</p>
							<p className="neoM00_16_greyish-brown ms-4">{contract?.tour?.name}</p>
						</div>

						<div className="d-flex align-items-start cmb-30">
							<p className="neoL00_16_greyish-two text-nowrap">기타</p>
							<p className="neoM00_16_greyish-brown ms-4">
								<span>
									제주대회 출전 시{' '}
									{contract?.contract?.expense_reimbursement_jeju > 0
										? `${formattingPrice(
												contract?.contract?.expense_reimbursement_jeju,
										  )}원`
										: '미지원'}
								</span>
								<br />
								<span>
									스폰서 모자{' '}
									{contract?.contract?.wear_sponsored_hat ? '착용' : '미착용'}
								</span>
							</p>
						</div>

						<p className="fontSize-20 color-greyish-brown cmd-30">경기 결과</p>

						<div className="w-100 border-bottom cmb-30"></div>
						<div className="d-flex align-items-start cmb-30">
							{isEmpty(contract?.contract?.ranking) ? (
								<p className="fontSize-16 neoL00_16_greyish-two">
									대회 결과 발표 전 입니다
								</p>
							) : (
								<p className="fontSize-16 neoL00_16_greyish-two">
									{contract?.contract?.ranking}
								</p>
							)}
						</div>
					</React.Fragment>
				)}

				{isStartedTour && contract?.contract?.contract_status === 'PROCEEDING' && (
					<div
						className="d-flex justify-content-center cmb-20"
						id="cancleBox"
						style={{ display: 'block' }}>
						<button
							className="btn btn-dark border border-greyish-brown font-R00 fontSize-20 color-white w-100 pb-2 pt-2"
							onClick={onOpenCancelModal}>
							계약 취소하기
						</button>
					</div>
				)}

				{isStartedTour &&
					contract?.contract?.cancel_request_user === userId &&
					contract?.contract?.contract_status === 'CANCEL_REQUEST' && (
						<div
							className="d-flex justify-content-center cmb-20"
							id="cancleBox"
							style={{ display: 'block' }}>
							<button
								disabled
								className="btn btn-dark border border-greyish-brown font-R00 fontSize-20 color-white w-100 pb-2 pt-2"
								onClick={onOpenCancelModal}>
								계약 취소 요청 중
							</button>
						</div>
					)}

				{isStartedTour &&
					contract?.contract?.cancel_request_user !== userId &&
					contract?.contract?.contract_status === 'CANCEL_REQUEST' && (
						<div
							className="d-flex justify-content-center cmb-20"
							id="cancleBox"
							style={{ display: 'block' }}>
							<button
								className="btn btn-dark border border-greyish-brown font-R00 fontSize-20 color-white w-100 pb-2 pt-2"
								onClick={onOpenCancelModal}>
								계약 취소 요청 수락하기
							</button>
						</div>
					)}
			</div>

			<ModalContractCancelModal
				modalVisible={contractCancelModelVisible}
				userType={userType}
				contractId={contract?.contract?.id}
				contractStatus={contract?.contract?.contract_status}
				cancelRequestContract={cancelRequestContract}
				cancelContract={cancelContract}
				onClose={onCloseCancelModal}
			/>
		</div>
	)
}
