import React from 'react'
import { Card } from 'react-bootstrap'
import { formattingPrice } from '../../utils'

export const ContractInfo = ({ contract, incentives, ...props }) => {
	incentives?.sort((a, b) => {
		return a?.startRank - b?.startRank
	})

	return (
		<React.Fragment>
			<p className="form_title">계약정보</p>
			<Card className="cmb-30">
				<Card.Body>
					<div className="row">
						<div className="col-12 col-md-6 cm-border-right">
							<div className="row mbcmb-10 ">
								<div className="col-12 col-md-6 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 color-greyish-two">
									임금 세금포함 여부{' '}
									<span style={{ fontSize: '0.875rem' }}>(원천징수3.3%)</span>
								</div>
								<div className="col-12 col-md-6 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 text-lg-end text-sm-left">
									{contract?.is_tax_included ? '세전' : '세후'}
								</div>
							</div>

							<div className="row mbcmb-10">
								<div className="col-12 col-md-6 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 color-greyish-two">
									임금 지급 방식
								</div>
								<div className="col-12 col-md-6 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 text-lg-end text-sm-left">
									{['single', 'long_term'].includes(contract?.contract_type) &&
										'주급'}
									{contract?.contract_type === 'year_first_half' &&
										'연계약(상반기)'}
									{contract?.contract_type === 'year_second_half' &&
										'연계약(하반기)'}
								</div>
							</div>

							<div className="row mbcmb-10">
								<div className="col-12 col-md-6 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 color-greyish-two">
									{['single', 'long_term'].includes(contract?.contract_type) &&
										'컷통과'}
								</div>
								<div className="col-12 col-md-6 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 text-lg-end text-sm-left color-spruce">
									{formattingPrice(contract?.weekly_salary)} 원
								</div>
							</div>

							{['single', 'long_term'].includes(contract?.contract_type) && (
								<div className="row mbcmb-10">
									<div className="col-12 col-md-6 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 color-greyish-two">
										컷탈락
									</div>
									<div className="col-12 col-md-6 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 text-lg-end text-sm-left color-spruce">
										{formattingPrice(contract?.weekly_salary_f)} 원
									</div>
								</div>
							)}
						</div>
						<div className="col-12 col-md-6">
							<div className="row mbcmb-10">
								<div className="col-12 col-md-6 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 color-greyish-two">
									인센티브 방식
								</div>

								{['single', 'long_term'].includes(contract?.contract_type) && (
									<div className="col-12 col-md-6 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 text-lg-end text-sm-left">
										{contract?.is_incentive_tax_included
											? '상금 표기액'
											: '상금 실수령액'}
									</div>
								)}

								{['year_first_half', 'year_second_half'].includes(
									contract?.contract_type,
								) && (
									<div className="col-12 col-md-6 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 text-lg-end text-sm-left">
										해당없음
									</div>
								)}
							</div>

							<div className="row mbcmb-10">
								<div className="col-12 col-md-4 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 color-greyish-two">
									인센티브
								</div>

								{['single', 'long_term'].includes(contract?.contract_type) && (
									<div className="col-12 col-md-8 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 text-lg-end text-sm-left">
										{incentives?.map((item, index) => {
											if (item?.endRank === 1) {
												return (
													<div
														key={item?.id}
														className="row flex-nowrap align-items-center">
														<div className="col text-end">우승</div>
														<div className="col-md-4 col-3 text-end">
															{item?.percentage}%
														</div>
													</div>
												)
											} else {
												return (
													<div
														key={item?.id}
														className="row flex-nowrap  align-items-center">
														<div className="col text-end">
															{item?.startRank}~
															{item?.endRank || 'CUT'}
														</div>
														<div className="col-md-4 col-3 text-end">
															{item?.percentage}%
														</div>
													</div>
												)
											}
										})}
									</div>
								)}

								{['year_first_half', 'year_second_half'].includes(
									contract?.contract_type,
								) && (
									<div className="col-12 col-md-8 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 text-lg-end text-sm-left">
										해당없음
									</div>
								)}
							</div>

							<div className="row mbcmb-10">
								<div className="col-12 col-md-4 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 color-greyish-two">
									제주도 지원금
								</div>

								{['single', 'long_term'].includes(contract?.contract_type) && (
									<div className="col-12 col-md-8 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 text-lg-end text-sm-left">
										{contract?.expense_reimbursement_jeju > 0
											? `${formattingPrice(
													contract?.expense_reimbursement_jeju,
											  )}원`
											: '미지원'}
									</div>
								)}

								{['year_first_half', 'year_second_half'].includes(
									contract?.contract_type,
								) && (
									<div className="col-12 col-md-8 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 text-lg-end text-sm-left">
										해당없음
									</div>
								)}
							</div>

							<div className="row mbcmb-10">
								<div className="col-12 col-md-8 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 color-greyish-two">
									선수 스폰서 모자 착용 가능 여부
								</div>

								{['single', 'long_term'].includes(contract?.contract_type) && (
									<div className="col-12 col-md-4 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 text-lg-end text-sm-left">
										{contract?.wear_sponsored_hat ? '착용' : '미착용'}
									</div>
								)}

								{['year_first_half', 'year_second_half'].includes(
									contract?.contract_type,
								) && (
									<div className="col-12 col-md-4 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 text-lg-end text-sm-left">
										해당없음
									</div>
								)}
							</div>
						</div>
					</div>
				</Card.Body>
			</Card>
		</React.Fragment>
	)
}
