import React, { useState, useEffect, useContext, createContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { DEFAULT_USER_SESSION_COOKIE_NAME, getCookie, setCookie } from './cookies'
import { isEmpty } from './form'

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

const AuthStateContext = createContext(undefined)

export const AuthProvider = ({ children }) => {
	const [authState, setAuthState] = useState({
		state: 'loading',
	})

	useEffect(() => {
		const user = getCookie(DEFAULT_USER_SESSION_COOKIE_NAME)

		onChange(user)
	}, [])

	const onChange = (user) => {
		if (!isEmpty(user)) {
			setCookie(DEFAULT_USER_SESSION_COOKIE_NAME, user)
			setAuthState({ state: 'loaded', isAuthentication: true, user: JSON.parse(user) })
		} else {
			setAuthState({ state: 'loaded', isAuthentication: false })
		}
	}
	const setError = (error) => {
		setAuthState({ state: 'error', error })
	}

	return (
		<AuthStateContext.Provider value={[authState, onChange]}>
			{children}
		</AuthStateContext.Provider>
	)
}

export const useAuthStatus = () => {
	const [authStatus, onChange] = useContext(AuthStateContext)

	if (!authStatus) throw new Error('AuthProvider not found')

	return [authStatus, onChange]
}

export const SignIn = async (input) => {
	return await fetch(`${API_ENDPOINT}/auth/signin`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(input),
	})
		.then(async (response) => {
			const result = await response.json()

			if (result.success === false) throw result

			return result?.user
		})
		.catch((errors) => {
			console.log(errors)
		})
}
