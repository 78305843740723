import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStateMachine } from 'little-state-machine'
import { SuggestionTypeForm } from '../../components'
import { updateFormInput } from '../../utils'

export const ProposalTypeSelectView = ({ ...props }) => {
	const navigate = useNavigate()
	const { actions, state } = useStateMachine({ updateFormInput })

	const onSubmit = (data) => {
		const payload = {
			contractInput: { ...state.contractInput, ...data },
		}

		actions.updateFormInput(payload)

		if (data?.contractType === 'year') {
			navigate('/proposal/create/annual-type')
		} else {
			navigate('/proposal/create/tour')
		}
	}

	return (
		<div className="wrap form_page">
			<div className="content_area">
				<div className="bgWrap">
					<SuggestionTypeForm onSubmit={onSubmit} />
				</div>
			</div>
		</div>
	)
}
