import Modal from 'react-bootstrap/Modal'
import { useState } from 'react'

export const ModalPhoneChange = ({ modalVisible, onClose, ...props }) => {
	const [certification, setCertification] = useState(false)

	return (
		<Modal
			id="changephoneModal"
			show={modalVisible}
			backdrop="static"
			tabIndex={-1}
			aria-labelledby="changephoneModalLabel"
			aria-hidden="true">
			<Modal.Header>
				<p className="modal-title w-100 text-center font-bold" id="staticBackdropLabel">
					전화번호 변경
				</p>
				<button type="button" className="btn-close" aria-label="Close" onClick={onClose} />
			</Modal.Header>

			<Modal.Body>
				<div className="reg_inputBox phoneChangeBox">
					<label htmlFor="phoneNumber" className="form-label">
						* 휴대폰 번호
					</label>
					<div className="d-flex w-100 mb-3">
						<input type="hidden" id="cNum2" />
						<input
							type="tel"
							name="phoneNumber"
							className="form-control underbarInput"
							id="phoneNumber"
							placeholder="휴대폰 번호를 입력해 주세요."
							data-message="휴대폰 번호를 정확히 입력해 주세요."
							required=""
						/>
						<button
							type="button"
							name="sendCerificationNumber"
							className="btn btn-outline-dark reg_buttons ms-4 text-nowrap sendCerificationNumber"
							id="sendCerificationNumber">
							인증번호 전송
						</button>
					</div>
					<div className="d-flex w-100 mb-2">
						<input
							type="text"
							name="certificationNum"
							className="form-control underbarInput"
							id="certification"
							placeholder="인증번호를 입력해 주세요."
							data-message="인증번호를 정확히 입력해 주세요."
							disabled={!certification}
							required=""
						/>
						<button
							type="button"
							id="checkCertification"
							name="checkCertification"
							className="btn btn-outline-dark reg_buttons ms-4 text-nowrap"
							disabled={!certification}>
							인증번호 확인
						</button>
					</div>
					{certification && (
						<div className="certificationCheckBox d-flex justify-content-between cmb-30">
							<div className="certificationTime">3:00</div>
							<div className="certificationText">
								<img src="/images/id_find_001_exicn01.png" alt="img" />
								인증번호를 다시 입력해 주세요.
							</div>
						</div>
					)}
				</div>

				<button
					type="button"
					className="btn btn-dark nextPageButton"
					onClick="changePhoneNum()">
					저장하기
				</button>
			</Modal.Body>
		</Modal>
	)
}
