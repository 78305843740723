import React, { useEffect, useState } from 'react'
import { Card, Nav, Tab } from 'react-bootstrap'
import { Calendar } from '../Calendar'
import { GradeItem } from './GradeItem'
import { getMatchTypeOptions, getToday, userTypes } from '../../utils'

export const MatchingAgencyInfo = ({
	API_ENDPOINT,
	PROFILE_BUCKET,
	user,
	favoredUserIds,
	fetchContractsByStatus,
	onClickFavored,
	onClickProposal,
	...props
}) => {
	const [tabKey, setTabKey] = useState('SCHEDULE')
	const [contractSchedules, setContractSchedules] = useState([])
	const [gradeList, setGradeList] = useState([])

	const today = getToday()

	useEffect(() => {
		fetchContractsByStatus('PROCEEDING').then((response) => {
			setContractSchedules(response)
		})
	}, [])

	const onSelectTab = async (key) => {
		switch (key) {
			case 'SCHEDULE':
				await fetchContractsByStatus('PROCEEDING').then((response) => {
					setContractSchedules(response)
				})
				break
			case 'GRADE':
				break
			// TODO: 스포이음에서 계약된 경기 결과 내역
		}

		setTabKey(key)
	}

	return (
		<React.Fragment>
			<div className="row cmb-30">
				<div className="col-12 col-md-6 cmb-10">
					<Card>
						<div className="d-flex">
							<div className="cardImage_caddie">
								<img
									src={
										!!user?.profile_type
											? PROFILE_BUCKET + user?.id + '.' + user?.profile_type
											: `/images/noImg.png`
									}
									alt="pfImage"
								/>
							</div>
							<Card.Body>
								<div className="d-flex justify-content-between">
									<div>
										<p className="font-R00 fontSize-13 color-greyish-two mb-1">
											{getMatchTypeOptions(userTypes, user?.user_type)}
										</p>
										<p className="font-SB00 fontSize-17 color-black mb-2">
											{user?.name}
										</p>
									</div>
									<div className="">
										<img
											src={`
											/images/${
												favoredUserIds.includes(user?.id)
													? 'pro-main-001-like-02.png'
													: 'pro-main-001-like-01.png'
											}`}
											alt="thumbup"
											className="button_thumup"
											id="thumb-130"
											onClick={onClickFavored(user?.id)}
										/>
									</div>
								</div>
								<p className="font-L00 fontSize-15 color-greyish-two">
									경력
									<span className="color-greyish-brown ms-2 me-3">3 년</span>
								</p>

								<p className="font-L00 fontSize-15 color-greyish-two">
									메인스폰서
									<span className="color-greyish-brown ms-2">스포이음</span>
								</p>
							</Card.Body>
						</div>
					</Card>
				</div>

				<div className="col-12 col-md-6 cmb-10">
					<div className="row h-100 align-items-end">
						<div className="col">
							<button
								className="btn pb-4 pt-4 btn-dark w-100"
								onClick={onClickProposal}>
								제안하기
							</button>
						</div>
					</div>
				</div>
			</div>

			<Tab.Container defaultActiveKey={tabKey} onSelect={onSelectTab}>
				<Nav className="nav-tabs nav-tabs-camel" id="myTab" role="tablist">
					<Nav.Item role="presentation" style={{ width: 'calc(100% /3)' }}>
						<Nav.Link eventKey={'SCHEDULE'} className="cumbw-100 tab-camel">
							에이전시 스케줄
						</Nav.Link>
					</Nav.Item>

					<Nav.Item role="presentation" style={{ width: 'calc(100% /3)' }}>
						<Nav.Link eventKey={'GRADE'} className="cumbw-100 tab-camel">
							성적/경력
						</Nav.Link>
					</Nav.Item>
				</Nav>

				<Tab.Content className="tab-content" id="myTabContent">
					<Tab.Pane eventKey={'SCHEDULE'} className="py-3">
						<p className="form_title cmb-30">계약 중인 스케줄 정보</p>
						{/*full calendar*/}
						<Card className="cmb-30">
							<Card.Body>
								<Calendar initialDate={today} events={contractSchedules} />
							</Card.Body>
						</Card>
					</Tab.Pane>
					<Tab.Pane eventKey={'GRADE'} className="py-3">
						<p className="form_title cmb-30">주요 이력</p>
						<Card className="cmb-30">
							<Card.Body>
								{gradeList?.length > 0
									? gradeList?.map((item) => <GradeItem dataSource={item} />)
									: '경력이 없습니다'}
							</Card.Body>
						</Card>
					</Tab.Pane>
				</Tab.Content>
			</Tab.Container>
		</React.Fragment>
	)
}
