export const userTypes = [
	{ label: '투어프로', value: 'tourPro' },
	{ label: '투어캐디', value: 'tourCaddie' },
	{ label: '하우스캐디', value: 'houseCaddie' },
	{ label: '에이전시', value: 'agency' },
]

// contract
export const contractTypes = [
	{ label: '단일계약', value: 'single' },
	{ label: '장기계약', value: 'long_term' },
	{ label: '상반기 연봉계약', value: 'year_first_half' },
	{ label: '하반기 연봉계약', value: 'year_second_half' },
]

export const contractStatusTypes = [
	{ label: '계약전', value: 'PRE' },
	{ label: '계약거절', value: 'REFUSE' },
	{ label: '계약중', value: 'PROCEEDING' },
	{ label: '계약취소요청', value: 'CANCEL_REQUEST' },
	{ label: '계약취소', value: 'CANCEL' },
	{ label: '미입금', value: 'NOTPAY' },
	{ label: '입금완료', value: 'END' },
]

export const oneInquireTypes = [
	{ label: '앱 사용 문의', value: 'app' },
	{ label: '결제 관련 문의', value: 'payment' },
	{ label: '계약 관련 문의', value: 'contract' },
	{ label: '기타 문의', value: 'etc' },
]

export const sponsorshipTypes = [
	{ label: '타이틀', value: 'title' },
	{ label: '서브', value: 'sub' },
]

export const getMatchTypeOptions = (types, currentType, options = { valueProp: 'value' }) => {
	return types.find((type) => type[options.valueProp] === currentType)?.label
}

export const parseStringToBool = (value) => {
	if (value && typeof value === 'string') {
		if (value.toLowerCase() === 'true') return true
		if (value.toLowerCase() === 'false') return false
	}
	return value
}
