import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

export const MessageModal = ({ modalVisible, content, onClickOk, onClose, ...props }) => {
	return (
		<Modal
			id="messageModal"
			className="messageModal"
			backdrop="static"
			centered={true}
			tabIndex="-1"
			aria-labelledby="messageModalLabel"
			aria-hidden="true"
			show={modalVisible}
			onHide={onClose}
			size="sm">
			<Modal.Body className="text-center">{content}</Modal.Body>
			<Modal.Footer className="py-1">
				<Button variant="warning" size="sm" onClick={onClickOk || onClose}>
					확인
				</Button>
			</Modal.Footer>
		</Modal>
	)
}
