import React, { useState } from 'react'
import { MessageModal } from '../../components'
import { FETCH_HEADER_OPTIONS, useAuthStatus, getCardOption, SETTLE_PG } from '../../utils'

export const PointChargeView = ({ API_ENDPOINT, ...props }) => {
	const [auth, onChangeAuthStatus] = useAuthStatus()

	const [selectedProduct, setSelectedProduct] = useState('')
	const [checkedAgree, setCheckedAgree] = useState(false)

	const [messageModalVisible, setMessageModalVisible] = useState(false)
	const [messageModalContent, setMessageModalContent] = useState('')

	const onSubmit = async () => {
		if (!!!selectedProduct) {
			handleMessageModalOpen('구매 내역을 선택해주세요.')
		} else {
			if (!checkedAgree) {
				handleMessageModalOpen('구매 내용을 동의 하셔야합니다.')
			} else {
				await fetch(`${API_ENDPOINT}/payment/create`, {
					method: 'POST',
					headers: FETCH_HEADER_OPTIONS,
					body: JSON.stringify({
						userId: auth.user.id,
						orderName: selectedProduct,
						purchaseAmount: parseInt(selectedProduct),
						paymentMethods: 'card',
						paymentStatus: 'incomplete',
					}),
				})
					.then(async (response) => {
						const payment = await response.json()

						// const option = {
						// 	env: 'https://tbnpg.settlebank.co.kr',
						// 	mchtId: 'nxca_jt_il',
						// 	method: 'card',
						// 	trdDt: '20211231',
						// 	trdTm: '100000',
						// 	mchtTrdNo: 'ORDER20211231100000',
						// 	mchtName: '헥토파이낸셜',
						// 	mchtEName: 'Hecto Financial',
						// 	pmtPrdtNm: '테스트상품',
						// 	trdAmt: 'AntV/eDpxIaKF0hJiePDKA==',
						// 	mchtCustNm: '홍길동',
						// 	custAcntSumry: '헥토파이낸셜',
						// 	notiUrl: 'https://example.com/notiUrl',
						// 	nextUrl: 'https://example.com/nextUrl',
						// 	cancUrl: 'https://example.com/cancUrl',
						// 	mchtParam: 'name=HongGilDong&age=25',
						// 	custIp: '127.0.0.1',
						// 	pktHash:
						// 		'f395b6725a9a18f2563ce34f8bc76698051d27c05e5ba815f463f00429061c0c',
						// 	ui: {
						// 		type: 'popup',
						// 		width: '430',
						// 		height: '660',
						// 	},
						// }

						const option = getCardOption(payment.id, selectedProduct)

						SETTLE_PG.pay(option, function (rsp) {
							console.log(rsp)
						})
					})
					.catch((errors) => {
						console.log(errors)
					})
			}
		}
	}

	const onSelectProduct = (value) => (event) => {
		setSelectedProduct(value)
	}

	const onCheckAgree = (event) => {
		setCheckedAgree(event.target.checked)
	}

	const handleMessageModalClose = () => {
		setMessageModalContent('')
		setMessageModalVisible(false)
	}
	const handleMessageModalOpen = (message) => {
		setMessageModalContent(message)
		setMessageModalVisible(true)
	}

	return (
		<div className="wrap form_page">
			<div className="content_area">
				<div className="container mainWrap">
					<div className="fontSize-25 font-M00 color-greyish-brown cmb-30 text-center">
						<span>포인트 충전</span>
					</div>

					<div className="card">
						<div className="card-body">
							<p className="fontSize-18 font-M00 color-greyish-brown-two cmt-30 cmb-30">
								현재 보유 포인트 : {auth?.user?.point} P
							</p>
							<div className="border-bottom border-d8d8d8 w-100 cmb-30"></div>

							<div className="row cmb-20" onClick={onSelectProduct('60000')}>
								<div className="col-6">
									<input type="radio" name="point" id="point60" value="60000" />
									<label htmlFor="point60">60,000 포인트 ( 60,000 P )</label>
								</div>
								<div className="col-6 d-flex justify-content-end">
									<button
										className="btn btn-sm btn-light border neoR00_14_greyish-brown-two"
										type="button">
										60,000 원
									</button>
								</div>
							</div>

							<div className="row cmb-20" onClick={onSelectProduct('100000')}>
								<div className="col-6">
									<input type="radio" name="point" id="point100" value="100000" />
									<label htmlFor="point100">100,000 포인트 ( 100,000 P )</label>
								</div>
								<div className="col-6 d-flex justify-content-end">
									<button
										className="btn btn-sm btn-light border neoR00_14_greyish-brown-two"
										type="button">
										100,000 원
									</button>
								</div>
							</div>

							<div className="row cmb-20" onClick={onSelectProduct('300000')}>
								<div className="col-6">
									<input type="radio" name="point" id="point300" value="300000" />
									<label htmlFor="point300">300,000 포인트 ( 300,000 P )</label>
								</div>
								<div className="col-6 d-flex justify-content-end">
									<button
										className="btn btn-sm btn-light border neoR00_14_greyish-brown-two"
										type="button">
										300,000 원
									</button>
								</div>
							</div>

							<div className="row cmb-20" onClick={onSelectProduct('500000')}>
								<div className="col-6">
									<input type="radio" name="point" id="point500" value="500000" />
									<label htmlFor="point500">500,000 포인트 ( 500,000 P )</label>
								</div>
								<div className="col-6 d-flex justify-content-end">
									<button
										className="btn btn-sm btn-light border neoR00_14_greyish-brown-two"
										type="button">
										500,000 원
									</button>
								</div>
							</div>

							<div className="border-bottom border-d8d8d8 w-100 cmb-30"></div>

							<input
								type="checkbox"
								name="agree_tos"
								id="agree"
								checked={checkedAgree}
								onChange={onCheckAgree}
							/>
							<label className="fontSize-14 font-L00 ms-1 cmb-30" htmlFor="agree">
								구매 내용을 확인하고 동의 합니다.
							</label>
							<button
								className="btn btn-dark nextPageButton cmb-30"
								onClick={onSubmit}>
								포인트 구매
							</button>
							<p className="fontSize-14 font-M00 color-greyish-brown">이용안내</p>
							<p className="fontSize-14 font-L00 color-greyish-brown">
								- 충전한 포인트는 스포이음의 각 서비스에 사용할 수 있습니다.
							</p>
							<p className="fontSize-14 font-L00 color-greyish-brown">
								- 구매 후 7일 이내, 사용하지 않은 포인트는 고객센터를 통해 구매
								취소할 수 있습니다.
							</p>
							<p className="fontSize-14 font-L00 color-greyish-brown">
								- 이벤트 등으로 발생한 포인트는 취소 및 환불 대상이 아닙니다.
							</p>
							<p className="fontSize-14 font-L00 color-greyish-brown">
								- 결제 및 포인트 사용 내역은 내 정보 &gt; 결제&amp;포인트 내역에서
								확인할 수 있습니다.
							</p>
						</div>
					</div>
				</div>
			</div>

			<MessageModal
				modalVisible={messageModalVisible}
				content={messageModalContent}
				onClose={handleMessageModalClose}
			/>
		</div>
	)
}
