import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getMatchTypeOptions, oneInquireTypes } from '../../../utils'
import { Card } from 'react-bootstrap'
import { MessageModal } from '../../../components'

export const OneInquiryDetailView = ({ API_ENDPOINT, ...props }) => {
	const navigate = useNavigate()

	const { oneInquireId } = useParams()

	const [oneInquire, setOneInQuire] = useState({})

	const [messageModalVisible, setMessageModalVisible] = useState(false)
	const [messageModalContent, setMessageModalContent] = useState('')

	useEffect(() => {
		// TODO : oneInquire 정보 불러오기
		fetchOneInQuire()
	}, [])

	const fetchOneInQuire = async () => {
		await fetch(`${API_ENDPOINT}/admin/question/${oneInquireId}`)
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) {
					throw result
				}

				setOneInQuire(result)
			})
			.catch((errors) => console.log(errors))
	}

	const destroyOneInQuire = async () => {
		await fetch(`${API_ENDPOINT}/admin/db-manage/question/delete/${oneInquireId}`, {
			method: 'DELETE',
		})
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) {
					throw result
				}

				navigate(`/mypages/one-in-quire?tab=LIST`)
			})
			.catch((errors) => {
				console.log(errors)
				handleMessageModalOpen('삭제에 실패했습니다.')
			})
	}

	const handleMessageModalOpen = (message) => {
		setMessageModalContent(message)
		setMessageModalVisible(true)
	}

	const handleMessageModalClose = () => {
		setMessageModalContent('')
		setMessageModalVisible(false)
	}

	return (
		<div className="wrap traning_page form_page">
			<div className="content_area">
				<div className="container mainWrap">
					<div className="cmb-30" style={{ fontSize: '3rem' }}>
						1:1 문의
					</div>

					<Card className="mb-5">
						<Card.Body>
							<div className="row align-items-center cmb-20">
								<div className="col-12">
									<div className="d-flex w-100">
										<div className="form_subTitle me-3 fontSize-18 color-greyish-brown-two">
											문의 유형
										</div>
										<div>
											{getMatchTypeOptions(oneInquireTypes, oneInquire?.type)}
										</div>
									</div>
								</div>
							</div>

							<div className="row align-items-center cmb-20">
								<div className="col-12">
									<div className="d-flex w-100">
										<div className="form_subTitle me-3 fontSize-18 color-greyish-brown-two">
											제목
										</div>
										<div>{oneInquire?.title || '-'}</div>
									</div>
								</div>
							</div>

							<div className="row align-items-center cmb-20">
								<div className="col-12">
									<div className="d-flex w-100">
										<div className="form_subTitle me-3 fontSize-18 color-greyish-brown-two">
											문의 내용
										</div>
										<div style={{ whiteSpace: 'pre' }}>
											{oneInquire?.detail || '-'}
										</div>
									</div>
								</div>
							</div>

							{oneInquire?.is_answer && (
								<div className="row align-items-center cmb-20">
									<div className="col-12">
										<div className="d-flex w-100">
											<div className="form_subTitle me-3 fontSize-18 color-greyish-brown-two">
												답변 내용
											</div>
											<div
												dangerouslySetInnerHTML={{
													__html: oneInquire?.answer_detail || '-',
												}}
												style={{ whiteSpace: 'pre' }}
											/>
										</div>
									</div>
								</div>
							)}
						</Card.Body>
					</Card>

					<div className="row justify-content-end align-items-center cmb-20">
						<div className="col-4 col-md-2 d-flex align-items-center cmb-10 mb_btn_size">
							<button
								type="submit"
								className="btn btn-danger pb-3 pt-3"
								style={{ width: '100%' }}
								onClick={destroyOneInQuire}>
								삭제
							</button>
						</div>

						<div className="col-4 col-md-2 d-flex align-items-center cmb-10 mb_btn_size">
							<Link
								to={'/mypages/one-in-quire?tab=LIST'}
								className="btn btn-white-three pb-3 pt-3"
								style={{ width: '100%' }}>
								이전
							</Link>
						</div>
					</div>
				</div>
			</div>

			<MessageModal
				modalVisible={messageModalVisible}
				content={messageModalContent}
				onClose={handleMessageModalClose}
			/>
		</div>
	)
}
