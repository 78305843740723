import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { formattingPrice } from '../../utils'
import { Calendar } from '../Calendar'
import dayjs from 'dayjs'

export const SuggestionTourSelectForm = ({ contractType, tours, onSubmit, ...props }) => {
	const [filteredTours, setFilteredTours] = useState([])

	useEffect(() => {
		parseEvent(tours)
	}, [tours])

	const today = new Date()

	const parseEvent = (tours) => {
		const color = (type) => {
			if (type === 'KPGA') return '#6495ed'
			else if (type === 'KLPGA') return '#ecdae3'
		}

		const events = tours.map((item) => {
			return {
				id: item.id,
				seq: item.id,
				title: item.name,
				start: dayjs(item.startedAt).format('YYYY-MM-DD'),
				end: dayjs(item.endedAt).add(1, 'days').format('YYYY-MM-DD'),
				purse: item.purse,
				color: color(item.type),
			}
		})

		setFilteredTours(events)
	}

	return (
		<div className="container">
			<p className="form_topTitle">
				<span className={'fw-bold'}>제안할 대회(기간)</span>를{' '}
				<span className="text-nowrap">선택해 주세요.</span>
			</p>
			<div className="card">
				<div className="card-body">
					<p className="form_progressTitle cmt-30">
						항목을 선택하시면 제안서가 작성됩니다. 2/3
					</p>
					<div className="progress">
						<div
							className="progress-bar"
							role="progressbar"
							style={{ width: '66.6666%' }}
							aria-valuenow="66.6666"
							aria-valuemin="0"
							aria-valuemax="100"
						/>
					</div>

					<div className="col-12 col-md-12 cmb-40">
						<div className="card card-boxShadow h-100">
							<div className="card-body">
								<div id="scheduleCalendar">
									<Calendar initialDate={today} events={filteredTours} />
								</div>
							</div>
						</div>
					</div>

					<div className="border-bottom border-d8d8d8 w-100 cmb-30" />

					<SelectTourForm
						contractType={contractType}
						tours={tours}
						filteredTours={filteredTours}
						parseEvent={parseEvent}
						setFilteredTours={setFilteredTours}
						onSubmit={onSubmit}
					/>
				</div>
			</div>
		</div>
	)
}

const SelectTourForm = ({ contractType, tours, filteredTours, parseEvent, onSubmit, ...props }) => {
	const { register, setValue, handleSubmit } = useForm({
		mode: 'onBlur',
	})

	const [selectedTourType, setSelectedTourType] = useState('')

	const parseInput = (data) => {
		const input = contractType === 'single' ? { tours: [data?.tours] } : data

		onSubmit(input)
	}

	const onClickType = (key) => (event) => {
		const filteredTours = tours.filter((tour) => tour.type === key)
		parseEvent(filteredTours)

		if (selectedTourType !== key) {
			setValue('tours', [])
		}

		setSelectedTourType(key)
	}

	return (
		<form
			id="form_recruit"
			className="needs-validation form_mw-662 mx-auto"
			noValidate
			onSubmit={handleSubmit(parseInput)}>
			<div className="row cmb-20">
				<div className="col-auto cmb-10">
					<input
						type="radio"
						className="btn-check"
						name="challenge"
						id="tour_type_KPGA"
						autoComplete="off"
						checked={selectedTourType === 'KPGA'}
						onChange={onClickType('KPGA')}
					/>
					<label
						className="d-flex justify-content-center btn btn-light btn-whiteShadow rounded-pill"
						style={{
							width: '7rem',
							height: '38px',
							alignItems: 'center',
							padding: 'unset',
						}}
						htmlFor="tour_type_KPGA">
						KPGA
					</label>
				</div>
				<div className="col-auto cmb-10">
					<input
						type="radio"
						className="btn-check"
						name="challenge"
						id="tour_type_KLPGA"
						autoComplete="off"
						checked={selectedTourType === 'KLPGA'}
						onChange={onClickType('KLPGA')}
					/>
					<label
						className="d-flex justify-content-center btn btn-light btn-whiteShadow rounded-pill"
						style={{
							width: '7rem',
							height: '38px',
							alignItems: 'center',
							padding: 'unset',
						}}
						htmlFor="tour_type_KLPGA">
						KLPGA
					</label>
				</div>
			</div>

			<div className="card card-boxShadow indexScheduleBoardBox cmb-30 input-group-box">
				<div
					className="card-body input-group-target"
					id="scheduleList"
					data-checktype="only">
					{filteredTours?.length > 0 ? (
						filteredTours?.map((item) => (
							<TourItem
								key={item?.id}
								contractType={contractType}
								tour={item}
								register={register}
							/>
						))
					) : (
						<div className="row cmb-10 challengecheckedBox kind_KPGA">
							<div className="col">
								<div className="indexScheduleBoard">
									<p className="indexScheduleBoardTitle">
										해당 기간에 대회가 없습니다.
									</p>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>

			<button type="submit" className="btn btn-dark nextPageButton">
				다음 단계로
			</button>
		</form>
	)
}

const TourItem = ({ contractType, tour, register, ...props }) => {
	return (
		<div className="row cmb-10 challengecheckedBox kind_KPGA">
			<div className="col-auto d-flex align-items-center">
				<div className="form-check">
					<input
						type={contractType === 'single' ? 'radio' : 'checkbox'}
						id={tour?.id}
						className="form-check-input challengechecked"
						value={JSON.stringify(tour)}
						{...register('tours', {
							required: true,
						})}
					/>
				</div>
			</div>
			<div className="col">
				<label htmlFor={tour?.id}>
					<div className="indexScheduleBoard">
						<div className="d-flex">
							<div className="indexScheduleBoardDateLeftBar"></div>
							<p className="indexScheduleBoardDate">
								{dayjs(tour?.start).format('YYYY-MM-DD')} ~{' '}
								{dayjs(tour?.end).format('YYYY-MM-DD')}
							</p>
						</div>
						<p className="indexScheduleBoardTitle">{tour?.title}</p>
						<p className="indexScheduleSubtitle">
							총상금 {formattingPrice(tour?.purse)} 원
						</p>
					</div>
				</label>
			</div>
		</div>
	)
}
