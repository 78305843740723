import React, { useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { formattingPrice, updateFormInput } from '../../utils'
import { useStateMachine } from 'little-state-machine'
import dayjs from 'dayjs'

export const BasicContractInfoForm = ({ onSubmit, ...props }) => {
	const { actions, state } = useStateMachine({ updateFormInput })

	const { control, register, getValues, setValue, resetField, watch, handleSubmit } = useForm({
		defaultValues: {
			isTaxIncluded: 'true',
			isIncentiveTaxIncluded: 'true',
			incentive: [
				{ startRank: 1, endRank: 1 },
				{ endRank: 0, percentage: 0 },
			],
			wearSponsoredHat: 'true',
		},
		mode: 'onBlur',
	})
	const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
		control,
		name: 'incentive',
		rules: { maxLength: 10 },
	})

	const [expenseReimbursementJejuVisible, setExpenseReimbursementJejuVisible] = useState(false)

	const onKeyupPrice = (key) => (event) => {
		const price = formattingPrice(event.target.value)

		setValue(key, price)
	}

	const addIncentive = (index) => (event) => {
		event.preventDefault()

		const prevIncentive = getValues('incentives')[index - 1]
		insert(index, { startRank: prevIncentive?.endRank + 1, percentage: 1 })
	}

	const removeIncentive = (index) => (event) => {
		event.preventDefault()
		remove(index)
	}

	const onClickExpenseReimbursementJeju = (value) => (event) => {
		setValue('expenseReimbursementJeju', '0')
		setExpenseReimbursementJejuVisible(value)
	}

	return (
		<form
			id="form_matchingPro"
			noValidate
			className="needs-validation"
			onSubmit={handleSubmit(onSubmit)}>
			<div className="card">
				<div className="card-body">
					<p className="form_title cmb-10">계약 기간</p>
					<div className="d-flex justify-content-between align-items-end cmb-30 w-100">
						<div className="border-bottom w-100 me-2">
							<div
								className="d-flex align-items-center form_inputText pe-0"
								id="contractTotal">
								<p>
									<span></span>
									<span className="text-nowrap me-1">
										{dayjs(state?.offerInput?.tours?.[0]?.start).format(
											'YYYY-MM-DD',
										)}{' '}
										~{' '}
										{dayjs(state?.offerInput?.tours?.at(-1)?.end).format(
											'YYYY-MM-DD',
										)}
									</span>
								</p>
								<p style={{ wordBreak: 'keep-all' }}>
									(총 {state?.offerInput?.tours?.length}경기)
								</p>
							</div>
						</div>
					</div>

					<p className="form_title">
						* 임금 세금 포함 여부
						<span
							style={{
								marginLeft: '0.625rem',
								fontSize: '0.75rem',
								color: '#aeaeae',
							}}>
							원천징수 3.3%
						</span>
					</p>
					<div className="row cmt-20 cmb-20">
						<div className="col cmb-10">
							<input
								id="paymentTax_1"
								type="radio"
								className="btn-check"
								autoComplete="off"
								value="true"
								{...register('isTaxIncluded', {
									required: true,
								})}
							/>
							<label className="btn btn-light btn-whiteShadow" htmlFor="paymentTax_1">
								세전
							</label>
						</div>
						<div className="col cmb-10">
							<input
								id="paymentTax_2"
								type="radio"
								className="btn-check"
								autoComplete="off"
								value="false"
								{...register('isTaxIncluded', {
									required: true,
								})}
							/>
							<label className="btn btn-light btn-whiteShadow" htmlFor="paymentTax_2">
								세후
							</label>
						</div>
					</div>

					<p className="form_title">임금 지급 방식</p>
					<div className="row cmb-30">
						<div className="col-6">
							<input
								id="paymentTypeWeekly"
								type="radio"
								className="btn-check"
								autoComplete="off"
								checked
							/>
							<label
								className="btn btn-light btn-whiteShadow"
								htmlFor="paymentTypeWeekly">
								주급
							</label>
						</div>
					</div>

					<p className="form_title pe-1">
						주급
						<span
							style={{
								marginLeft: '0.625rem',
								fontSize: '0.75rem',
								color: '#aeaeae',
							}}>
							(컷통과와 컷탈락의 금액을 동일하게 설정하길 희망할 경우 동일한 금액을
							입력해 주세요.)
						</span>
					</p>
					<div className="row align-items-end cmt-10 cmb-30">
						<div className="col-12 col-md-6 cmb-10">
							<div className="row align-items-center">
								<div className="col-auto reg_subTitle text-end">컷통과</div>
								<div className="col">
									<div className="d-flex justify-content-end align-items-center underbarInput">
										<input
											id="weeklySalaryForKPGA"
											className="border-0 w-100 form-control no-focus form_inputText regUnderbarInput"
											placeholder="금액을 입력해 주세요."
											{...register('weeklySalary', {
												required: true,
												onChange: onKeyupPrice('weeklySalary'),
											})}
										/>
										원
									</div>
								</div>
							</div>
						</div>

						<div className="col-12 col-md-6 cmb-10">
							<div className="row align-items-center">
								<div className="col-auto reg_subTitle text-end">컷탈락</div>
								<div className="col">
									<div className="d-flex justify-content-end align-items-center underbarInput">
										<input
											id="weeklySalaryFailForKLPGA"
											className="border-0 w-100 form-control no-focus form_inputText regUnderbarInput"
											placeholder="금액을 입력해 주세요."
											{...register('weeklySalaryF', {
												required: true,
												onChange: onKeyupPrice('weeklySalaryF'),
											})}
										/>
										원
									</div>
								</div>
							</div>
						</div>
					</div>

					<p className="form_title">인센티브 방식</p>
					<div className="row cmb-30">
						<div className="col">
							<input
								id="incentiveTax_1"
								type="radio"
								className="btn-check"
								name="radioTax2"
								autoComplete="off"
								value="true"
								{...register('isIncentiveTaxIncluded')}
							/>
							<label
								className="btn btn-light btn-whiteShadow"
								htmlFor="incentiveTax_1">
								상금 표기액
							</label>
						</div>
						<div className="col">
							<input
								id="incentiveTax_2"
								type="radio"
								className="btn-check"
								autoComplete="off"
								value="false"
								{...register('isIncentiveTaxIncluded')}
							/>
							<label
								className="btn btn-light btn-whiteShadow"
								htmlFor="incentiveTax_2">
								상금 실수령액
							</label>
						</div>
					</div>

					<p className="form_title">인센티브</p>
					<div className="row" id="incenBox">
						{fields.map((field, index) => {
							return (
								<div className="col-12 cmb-10" key={field?.id}>
									<div className="row flex-nowrap align-items-center">
										<div className="col-1 fontSize-16 font-SB00 text-end incenNum">
											{index + 1}
										</div>
										<div className="col fontSize-16 font-SB00 text-end">
											<div
												className="row justify-content-end pb-2"
												style={{
													display: index === 0 ? 'none' : 'auto',
													flexWrap: 'nowrap',
												}}>
												<input
													type="number"
													className="border-0 border-bottom no-focus text-end p-0"
													placeholder="등수"
													style={{
														width: '40px',
													}}
													{...register(`incentive.${index}.startRank`, {
														required: true,
														valueAsNumber: true,
														min: 0,
													})}
												/>
												~
												<input
													type="number"
													className="border-0 border-bottom no-focus text-end p-0"
													placeholder="등수"
													style={{
														width: '40px',
														display:
															field?.endRank === 0 ? 'none' : 'auto',
													}}
													{...register(`incentive.${index}.endRank`, {
														required: true,
														valueAsNumber: true,
														min: 0,
													})}
												/>
												{field?.endRank === 0 && ' CUT'}
											</div>
											{index === 0 && '우승'}
										</div>
										<div className="col-md-4 col-3 d-flex">
											<div className="incentiveItemInput underbarInput">
												<input
													type="number"
													id="incen_win"
													className="rgPro003_noBorderInput rgPro003_incentiveInput p-right"
													key={field?.id}
													placeholder="0"
													{...register(`incentive.${index}.percentage`, {
														required: true,
														valueAsNumber: true,
														min: field?.endRank === 0 ? 0 : 1,
														max: 100,
													})}
												/>
												%
											</div>
										</div>

										<div className="col-auto ps-0">
											<button
												type="button"
												className="btn btn-danger mb-2 onlyPc"
												style={{
													visibility:
														index === 0 || index === fields?.length - 1
															? 'hidden'
															: 'auto',
												}}
												onClick={removeIncentive(index)}>
												삭제
											</button>
											<button
												type="button"
												className="btn btn-danger mb-2 onlyMobile"
												style={{
													padding: '0.4rem',
													lineHeight: '0.8',
													visibility:
														index === 0 || index === fields?.length - 1
															? 'hidden'
															: 'auto',
												}}
												onClick={removeIncentive(index)}>
												X
											</button>
										</div>
									</div>
								</div>
							)
						})}
					</div>
					<button
						type="button"
						className="btn btn-light rgPro003_addIncentive"
						disabled={fields?.length >= 9}
						onClick={addIncentive(fields?.length - 1)}>
						+ 추가하기
					</button>

					<p className="form_title">업무 외 비용</p>
					<div className="row align-items-center cmb-10">
						<div className="col-auto regNWExpensesText text-end">제주대회 출전 시</div>
						{expenseReimbursementJejuVisible && (
							<div className="col">
								<div className="d-flex justify-content-end">
									<div className="col justify-content-end regUnderbarInput">
										<input
											className="border-0 no-focus text-end"
											id="expenseReimbursementJeju"
											placeholder="희망 금액을 입력해 주세요."
											{...register('expenseReimbursementJeju', {
												required: expenseReimbursementJejuVisible,
												onChange: onKeyupPrice('expenseReimbursementJeju'),
											})}
										/>
										원
									</div>
								</div>
							</div>
						)}
					</div>
					<div className="row cmb-30">
						<div className="col">
							<input
								type="radio"
								className="btn-check"
								id="jeju_1"
								autoComplete="off"
								checked={expenseReimbursementJejuVisible}
								onClick={onClickExpenseReimbursementJeju(true)}
							/>
							<label className="btn btn-light btn-whiteShadow" htmlFor="jeju_1">
								지원
							</label>
						</div>
						<div className="col">
							<input
								type="radio"
								className="btn-check"
								id="jeju_2"
								autoComplete="off"
								checked={!expenseReimbursementJejuVisible}
								onClick={onClickExpenseReimbursementJeju(false)}
							/>
							<label className="btn btn-light btn-whiteShadow" htmlFor="jeju_2">
								미지원
							</label>
						</div>
					</div>

					<p className="form_title">선수 스폰서 모자 착용 가능 여부</p>
					<div className="row cmb-30">
						<div className="col">
							<input
								id="wearing"
								type="radio"
								className="btn-check"
								autoComplete="off"
								value="true"
								{...register('wearSponsoredHat')}
							/>
							<label className="btn btn-light btn-whiteShadow" htmlFor="wearing">
								착용
							</label>
						</div>
						<div className="col">
							<input
								id="notWorn"
								type="radio"
								className="btn-check"
								autoComplete="off"
								value="false"
								{...register('wearSponsoredHat')}
							/>
							<label className="btn btn-light btn-whiteShadow" htmlFor="notWorn">
								미착용
							</label>
						</div>
					</div>

					<p className="form_title">글 내용</p>
					<input
						id="mPro_noticeTitle"
						className="w-100 cmb-10"
						placeholder="제목을 입력해주세요"
						{...register('postTitle', {
							required: true,
						})}
					/>
					<textarea
						id="mPro_noticeComment"
						className="w-100 cmb-30"
						rows="7"
						placeholder="본문 내용을 입력해주세요"
						{...register('postDetail', {
							required: true,
						})}></textarea>

					<div className="border-bottom border-d8d8d8 w-100 cmb-30"></div>

					<button type="submit" className="btn btn-dark nextPageButton">
						다음 단계로
					</button>
				</div>
			</div>
		</form>
	)
}
