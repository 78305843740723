import AWS from 'aws-sdk'
import { isEmpty } from './form'

export const convertURLtoFile = async (url) => {
	if (isEmpty(url)) return

	const response = await fetch(url)
	const data = await response.blob()
	const ext = data.type.split('/').pop()
	const metadata = { type: data.type }
	return new File([data], `temp.${ext}`, metadata)
}

export const getFileExt = (file) => {
	return file.type.split('/').pop() || null
}

export const uploadS3 = async (userId, type, file) => {
	if (isEmpty(file)) return

	const REGION = process.env.REACT_APP_S3_REGION
	const ACCESS_KEY_ID = process.env.REACT_APP_S3_ACCESS_KEY_ID
	const SECRET_ACCESS_KEY_ID = process.env.REACT_APP_S3_SECRET_ACCESS_KEY_ID

	AWS.config.update({
		region: REGION,
		accessKeyId: ACCESS_KEY_ID,
		secretAccessKey: SECRET_ACCESS_KEY_ID,
	})

	const fileName = file.name
	const lastDot = fileName.lastIndexOf('.')
	const fileExt = fileName.substring(lastDot, fileName.length).toLowerCase()

	const upload = new AWS.S3.ManagedUpload({
		params: {
			ACL: 'public-read',
			Bucket: 'spoeum',
			Key: `${type}/${userId}${fileExt}`,
			Body: file,
		},
	})

	const data = await upload
		.promise()
		.then((data) => {
			return data.Location
		})
		.catch((errors) => {
			console.log(errors)
		})

	return data
}
