import React, { useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import Modal from 'react-bootstrap/Modal'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ContractInfo, ModalContractTourList } from '../../../components'
import {
	contractTypes,
	formattingPrice,
	getMatchTypeOptions,
	useAuthStatus,
	userTypes,
} from '../../../utils'
import dayjs from 'dayjs'
import { Card } from 'react-bootstrap'

export const OfferDetailView = ({ API_ENDPOINT, PROFILE_BUCKET, ...props }) => {
	const [auth, onChangeAuth] = useAuthStatus()

	const navigate = useNavigate()

	const { offerId } = useParams()

	const [offer, setOffer] = useState({})
	const [applies, setApplies] = useState([])
	const [apply, setApply] = useState({})
	const [contractIncentives, setContractIncentives] = useState([])

	const [tabKey, setTabKey] = useState('WRITTEN')
	const [destroyModalVisible, setDestroyModalVisible] = useState(false)
	const [tourListModalVisible, setTourListModalVisible] = useState(false)

	useEffect(() => {
		fetchOffer()
	}, [])

	const fetchOffer = async () => {
		await fetch(`${API_ENDPOINT}/contract/offer/${offerId}`)
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) throw new Error(result.massage)

				setOffer(result)
			})
			.catch((errors) => setOffer({}))
	}

	const destroyOffer = async () => {
		await fetch(`${API_ENDPOINT}/contract/offer/delete/${offerId}`, { method: 'DELETE' })
			.then(async (response) => {
				navigate('/mypages/offers')
			})
			.catch((errors) => console.log(errors))
	}

	const fetchApplies = async () => {
		await fetch(`${API_ENDPOINT}/contract/contract/offer/apply/${offerId}`)
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) throw result

				console.log(result)

				if (auth?.user?.id !== offer?.offer?.user_id) {
					const apply = result?.find((item) => item?.offer?.user_id === auth?.user?.id)

					await fetchContractIncentives(apply?.offer?.id)

					setApply(apply)
				} else {
					setApplies(result)
				}
			})
			.catch((errors) => setOffer({}))
	}

	const fetchContractIncentives = async (contractId) => {
		await fetch(`${API_ENDPOINT}/contract/contract/detail/incentive/${contractId}`)
			.then(async (response) => {
				const result = await response.json()

				setContractIncentives(result)
			})
			.catch((errors) => {
				console.log(errors)
			})
	}

	const handleOfferTabSelect = async (key) => {
		if (key === 'WRITTEN') {
			await fetchOffer()
		} else if (key === 'OFFER') {
			await fetchApplies()
		}

		setTabKey(key)
	}

	const handleDestroyModalOpen = () => setDestroyModalVisible(true)
	const handleDestroyModalClose = () => setDestroyModalVisible(false)

	const handleTourListModalModalOpen = () => setTourListModalVisible(true)
	const handleTourListModalModalClose = () => setTourListModalVisible(false)

	return (
		<div className="wrap notice_page form_page">
			<div className="content_area">
				<div className="container mainWrap">
					<div className="row cmb-20">
						<div className="col-auto col-md-6">
							<div className="col-auto fontSize-30 cmb-10">
								{offer?.offer?.post_title}
							</div>
							<div className="col-auto fontSize-20 color-greyish-two cmb-10">
								{dayjs(offer?.offer?.created_at).format('YYYY-MM-DD')}
							</div>
						</div>
						{auth?.user?.id === offer?.offer?.user_id && (
							<div className="col-12 col-md-6 d-flex align-items-center cmb-10 mb_btn_size">
								<button
									className="btn btn-danger"
									style={{ width: '80%' }}
									onClick={handleDestroyModalOpen}>
									공고 삭제하기
								</button>
							</div>
						)}
					</div>

					<Tab.Container defaultActiveKey={tabKey} onSelect={handleOfferTabSelect}>
						<Nav className="nav-tabs nav-tabs-camel" id="myTab" role="tablist">
							<Nav.Item className="w-50" role="presentation">
								<Nav.Link eventKey={'WRITTEN'} className="cumbw-100 tab-camel">
									작성한 공고
								</Nav.Link>
							</Nav.Item>

							<Nav.Item className="w-50" role="presentation">
								<Nav.Link eventKey={'OFFER'} className="cumbw-100 tab-camel">
									신청 내역
								</Nav.Link>
							</Nav.Item>
						</Nav>

						<Tab.Content className="tab-content" id="myTabContent">
							<Tab.Pane eventKey={'WRITTEN'} className="py-3">
								<p className="form_title cmt-10">기본정보</p>
								<Card className="cmb-30">
									<Card.Body>
										<div className="row cmt-10">
											<div className="col-12 col-md-auto color-greyish-two">
												계약유형
											</div>
											<div className="col-12 col-md-auto">
												{getMatchTypeOptions(
													contractTypes,
													offer?.offer?.contract_type,
												)}
											</div>
										</div>
										{['single', 'long_term'].includes(
											offer?.offer?.contract_type,
										) && (
											<div className="row cmt-10">
												<div className="col-12 col-md-auto color-greyish-two">
													계약기간
												</div>
												<div className="col-12 col-md-auto">
													{dayjs(offer?.tour[0]?.started_at).format(
														'YYYY-MM-DD',
													)}{' '}
													~{' '}
													{dayjs(offer?.tour?.at(-1)?.ended_at).format(
														'YYYY-MM-DD',
													)}{' '}
													총 {offer?.tour?.length}경기
													<button
														className="btn btn-light form_titleSubButton text-nowrap"
														type="button"
														onClick={handleTourListModalModalOpen}>
														대회목록보기
													</button>
												</div>
											</div>
										)}
										<div className="row cmt-10">
											<div className="col-12 col-md-auto color-greyish-two">
												공고내용
											</div>
											<div
												className="col-12 col-md-auto"
												dangerouslySetInnerHTML={{
													__html: offer?.offer?.post_detail,
												}}
												style={{ whiteSpace: 'pre' }}
											/>
										</div>
									</Card.Body>
								</Card>

								<ContractInfo
									contract={offer?.offer}
									incentives={offer?.incentive}
								/>
							</Tab.Pane>

							<Tab.Pane eventKey={'OFFER'} className="py-3">
								{auth?.user?.id === offer?.offer?.user_id && (
									<React.Fragment>
										<p className="form_title cmt-10">
											받은 신청 {applies?.length}개
										</p>
										<div className="row align-items-center cmb-30">
											{applies?.map((apply) => (
												<div className="col-12 col-md-6 cmb-10">
													<Link
														to={`/mypages/contract-process/${apply?.offer?.id}`}>
														<Card className="card">
															<div className="d-flex">
																<Card.Header
																	src={
																		!!apply?.user?.profile_type
																			? PROFILE_BUCKET +
																			  apply?.user?.id +
																			  '.' +
																			  apply?.user
																					?.profile_type
																			: `/images/noImg.png`
																	}
																	alt="profileImage"
																	className="cardImage_caddie"
																/>
																<Card.Body className="card-body">
																	<div className="d-flex justify-content-between">
																		<div>
																			<p className="font-R00 fontSize-13 color-greyish-two mb-1">
																				{getMatchTypeOptions(
																					userTypes,
																					apply?.user
																						?.user_type,
																				)}
																			</p>
																			<p className="font-SB00 fontSize-17 color-black mb-2">
																				{apply?.user?.name}
																			</p>
																		</div>
																	</div>
																	<p className="font-L00 fontSize-15 color-greyish-two">
																		소속
																		<span className="color-greyish-brown ms-2">
																			{apply?.user?.club ||
																				'-'}
																		</span>
																	</p>
																	{[
																		'single',
																		'long_term',
																	].includes(
																		apply?.offer?.contract_type,
																	) && (
																		<p className="font-L00 fontSize-16 color-greyish-two">
																			제안한 주급
																			<button className="btn btn-light form_titleSubButton radius-5">
																				컷통과{' '}
																				{formattingPrice(
																					apply?.offer
																						?.weekly_salary,
																				)}{' '}
																				원
																			</button>
																			<button className="btn btn-light form_titleSubButton radius-5">
																				컷탈락{' '}
																				{formattingPrice(
																					apply?.offer
																						?.weekly_salary_f,
																				)}{' '}
																				원
																			</button>
																		</p>
																	)}

																	{[
																		'year_first_half',
																		'year_second_half',
																	].includes(
																		apply?.offer?.contract_type,
																	) && (
																		<p className="font-L00 fontSize-16 color-greyish-two">
																			제안한 연봉
																			<button className="btn btn-light form_titleSubButton radius-5">
																				{formattingPrice(
																					apply?.offer
																						?.weekly_salary,
																				)}{' '}
																				원
																			</button>
																		</p>
																	)}
																</Card.Body>
															</div>
														</Card>
													</Link>
												</div>
											))}
											{/*<Link to={`/mypages/proposals/1234`}>*/}
											{/*	<div className="card">*/}
											{/*		<div className="d-flex">*/}
											{/*			<div className="cardImage_caddie">*/}
											{/*				<img*/}
											{/*					src={`/images/noImg02.png`}*/}
											{/*					alt="profileImage"*/}
											{/*				/>*/}
											{/*			</div>*/}
											{/*			<div className="card-body">*/}
											{/*				<div className="d-flex justify-content-between">*/}
											{/*					<div>*/}
											{/*						<p className="font-R00 fontSize-13 color-greyish-two mb-1">*/}
											{/*							투어캐디*/}
											{/*						</p>*/}
											{/*						<p className="font-SB00 fontSize-17 color-black mb-2">*/}
											{/*							이슬희*/}
											{/*						</p>*/}
											{/*					</div>*/}
											{/*					<div className="pe-none">*/}
											{/*						<img*/}
											{/*							src={`*/}
											{/*					'/images/pro-main-001-like-01.png'*/}
											{/*				`}*/}
											{/*							alt="thumbup"*/}
											{/*							className="button_thumup"*/}
											{/*						/>*/}
											{/*					</div>*/}
											{/*				</div>*/}
											{/*				<p className="font-L00 fontSize-15 color-greyish-two">*/}
											{/*					소속*/}
											{/*					<span className="color-greyish-brown ms-2">*/}
											{/*						없음*/}
											{/*					</span>*/}
											{/*				</p>*/}
											{/*				<div>*/}
											{/*					<button className="btn btn-light form_titleSubButton radius-5">*/}
											{/*						제안금액 / 900,000 원*/}
											{/*					</button>*/}
											{/*				</div>*/}
											{/*				<p></p>*/}
											{/*			</div>*/}
											{/*		</div>*/}
											{/*	</div>*/}
											{/*</Link>*/}
										</div>
									</React.Fragment>
								)}
								{auth?.user?.id !== offer?.offer?.user_id && (
									<ContractInfo
										contract={apply?.offer}
										incentives={contractIncentives}
									/>
								)}
							</Tab.Pane>
						</Tab.Content>
					</Tab.Container>
				</div>
			</div>

			<ModalDestroyOffer
				modalVisible={destroyModalVisible}
				destroyOffer={destroyOffer}
				onClose={handleDestroyModalClose}
			/>

			<ModalContractTourList
				modalVisible={tourListModalVisible}
				tours={offer?.tour}
				onClose={handleTourListModalModalClose}
			/>
		</div>
	)
}

const ModalDestroyOffer = ({ modalVisible, destroyOffer, onClose, ...props }) => {
	return (
		<Modal
			id="destroyModal"
			backdrop="static"
			tabIndex="-1"
			aria-labelledby="destroyModalLabel"
			aria-hidden="true"
			show={modalVisible}
			onHide={onClose}
			centered>
			<Modal.Header className="border-0">
				<button type="button" className="btn-close" aria-label="Close" onClick={onClose} />
			</Modal.Header>
			<Modal.Body>
				<p class="fontSize-20 font-L00 color-greyish-brown text-center">
					제안이 취소됩니다.
				</p>
				<p class="fontSize-20 font-L00 color-greyish-brown text-center cmb-30">
					진행하시겠습니까?
				</p>
				<div className="row cmb-20">
					<div className="col-6">
						<button
							type="button"
							aria-label="Close"
							className="btn btn-danger registerNextButton w-100 pb-3 pt-3 cmb-10"
							onClick={onClose}>
							취소
						</button>
					</div>
					<div className="col-6">
						<button
							type="button"
							className="btn btn-dark registerNextButton w-100 pb-3 pt-3 cmb-10"
							onClick={destroyOffer}>
							확인
						</button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
}
