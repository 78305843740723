import { convertObjectPropertiesSnakeCaseToCamelCase, convertSnakeCaseToCamelCase } from './prams'
import isArray from 'lodash/isArray'

export const formInitState = {
	contractInput: {
		userId: '',
		targetUserId: '',
		contractGroupId: '',
		contractType: '',
		isTaxIncluded: true,
		isIncentiveTaxIncluded: true,
		weeklySalary: 0,
		weeklySalaryF: 0,
		percentage: 0,
		expenseReimbursementJeju: 0,
		wearSponsoredHat: true,
		contractStatus: 'PRE',
		etc: 'string',
		tourId: '',
	},
	offerInput: {
		userId: '',
		contractType: '',
		isTaxIncluded: true,
		isIncentiveTaxIncluded: true,
		weeklySalary: 0,
		weeklySalaryF: 0,
		percentage: 0,
		expenseReimbursementJeju: 0,
		wearSponsoredHat: true,
		contractStatus: 'PRE',
		postTitle: '',
		postDetail: '',
		tours: [], //  ['tourId', ...]
		incentive: [],
	},
}

// actions
export const initFormState = (state, payload) => {
	return { ...formInitState }
}

export const updateFormInput = (state, payload) => {
	return { ...state, ...payload }
}

// refExp
export const REG_EMAIL =
	/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
export const REG_PASSWORD = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*?_]).{6,16}$/
export const REG_PHONE =
	/^((01[1|6|7|8|9])-([1-9]+[0-9]{2,3})-([0-9]{4}))|(010-([1-9]+[0-9]{3})-([0-9]{4}))$/

export const isEmpty = (value) => {
	if (value === null) return true
	if (typeof value === 'undefined') return true
	if (typeof value === 'string' && value === '') return true
	if (Array.isArray(value) && value.length < 1) return true
	if (
		typeof value === 'object' &&
		value.constructor.name === 'Object' &&
		Object.keys(value).length < 1 &&
		Object.getOwnPropertyNames(value) < 1
	)
		return true
	if (
		typeof value === 'object' &&
		value.constructor.name === 'String' &&
		Object.keys(value).length < 1
	)
		return true // new String()
	return false
}

// validate
export const validateEmail = (email) => {
	return REG_EMAIL.test(email)
}

export const validatePassword = (value) => {
	return REG_PASSWORD.test(value)
}

// validate phone number
export const validatePhone = (phoneNumber) => {
	phoneNumber = phoneNumber.split('-').join('')

	return REG_PHONE.test(phoneNumber)
}

export const validateInput = (target, prop) => {
	if (prop === undefined) {
		if (target.value === '') {
			target.classList.remove('is-valid')
			target.classList.add('is-invalid')
		} else {
			target.classList.remove('is-invalid')
			target.classList.add('is-valid')
		}
	} else {
		eval(`
          if (target.value === "") {
              target.${prop}.classList.remove("is-valid");
              target.${prop}.classList.add("is-invalid");
          } else {
              target.${prop}.classList.remove("is-invalid");
              target.${prop}.classList.add("is-valid");
          }
      `)
	}
}

export const parseFields = (obj) => {
	if (!obj) return {}

	const fields = {}

	for (const [key, value] of Object.entries(obj)) {
		const camelCaseKey = convertSnakeCaseToCamelCase(key)

		if (!isEmpty(value)) {
			if (typeof value === 'boolean') {
				fields[camelCaseKey] = value.toString()
			} else if (typeof value === 'object' && !('value' in value)) {
				if (isArray(value)) {
					fields[key] = value?.map((item) =>
						convertObjectPropertiesSnakeCaseToCamelCase(item),
					)
				} else {
					fields[key] = convertObjectPropertiesSnakeCaseToCamelCase(value)
				}
			} else {
				fields[camelCaseKey] = value
			}
		}
	}

	return fields
}
