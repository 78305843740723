export const certificationNice = async (returnUrl) => {
	const { form } = document
	const option = `width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no`
	const parsedReturnUrl = encodeURIComponent(returnUrl) // 본인인증 결과를 전달받을 api url

	// token api가 요청 데이터를 암호화한 후 표준창 호출에 필요한 데이터를 응답해준다.
	await fetch(`https://www.spoeum.com:3095/api/users/niceapi/token/${parsedReturnUrl}`)
		.then(async (response) => {
			const result = await response.json()

			if (result.success === false) throw result

			if (form && result) {
				const { tokenVersionId, encData, integrityValue, ...certificationInfo } = result
				window.open('', 'nicePopup', option)

				form.target = 'nicePopup'
				form.enc_data.value = encData
				form.token_version_id.value = tokenVersionId
				form.integrity_value.value = integrityValue
				form.submit()
			}
		})
		.catch((errors) => console.log(errors))
}
