import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStateMachine } from 'little-state-machine'
import {
	FETCH_HEADER_OPTIONS,
	initFormState,
	parsePriceToNumber,
	parseStringToBool,
} from '../../utils'
import { AnnualSuggestionInfoForm } from '../../components'

export const AnnualProposalInfoFormView = ({ API_ENDPOINT, ...props }) => {
	const navigate = useNavigate()
	const { actions, state } = useStateMachine({ initFormState })

	const [serviceConfig, setServiceConfig] = useState({})

	useEffect(() => {
		fetchSettingContract()
	}, [])

	const onSubmit = async (data) => {
		const { isTaxIncluded, weeklySalary, ...others } = data

		const input = {
			...state?.contractInput,
			...others,
			tour_id: '',
			isTaxIncluded: parseStringToBool(isTaxIncluded),
			weeklySalary: parsePriceToNumber(weeklySalary),
			percentage: 0,
		}

		await createContract(input)
	}

	const createContract = async (input) => {
		await fetch(`${API_ENDPOINT}/contract/contract`, {
			method: 'POST',
			headers: FETCH_HEADER_OPTIONS,
			body: JSON.stringify(input),
		})
			.then(async (response) => {
				const result = await response.json()

				if (result?.success === false) throw result

				actions.initFormState()
				navigate('/mypages/proposals?tab=SENT')
			})
			.catch((error) => {
				// TODO : 에러메세지 관련 안내창 출력
				console.log(error)
			})
	}

	const fetchSettingContract = async () => {
		await fetch(`${API_ENDPOINT}/admin/payment-manage/admin/setting`)
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) throw result

				setServiceConfig(result)
			})
			.catch((errors) => console.log(errors))
	}

	return (
		<div className="wrap register_page form_page">
			<div className="content_area">
				<div className="bgWrap">
					<AnnualSuggestionInfoForm serviceConfig={serviceConfig} onSubmit={onSubmit} />
				</div>
			</div>
		</div>
	)
}
