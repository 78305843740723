import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
	convertURLtoFile,
	getFileExt,
	REG_EMAIL,
	SignIn,
	uploadS3,
	useAuthStatus,
} from '../../utils'
import Modal from 'react-bootstrap/Modal'
import { MessageModal } from '../../components'
import { ModalLoginFail } from '../../components/modal'

export const RegisterAgencyView = ({ API_ENDPOINT, ...props }) => {
	const [auth, onChangeAuthStatus] = useAuthStatus()

	const navigate = useNavigate()

	const {
		register,
		setValue,
		handleSubmit,
		formState: { isSubmitting, isSubmitted, errors },
	} = useForm({
		mode: 'onBlur',
	})

	const [agencyPlayers, setAgencyPlayers] = useState([])
	const [playerField, setPlayerField] = useState({
		tourType: 'KPGA',
		name: '',
		fullMembershipNumber: '',
	})

	useEffect(() => {
		if (auth.isAuthentication) {
			navigate('/')
		}
	}, [])

	const onSubmit = async (data) => {
		// TODO: Input이랑 field랑 매칭 X
		// input format
		// {
		//     "name": "string",
		//     "firstName": "string",
		//     "lastName": "string",
		//     "email": "string",
		//     "password": "string",
		//     "phone": "string",
		//     "notification": true,
		//     "userType": "tourPro",
		//     "corporateName": "string",
		//     "businessLicense": "string",
		//     "businessLicenseNumber": "string",
		//     "corporationRepresentativeName": "string",
		//     "palyerMembershipNumber": "string"
		// }

		const { profileImg, ...basicFields } = JSON.parse(localStorage.getItem('basicInfo'))
		const { businessLicense, businessLicenseNumber, ...additionalFields } = data

		const convertedProfile = await convertURLtoFile(profileImg)
		!!businessLicense && (await uploadS3(businessLicenseNumber, 'business', businessLicense))

		const input = {
			userType: 'agency',
			profileType: !!convertedProfile ? getFileExt(convertedProfile) : null,
			businessLicense: businessLicenseNumber + '.pdf',
			businessLicenseNumber,
			...basicFields,
			...additionalFields,
		}

		// TODO: create player
		await fetch(`${API_ENDPOINT}/users/agency`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(input),
		})
			.then(async (response) => {
				const result = await response.json()

				await uploadS3(result?.user_id, 'profile', convertedProfile)

				if (result.success === false) {
					throw new Error('회원가입 실패')
				} else {
					localStorage.clear()
					await SignIn({ email: input?.email, password: input?.password })
						.then((userInfo) => {
							onChangeAuthStatus(JSON.stringify(userInfo))
							navigate('/matching')
						})
						.catch((errors) => {
							handleLoginFailModalOpen()
						})
				}
			})
			.catch((errors) => {
				console.log(errors)
			})
	}

	const oninvalid = (errors) => {
		handleMessageModalOpen('필수 값을 입력해주세요.')
	}

	const onChangePlayerAddForm = (fieldKey) => (event) => {
		const fieldValue = event.target.value

		setPlayerField({ ...playerField, [fieldKey]: fieldValue })
	}

	const addAgencyPlayer = () => {
		setAgencyPlayers([...agencyPlayers, playerField])
		setPlayerField({
			tourType: 'KPGA',
			name: '',
			fullMembershipNumber: '',
		})
	}

	const destroyPlayer = (index) => (event) => {
		const players = [...agencyPlayers]

		setAgencyPlayers(players)
	}

	// modal
	const [businessLicenseModalVisible, setBusinessLicenseModalVisible] = useState(false)
	const [messageModalVisible, setMessageModalVisible] = useState(false)
	const [messageModalContent, setMessageModalContent] = useState('')
	const [loginFailModalVisible, setLoginFailModal] = useState(false)

	const handleMessageModalOpen = (message) => {
		setMessageModalContent(message)
		setMessageModalVisible(true)
	}

	const handleMessageModalClose = () => {
		setMessageModalContent('')
		setMessageModalVisible(false)
	}

	const onClickLoginFailOk = () => navigate('/login')

	const handleLoginFailModalOpen = () => setLoginFailModal(true)

	const handleLoginFailModalClose = () => setLoginFailModal(false)

	const handleBusinessLicenseModalOpen = () => setBusinessLicenseModalVisible(true)
	const handleBusinessLicenseModalClose = () => setBusinessLicenseModalVisible(false)

	return (
		<div className="wrap form_page register_page">
			<div className="content_area">
				<div className="bgWrap rg002_bgWrap">
					<div className="container form_mw-662">
						<form
							className="needs-validation"
							id="form_registorTour"
							noValidate
							onSubmit={handleSubmit(onSubmit, oninvalid)}>
							<p className="form_topTitle">회원가입</p>
							<div className="card" style={{ display: 'flex' }}>
								<div className="card-body">
									<div className="rg002_inputBox">
										<label
											htmlFor="rg002_corName"
											className="form-label cmb-10">
											* 법인명(단체명)
										</label>
										<div className="d-flex">
											<input type="checkbox" className="d-none" />
											<input
												type="text"
												id="rg002_corName"
												className="form-control underbarInput"
												placeholder="법인명을 입력해 주세요."
												{...register('corporateName', {
													required: true,
												})}
											/>
										</div>
									</div>
									<div className="rg002_inputBox cmb-30">
										<label className="form-label underline me-2 cmb-10">
											* 사업자 등록증
										</label>

										<button
											type="button"
											className="btn btn-light rgPro002_addCBPR"
											id="rgPro002_addCBPR"
											onClick={handleBusinessLicenseModalOpen}>
											등록하기
										</button>

										<input
											type="hidden"
											id="tochange-blicense"
											{...register('businessLicense', {
												required: true,
											})}
										/>
										<input
											type="text"
											className="form-control underbarInput w-100"
											id="rgPro002_ceoName"
											placeholder="대표자 이름을 입력해 주세요."
											{...register('corporationRepresentativeName', {
												required: true,
											})}
										/>
									</div>

									<div className="rg002_inputBox">
										{/*comRegNumber 관련 db에 컬럼 생성 아직 안한상태*/}
										<label
											htmlFor="rg002_comRegNumber"
											className="form-label cmb-10">
											* 사업자 등록번호
										</label>
										<div className="d-flex w-100">
											<input
												type="text"
												name="comRegNumber"
												className="form-control underbarInput"
												id="rg002_comRegNumber"
												placeholder="사업자 등록번호를 입력해 주세요."
												{...register('businessLicenseNumber', {
													required: true,
												})}
											/>
										</div>
									</div>
									<div className="rg002_inputBox">
										<label
											htmlFor="rg002_taxEmail"
											className="form-label cmb-10">
											* 세금계산서 발행 이메일
										</label>
										<div className="d-flex w-100">
											<input
												type="text"
												className="form-control underbarInput"
												id="rg002_taxEmail"
												placeholder="세금계산서 이메일을 입력해 주세요."
												{...register('taxEmail', {
													required: true,
													pattern: {
														value: REG_EMAIL,
														message: '이메일을 정확히 입력하세요.',
													},
												})}
											/>
										</div>
									</div>
									{/*<div className="rg002_inputBox">*/}
									{/*	<label htmlFor="rg002_agency" className="form-label cmb-20">*/}
									{/*		* 소속 프로 관리*/}
									{/*	</label>*/}
									{/*	<div className="row align-items-center w-100 cmb-30">*/}
									{/*		<div className="col-auto pe-3">*/}
									{/*			<span>프로추가</span>*/}
									{/*		</div>*/}
									{/*		<div className="col-auto" style={{ paddingTop: '3px' }}>*/}
									{/*			<select*/}
									{/*				className="rgAgen001_memberAddSelect"*/}
									{/*				id="tourType"*/}
									{/*				name="tourType"*/}
									{/*				value={playerField?.tourType}*/}
									{/*				onChange={onChangePlayerAddForm('tourType')}>*/}
									{/*				/!*onchange="changeStyleColor(this, 'var(--greyish-brown');"*!/*/}
									{/*				<option value="KPGA" className="text-greyish-brown">*/}
									{/*					KPGA*/}
									{/*				</option>*/}
									{/*				<option*/}
									{/*					value="KLPGA"*/}
									{/*					className="text-greyish-brown">*/}
									{/*					KLPGA*/}
									{/*				</option>*/}
									{/*			</select>*/}
									{/*		</div>*/}
									{/*		<div className="col-12 col-md-7">*/}
									{/*			<div className="d-flex">*/}
									{/*				<input*/}
									{/*					type="text"*/}
									{/*					name="name"*/}
									{/*					id="name"*/}
									{/*					value={playerField?.name}*/}
									{/*					className="form-control underbarInput rgAgen001_name me-2"*/}
									{/*					placeholder="이름"*/}
									{/*					onChange={onChangePlayerAddForm('name')}*/}
									{/*				/>*/}
									{/*				<input*/}
									{/*					type="text"*/}
									{/*					name="memNum"*/}
									{/*					id="memNum"*/}
									{/*					value={playerField?.fullMembershipNumber}*/}
									{/*					className="form-control underbarInput rgAgen001_memNum me-2"*/}
									{/*					placeholder="정회원 번호"*/}
									{/*					onChange={onChangePlayerAddForm(*/}
									{/*						'fullMembershipNumber',*/}
									{/*					)}*/}
									{/*				/>*/}
									{/*				<input*/}
									{/*					type="button"*/}
									{/*					className="btn btn-dark rgAgen001_memberAddBtn"*/}
									{/*					value="추가"*/}
									{/*					id="memberAddBtn"*/}
									{/*					onClick={addAgencyPlayer}*/}
									{/*				/>*/}
									{/*			</div>*/}
									{/*		</div>*/}
									{/*	</div>*/}
									{/*	<div className="d-flex w-100">*/}
									{/*		<table*/}
									{/*			className="table table-hover text-center rgAgen001_table"*/}
									{/*			id="memberTable">*/}
									{/*			<thead>*/}
									{/*				<tr>*/}
									{/*					<th>투어</th>*/}
									{/*					<th>이름</th>*/}
									{/*					<th>정회원번호</th>*/}
									{/*					<th>관리</th>*/}
									{/*				</tr>*/}
									{/*			</thead>*/}
									{/*			<tbody className="proTbody">*/}
									{/*				{agencyPlayers.map((player, index) => (*/}
									{/*					<tr*/}
									{/*						key={`${index}-player`}*/}
									{/*						className="tableRow">*/}
									{/*						<td key={`${index}-toueType`}>*/}
									{/*							{player?.tourType}*/}
									{/*						</td>*/}
									{/*						<td key={`${index}-name`}>*/}
									{/*							{player?.name}*/}
									{/*						</td>*/}
									{/*						<td key={`${index}-fullMembershipNumber`}>*/}
									{/*							{player?.fullMembershipNumber}*/}
									{/*						</td>*/}
									{/*						<td key={`${index}-action`}>*/}
									{/*							<button*/}
									{/*								type="button"*/}
									{/*								className="btn"*/}
									{/*								onClick={destroyPlayer(index)}>*/}
									{/*								X*/}
									{/*							</button>*/}
									{/*						</td>*/}
									{/*					</tr>*/}
									{/*				))}*/}
									{/*			</tbody>*/}
									{/*		</table>*/}
									{/*	</div>*/}
									{/*</div>*/}
									<button
										type="submit"
										className="btn btn-dark registerNextButton"
										id="registerNextButton">
										회원가입
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>

			<ModalAddSBPR
				API_ENDPOINT={API_ENDPOINT}
				modalVisible={businessLicenseModalVisible}
				setSBPR={setValue}
				onClose={handleBusinessLicenseModalClose}
			/>

			<MessageModal
				modalVisible={messageModalVisible}
				content={messageModalContent}
				onClose={handleMessageModalClose}
			/>

			<ModalLoginFail
				modalVisible={loginFailModalVisible}
				onClickOk={onClickLoginFailOk}
				onClose={handleLoginFailModalClose}
			/>
		</div>
	)
}

const ModalAddSBPR = ({ API_ENDPOINT, modalVisible, setSBPR, onClose, ...props }) => {
	const inputFile = useRef(null)
	const [businessLicense, setBusinessLicense] = useState(null)

	const onSubmit = () => {
		// console.log(businessLicense)
		setSBPR('businessLicense', businessLicense)
		onClose()
	}

	const handleUploadedFile = (event) => {
		const file = event.target.files[0]

		setBusinessLicense(file)
	}

	return (
		<Modal
			id="addSBPRModal"
			show={modalVisible}
			backdrop="static"
			tabIndex={-1}
			size="lg"
			aria-labelledby="addSBPRModalLabel"
			aria-hidden="true">
			<Modal.Header className="border-0">
				<p className="modal-title w-100" id="staticBackdropLabel">
					사업자 등록증 등록하기
				</p>
				<button type="button" className="btn-close" aria-label="Close" onClick={onClose} />
			</Modal.Header>

			<Modal.Body className="modal-body">
				<p className="rgPro002_addSBPRLabel">사업자 등록증</p>
				<div className="d-flex justify-content-between align-items-center mb-5">
					<input
						type="file"
						className="form-control d-none"
						id="rgPro002_uploadFileInput"
						accept=".pdf"
						onChange={handleUploadedFile}
						ref={(el) => {
							inputFile.current = el
						}}
					/>
					<label className="form-label d-flex w-100" htmlFor="rgPro002_uploadFileInput">
						<input
							id="rgPro002_uploadTextInput"
							type="text"
							className="form-control underbarInput rgPro002_fileInput"
							placeholder="파일 찾아보기"
							readOnly
							value={!!businessLicense ? businessLicense.name : ''}
						/>
						<button
							type="button"
							className="btn btn-outline-dark"
							style={{ width: '8.125rem' }}
							onClick={() => {
								inputFile.current.click()
							}}>
							업로드
						</button>
					</label>
				</div>
				<button
					id="rgPro002_addCBPRButton"
					type="button"
					className="btn btn-dark registerNextButton"
					onClick={onSubmit}>
					등록하기
				</button>
			</Modal.Body>
		</Modal>
	)
}
