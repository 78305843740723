import React, { useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import { Link, useSearchParams } from 'react-router-dom'
import { contractTypes, getMatchTypeOptions, useAuthStatus } from '../../../utils'
import dayjs from 'dayjs'

export const OfferListView = ({ API_ENDPOINT, ...props }) => {
	const [auth, onChangeAuth] = useAuthStatus()

	const [searchParams] = useSearchParams()

	const [offers, setOffers] = useState([])
	const [applies, setApplies] = useState([])

	const [tabKey, setTabKey] = useState(searchParams.get('tab') || 'JOB_POST')

	useEffect(() => {
		fetchOffers()
	}, [])

	const fetchOffers = async () => {
		await fetch(`${API_ENDPOINT}/contract/offer/list/${auth?.user?.id}`)
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) throw new Error(result.massage)

				setOffers(result)
			})
			.catch((errors) => {
				console.log(errors)
				setOffers([])
			})
	}

	const fetchApplies = async () => {
		const res = await fetch(`${API_ENDPOINT}/contract/contract/my/apply/${auth?.user?.id}`)
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) throw new Error(result.massage)

				setApplies(result)
			})
			.catch((errors) => {
				console.log(errors)
				setApplies([])
			})
	}

	const handleOfferTabSelect = async (key) => {
		if (key === 'JOB_POST') {
			await fetchOffers()
		} else if (key === 'JOB_MATCH') {
			await fetchApplies()
		}

		setTabKey(key)
	}

	return (
		<div className="wrap form_page">
			<div className="content_area">
				<div className="container mainWrap">
					<div className="row cmb-20">
						<div className="col-auto col-md-6">
							<div className="col-auto fontSize-30 fw-bold cmb-10">
								내 구인/신청 내역
							</div>
							<div className="col-auto fontSize-20 cmb-10">
								작성한 구인 공고의 신청을 확인해보세요
							</div>
						</div>
						<div className="col-12 col-md-6 d-flex align-items-center cmb-10 mb_btn_size">
							<Link
								to={'/recruits'}
								className="btn btn-success pb-4 pt-4"
								style={{ width: '80%' }}>
								구인공고 바로가기 &gt;
							</Link>
						</div>
						{/*<div className="col-12 col-md-6 d-flex align-items-center cmb-10 mb_btn_size"><a className="btn btn-white-three pb-4 pt-4" style="width:80%;" href="<$path?>_request_001.php">구인공고 바로가기 ></a></div> 트렐로 검수사항으로 인해 변경*/}

						<Tab.Container defaultActiveKey={tabKey} onSelect={handleOfferTabSelect}>
							<Nav className="nav-tabs nav-tabs-camel" id="myTab" role="tablist">
								<Nav.Item className="w-50" role="presentation">
									<Nav.Link
										eventKey={'JOB_POST'}
										className=" cumbw-100 tab-camel">
										내 구인내역
									</Nav.Link>
								</Nav.Item>
								<Nav.Item className="w-50" role="presentation">
									<Nav.Link
										eventKey={'JOB_MATCH'}
										className=" cumbw-100 tab-camel">
										내 신청내역
									</Nav.Link>
								</Nav.Item>
							</Nav>

							<Tab.Content className="tab-content" id="myTabContent">
								<Tab.Pane eventKey={'JOB_POST'} className="py-3">
									{offers?.length > 0 ? (
										offers?.map((offer) => (
											<OfferCard
												key={offer?.offer?.id}
												offer={offer?.offer}
											/>
										))
									) : (
										<div className="row cmb-20 position-relative">
											<div className="card bg-white-two">
												<div className="card-body">
													구인 내역이 없습니다.
												</div>
											</div>
										</div>
									)}
								</Tab.Pane>

								<Tab.Pane eventKey={'JOB_MATCH'} className="py-3">
									{applies?.length > 0 ? (
										applies?.map((apply) => (
											<ApplyCard key={apply?.offer?.id} apply={apply} />
										))
									) : (
										<div className="row cmb-20 position-relative">
											<div className="card bg-white-two">
												<div className="card-body">
													신쳥 내역이 없습니다.
												</div>
											</div>
										</div>
									)}
								</Tab.Pane>
							</Tab.Content>
						</Tab.Container>
					</div>
				</div>
			</div>
		</div>
	)
}

const OfferCard = ({ offer, ...props }) => {
	return (
		<Link to={`/mypages/offers/${offer?.id}`}>
			<div className="row cmb-20 position-relative">
				<div className="col-12">
					<div className="border bg-white-two">
						<div className="text-center pt-3 fontSize-16 color-greyish-two">
							{dayjs(offer?.created_at).format('YYYY-MM-DD')}
						</div>
						<div className="row align-items-center">
							<div className="col-auto w-100 text-center">
								<span className="fontSize-20 mb-1 mt-1">{offer?.post_title}</span>
								{/*<button*/}
								{/*	type="button"*/}
								{/*	className="btn btn-secondary rounded-pill"*/}
								{/*	style={{ width: '3rem' }}>*/}
								{/*	0*/}
								{/*</button>*/}
							</div>
						</div>
						<div className="text-center fontSize-18 pb-3 color-spruce">
							#{getMatchTypeOptions(contractTypes, offer?.contract_type)}
						</div>
					</div>
				</div>
			</div>
		</Link>
	)
}

const ApplyCard = ({ apply, ...props }) => {
	return (
		<Link to={`/mypages/offers/${apply?.post?.id}`}>
			<div className="row cmb-20 position-relative">
				<div className="col-12">
					<div className="border bg-white-two">
						<div className="text-center pt-3 fontSize-16 color-greyish-two">
							{dayjs(apply?.post?.created_at).format('YYYY-MM-DD')}
						</div>
						<div className="row align-items-center">
							<div className="col-auto w-100 text-center">
								<span className="fontSize-20 mb-1 mt-1">
									{apply?.post?.post_title}
								</span>
								{/*<button*/}
								{/*	type="button"*/}
								{/*	className="btn btn-secondary rounded-pill"*/}
								{/*	style={{ width: '3rem' }}>*/}
								{/*	0*/}
								{/*</button>*/}
							</div>
						</div>
						<div className="text-center fontSize-18 pb-3 color-spruce">
							#{getMatchTypeOptions(contractTypes, apply?.post?.contract_type)}
						</div>
					</div>
				</div>
			</div>
		</Link>
	)
}
