import React, { useCallback, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const CompleteNiceView = ({ API_ENDPOINT, CDN_ENDPOINT, ...props }) => {
	const navigate = useNavigate()
	const location = useLocation()
	const queryString = location.search

	const handleLoad = useCallback(async () => {
		window.opener.document.location = `${CDN_ENDPOINT}/register/main?status=true`
		window.sessionStorage.setItem('isCertification', JSON.stringify(true))
		window.close()
	}, [CDN_ENDPOINT])

	useEffect(() => {
		if (!window.opener) navigate(-1) // 보통 페이지와 다를 것 없기 때문에 비정상적인 방법으로 진입 시 뒤로가기

		handleLoad() // 정상적인 루트로 진입한 경우 실행된다.
	}, [handleLoad, queryString])

	return <></>
}
