import React from 'react'

export const ModalTerm = () => {
	return (
		<div
			className="modal fade"
			id="termModal"
			data-bs-backdrop="static"
			tabIndex="-1"
			aria-labelledby="termModalLabel"
			aria-hidden="true">
			<div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">이용약관</h5>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"></button>
					</div>
					<div className="modal-body">
						<div className="WordSection1">
							<p
								className="MsoNormal"
								style={{ textAlign: 'left', lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '18pt',
											linHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										서비스 이용약관
									</span>
								</b>
							</p>

							<p
								className="MsoNormal"
								style={{ textAlign: 'left', lineHeight: '150%' }}>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '14pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										&nbsp;
									</span>
								</b>
							</p>

							<p
								className="MsoNormal"
								style={{ textAlign: 'left', lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '14pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">1</span>장 총 칙
									</span>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">1</span>조<span lang="EN-US">(</span>본
										약관의 목적<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<div className="MsoNormal" style={{ lineHeight: '150%' }}>
								<p name="_Hlk48724701">
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										본 서비스 이용약관<span lang="EN-US">(</span>이하{' '}
										<span lang="EN-US">“</span>본 약관
										<span lang="EN-US">”</span>이라 합니다
										<span lang="EN-US">)</span>은 <b>주식회사 스포이음</b>
										<span lang="EN-US">(</span>이하 <span lang="EN-US">“</span>
										회사<span lang="EN-US">”</span>라 합니다
										<span lang="EN-US">)</span>이 제공하는{' '}
										<u>
											회원 간에 이루어지는 거래에 대한 중개 서비스 및 관련
											제반 서비스
										</u>
										<span lang="EN-US">(</span>이하 <span lang="EN-US">“</span>
										서비스<span lang="EN-US">”</span>라 합니다
										<span lang="EN-US">)</span>의 이용에 대한 회사와 회원 간의
										권리 · 의무<span lang="EN-US">,</span>책임사항 및 기타
										필요한 사항을 규정함으로써 상호간의 이해관계를 합리적으로
										조정하고<span lang="EN-US">, </span>이에 대한 원활한 서비스
										시스템이 운영 및 유지될 수 있도록 하는 것을 목적으로 합니다
										<span lang="EN-US">.</span>
									</span>
								</p>
							</div>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">2</span>조<span lang="EN-US">(</span>
										용어의 정의<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									본 약관에서 명시하고 있는 주요 용어의 정의는 다음과 같습니다
									<span lang="EN-US">. </span>아래에서 명시하지 않거나 본 약관에서
									별도로 정하고 있지 않은 용어의 경우<span lang="EN-US">,</span>
									관계법령 등에서 정하는 바에 따라 적용 · 해석합니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
										color: 'black',
									}}>
									&nbsp;
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineheight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									1.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										“
									</span>
								</b>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										서비스<span lang="EN-US">”</span>
									</span>
								</b>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									는 회사가 운영 및 관리하는 스포이음 서비스 플랫폼
									<span lang="EN-US">(</span>웹<span lang="EN-US">, </span>모바일
									웹<span lang="EN-US"> · </span>앱 포함
									<span lang="EN-US">, </span>이하 <span lang="EN-US">“</span>
									플랫폼<span lang="EN-US">”</span>이라 합니다
									<span lang="EN-US">)</span>에서 제공되는 회원 간에 이루어지는
									거래에 대한 계약 및 이에 관한 회사의 중개 서비스와 이와 관련한
									제반 서비스 전반을 의미합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									fontSize: '18pt',
									linHeight: '150%',
									textIndent: '-18pt',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									2.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										“
									</span>
								</b>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										선수회원<span lang="EN-US">”</span>
									</span>
								</b>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									은{' '}
									<u>
										플랫폼에서 골프 대회에 참가하는 골퍼로서의 지위를 갖는
										회원을 의미
									</u>
									합니다<span lang="EN-US">. </span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									fontSize: '18pt',
									linHeight: '150%',
									textIndent: '-18pt',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									3.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										“
									</span>
								</b>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										프로<span lang="EN-US">(TOUR PRO)</span>회원
										<span lang="EN-US">”</span>
									</span>
								</b>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									은 선수회원 중에서 아마추어 회원을 제외한 회원을 의미합니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									4.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										“
									</span>
								</b>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										아마추어<span lang="EN-US">(AMATURE)</span>회원
										<span lang="EN-US">”</span>
									</span>
								</b>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									은 선수회원 중{' '}
									<u>
										연령이 <span lang="EN-US">[*]</span>세부터{' '}
										<span lang="EN-US">[*]</span>세에 이르는 회원을
									</u>{' '}
									포괄하여 의미합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									fontSize: '18pt',
									linHeight: '150%',
									textIndent: '-18pt',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									5.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										“
									</span>
								</b>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										에이전시<span lang="EN-US">(AGENCY)”</span>
									</span>
								</b>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									는 선수회원으로부터 선수회원을 대리하여 거래를 체결할 수 있는
									권한을 부여받은 회원을 의미합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									6.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										“
									</span>
								</b>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										캐디회원<span lang="EN-US">”</span>
									</span>
								</b>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									은 플랫폼에서 캐디 업무를 수행하는 자로서의 지위를 갖는 회원을
									의미합니다<span lang="EN-US">. </span>캐디회원은
									<span lang="EN-US">, </span>소속 등의 내용에 따라{' '}
									<u>
										<span lang="EN-US">“</span>
									</u>
									하우스캐디<span lang="EN-US">”</span>와{' '}
									<span lang="EN-US">“</span>투어캐디<span lang="EN-US">”</span>로
									구분합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									7.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										“
									</span>
								</b>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										하우스캐디<span lang="EN-US">(HOUSE CADDIE)”</span>
									</span>
								</b>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									는 컨트리 클럽 또는 골프 클럽에서 소속되어 근무하면서
									<span lang="EN-US">, </span>캐디업무를 이행하는 캐디를
									의미합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									8.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										“
									</span>
								</b>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										투어캐디<span lang="EN-US">(TOUR CADDIE)”</span>
									</span>
								</b>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									는 골프 대회의 주관사에서 홈페이지를 통해 공지하는 대회 요강 중
									참가자격에 해당하는 선수에 대한 캐디업무만을 전문적으로 하는
									캐디를 의미합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									9.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										“
									</span>
								</b>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										피지컬회원<span lang="EN-US">(PHYSICAL)”</span>
									</span>
								</b>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									은 플랫폼에서 선수회원의 피지컬 향상에 관한 업무
									<span lang="EN-US">(</span>피지컬 업무
									<span lang="EN-US">)</span>를 수행하는 자로서의 지위를 갖는
									회원을 의미합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									10.
									<span style={{ font: '7pt "Times New Roman"' }}>&nbsp; </span>
								</span>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										“
									</span>
								</b>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										회원<span lang="EN-US">”</span>
									</span>
								</b>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									은 플랫폼에 회원가입을 한 자로서 회사가 플랫폼에서 제공하는 모든
									서비스를 계속적으로 이용할 수 있는 지위를 가진 자를 의미하며
									<span lang="EN-US">, “</span>선수회원
									<span lang="EN-US">”, “</span>에이전시
									<span lang="EN-US">”, “</span>캐디회원
									<span lang="EN-US">”, “</span>피지컬회원
									<span lang="EN-US">”</span>을 총칭하여 의미합니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									11.
									<span style={{ font: '7pt "Times New Roman"' }}>&nbsp; </span>
								</span>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										“
									</span>
								</b>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										거래<span lang="EN-US">”</span>
									</span>
								</b>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									는 선수회원과 캐디회원 간에 이루어지는 캐디업무 제공 용역에 대한
									거래<span lang="EN-US">(</span>선수회원 대신 에이전시가 체결하는
									경우도 포함<span lang="EN-US">) </span>또는 선수회원과
									피지컬회원 간에 이루어지는 피지컬 업무 제공 용역에 대한 거래를
									의미합니다<span lang="EN-US">. </span>여기에서 거래는 플랫폼을
									통한 거래를 원칙으로 하며<span lang="EN-US">, </span>회사의 관여
									하에서만 본 약관의 적용을 받는 플랫폼 외 거래가 예외적으로
									허용됩니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									12.
									<span style={{ font: '7pt "Times New Roman"' }}>&nbsp; </span>
								</span>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										“
									</span>
								</b>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										캐디업무<span lang="EN-US">”</span>
									</span>
								</b>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									는 선수회원의 경기를 보조하면서 골프채가 들어 있는 골프가방 운반
									<span lang="EN-US">, </span>선수회원의 요구에 응하여 골프채를
									꺼내어 전달하고<span lang="EN-US">, </span>선수회원이 골프채를
									휘두를 때 발생하는 잔디 파손 부분 손질
									<span lang="EN-US">, </span>코스 매니지먼트 등 선수회원이 골프
									대회에 참가하여 치루는 경기를 보조하는 업무를 총괄하여
									의미합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									13.
									<span style={{ font: '7pt "Times New Roman"' }}>&nbsp; </span>
								</span>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										“
									</span>
								</b>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										피지컬 업무<span lang="EN-US">”</span>
									</span>
								</b>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									는 선수회원이 참가하는 골프 대회의 경기를 위하여 피지컬 향상에
									관한 업무<span lang="EN-US">(</span>골프 대회 참가 전
									<span lang="EN-US">, </span>후의 선수회원에 대한 마사지
									<span lang="EN-US">,</span>음식조절<span lang="EN-US">, </span>
									운동 등 포함<span lang="EN-US">) </span>및 관련 제반 업무를
									포괄하여 의미합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									14.
									<span style={{ font: '7pt "Times New Roman"' }}>&nbsp; </span>
								</span>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										“
									</span>
								</b>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										매칭 수수료<span lang="EN-US">”</span>
									</span>
								</b>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									는 회원이 회사의 중개 서비스에 대하여 지급하는 대가를 의미합니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									15.
									<span style={{ font: '7pt "Times New Roman"' }}>&nbsp; </span>
								</span>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										“
									</span>
								</b>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										수수료<span lang="EN-US">”</span>
									</span>
								</b>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									는 회원이 대회 참가 및 거래에 따라 수령하게 되는 공식 상금 또는
									인센티브 금액에 대하여 일정 비율에 해당하는 금액으로 회사에
									지급하는 것을 의미합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									16.
									<span style={{ font: '7pt "Times New Roman"' }}>&nbsp; </span>
								</span>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										“
									</span>
								</b>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										용역비용<span lang="EN-US">”</span>
									</span>
								</b>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									은 선수회원이 거래에 따른 캐디업무 용역 제공에 대하여
									캐디회원에게 지급하는 대가를 의미합니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									17.
									<span style={{ font: '7pt "Times New Roman"' }}>&nbsp; </span>
								</span>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										“
									</span>
								</b>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										공식상금<span lang="EN-US">”</span>
									</span>
								</b>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									은 <span lang="EN-US">KPGA, KLPGA</span>등의 협회에서 공시하는
									상금을 의미합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									18.
									<span style={{ font: '7pt "Times New Roman"' }}>&nbsp; </span>
								</span>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										“
									</span>
								</b>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										인센티브<span lang="EN-US">”</span>
									</span>
								</b>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									는 선수회원이 거래에 따라 캐디회원에게 지급하는 공식상금에 대한
									선수회원과 캐디회원 간 약정한 비율에 따른 금액을 의미합니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									19.
									<span style={{ font: '7pt "Times New Roman"' }}>&nbsp; </span>
								</span>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										“
									</span>
								</b>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										계정정보<span lang="EN-US">”</span>
									</span>
								</b>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									라 함은 회원<span lang="EN-US">(</span>또는 회원이 되려고 하는
									자<span lang="EN-US">)</span>이 플랫폼을 통하여 회사에 제공하는
									이메일 주소<span lang="EN-US">, ID, </span>비밀번호 등 회원과
									관련한 정보를 포괄하여 의미합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">3</span>조<span lang="EN-US">(</span>본
										약관의 게시와 개정<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 회원이 본 약관의 내용을 쉽게 알 수 있도록 플랫폼의 초기
									화면에 게시하거나 연결화면을 통해 볼 수 있도록 조치합니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 회원이 본 약관의 내용에 대한 문의사항을 회사에 질의할 수
									있도록 고객센터를 두는 등의 조치를 취합니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									③
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 『약관의 규제에 관한 법률』<span lang="EN-US">,</span>
									『정보통신망이용촉진 및 정보보호에 관한 법률』
									<span lang="EN-US">, </span>『전자상거래 등에서의 소비자보호에
									관한 법률』 등 관계법령을 위배하지 않는 범위 내에서 본 약관을
									개정할 수 있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									④
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사가 본 약관을 개정하는 경우<span lang="EN-US">,</span>회사는
									적용일자<span lang="EN-US">, </span>개정내용
									<span lang="EN-US">, </span>개정이유 등을 명시하여 개정된 약관의
									적용일
									<span lang="EN-US">7</span>일 전부터 그 적용일까지 플랫폼의 초기
									화면 또는 연결화면 등을 통해 공지합니다
									<span lang="EN-US">.</span>다만<span lang="EN-US">, </span>
									회원에게 불리하거나 중요한 약관의 내용을 개정하는 경우에는
									적용일 <span lang="EN-US">30</span>일 전부터 공지해야 하며
									<span lang="EN-US">, </span>이 경우 회원이 등록한 이메일로 개별
									고지할 수 있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									⑤
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 제<span lang="EN-US">4</span>항에 따른 공지를 한 후에
									개정된 약관의 적용에 대한 회원의 동의 여부를 확인합니다
									<span lang="EN-US">. </span>단<span lang="EN-US">, </span>제
									<span lang="EN-US">4</span>항의 공지 시에 회원이 동의 또는
									거부의 의사표시를 하지 않으면 승낙한 것으로 간주하겠다는 내용도
									함께 공지한 경우에는 회원이 개정된 약관의 시행일까지 거부의사를
									표시하지 않을 경우 개정된 약관의 내용을 확인하고 동의한 것으로
									간주합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									⑥
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회원이 개정된 약관의 적용에 동의하지 않는 경우
									<span lang="EN-US">, </span>회사는 해당 회원에 대해 개정된
									약관의 내용을 적용할 수 없으며<span lang="EN-US">, </span>서비스
									이용계약을 해지할 수 있습니다<span lang="EN-US">. </span>회원
									역시 회원탈퇴의 방법으로 서비스 이용계약을 해지할 수 있습니다
									<span lang="EN-US">. </span>만일 회원이 약관의 개정에 동의하지
									않음에도 불구하고 회사의 공지 또는 개별 고지일자로부터 개정된
									약관의 시행일까지 회원탈퇴를 하지 않고 서비스를 계속 이용하는
									경우에는 해당 회원이 개정된 약관의 내용을 확인하고 동의한 것으로
									간주합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">4</span>조<span lang="EN-US">(</span>
										약관 외 합의<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 본 약관과 별도로 회원과 개별계약을 체결할 수 있으며
									<span lang="EN-US">, </span>해당 계약의 내용이 본 약관의 내용과
									상충하는 경우에는 개별계약이 우선하여 적용됩니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회원이 서비스 이용과 관련하여 다른 회원과 별도의 합의 내지
									계약을 한 경우<span lang="EN-US">, </span>회사는 회사가
									개입하거나 하는 등의 사정이 없는 한 이에 관여하거나 관련한
									책임을 지지 않습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									③
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									본 약관에서 명시되지 않은 내용은 회사의 개별 약관 등의 운영정책
									및 관계 법령의 규정 또는 일반 상관례에 따라 결정합니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									④
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									본 약관 및 서비스와 관련하여 회사의 정책 변경
									<span lang="EN-US">, </span>법령의 제
									<span lang="EN-US"> · </span>개정 또는 공공기관의 고시나 지침
									등에 따라 회사가 플랫폼을 통해 공지하는 내용도 약관의 일부를
									구성합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									&nbsp;
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '14pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">2</span>장 서비스 이용계약의 체결 및
										회원의 정보 수집<span lang="EN-US">, </span>보호
									</span>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">5</span>조<span lang="EN-US">(</span>
										서비스 이용계약 등<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사가 제공하는 서비스에 관한 이용계약은 서비스를 이용하고자
									하는 자의 이용신청<span lang="EN-US">(</span>회원가입 신청
									<span lang="EN-US">)</span>에 대하여 회사가 승낙함으로써
									성립합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									제<span lang="EN-US">1</span>항에 따른 서비스 이용계약은
									회원가입 절차의 완료 시를 기준으로 성립합니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									③
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회원은 회원가입 절차에서 본 약관의 동의를 표시함으로써 본 약관의
									내용을 인지하고 이해하였으며 적용을 받음에 동의하는 것으로
									간주됩니다<span lang="EN-US">. </span>따라서 회원은 서비스를
									이용하기 전에 반드시 본 약관을 꼼꼼히 읽고 이해하여 서비스
									이용과 관련한 분쟁이 발생하지 않도록 해야 하며
									<span lang="EN-US">, </span>약관의 부지로 인하여 발생한 상황이나
									손해에 대하여는 회사에 책임을 물을 수 없습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">6</span>조<span lang="EN-US">(</span>
										회원가입 신청 및 승낙<span lang="EN-US">, </span>제한
										<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회원이 되려고 하는 자는 회사가 정한 가입 절차와 방법에 따라
									회원가입 신청절차를 완료해야 하고<span lang="EN-US">, </span>
									회사는 이 과정에서 전문기관을 통한 실명확인 및 본인인증 등의
									본인확인절차를 요청할 수 있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 회원이 되려고 하는 자의 회원가입 신청에 대하여 승낙함을
									원칙으로 합니다<span lang="EN-US">. </span>다만
									<span lang="EN-US">, </span>다음 각 호에 해당하는 회원가입
									신청에 대하여는 승낙하지 않거나 사후에 서비스 이용계약을 해지할
									수 있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '25.05pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									1.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사에서 정하는 회원가입 절차에 따른 내용을 기재하지 않았거나
									<span lang="EN-US">, </span>허위의 정보를 제공한 경우
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '25.05pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									2.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									이미 가입된 회원 정보와 동일한 정보로 신청하는 경우
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '25.05pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									3.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회원이 되려고 하는 자의 실명이 아니거나 타인의 명의를 이용한
									경우
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '25.05pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									4.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회원이 되려고 하는 자의 책임 있는 사유에 의한 서비스 이용제한
									기록이 있는 자가 신청을 하는 경우
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '25.05pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									5.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									법령에서 금지하는 위법행위를 할 목적으로 신청을 하는 경우
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '25.05pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									6.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사의 이익을 저해하려는 목적으로 신청하는 경우
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '25.05pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									7.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									이전에 회원의 자격을 상실한 적이 있는 자로서 회사의 회원 재가입
									승낙을 얻지 못한 경우
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '25.05pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									8.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									위 각호에 준하는 사유로서 승낙이 부적절하다고 판단되는 경우
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									③
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 서비스 관련 설비의 여유가 없거나
									<span lang="EN-US">, </span>기술상 또는 업무상의 문제가 있는
									경우<span lang="EN-US">, </span>플랫폼 또는 서비스 상의 장애가
									발생한 경우 및 이에 준하는 사유로서 회원가입 신청에 대한 승낙이
									곤란한 경우에는 그 사유가 해소될 때까지 승낙을 유보할 수
									있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">7</span>조<span lang="EN-US">(</span>
										정보의 수집 등<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '7.05pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 서비스의 운영<span lang="EN-US">,</span>프로그램 안정화
									및 오류개선<span lang="EN-US">, </span>악성코드 감염여부 확인 등
									서비스 품질개선을 위해 회원들의 개인정보를 제외한
									<span lang="EN-US"> PC </span>등 단말기 설정 및 사양정보와
									시스템 정보<span lang="EN-US">, </span>오류 정보를 수집
									<span lang="EN-US"> · </span>활용할 수 있습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '7.05pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 회사가 제공하는 서비스와 관련하여 채팅
									<span lang="EN-US">, </span>전화상담<span lang="EN-US">, </span>
									서비스에 대한 의견 내지 정보를 작성<span lang="EN-US"> ·</span>
									게시할 수 있는 게시판 등을 통하여 회사와 회원 간에 이루어지는
									모든 소통 내용을 저장<span lang="EN-US">, </span>보관할 수
									있습니다<span lang="EN-US">. </span>회사는 회사와 회원 또는 회원
									상호 간의 분쟁 조정<span lang="EN-US">, </span>민원처리 또는
									서비스 이용 질서의 유지를 위하여 회사가 필요하다고 판단하는
									경우에 한하여 본 정보를 열람할 수 있으며
									<span lang="EN-US">, </span>본 정보는 회사만이 보유하고
									<span lang="EN-US">, </span>법령으로 권한을 부여 받지 않은 제
									<span lang="EN-US">3</span>자는 열람할 수 없습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">8</span>조<span lang="EN-US">(</span>
										회원의 정보 관리<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 회원의 계정정보를 통하여 서비스 이용가능 여부 등 회원의
									서비스 이용에 대한 관리 및 이에 필요한 제반 업무를 수행합니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회원의 계정정보에 대한 관리 책임은 회원에게 있습니다
									<span lang="EN-US">. </span>따라서 회원은 자신의 계정정보를 제
									<span lang="EN-US">3</span>자가 이용하도록 허용해서는 안 됩니다
									<span lang="EN-US">. </span>만일 회원이 본인의 계정정보 관리
									내지 보호를 소홀히 함으로써 발생하는 손해에 대해서는 회원에게 그
									책임이 있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									③
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회원은 자신의 계정정보가 도용되거나 제
									<span lang="EN-US">3</span>자가 사용하고 있음을 인지한 경우에는
									이를 즉시 회사에 통지하고<span lang="EN-US">, </span>회사의
									안내에 따라야 합니다<span lang="EN-US">. </span>만일 해당 회원이
									회사에 그 사실을 즉시 통지하지 않거나
									<span lang="EN-US">, </span>통지한 경우에도 회사의 안내에 따르지
									않아 불이익 내지 손해가 발생한 경우<span lang="EN-US">, </span>
									회사는 이에 대한 책임을 지지 않습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									④
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회원은 정기적으로 비밀번호를 변경하여야 하며
									<span lang="EN-US">, </span>회사는 회원이 원하는 경우 언제든지
									비밀번호를 변경할 수 있도록 조치합니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">9</span>조<span lang="EN-US">(</span>
										회원 정보의 제공 및 변경<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회원은 본 약관에 따라 회사에 정보를 제공해야 하는 경우
									<span lang="EN-US">, </span>진실된 정보를 제공하여야 하며
									<span lang="EN-US">, </span>허위정보 제공으로 인하여 발생한
									불이익 내지 손해에 대해서는 해당 회원에게 그 책임이 있습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회원은 플랫폼을 통하여 언제든지 본인의 개인정보와 계정정보
									내역을 열람하고 수정할 수 있습니다<span lang="EN-US">. </span>
									다만<span lang="EN-US">, </span>회사의 서비스 관리를 위해 필요한
									경우 계정정보의 일부 정보는 수정이 불가능할 수 있습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									③
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회원은 회원가입 신청 시 회사에 제공한 정보가 변경되었을 경우
									<span lang="EN-US">, </span>플랫폼에서 직접 수정을 하거나
									전자우편 기타 방법으로 회사에 대하여 그 변경사항을 알려야 합니다
									<span lang="EN-US">.</span>만일 이를 회사에 알리지 않음으로
									인하여 발생한 불이익 내지 손해에 대해서는 회원에게 그 책임이
									있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">10</span>조<span lang="EN-US">(</span>
										개인정보의 보호 및 관리<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 『개인정보 보호법』 및 『정보통신망 이용촉진 및 정보보호
									등에 관한 법률』 등 관계법령이 정하는 바에 따라 회원의
									개인정보를 보호하기 위해 노력합니다<span lang="EN-US">. </span>
									회원 개인정보의 보호 및 사용에 대해서는 관계법령 및 회사의
									개인정보처리방침이 적용됩니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									플랫폼에 링크된 제<span lang="EN-US">3</span>자의 사이트 및 제
									<span lang="EN-US">3</span>자가 제공하는 서비스 이용 시에는
									회사의 개인정보처리방침이 적용되지 않습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									③
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 회원의 귀책사유로 인하여 노출된 회원의 계정정보를 포함한
									모든 정보에 대해서 일체의 책임을 지지 않습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									④
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 회사의 서비스를 확장 내지 추가하는 경우
									<span lang="EN-US">, </span>회원의 개인정보 및 데이터를 이전할
									수 있고<span lang="EN-US">, </span>이전한 개인정보 및 데이터를
									확장 내지 추가한 서비스 제공에 이용할 수 있습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									&nbsp;
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '14pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">3</span>장 계약 당사자의 의무
									</span>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">11</span>조<span lang="EN-US">(</span>
										회사의 의무<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 서비스를 제공함에 있어 관계법령과 본 약관이 금지하거나
									공서양속에 반하는 행위를 하지 않으며<span lang="EN-US">, </span>
									회원에게 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을
									다하여 노력합니다<span lang="EN-US">. </span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보 보호를
									위하여
									<span>
										보안시스템을 구비해야 하며<span lang="EN-US">,</span>
									</span>
									<span lang="EN-US"> </span>개인정보처리방침을 공시하고
									준수합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									&nbsp;
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									③
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 계속적이고 안정적인 서비스의 제공을 위하여 서비스 개선을
									시행하던 중 설비에 장애가 발생하거나 데이터 등이 멸실된 때에는
									천재지변<span lang="EN-US">, </span>비상사태 등 이에 준하는 사유
									또는 현재의 기술로는 해결이 불가능한 결함 및 장애 등 부득이한
									사유가 없는 한 지체 없이 이를 수리 또는 복구하도록 최선의 노력을
									다합니다<span lang="EN-US">. </span>다만
									<span lang="EN-US">,</span>협력사의 설비에 장애가 발생하거나
									협력사의 고의 또는 과실로 인하여 데이터 등이 멸실된 때에는
									회사에 고의 또는 중과실이 없는 한 회사는 면책됩니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									④
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사가 제공하는 서비스로 인하여 회원에게 손해가 발생한 경우에는
									그러한 손해가 회사의 고의 또는 중과실에 의하여 발생한 경우에
									한하여 책임을 부담하며<span lang="EN-US">, </span>그 책임의
									범위는 통상손해에 해당합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">12</span>조<span lang="EN-US">(</span>
										회원의 의무<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회원은 본 약관의 규정<span lang="EN-US">,</span>서비스 이용안내
									및 이와 관련하여 이루어진 공지사항<span lang="EN-US">, </span>
									회사가 통지하는 사항 등을 확인하고 준수할 의무가 있습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회원은 다음 행위<span lang="EN-US">(</span>또는 이에 준하는
									행위를 포함합니다<span lang="EN-US">)</span>를 하여서는 안
									됩니다<span lang="EN-US">. </span>아래 각 호에 명시된 구체적인
									행위태양은 예시입니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										1.
										<span style={{ font: '7pt "Times New Roman"' }}>
											&nbsp;&nbsp;&nbsp;{' '}
										</span>
									</span>
								</b>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										회사의 플랫폼을 통하지 않고 직접 거래를 유도하거나 권유
										<span lang="EN-US">, </span>제안하는 행위
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									2.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									비정상적인 시스템 접근 <span lang="EN-US">·</span>악성코드
									<span lang="EN-US">, </span>바이러스 등의 프로그램을 고의 또는
									과실로 설치<span lang="EN-US">, </span>유포하는 행위
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '32.15pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									-
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									플랫폼의 정보 및 협력사의 정보를 수집하기 위한 불법적인 크롤링
									<span lang="EN-US">(crawling), </span>리버스 엔지니어링
									<span lang="EN-US">(reverse engineering), </span>디컴파일
									<span lang="EN-US">(decompile), </span>디스어셈블
									<span lang="EN-US">(disassemble) </span>및 기타 일체의
									가공행위를 통하여 서비스를 복제<span lang="EN-US">, </span>분해
									또는 모방 기타 변형하는 행위
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									3.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									비정상적인 서비스 이용<span lang="EN-US"> ·</span>허위 또는
									타인의 정보로 가입을 하는 행위
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '32.15pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									-
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									정당한 사유 없이 다수의 계정으로 활동을 하는 행위
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '32.15pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									-
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									계정 내 불리한 정보를 삭제하기 위해 의도적으로 계정을 탈퇴
									<span lang="EN-US">/</span>재가입하는 행위
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									4.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									허위 또는 과장된 정보 입력 행위
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '32.15pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									-
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사의 서비스 이용 시 회원이 입력하는 정보 일체에 대하여 허위
									또는 과장된 정보를 <span lang="EN-US"> </span>입력하는 행위
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									5.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사가 운영하는 플랫폼 내에 상호<span lang="EN-US">(</span>성명
									<span lang="EN-US">), </span>로고 등 상표
									<span lang="EN-US">, </span>주소<span lang="EN-US">, </span>
									전화번호<span lang="EN-US">, </span>이메일 주소 등 신원 식별을
									용이하게 하는 정보를 회사의 사전 동의 내지 허락을 얻지 아니하고
									입력 내지 사용하는 행위
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									6.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									공공질서 위반<span lang="EN-US">, </span>성별 · 정치 · 종교 ·
									장애 · 연령 · 사회적 신분 · 인종 · 지역 · 직업 등을 차별하거나
									이에 대한 편견을 조장하는 행위
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									7.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사 또는 다른 회원<span lang="EN-US">,</span>제
									<span lang="EN-US">3</span>자를 차별 또는 비방하거나 욕설
									<span lang="EN-US">, </span>폭언<span lang="EN-US">,</span>협박
									<span lang="EN-US">, </span>명예를 훼손하는 행위
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									8.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									과도한 신체 노출이나 음란한 행위를 묘사하거나
									<span lang="EN-US">, </span>성매매 관련 정보를 공유하거나
									<span lang="EN-US">, </span>타인에게 성적 수치심이나 불쾌감을
									유발할 수 있는 내용을 게시하는 등 미풍양속에 반하는 정보를
									등록하는 행위
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									9.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									혐오스러운 사진 또는 내용을 게시하거나
									<span lang="EN-US">,</span>욕설<span lang="EN-US">, </span>
									비속어<span lang="EN-US">, </span>은어를 사용하는 등 사회 통념에
									반하는 비정상적인 활동을 하는 행위
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									10.
									<span style={{ font: '7pt "Times New Roman"' }}>&nbsp; </span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									타인의 권리 침해<span lang="EN-US"> · </span>회사 및 다른 회원
									<span lang="EN-US">, </span>제<span lang="EN-US">3</span>자의
									특허권<span lang="EN-US">,</span>상표권
									<span lang="EN-US">, </span>저작권 등 지적재산권을 침해하는 행위
									또는 침해할 우려가 있는 행위{' '}
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '32.15pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									-
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									타인의 개인정보를 침해하는 행위
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '32.15pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									-
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									기타 웹 해킹 프로그램<span lang="EN-US">,</span>매크로 프로그램
									<span lang="EN-US">, </span>보이스 피싱을 위한 미러링 사이트 등
									타인의 권리를 침해하거나 침해할 우려가 있는 모든 행위
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									11.
									<span style={{ font: '7pt "Times New Roman"' }}>&nbsp; </span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									계정 및 계정 내 정보를 타인에게 판매<span lang="EN-US">,</span>
									양도<span lang="EN-US">, </span>대여하거나
									<span lang="EN-US">, </span>타인에게 그 이용을 허락 또는 이를
									시도하는 행위 및 이를 알선하는 행위
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									12.
									<span style={{ font: '7pt "Times New Roman"' }}>&nbsp; </span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									명의 사칭<span lang="EN-US">, </span>도용 등으로 다른 회원
									<span lang="EN-US">, </span>제<span lang="EN-US">3</span>자 또는
									회사의 직원을 사칭하는 행위
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									13.
									<span style={{ font: '7pt "Times New Roman"' }}>&nbsp; </span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사의 이익<span lang="EN-US">(</span>영업
									<span lang="EN-US">)</span>을 저해하는 행위
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									14.
									<span style={{ font: '7pt "Times New Roman"' }}>&nbsp; </span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									기타 위 각 호에 준하는 행위
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '32.15pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									-
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사의 동의 없이 영리<span lang="EN-US">,</span>영업
									<span lang="EN-US">, </span>광고<span lang="EN-US">, </span>
									정치활동 등을 목적으로 서비스를 사용하는 행위
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '32.15pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									-
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									자기 또는 타인에게 재산상의 이익을 주거나 타인에게 손해를 가할
									목적으로 허위의 <span lang="EN-US">&nbsp;&nbsp;&nbsp; </span>
									정보를 유통시키는 행위
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '32.15pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									-
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									관련 법령에서 금지하거나 선량한 풍속 기타 사회통념상 허용되지
									아니하거나 원활한 <span lang="EN-US">&nbsp; </span>서비스 제공에
									악영향을 끼치는 행위
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">13</span>조<span lang="EN-US"> (</span>
										양도금지<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회원은 본 약관에 의한 서비스의 이용권한
									<span lang="EN-US">, </span>서비스 이용계약 상의 권리
									<span lang="EN-US">, </span>지위 등을 타인에게 양도 및 증여할 수
									없으며<span lang="EN-US">, </span>이를 담보로 제공할 수 없습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">14</span>조<span lang="EN-US">(</span>
										회원의 의무위반에 대한 제재 등<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 회원이 본 약관상의 내용을 위반한 경우
									<span lang="EN-US">, </span>사안의 중요성 및 다른 회원
									<span lang="EN-US">, </span>제<span lang="EN-US">3</span>자
									<span lang="EN-US">, </span>회사가 입은 불이익 내지 손해를
									고려하여 위반 회원에게 다음과 같은 제재를 할 수 있습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									1.{' '}
								</span>
								<b>
									<u>
										<span
											style={{
												fontSize: '11pt',
												lineHeight: '150%',
												fontFamily: '나눔스퀘어',
											}}>
											단순 경고
										</span>
									</u>
								</b>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									:{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 본 약관상의 의무를 위반한 회원에게 의무위반의 내용
									<span lang="EN-US">, </span>위반 시기 등 관련 내용을 고지하고
									<span lang="EN-US">, </span>이에 대한 위반을 하지 않을 것을
									경고하는 조치를 취할 수 있습니다<span lang="EN-US">. </span>향후
									회원의 의무 위반이 이루어지는 경우에는 회사의 제재가 있을 수
									있다는 사실을 함께 알립니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									2.{' '}
								</span>
								<b>
									<u>
										<span
											style={{
												fontSize: '11pt',
												lineHeight: '150%',
												fontFamily: '나눔스퀘어',
											}}>
											시정조치 요청<span lang="EN-US">:</span>
										</span>
									</u>
								</b>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 회원의 의무위반에 대하여 단순 경고를 하면서
									<span lang="EN-US">, </span>이에 대한 시정조치를 함께 요청할 수
									있습니다<span lang="EN-US">. </span>다만
									<span lang="EN-US">,</span>해당 회원이 시정조치를 하지 않은
									상태에서 발생한 불이익 내지 손해에 대해서는 회사는 면책됩니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									3.{' '}
								</span>
								<b>
									<u>
										<span
											style={{
												fontSize: '11pt',
												lineHeight: '150%',
												fontFamily: '나눔스퀘어',
											}}>
											서비스 이용 제한<span lang="EN-US">:</span>
										</span>
									</u>
								</b>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 일정한 기간 또는 서비스의 범위를 정하여 서비스 이용을
									제한할 수 있습니다<span lang="EN-US">. </span>이 경우 회원은
									회사가 제한한 범위 내에서는 회원으로서의 권리를 행사할 수
									없습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									4.{' '}
								</span>
								<b>
									<u>
										<span
											style={{
												fontSize: '11pt',
												lineHeight: '150%',
												fontFamily: '나눔스퀘어',
											}}>
											서비스 이용계약 해지<span lang="EN-US">:</span>
										</span>
									</u>
								</b>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 해당 회원이 서비스를 이용하지 못하도록 서비스 이용계약을
									해지할 수 있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										5.
										<span style={{ font: '7pt "Times New Roman"' }}>
											&nbsp;&nbsp;&nbsp;{' '}
										</span>
									</span>
								</b>
								<b>
									<u>
										<span
											style={{
												fontSize: '11pt',
												lineHeight: '150%',
												fontFamily: '나눔스퀘어',
											}}>
											기타 회사가 필요하다고 인정하는 조치 내지 제재
										</span>
									</u>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 본 조에 따른 제재 조치를 하는 과정에서 해당 회원의 이메일
									등을 통해 개별적으로 고지할 수 있습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									③
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회원이 본 약관의 내용을 위반하여 회사 또는 제
									<span lang="EN-US">3</span>자<span lang="EN-US">(</span>다른
									회원 포함<span lang="EN-US">)</span>에게 손해가 발생한 경우
									회원은 그로 인하여 발생한 손해를 배상해야 합니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									&nbsp;
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '14pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">4</span>장 서비스의 제공
									</span>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">15</span>조<span lang="EN-US">(</span>
										회사가 제공하는 서비스<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 플랫폼에서 아래의 서비스를 회원에게 제공합니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '32.15pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									1.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회원 간 거래 중개 및 관련 제반 서비스
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '32.15pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									2.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회원 간 거래를 위한 정보 제공
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '32.15pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									3.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									위 각호에 관련된 제반 서비스 일체
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 제<span lang="EN-US">1</span>항 각 호의 서비스 외에도
									추가서비스를 제공할 수 있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									③
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회원은 본 약관 및 회사가 설정한 규칙을 준수해야만 회사가
									제공하는 서비스를 이용할 수 있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">16</span>조<span lang="EN-US">(</span>
										서비스의 변경 및 내용 수정<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 회사가 개발 및 기획한 서비스 내용의 제작
									<span lang="EN-US">, </span>변경<span lang="EN-US">, </span>유지
									<span lang="EN-US">, </span>보수에 관한 포괄적인 권한을 가지며
									<span lang="EN-US">, </span>서비스의 질서 유지를 위하여 필요한
									조치를 취할 수 있습니다<span lang="EN-US">. </span>회원은 회사의
									조치를 준수해야 할 의무를 부담합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 분할<span lang="EN-US">, </span>합병
									<span lang="EN-US">, </span>영업양도<span lang="EN-US">, </span>
									서비스의 수익 악화<span lang="EN-US">, </span>기술상ㆍ운영상의
									필요 등 상당한 이유가 있는 경우<span lang="EN-US">, </span>
									제공하고 있는 서비스의 전부 또는 일부의 내용을 변경할 수 있으며
									<span lang="EN-US">,</span>변경 전<span lang="EN-US"> 7</span>일
									전부터 변경된 서비스의 적용일 전일까지 플랫폼을 통하여 사전
									공지합니다<span lang="EN-US">.</span>다만
									<span lang="EN-US">, </span>사전에 공지할 수 없는 부득이한
									사정이 있는 경우 사후에 공지할 수 있습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									③
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 운영정책
									및 운영상의 필요에 따라 수정<span lang="EN-US">, </span>중단
									<span lang="EN-US">, </span>변경할 수 있으며
									<span lang="EN-US">,</span>이에 대하여 관계법령에 특별한 규정이
									없는 한 회원에게 별도의 보상을 하지 않습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">17</span>조<span lang="EN-US">(</span>
										서비스의 제공 및 중단 등<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 회원의 서비스 이용 신청을 승낙한 때
									<span lang="EN-US">(</span>회원가입 완료 시
									<span lang="EN-US">)</span>부터 서비스를 개시합니다
									<span lang="EN-US">.</span>단<span lang="EN-US">, </span>회사가
									필요한 경우 일부 서비스는 특정 시점부터 개시할 수 있습니다
									<span lang="EN-US">. </span>이러한 경우 회사는 사전 또는 사후에
									이를 공지합니다<span lang="EN-US">. </span>회원으로 가입한
									이후라도 일부 서비스는 회사가 필요한 경우 특정 회원에게만 제공할
									수 있습니다<span lang="EN-US">. </span>또한 회사는 서비스를 일정
									범위로 분할하여 각 범위 별로 이용 가능 시간이나 조건을 별도로
									정할 수 있습니다<span lang="EN-US">. </span>이 경우 그 내용을
									사전 또는 사후에 플랫폼에 공지하거나 회원에게 통지합니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									서비스는 연중무휴<span lang="EN-US">, 1</span>일
									<span lang="EN-US"> 24</span>시간 제공하는 것을 원칙으로 합니다
									<span lang="EN-US">. </span>다만 서비스 성격에 따라 토요일
									<span lang="EN-US">, </span>일요일<span lang="EN-US">, </span>
									공휴일과 평일 오후<span lang="EN-US"> 6</span>시 이후에는 제공이
									제한될 수 있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									③
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									제<span lang="EN-US">2</span>항에도 불구하고
									<span lang="EN-US">, </span>다음 각 호의 어느 하나에 해당하는
									경우에는 일정한 시간 동안 서비스가 제공되지 아니할 수 있으며
									<span lang="EN-US">,</span>해당 시간 동안 회사는 서비스를 제공할
									의무가 없습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '32.15pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									1.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									컴퓨터 등 정보통신설비의 보수점검<span lang="EN-US">,</span>교체
									<span lang="EN-US">, </span>정기점검 또는 서비스의 수정을 위하여
									필요한 경우
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '32.15pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									2.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									해킹 등의 전자적 침해사고<span lang="EN-US">,</span>통신 사고
									<span lang="EN-US">, </span>회원들의 비정상적인 서비스 이용행태
									<span lang="EN-US">, </span>예상할 수 없는 서비스의 불안정성에
									대응하기 위하여 필요한 경우
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '32.15pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									3.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									관련 법령에서 특정 시간 또는 방법으로 서비스 제공을 금지하는
									경우
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '32.15pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									4.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									천재지변<span lang="EN-US">,</span>국가비상사태
									<span lang="EN-US">, </span>정전<span lang="EN-US">, </span>
									서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인
									서비스 제공이 불가능할 경우
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '32.15pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									5.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									분할<span lang="EN-US">, </span>합병<span lang="EN-US">, </span>
									영업양도<span lang="EN-US">, </span>영업의 폐지
									<span lang="EN-US">, </span>당해 서비스의 수익 악화 등 회사의
									경영상 중대한 필요에 의한 경우
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									④
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 제<span lang="EN-US">3</span>항 각호의 경우
									<span lang="EN-US">
										, <u>7</u>
									</span>
									<u>
										일 전에 그 사실을 회원에게 플랫폼을 통해 사전 공지합니다
										<span lang="EN-US">.</span>
									</u>
									<span lang="EN-US"> </span>다만 회사가 사전에 고지하였음에도
									불구하고 회사의 귀책사유 없이 위 기간 동안 회원이 공지 내용을
									인지하지 못한데 대하여 회사는 책임을 부담하지 아니합니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									⑤
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									제<span lang="EN-US">4</span>항에도 불구하고
									<span lang="EN-US">, </span>회사는 사전에 회원에게 공지 내지
									통지할 수 없는 부득이한 사정<span lang="EN-US">(</span>긴급한
									시스템 점검
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									·{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									증설 · 교체
								</span>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									,{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									시스템 관리자의 고의<span lang="EN-US">, </span>과실 없는 디스크
									장애{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									·{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									시스템
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									{' '}
									다운<span lang="EN-US">, </span>제<span lang="EN-US">3</span>
									자인<span lang="EN-US"> PC</span>통신 회사
									<span lang="EN-US">, </span>기간통신사업자 등의 사정으로 인한
									서비스 제공의 불가 등 회사가 통제할 수 없는 사정
									<span lang="EN-US">)</span>이 있는 경우에는 그 사정이 종료된
									후에 공지 내지 통지할 수 있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">18</span>조<span lang="EN-US"> (</span>
										회사의 정보 제공 및 광고 게재<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 회원의 편의를 위하여 회원이 서비스 이용에 있어 필요하다고
									인정되는 다양한 정보 및 광고를 전자우편
									<span lang="EN-US">, </span>서신우편<span lang="EN-US">, </span>
									휴대폰 문자메시지<span lang="EN-US">, </span>전화
									<span lang="EN-US">, </span>알림 등의 방법으로 회원에게
									전달하거나 플랫폼에 게시하는 방법으로 제공할 수 있습니다
									<span lang="EN-US">. </span>회원은 원하지 않을 경우 회사가
									제공하는 방법에 따라 수신을 거부할 수 있습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 전항 단서에 따른 수신 거부 회원의 경우라도 이용약관
									<span lang="EN-US">, </span>개인정보처리방침
									<span lang="EN-US">, </span>기타 회원의 이익에 영향을 미칠 수
									있는 중요한 사항의 변경 등 회원이 반드시 알고 있어야 하는 사항에
									대해서는 본 조 제<span lang="EN-US">1</span>항의 방법 중 하나로
									정보 등의 제공을 할 수 있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									③
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									플랫폼에는 배너와 링크<span lang="EN-US">(Link)</span>등 다양한
									형태의 광고가 포함될 수 있으며<span lang="EN-US">, </span>이는
									제<span lang="EN-US">3</span>자가 제공하는 페이지와 연결될 수
									있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									④
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									제<span lang="EN-US">3</span>항에 따라 제
									<span lang="EN-US">3</span>자가 제공하는 페이지로 연결될 경우
									<span lang="EN-US">, </span>해당 페이지는 회사의 서비스 영역이
									아니므로 회사가 신뢰성<span lang="EN-US">, </span>안정성 등을
									보장하지 않으며 그로 인한 회원의 손해에 대하여도 회사는 책임을
									지지 않습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									&nbsp;
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '14pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">5</span>장 회원 간 서비스 계약
									</span>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">19</span>조<span lang="EN-US">(</span>
										계약의 체결 및 효력<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '14.15pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회원들은 플랫폼에서 제공하는 서비스를 이용하여 거래에 관한
									계약을 체결할 수 있습니다<span lang="EN-US">. </span>계약의
									당사자는 회원들<span lang="EN-US">(</span>선수회원과 캐디회원
									<span lang="EN-US">,</span>에이전시<span lang="EN-US">, </span>
									피지컬회원<span lang="EN-US">)</span>이며
									<span lang="EN-US">, </span>회사는 본 약관에서 정한 경우를
									제외하고는 거래에 개입하거나 관여하지 않습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '14.15pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									플랫폼에서 이루어지는 회원 간의 거래에 대한 계약은 본 약관과
									별도로 계약의 성립 시에 효력이 발생합니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '14.15pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									③
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									제<span lang="EN-US">2</span>항의 계약의 성립은
									<span lang="EN-US">, </span>계약의 당사자 모두
									<span lang="EN-US"> (</span>전자<span lang="EN-US">)</span>서명
									또는 날인을 완료한 때를 기준으로 합니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '14.15pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									④
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									본 조에 따라 체결되는 계약의 효력은 본 약관의 효력에 영향을 주지
									않습니다<span lang="EN-US">. </span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '14.15pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									⑤
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									본 조에 따라 체결되는 계약에 명시되지 않은 내용은
									<span lang="EN-US">, </span>본 약관의 내용과 상충되지 않는 범위
									내에서 본 약관이 적용되는 것을 원칙으로 합니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">20</span>조<span lang="EN-US">(</span>
										계약에 따른 회원의 의무<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회원은 계약의 내용을 준수하여야 하며<span lang="EN-US">,</span>
									이를 불이행하거나 지체하는 등 계약 위반이 발생하는 경우
									<span lang="EN-US">, </span>그로 인한 불이익은 해당 회원의
									비용과 책임으로 부담하여야 합니다<span lang="EN-US">. </span>
									회사는 계약에 관한 내용 및 준수여부에 대한 책임을 지거나
									개입하지 않습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									거래에 관한 계약의 당사자인 회원은 계약의 내용에 따른 의무를
									성실히 이행하여야 하며<span lang="EN-US">, </span>업무를
									진행함에 있어 계약의 상대방인 다른 회원과 원활한 소통을 해야 할
									의무가 있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									&nbsp;
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '14pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">6</span>장 수수료 등의 지급
									</span>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">21</span>조<span lang="EN-US">(</span>
										매칭 수수료의 지급<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회원은 거래에 관한 계약이 체결된 경우<span lang="EN-US">,</span>
									아래와 같이 거래의 유형에 따라{' '}
									<u>
										계약체결일로부터 <span lang="EN-US">3</span>일 이내에
									</u>{' '}
									매칭 수수료를 결제해야 합니다<span lang="EN-US">. </span>
									장기계약의 경우<span lang="EN-US">, </span>캐디회원이 용역비용의
									지급을 요청한 대회를 기준으로 기한을 계산합니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										[
									</span>
								</b>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										선수회원<span lang="EN-US">]</span>
									</span>
								</b>
							</p>
							<table>
								<tbody>
									<tr className="tline">
										<th></th>
										<th className="tline">
											<p className="text-center">거래의 유형</p>
											<p className="text-center">(계약의 종류)</p>
										</th>
										<th className="tline text-center">매칭 수수료</th>
									</tr>
									<tr className="tline">
										<th>투어캐디</th>
										<th className="tline text-center">
											단기계약, 장기계약, 연 계약
										</th>
										<th className="text-center">용역비용의 11%(부가세포함)</th>
									</tr>
									<tr className="tline">
										<th>하우스캐디</th>
										<th className="tline text-center">단기계약</th>
										<th className="text-center">120,000원(부가세포함)</th>
									</tr>
									<tr className="tline">
										<th>피지컬회원</th>
										<th className="tline text-center">
											단기계약, 장기계약, 연 계약
										</th>
										<th className="text-center">용역비용의 11%(부가세포함)</th>
									</tr>
								</tbody>
							</table>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									&nbsp;
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										[
									</span>
								</b>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										캐디회원<span lang="EN-US">]</span>
									</span>
								</b>
							</p>
							<table className="tline">
								<tbody>
									<tr className="tline">
										<th></th>
										<th className="tline">
											<p className="text-center">거래의 유형</p>
											<p className="text-center">(계약의 종류)</p>
										</th>
										<th className="text-center">매칭 수수료</th>
									</tr>
									<tr className="">
										<th className="tline">투어캐디</th>
										<th className="text-center tline">
											단기계약, 장기계약, 연 계약
										</th>
										<th className="text-center">용역비용의 5%</th>
									</tr>
									<tr className="">
										<th className="tline">하우스캐디</th>
										<th className="tline text-center">단기계약</th>
										<th className="text-center">(부가세포함)</th>
									</tr>
								</tbody>
							</table>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										&nbsp;
									</span>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										[
									</span>
								</b>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										피지컬회원<span lang="EN-US">]</span>
									</span>
								</b>
							</p>
							<table>
								<tbody>
									<tr className="tline">
										<th className="tline"></th>
										<th className="tline text-center">
											<p>거래의 유형</p>
											<p>(계약의 종류)</p>
										</th>
										<th className="tline text-center">매칭 수수료</th>
									</tr>

									<tr className="tline">
										<th className="tline text-center">피지컬회원</th>
										<th className="tline text-center">
											단기계약, 장기계약, 연 계약
										</th>
										<th className="tline text-center">
											용역비용의 5% (부가세포함)
										</th>
									</tr>
								</tbody>
							</table>

							<p
								className="MsoListParagraph"
								style={{ marginLeft: '18pt', lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									&nbsp;
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									에이전시는 선수회원을 대신하여 제<span lang="EN-US">1</span>항에{' '}
									<span lang="EN-US">[</span>선수회원<span lang="EN-US">]</span>에
									명시된 매칭 수수료를 회사에 지급할 수 있습니다
									<span lang="EN-US">. </span>이 경우 선수회원은 해당 거래에 대한
									매칭 수수료를 납부할 의무가 면제됩니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									③
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									제<span lang="EN-US">1</span>항을 위반하는 경우
									<span lang="EN-US">, </span>회사는 계약의 상대방 회원에게 이러한
									사실을 통지하고<span lang="EN-US">, </span>통지를 받은 회원은
									계약의 내용에 따라 계약을 해지할 수 있습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									④
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									제<span lang="EN-US">2</span>항과 별도로 본 조를 위반한 회원은
									서비스 이용제한 등 발생하는 불이익에 대하여 자신의 책임과
									비용으로 해결하여야 하며<span lang="EN-US">, </span>이에 대한
									내용에 대하여 회사에 이의를 제기할 수 없다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									⑤
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									본 조의 매칭 수수료 및 관련 내용은 회사의 정책에 따라 변경될 수
									있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">22</span>조<span lang="EN-US">(</span>
										수수료의 지급<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회원은 아래와 같이 거래 유형에 따라{' '}
									<u>
										대회 종료일로부터 <span lang="EN-US">3</span>일 이내에
									</u>{' '}
									수수료를 결제해야 합니다<span lang="EN-US">. </span>장기계약의
									경우<span lang="EN-US">,</span>캐디회원이 용역비용의 지급을
									요청한 대회를 기준으로 기한을 계산합니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										[
									</span>
								</b>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										선수회원<span lang="EN-US">]</span>
									</span>
								</b>
							</p>
							<table className="tline">
								<tbody>
									<tr className="tline">
										<th></th>
										<th className="tline">
											<p className="text-center">거래의 유형</p>
											<p className="text-center">(계약의 종류)</p>
										</th>
										<th className="text-center">수수료</th>
									</tr>
									<tr className="">
										<th className="tline">투어캐디</th>
										<th className="text-center tline">
											단기계약, 장기계약, 연 계약
										</th>
										<th className="text-center">인센티브의 3%</th>
									</tr>
									<tr className="">
										<th className="tline">하우스캐디</th>
										<th className="tline text-center">단기계약</th>
										<th className="text-center">(부가세포함)</th>
									</tr>
								</tbody>
							</table>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									&nbsp;
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										[
									</span>
								</b>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										캐디회원<span lang="EN-US">]</span>
									</span>
								</b>
							</p>
							<table className="tline">
								<tbody>
									<tr className="tline">
										<th></th>
										<th className="tline">
											<p className="text-center">거래의 유형</p>
											<p className="text-center">(계약의 종류)</p>
										</th>
										<th className="text-center">수수료</th>
									</tr>
									<tr className="">
										<th className="tline">투어캐디</th>
										<th className="text-center tline">
											단기계약, 장기계약, 연 계약
										</th>
										<th className="text-center">인센티브의 3%</th>
									</tr>
									<tr className="">
										<th className="tline">하우스캐디</th>
										<th className="tline text-center">단기계약</th>
										<th className="text-center">(부가세포함)</th>
									</tr>
								</tbody>
							</table>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									&nbsp;
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									본 조를 위반한 회원은 서비스 이용제한 등 발생하는 불이익에
									대하여 자신의 책임과 비용으로 해결하여야 하며
									<span lang="EN-US">, </span>이에 대한 내용에 대하여 회사에
									이의를 제기할 수 없다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									③
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									본 조의 수수료 및 관련 내용은 회사의 정책에 따라 변경될 수
									있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									&nbsp;
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '14pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">7</span>장 회원의 서비스 이용
									</span>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">23</span>조<span lang="EN-US">(</span>
										에이전시의 선수회원 등록<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									에이전시는 이미 플랫폼에 가입된 에이전시 소속 선수회원을
									에이전시 소속으로 등록할 수 있습니다<span lang="EN-US">. </span>
									이 경우 에이전시가 등록할 수 있는 선수회원은 이미 플랫폼에
									가입한 선수회원에 한정됩니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">24</span>조<span lang="EN-US">(</span>
										에이전시의 거래 계약 체결 대리<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									에이전시는 제<span lang="EN-US">23</span>조에 따라 플랫폼에
									등록한 선수회원<span lang="EN-US">(</span>본 조에서 동일
									<span lang="EN-US">)</span>과 캐디회원 또는 선수회원과
									피지컬회원과의 거래에 대한 계약체결의 권한을 위임받아 계약체결을
									진행할 수 있습니다<span lang="EN-US">. </span>이 과정에서 회사는
									개입하거나 관여하지 않습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									본 조에 따라 에이전시가 선수회원을 대리하여 체결한 거래에 대한
									계약 및 이와 관련된 분쟁이 발생한 경우
									<span lang="EN-US">, </span>회사는 해당 거래에 개입하거나
									관여하지 않는 한 이에 대한 책임을 지지 않습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									&nbsp;
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">25</span>조<span lang="EN-US">(</span>
										아마추어 회원의 서비스 이용<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									아마추어 회원이 플랫폼에서 회원가입 및 거래에 대한 계약체결 외에
									다른 서비스를 이용함에 있어 선수회원과 동일한 내용이 적용됩니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									아마추어 회원은 아래의 각 호의 어느 하나에 해당하는 경우
									<span lang="EN-US">, </span>프로회원으로의 전환을 위하여 회사가
									요청하는 서류 또는 정보를 제공해야 한다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '36pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									1.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									민법상 성년에 이르는 경우
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '36pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									2.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									프로대회의 수상경력이 있는 경우
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									&nbsp;
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">26</span>조<span lang="EN-US">(</span>
										아마추어 회원의 거래계약 체결<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									아마추어 회원은 플랫폼 내에서 캐디회원 또는 피지컬 회원과 거래에
									대한 계약을 체결할 수 있습니다<span lang="EN-US">. </span>다만
									<span lang="EN-US">, </span>이 과정에서 법정대리인의 동의를
									받아야 합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 제<span lang="EN-US">1</span>항의 법정대리인의 동의를
									받는 과정에서 관련 법령 및 회사의 정책에 따라 법정대리인의 동의
									인증 등 필요한 절차의 진행을 요청할 수 있습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									&nbsp;
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
										color: '#1f4e79',
									}}>
									&nbsp;
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '14pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">9</span>장 저작권 등에 관한 내용
									</span>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">27</span>조<span lang="EN-US">(</span>
										저작권<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									본 약관에 따라 회원<span lang="EN-US">(</span>본장에서 이하 동일
									<span lang="EN-US">)</span>이 직접 제작하여 플랫폼에 게시 내지
									업로드한 <b>콘텐츠</b>
									<span lang="EN-US">(</span>부호<span lang="EN-US">, </span>문자
									<span lang="EN-US">, </span>도형<span lang="EN-US">, </span>색채
									<span lang="EN-US">, </span>음성<span lang="EN-US">, </span>음향
									<span lang="EN-US">, </span>음원<span lang="EN-US">, </span>
									이미지 및 영상 등<span lang="EN-US">(</span>이들의 복합체를
									포함한다<span lang="EN-US">), </span>이하 동일
									<span lang="EN-US">)</span>에 대한 저작권은 해당 회원이 소유하며
									<span lang="EN-US">, </span>회사는 플랫폼 내에서 서비스의 원활한
									제공을 위한 범위 내에서 해당 콘텐츠를 게시
									<span lang="EN-US">(</span>변형<span lang="EN-US">, </span>가공
									<span lang="EN-US">, </span>요약<span lang="EN-US">, </span>발췌
									<span lang="EN-US">, </span>취합 행위를 포함
									<span lang="EN-US">)</span>할 수 있는 권리를 갖습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">28</span>조<span lang="EN-US">(</span>
										회원이 제작한 콘텐츠 등에 대한 관리방안
										<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: "'나눔명조', serif",
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 회원이 플랫폼에 게시하는 콘텐츠가 다음 각 호에 해당하는
									경우 플랫폼 게시를 금지합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '32.15pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									1.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									서비스 이용 목적에 부합하지 않는 경우
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '32.15pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									2.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									제<span lang="EN-US">3</span>자의 개인정보를 직접 기재하는
									등으로 제<span lang="EN-US">3</span>자의 권리를 침해하는 경우
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '32.15pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									3.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사의 플랫폼을 통하지 않고 직접 거래를 유도하거나 권유
									<span lang="EN-US">, </span>제안하는 경우
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '32.15pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									4.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									본 약관에 위배되거나 불법<span lang="EN-US">,</span>음란
									<span lang="EN-US">, </span>저속하다고 판단되는 내용인 경우
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '32.15pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									5.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									다른 회원 또는 제<span lang="EN-US">3</span>자를 비방하거나
									명예를 훼손시키는 내용인 경우
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '32.15pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									6.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									공공질서 및 공서양속에 위반되는 내용인 경우
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '32.15pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									7.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									범죄적 행위에 결부된다고 인정되는 내용일 경우
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '32.15pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									8.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									제<span lang="EN-US">3</span>자의 초상권
									<span lang="EN-US">, </span>저작권 등 기타 권리를 침해하는
									내용인 경우
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '32.15pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									9.
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									기타 관계 법령에 위배되는 경우
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: "'나눔명조', serif",
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 회원이 다른 회원 또는 제<span lang="EN-US">3</span>자의
									저작권을 침해하는 경우<span lang="EN-US">, </span>저작권법 등
									관계법령에 정해진 절차에 의하여 저작권자 또는 회원의 요청에 의해
									해당 콘텐츠를 삭제하거나 다시 게시할 수 있으며
									<span lang="EN-US">, </span>이 경우 회사는 면책됩니다
									<span lang="EN-US">.</span>회원이 다른 회원 또는 제
									<span lang="EN-US">3</span>자의 저작권 등의 권리를 침해함으로써
									발생하는 민<span lang="EN-US">,</span>형사상의 책임은 침해한
									회원이 전적으로 부담합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">29</span>조<span lang="EN-US">(</span>
										회사의 저작권 등의 권리 및 이에 따른 회원의 의무
										<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: "'나눔명조', serif",
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사가 제작한 콘텐츠에 대한 저작권 및 기타 지식재산권은 회사의
									소유입니다<span lang="EN-US">. </span>회사는 서비스와 관련하여
									회사가 정한 이용조건에 따라 서비스를 이용할 수 있는 이용권한만을
									회원에게 부여하며<span lang="EN-US">, </span>회원은 서비스를
									이용하여 얻은 정보를 가공<span lang="EN-US">, </span>판매하는
									행위 등 플랫폼에 게재된 콘텐츠를 상업적으로 사용할 수 없으며
									<span lang="EN-US">, </span>이를 유상양도
									<span lang="EN-US">, </span>판매<span lang="EN-US">,</span>
									담보제공 등의 처분행위를 할 수 없습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: "'나눔명조', serif",
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회원은 회사가 제공하는 서비스를 이용함으로써 얻은 정보 중 회사
									또는 제<span lang="EN-US">3</span>자에게 지적재산권 등이 귀속된
									정보를 회사 또는 제<span lang="EN-US">3</span>자의 사전 승낙
									없이 복제<span lang="EN-US">, </span>전송
									<span lang="EN-US">, </span>출판<span lang="EN-US">, </span>배포
									<span lang="EN-US">, </span>방송 기타 방법에 의하여 이용하거나
									제<span lang="EN-US">3</span>자에게 이용하게 하여서는 안 됩니다
									<span lang="EN-US">. </span>이 경우<span lang="EN-US">, </span>
									영리<span lang="EN-US">, </span>비영리 이용을 모두 포함한다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: "'나눔명조', serif",
									}}>
									③
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사가 제공하는 서비스<span lang="EN-US">,</span>그에 필요한
									소프트웨어<span lang="EN-US">, </span>이미지
									<span lang="EN-US">, </span>마크<span lang="EN-US">,</span>로고
									<span lang="EN-US">, </span>디자인<span lang="EN-US">, </span>
									서비스 명칭<span lang="EN-US">, </span>정보 및 상표 등과 관련된
									지식재산권 및 기타 권리는 회사에게 있습니다
									<span lang="EN-US">. </span>회원은 회사가 명시적으로 승인한
									경우를 제외하고는 상기에 명시한 소정의 각 재산에 대한 전부 또는
									일부의 수정<span lang="EN-US">, </span>대여
									<span lang="EN-US">, </span>대출<span lang="EN-US">,</span>판매
									<span lang="EN-US">, </span>배포<span lang="EN-US">, </span>제작
									<span lang="EN-US">, </span>양도<span lang="EN-US">, </span>
									재라이센스<span lang="EN-US">, </span>담보권설정행위 등 일체의
									상업적 이용행위를 할 수 없으며<span lang="EN-US">, </span>제
									<span lang="EN-US">3</span>자로 하여금 이와 같은 행위를 하도록
									허락할 수 없습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{ marginLeft: '18pt', lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									&nbsp;
								</span>
							</p>

							<p
								className="MsoNormal"
								style={{ textAlign: 'left', lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '14pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">10</span>장 서비스 이용계약의 종료
									</span>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">30</span>조<span lang="EN-US">(</span>
										회원탈퇴<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회원은 언제든지 플랫폼을 통하여 서비스 이용계약의 해지 신청
									<span lang="EN-US">(</span>회원탈퇴<span lang="EN-US">)</span>을
									할 수 있습니다<span lang="EN-US">. </span>이 경우 회사는 회원의
									본인여부 확인 등 필요한 절차를 요구할 수 있으며
									<span lang="EN-US">, </span>신속히 처리합니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									제<span lang="EN-US">1</span>항의 경우
									<span lang="EN-US">, </span>회사는 탈퇴 회원의 정보를 별도의
									보관 법령 또는 회사 내부 규정이 있는 경우를 제외하고 즉시
									삭제합니다<span lang="EN-US">.</span>이 경우 탈퇴 회원의
									아이디는 <span lang="EN-US">3</span>개월이 경과한 시점에 재가입
									시 중복 아이디에 해당하지 않습니다<span lang="EN-US">. </span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										③
										<span style={{ font: '7pt "Times New Roman"' }}>
											&nbsp;&nbsp;{' '}
										</span>
									</span>
								</b>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 회원이 탈퇴하는 경우<span lang="EN-US">,</span>탈퇴
									회원이 플랫폼에 게시한 글<span lang="EN-US">, </span>댓글을
									삭제하여야 하며<span lang="EN-US">, </span>해당 조치는 회원의
									탈퇴일이 해당하는 날이 속하는 달의 마지막 날에 일괄적으로
									진행합니다<span lang="EN-US">. </span>만약 회원이 그 기간 전에
									삭제를 원하는 경우<span lang="EN-US">, </span>회사에 즉시 삭제를
									요청할 수 있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										④
										<span style={{ font: '7pt "Times New Roman"' }}>
											&nbsp;&nbsp;{' '}
										</span>
									</span>
								</b>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회원의 탈퇴는<span lang="EN-US">, </span>플랫폼에서 이루어진
									거래에 대한 계약의 효력에 영향이 없습니다
									<span lang="EN-US">. </span>따라서 회원이 탈퇴하더라도 이미
									성립된 계약에 따른 매칭수수료 또는 수수료에 대한 지급 의무를
									부담합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">31</span>조<span lang="EN-US">(</span>
										회사의 해지<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 회원이 본 약관을 위반한 경우 또는 다음 각 호의 어느
									하나에 해당하는 경우에는 회원에 대하여
									<span lang="EN-US"> 10</span>일 이내의 기간을 정하여 시정할 것을
									최고하고<span lang="EN-US">, </span>그 기간 동안 시정되지 않을
									경우 서비스 이용계약을 해지할 수 있습니다
									<span lang="EN-US">. </span>다만<span lang="EN-US">, </span>
									회원이 현행법 위반 및 고의 또는 중대한 과실로 회사에 손해를 입힌
									경우에는 사전통보 없이 서비스 이용계약을 해지할 수 있습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{ marginLeft: '18pt', lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									1.{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회원이 제공한 데이터가 허위임이 판명된 경우
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{ marginLeft: '18pt', lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									2.{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									범죄적 행위에 관련되는 경우
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{ marginLeft: '18pt', lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									3.{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									국익 또는 사회적 공익을 저해할 목적으로 서비스 이용을 계획 또는
									실행할 경우
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{ marginLeft: '18pt', lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									4.{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									타인의 서비스 계정 및 비밀번호를 도용한 경우
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{ marginLeft: '18pt', lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									5.{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									타인의 명예를 손상시키거나 불이익을 주는 경우
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{ marginLeft: '18pt', lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									6.{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									같은 사용자가 다른 아이디로 이중 등록을 한 경우
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{ marginLeft: '18pt', lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									7.{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									서비스의 건전한 이용을 저해하는 경우
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{ marginLeft: '18pt', lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									8.{' '}
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									기타 관련 법령이나 회사가 정한 이용조건에 위배되는 경우
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사가 특정 회원과의 서비스 이용계약을 해지하는 경우 회사는 해당
									회원에게 해지사유 및 해지일을 기재한 서면
									<span lang="EN-US">, </span>전자우편 또는 이에 준하는 방법으로
									회원에게 통보합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									&nbsp;
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '14pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">11</span>장 회사의 책임제한 등
									</span>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">32</span>조<span lang="EN-US"> (</span>
										회사의 책임제한<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 전시<span lang="EN-US">, </span>사변
									<span lang="EN-US">, </span>천재지변<span lang="EN-US">, </span>
									비상사태<span lang="EN-US">, </span>현재의 기술로는 해결이
									불가능한 기술적 결함 또는 이에 준하는 불가항력으로 인하여
									서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이
									면제됩니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 회원의 귀책사유로 인한 서비스 이용의 중지
									<span lang="EN-US">, </span>이용장애 및 계약 해지에 대하여는
									책임을 지지 않습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									③
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회원 또는 회사의 서비스 이용계약 해지 등으로 인하여 서비스
									이용계약이 종료되는 경우<span lang="EN-US">, </span>관계법령 및
									개인정보처리방침에 따라 회사가 회원정보를 보유하는 경우를
									제외하고는 회사는 보다 나은 서비스 환경 등을 위하여 해지 즉시
									회원의 계정정보 및 관련된 모든 데이터를 삭제할 수 있으며
									<span lang="EN-US">, </span>회사는 서비스 이용계약 종료 이후
									회원의 계정정보 및 관련 데이터 삭제로 인한 책임을 부담하지
									않습니다<span lang="EN-US">. </span>다만
									<span lang="EN-US">, </span>해지 이후에도 일정 기간 보유할
									필요가 있는 경우에는 합리적인 기간 동안 보유할 수 있습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									④
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 기간통신사업자가 전기통신서비스를 중지하거나 정상적으로
									제공하지 아니하여 회원에게 손해가 발생한 경우에 대해서 회사의
									고의 또는 중대한 과실이 없는 한 책임이 면제됩니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									⑤
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 사전에 공지된 서비스 시스템 설비의 보수
									<span lang="EN-US">, </span>교체<span lang="EN-US">, </span>
									정기점검<span lang="EN-US">, </span>공사 등 부득이한 사유로
									서비스가 중지되거나 장애가 발생한 경우에 대해서 회사의 고의 또는
									중대한 과실이 없는 한 책임이 면제됩니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									⑥
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 회원이 사용하는 모바일 기기<span lang="EN-US">,</span>
									컴퓨터 등의 상태로 인하여 발생하는 제반 문제 또는 회사의 고의
									또는 중대한 과실이 없는 네트워크 환경으로 인하여 발생하는 문제에
									대해서 책임이 면제됩니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									⑦
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 회원 또는 제<span lang="EN-US">3</span>자가 서비스와
									관련하여 게재한 정보<span lang="EN-US">, </span>자료
									<span lang="EN-US">, </span>사실의 신뢰도
									<span lang="EN-US">,</span>정확성 등의 내용에 관하여는 회사의
									고의 또는 중대한 과실이 없는 한 책임을 지지 않습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									⑧
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 서비스 상에 게재되어 있거나 서비스를 통한 제
									<span lang="EN-US">3</span>자와의 판촉활동에 회원이 참여하거나
									교신 또는 거래의 결과로서 발생하는 모든 손실 또는 손해에 대해
									관련 법에 특별한 규정이 있거나 회사의 고의 또는 중과실로 인한
									경우가 아닌 한 책임을 지지 않습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									⑨
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 회원이 다른 회원 또는 제<span lang="EN-US">3</span>자와
									서비스를 매개로 하여 거래 등을 한 경우에는 개입할 의무가 없으며
									<span lang="EN-US">, </span>이로 인한 손해를 배상할 책임도
									없습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									⑩
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 무료로 제공하는 서비스의 이용과 관련하여 회원이 입은
									손해에 대하여<span lang="EN-US">, </span>회사의 고의 또는
									중과실로 발생한 것이 아닌 이상 책임을 지지 않습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									⑪
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 회원이 자신의 신상정보 및 전자우편주소 등의 정보를
									부정확하게 기재하거나 기재하지 않음으로써 손해가 발생한 경우
									<span lang="EN-US">, </span>회사의 고의 또는 중대한 과실이 없는
									한 책임이 면제됩니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									⑫
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 관계법령<span lang="EN-US">, </span>정부 정책 등에 따라
									서비스 제공을 할 수 없는 경우 책임이 면제됩니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									⑬
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회원의 불법적인 행위 또는 본 약관에 위배되는 행위로 인하여
									회사에 손해가 발생하거나 수사기관<span lang="EN-US">, </span>
									행정청으로부터 형사처벌 또는 제재를 받은 경우 회원은 회원의 비용
									<span lang="EN-US">(</span>손해배상금
									<span lang="EN-US">, </span>소송비용<span lang="EN-US">, </span>
									변호사 비용 등을 포함하되 이에 한정하지 아니함
									<span lang="EN-US">)</span>으로 손해를 배상하고 회사를
									면책하여야 합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">33</span>조<span lang="EN-US"> (</span>
										회사의 고충처리 및 분쟁해결<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 회원의 편의를 고려하여 회원의 의견이나 불만을 제시하는
									방법을 플랫폼내에서 안내합니다<span lang="EN-US">. </span>회사는
									이러한 회원의 의견이나 불만을 처리하기 위한 전담조직을 운영할 수
									있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 회원으로부터 제기되는 의견이나 불만이 정당하다고
									객관적으로 인정될 경우에는 합리적인 기간 내에 이를 신속하게
									처리합니다<span lang="EN-US">. </span>다만
									<span lang="EN-US">, </span>처리에 장기간이 소요되는 경우에는
									장기간이 소요되는 사유와 처리일정을 플랫폼에 공지하거나 회원에게
									전자우편<span lang="EN-US">, </span>전화 또는 서면 등으로
									개별적으로 통보합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									③
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사와 회원 간에 분쟁이 발생하여 제<span lang="EN-US">3</span>의
									분쟁조정기관이 조정할 경우<span lang="EN-US">, </span>회사는
									이용 제한 등 회원에게 조치한 사항을 성실히 증명하고
									<span lang="EN-US">, </span>조정기관의 조정에 따를 수 있습니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">34</span>조<span lang="EN-US"> (</span>
										회원에 대한 통지<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 회원에 대한 통지를 하는 경우<span lang="EN-US">,</span>본
									약관에 별도 규정이 없는 한 회원이 등록한 전자우편
									<span lang="EN-US">, </span>전화번호 및 기타의 방법으로 할 수
									있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사는 전체 또는 상당수 회원에게 통지를 하는 경우
									<span lang="EN-US">, </span>플랫폼에<span lang="EN-US"> 7</span>
									일 이상 게시하거나 연결화면<span lang="EN-US">, </span>팝업화면
									등을 통해 게시함으로써 제<span lang="EN-US">1</span>항의 통지에
									갈음할 수 있습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">35</span>조<span lang="EN-US"> (</span>
										분쟁의 해결<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									본 약관과 관련하여 분쟁이 발생한 경우<span lang="EN-US">,</span>
									분쟁의 당사자인 회사와 회원은 해당 분쟁을 해결하기 위하여 최선의
									노력을 다하여야 합니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회원 간의 서비스 이용에 대한 분쟁이 발생한 경우
									<span lang="EN-US">, </span>
									<u>
										회사는 회사의 고의 또는 중과실이 없는 한 분쟁에 개입하거나
										관여하지 않으며
									</u>
									<span lang="EN-US">, </span>이에 대한 일체의 책임분담을 하지
									않습니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">36</span>조<span lang="EN-US"> (</span>
										준거법 및 재판관할<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									①
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사와 회원 간의 제기된 소송은 대한민국법령을 준거법으로 합니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p
								className="MsoListParagraph"
								style={{
									marginLeft: '18pt',
									textIndent: '-18pt',
									lineHeight: '150%',
								}}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									②
									<span style={{ font: '7pt "Times New Roman"' }}>
										&nbsp;&nbsp;{' '}
									</span>
								</span>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									회사와 회원 간 발생한 분쟁에 관한 소송은
									<span lang="EN-US">, </span>제소 당시 회원의 주소에 의하고
									<span lang="EN-US">, </span>주소가 없는 경우 거소를 관할하는
									지방법원의 전속관할로 합니다<span lang="EN-US">. </span>단
									<span lang="EN-US">, </span>제소 당시 회원의 주소 또는 거소가
									명확하지 아니한 경우의 관할법원은 민사소송법에 따라 정합니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										제<span lang="EN-US">37</span>조<span lang="EN-US"> (</span>
										약관조항의 무효<span lang="EN-US">)</span>
									</span>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									본 약관의 일부 내용이 무효인 경우에도 나머지 조항의 효력은
									여전히 존속하며<span lang="EN-US">, </span>유효합니다
									<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									&nbsp;
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<b>
									<span
										lang="EN-US"
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										&lt;
									</span>
								</b>
								<b>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '150%',
											fontFamily: '나눔스퀘어',
										}}>
										부칙<span lang="EN-US">&gt;</span>
									</span>
								</b>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									본 약관은{' '}
									<u>
										<span lang="EN-US">2021</span>년<span lang="EN-US"> 3</span>
										월<span lang="EN-US"> 26</span>일부터 시행
										<span lang="EN-US"> · </span>적용
									</u>
									되며<span lang="EN-US">, </span>본 약관이 제정되기 전에 가입한
									회원에게도 동일하게 적용됩니다<span lang="EN-US">.</span>
								</span>
							</p>

							<p className="MsoNormal" style={{ lineHeight: '150%' }}>
								<span
									lang="EN-US"
									style={{
										fontSize: '11pt',
										lineHeight: '150%',
										fontFamily: '나눔스퀘어',
									}}>
									&nbsp;
								</span>
							</p>
						</div>
					</div>
					<div className="modal-footer py-1">
						<button
							type="button"
							className="btn btn-warning btn-sm"
							data-bs-dismiss="modal">
							확인
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}
