import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

export const ModalLoginFail = ({ modalVisible, content, onClickOk, onClose, ...props }) => {
	return (
		<Modal
			id="loginFailModal"
			className="loginFailModal"
			backdrop="static"
			centered={true}
			tabIndex="-1"
			aria-labelledby="loginFailModalLabel"
			aria-hidden="true"
			show={modalVisible}
			onHide={onClose}
			size="sm">
			<Modal.Body className="text-center">로그인에 실패하였습니다.</Modal.Body>
			<Modal.Footer className="py-1">
				<Button variant="warning" size="sm" onClick={onClickOk || onClose}>
					확인
				</Button>
			</Modal.Footer>
		</Modal>
	)
}
