import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import { MessageModal } from '../../../components'
import {
	FETCH_HEADER_OPTIONS,
	useAuthStatus,
	oneInquireTypes,
	getMatchTypeOptions,
	isEmpty,
} from '../../../utils'
import dayjs from 'dayjs'

export const OneInQuireListView = ({ API_ENDPOINT, ...props }) => {
	const [auth, onChangeAuth] = useAuthStatus()

	const navigate = useNavigate()
	const [searchParams] = useSearchParams()

	const {
		register,
		reset,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: 'onBlur',
	})
	const [oneInQuireList, setOneInQuireList] = useState([])

	const [tabKey, setTabKey] = useState(searchParams.get('tab') || 'FORM')

	const [messageModalVisible, setMessageModalVisible] = useState(false)
	const [messageModalContent, setMessageModalContent] = useState('')

	useEffect(() => {
		tabKey === 'LIST' && fetchOneInQuireList()
	}, [tabKey])

	const onSubmit = async ({ check_agree, ...input }) => {
		await fetch(`${API_ENDPOINT}/admin/db-manage/question`, {
			method: 'POST',
			headers: FETCH_HEADER_OPTIONS,
			body: JSON.stringify({ user_id: auth?.user?.id, ...input }),
		})
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) {
					throw result
				}

				await handleTabSelect('LIST')
				reset()
			})
			.catch((errors) => {
				console.log(errors)
				handleMessageModalOpen('저장에 실패했습니다.')
			})
	}

	const fetchOneInQuireList = async () => {
		await fetch(`${API_ENDPOINT}/admin/question/user/${auth?.user?.id}`)
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) {
					throw result
				}

				setOneInQuireList(result)
			})
			.catch((errors) => {
				console.log(errors)
			})
	}

	const handleTabSelect = async (key) => {
		key === 'LIST' && (await fetchOneInQuireList())

		setTabKey(key)
	}

	const onClickOneInQuire = (oneInQuireId) => (event) => {
		navigate(`/mypages/one-in-quire/${oneInQuireId}`)
	}

	const handleMessageModalOpen = (message) => {
		setMessageModalContent(message)
		setMessageModalVisible(true)
	}

	const handleMessageModalClose = () => {
		setMessageModalContent('')
		setMessageModalVisible(false)
	}

	return (
		<div className="wrap notice_page form_page">
			<div className="content_area">
				<div className="container mainWrap">
					<div className="cmb-30" style={{ fontSize: '3rem' }}>
						1:1 문의
					</div>

					<Tab.Container activeKey={tabKey} onSelect={handleTabSelect}>
						<Nav className="nav-tabs nav-tabs-camel" id="myTab" role="tablist">
							<Nav.Item className="w-50" role="presentation">
								<Nav.Link eventKey={'FORM'} className="cumbw-100 tab-camel">
									문의하기
								</Nav.Link>
							</Nav.Item>

							<Nav.Item className="w-50" role="presentation">
								<Nav.Link eventKey={'LIST'} className="cumbw-100 tab-camel">
									문의내역 확인
								</Nav.Link>
							</Nav.Item>
						</Nav>

						<Tab.Content id="myTabContent">
							<Tab.Pane eventKey={'FORM'} className="py-3">
								<div className="card">
									<div className="card-body">
										<form onSubmit={handleSubmit(onSubmit)}>
											<div className="col-auto contract_topSubTitleText cmb-30">
												문의 유형을 선택해 주세요
											</div>

											<div className="row cmb-20 align-items-end">
												<div className="col-auto cmb-10">
													<input
														type="radio"
														id="qna_app"
														className="btn-check"
														autoComplete="off"
														value="app"
														{...register('type', {
															required: true,
														})}
													/>
													<label
														className="d-flex justify-content-center btn btn-light btn-whiteShadow rounded-pill"
														style={{
															width: '7rem',
															height: '3rem',
															alignItems: 'center',
															padding: 'unset',
															fontSize: '18px',
															fontFamily: 'AppleSDGothicNeoR00',
														}}
														htmlFor="qna_app">
														앱 사용 문의
													</label>
												</div>
												<div className="col-auto cmb-10">
													<input
														type="radio"
														id="qna_payment"
														className="btn-check"
														autoComplete="off"
														value="payment"
														{...register('type', {
															required: true,
														})}
													/>
													<label
														className="d-flex justify-content-center btn btn-light btn-whiteShadow rounded-pill"
														style={{
															width: '7rem',
															height: '3rem',
															alignItems: 'center',
															padding: 'unset',
															fontSize: '18px',
															fontFamily: 'AppleSDGothicNeoR00',
														}}
														htmlFor="qna_payment">
														결제 관련 문의
													</label>
												</div>
												<div className="col-auto cmb-10">
													<input
														type="radio"
														className="btn-check"
														id="qna_contract"
														autoComplete="off"
														value="contract"
														{...register('type', {
															required: true,
														})}
													/>
													<label
														className="d-flex justify-content-center btn btn-light btn-whiteShadow rounded-pill"
														style={{
															width: '7rem',
															height: '3rem',
															alignItems: 'center',
															padding: 'unset',
															fontSize: '18px',
															fontFamily: 'AppleSDGothicNeoR00',
														}}
														htmlFor="qna_contract">
														계약 관련 문의
													</label>
												</div>
												<div className="col-auto cmb-10">
													<input
														type="radio"
														className="btn-check"
														id="qna_etc"
														autoComplete="off"
														value="etc"
														{...register('type', {
															required: true,
														})}
													/>
													<label
														className="d-flex justify-content-center btn btn-light btn-whiteShadow rounded-pill"
														style={{
															width: '7rem',
															height: '3rem',
															alignItems: 'center',
															padding: 'unset',
															fontSize: '18px',
															fontFamily: 'AppleSDGothicNeoR00',
														}}
														htmlFor="qna_etc">
														기타 문의
													</label>
												</div>
											</div>

											<textarea
												id="qna_Title"
												className="w-100 cmb-10"
												rows="1"
												placeholder="  글 제목을 입력해 주세요."
												{...register('title', {
													required: true,
												})}
											/>
											<textarea
												id="qna_Comment"
												className="w-100 cmb-30"
												rows="7"
												placeholder="  ※ 문의내용을 입력해주시면 보다 빠른 답변에 도움이 됩니다."
												{...register('detail', {
													required: true,
												})}
											/>

											<div className="row align-items-center cmb-20">
												<div className="col-12 col-md-6 cmb-10">
													<div className="col-auto w-100 form_subTitle">
														<span className="me-2 fontSize-18 color-greyish-brown-two">
															스포이음의 개인정보 수집 및 이용동의
														</span>
														<input
															type="checkbox"
															className="d-none"
															id="agreeCheck"
															{...register('check_agree', {
																required: true,
															})}
														/>
														<label
															htmlFor="agreeCheck"
															className="checkCustom"
														/>
													</div>
												</div>
											</div>

											<button
												type="submit"
												id="rgPro002_doneUserNumberButton"
												disabled={!isEmpty(errors)}
												className="btn btn-dark registerNextButton w-100 cmb-30"
												style={{ height: '70px' }}>
												문의 접수
											</button>
										</form>
									</div>
								</div>
							</Tab.Pane>

							<Tab.Pane eventKey={'LIST'} className="py-3">
								<div className="card">
									<div className="card-body">
										<div className="row align-items-center cmb-30">
											<table
												className="table table-hover text-center"
												id="zero-config">
												<thead>
													<tr>
														<th>No.</th>
														<th>문의 유형</th>
														<th>문의 내용</th>
														<th>답변 여부</th>
														<th>작성일</th>
													</tr>
												</thead>
												<tbody id="sponsorshipTableBody">
													{oneInQuireList?.map((item) => (
														<tr onClick={onClickOneInQuire(item?.id)}>
															<td>{item?.id}</td>
															<td>
																{getMatchTypeOptions(
																	oneInquireTypes,
																	item?.type,
																)}
															</td>
															<td>{item?.title}</td>
															<td>
																{item?.is_answer
																	? '답변 완료'
																	: '답변 필요'}
															</td>
															<td>
																{dayjs(item?.created_at).format(
																	'YYYY-MM-DD HH:mm:ss',
																)}
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</Tab.Pane>
						</Tab.Content>
					</Tab.Container>
				</div>
			</div>

			<MessageModal
				modalVisible={messageModalVisible}
				content={messageModalContent}
				onClose={handleMessageModalClose}
			/>
		</div>
	)
}
