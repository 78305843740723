import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { formattingPrice } from '../../utils'

export const ModalPayServiceFeeInfo = ({
	modalVisible,
	payment,
	onClickPayment,
	onClose,
	...props
}) => {
	return (
		<Modal
			id="acceptProposal"
			show={modalVisible}
			ß
			size="lg"
			backdrop="static"
			tabIndex={-1}
			centered
			aria-labelledby="acceptProposal"
			aria-hidden="true"
			className="wrap register_page form_page re_form">
			<Modal.Header class="border-0">
				<p className="modal-title w-100 text-center font-bold" id="staticBackdropLabel">
					결제 하기
				</p>
				<button type="button" className="btn-close" aria-label="Close" onClick={onClose} />
			</Modal.Header>
			<Modal.Body>
				<div className="text-center">
					<p className="fontSize-20 color-greyish-brown font-L00 cmb-10">
						대회는 잘 마치셨나요?
						<br />
						캐디와의 용역 비용과 서비스 이용료를 지불해 주세요.
					</p>
					<p className="fontSize-20 color-greyish-brown font-L00 cmb-30">
						총 결제 필요 금액: {formattingPrice(payment)}원
					</p>
				</div>

				<button
					type="button"
					className="btn btn-dark pb-2 pt-2 w-100 registerNextButton"
					onClick={onClose}>
					취소
				</button>
				<button
					type="button"
					id="canclebtn"
					className="btn btn-success pb-2 pt-2 w-100 cmb-20"
					onClick={onClickPayment}>
					확인
				</button>
			</Modal.Body>
		</Modal>
	)
}
