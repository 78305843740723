import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

export const SuggestionAnnualTypeSelectForm = ({ onSubmit, ...props }) => {
	const { register, watch, handleSubmit } = useForm({
		mode: 'onBlur',
	})

	// TODO: 상반기, 하반기 버튼 출력 조건 렌더링 추가

	return (
		<div className="container">
			<p className="form_topTitle">
				<span className={'fw-bold'}>계약 기간</span>을{' '}
				<span className="text-nowrap">선택해 주세요.</span>
			</p>
			<div className="card">
				<div className="card-body">
					<form
						id="form_recruit"
						className="needs-validation form_mw-662 mx-auto"
						noValidate
						onSubmit={handleSubmit(onSubmit)}>
						<p className="form_progressTitle cmt-30">
							항목을 선택하시면 제안서가 작성됩니다. 2/3
						</p>
						<div className="progress">
							<div
								className="progress-bar"
								role="progressbar"
								style={{ width: '66.6666%' }}
								aria-valuenow="66.66666"
								aria-valuemin="0"
								aria-valuemax="100"></div>
						</div>

						<div className="row cmb-20 input-group-box align-items-stretch">
							<div className="col-6 cmb-10">
								<label
									className={`form-check-label partner_radioBox w-100 h-100 ${
										watch('contractType') === 'year_first_half'
											? 'actived'
											: null
									}`}
									htmlFor="contract_type_3">
									<input
										type="radio"
										id="contract_type_3"
										className="form-check-input mt-0"
										name="contract"
										value="year_first_half"
										{...register('contractType', {
											required: true,
										})}
									/>
									<div className="partner_labelBox w-100">
										<p
											className="year_second_half"
											style={{ wordBreak: 'keep-all' }}>
											상반기
										</p>
									</div>
								</label>
							</div>
							<div className="col-6 cmb-10">
								<label
									className={`form-check-label partner_radioBox w-100 h-100 ${
										watch('contractType') === 'year_second_half'
											? 'actived'
											: null
									}`}
									htmlFor="contract_type_4">
									<input
										type="radio"
										id="contract_type_4"
										className="form-check-input mt-0"
										value="year_second_half"
										{...register('contractType', {
											required: true,
										})}
									/>
									<div className="partner_labelBox w-100">
										<p className="text-center">하반기</p>
									</div>
								</label>
							</div>
						</div>

						<button type="submit" className="btn btn-dark nextPageButton">
							다음 단계로
						</button>
					</form>
				</div>
			</div>
		</div>
	)
}
