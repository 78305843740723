import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import bootstrap5Plugin from '@fullcalendar/bootstrap5'
import interactionPlugin from '@fullcalendar/interaction'

export const Calendar = ({ initialDate, events, onClickEvent, ...props }) => {
	// const onClickDate = (info) => {
	// 	info.dayEl.style.backgroundColor = '#FFC3C3AA'
	// }
	//
	// const onClickEvent = (info) => {
	// 	const seq = info.event.extendedProps.seq
	// 	// getContract(seq, 'player')
	// 	info.el.style.borderColor = 'black'
	// }

	return (
		<FullCalendar
			id="scheduleCalendar"
			className="fc fc-media-screen fc-direction-ltr fc-theme-bootstrap"
			plugins={[dayGridPlugin, bootstrap5Plugin, interactionPlugin]}
			locale="en"
			firstDay={1}
			initialDate={initialDate}
			initialView="dayGridMonth"
			themeSystem="bootstrap5"
			headerToolbar={{
				start: 'prev',
				center: 'title',
				end: 'next',
			}}
			aspectRatio={5}
			height="auto"
			contentHeight="auto"
			events={events}
			eventClick={onClickEvent}
			editable={true} // 사용자의 수정 가능 여부 (이벤트 추가/수정, 드래그 앤 드롭 활성화)
			selectable={true} // 사용자의 날짜 선택 여부
		/>
	)
}
