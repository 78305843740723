import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import {
	contractTypes,
	formattingPrice,
	getMatchTypeOptions,
	isEmpty,
	userTypes,
} from '../../utils'
import { Card, CardBody } from 'react-bootstrap'

export const ProposalCard = ({ PROFILE_BUCKET, tabKey, proposal, ...props }) => {
	const { user, targetUser, contract, tour } = proposal

	const userInfo = tabKey === 'RECEIVED' ? user : targetUser

	const renderProposalType = (proposalStatus) => {
		// 회신 대기 중
		// <p className="font-M00 fontSize-16 color-greyish-two">회신을 기다리는 중입니다.</p>
		// 다른 제안이 왔을 때
		// <p className="font-M00 fontSize-16 color-greyish-brown">다른 제안이 도착했습니다.</p>
		// 거절 시
		// <p className="font-L00 fontSize-16 color-rouge">아쉽게도 거절되었습니다.</p>
		// <p className="font-L00 fontSize-16 color-rouge text-end">(사유확인)</p>
		// 수락 시
		// <p className="font-M00 fontSize-16 color-spruce">축하합니다. 수락되었습니다.</p>
		// <p className="font-M00 fontSize-16 color-spruce">결제 후 계약을 마무리하세요.</p>
		// 회신 대기 중
		// <p className="font-M00 fontSize-16 color-greyish-two">새 제안이 도착했습니다.</p>
	}

	return (
		<Link
			to={`/mypages/proposals/${contract?.id}`}
			className="cmb-30 pro_matching_list_001_card">
			<Card>
				<div className="d-flex">
					<Card.Img
						src={
							!isEmpty(userInfo?.profile_type)
								? PROFILE_BUCKET + userInfo?.id + '.' + userInfo?.profile_type
								: `/images/noImg.png`
						}
						alt="pfImage"
						className="cardImage_caddie"
					/>
					<Card.Body>
						<div className="d-flex justify-content-between">
							<div>
								<p className="font-L00 fontSize-12 color-greyish-two mb-1">
									{getMatchTypeOptions(userTypes, userInfo?.user_type)}
								</p>
								<p className="font-SB00 fontSize-18 color-greyish-brown mb-2">
									{userInfo?.name}
								</p>

								<p className="font-L00 fontSize-16 color-greyish-two">
									계약유형
									<span className="color-greyish-brown ms-2 me-3">
										{getMatchTypeOptions(
											contractTypes,
											contract?.contract_type,
										)}
									</span>
								</p>

								{['single', 'long_term'].includes(contract?.contract_type) && (
									<div>
										<p className="font-L00 fontSize-16 color-greyish-two">
											계약기간
											<span className="color-greyish-brown ms-2 me-3">
												{dayjs(tour?.started_at).format('YYYY-MM-DD')} ~{' '}
												{dayjs(tour?.ended_at).format('YYYY-MM-DD')}
												(총 1경기)
											</span>
										</p>
										<p className="font-L00 fontSize-16 color-greyish-two">
											계약대회
											<span className="color-greyish-brown ms-2 me-3">
												{tour?.name}
											</span>
										</p>
									</div>
								)}

								{/*<div className="abbox">*/}
								{/*	<p className="font-M00 fontSize-16 color-greyish-two">*/}
								{/*		새 제안이 도착했습니다.*/}
								{/*	</p>*/}
								{/*	회신 대기 중*/}
								{/*	<p className="font-M00 fontSize-16 color-greyish-two">회신을 기다리는 중입니다.</p>*/}
								{/*	다른 제안이 왔을 때*/}
								{/*	<p className="font-M00 fontSize-16 color-greyish-brown">다른 제안이 도착했습니다.</p>*/}
								{/*	거절 시*/}
								{/*	<p className="font-L00 fontSize-16 color-rouge">아쉽게도 거절되었습니다.</p>*/}
								{/*	<p className="font-L00 fontSize-16 color-rouge text-end">(사유확인)</p>*/}
								{/*	수락 시*/}
								{/*	<p className="font-M00 fontSize-16 color-spruce">축하합니다. 수락되었습니다.</p>*/}
								{/*	<p className="font-M00 fontSize-16 color-spruce">결제 후 계약을 마무리하세요.</p>*/}
								{/*	회신 대기 중*/}
								{/*	<p className="font-M00 fontSize-16 color-greyish-two">새 제안이 도착했습니다.</p>*/}
								{/*</div>*/}

								{['single', 'long_term'].includes(contract?.contract_type) && (
									<p className="font-L00 fontSize-16 color-greyish-two">
										제안한 주급
										<button className="btn btn-light form_titleSubButton radius-5">
											컷통과 {formattingPrice(contract?.weekly_salary)} 원
										</button>
										<button className="btn btn-light form_titleSubButton radius-5">
											컷탈락 {formattingPrice(contract?.weekly_salary_f)} 원
										</button>
									</p>
								)}

								{['year_first_half', 'year_second_half'].includes(
									contract?.contract_type,
								) && (
									<p className="font-L00 fontSize-16 color-greyish-two">
										제안한 연봉
										<button className="btn btn-light form_titleSubButton radius-5">
											{formattingPrice(contract?.weekly_salary)} 원
										</button>
									</p>
								)}
							</div>
							<div className="pe-none">
								{/*<img src={`/images/pro-main-001-like-01.png`} alt="thumbup" className="button_thumup">*/}
							</div>
						</div>
					</Card.Body>
				</div>
			</Card>
		</Link>
	)
}
