import React from 'react'
import { Link } from 'react-router-dom'

export const MatchingCompleteView = ({ ...props }) => {
	return (
		<div className="wrap form_page">
			<div className="content_area">
				<div className="bgWrap">
					<div className="container">
						<p className="form_topTitle fw-bold">
							제안서 발송이{' '}
							<span className="text-nowrap" style={{ fontWeight: 'normal' }}>
								완료되었습니다!
							</span>
						</p>
						<div className="card">
							<div className="card-body">
								<p className="fontSize-30 fw-bold text-center cmb-20">
									스포이음 서비스
									<span className="fontSize-30">를 이용해주셔서 감사합니다!</span>
								</p>
								<p className="fontSize-20 text-center cmb-40">
									회신이 도착하면 알려드리겠습니다.
								</p>
								<p className="fontSize-30 fw-bold text-center cmb-40">...</p>
								<p className="fontSize-25 fw-bold text-center">
									'계약관리 &gt; 내 제안/회신내역'
									<span className="fontSize-25">에서</span>
								</p>
								<p className="fontSize-25 text-center cmb-40">
									자세한 정보를 확인할 수 있습니다.
								</p>

								<Link
									to={'/mypages/proposals/detail'}
									className="btn btn-dark nextPageButton">
									제안 내역 확인하기
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
