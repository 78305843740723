import React from 'react'

export const GradeItem = ({ dataSource, ...props }) => {
	return (
		<div className="indexScheduleBoard">
			<p className="indexScheduleBoardTitle">LG SIGNATURE 플레이어스 챔피언십</p>
			<p className="indexScheduleSubtitle">
				2020-11-05 ~ 2020-11-08
				<span className="ms-2 me-2 d-none">전체성적:206</span>
				<span className="ms-2 me-2">순위:79</span>
			</p>
		</div>
	)
}
