import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ContractInfo, ModalPayServiceFeeInfo } from '../../../components'
import { contractTypes, getMatchTypeOptions, useAuthStatus, userTypes } from '../../../utils'
import dayjs from 'dayjs'

export const ContractProcessingDetailView = ({ API_ENDPOINT, ...props }) => {
	const [auth, onChangeAuthStatus] = useAuthStatus()

	const { contractId } = useParams()

	const [contract, setContract] = useState(null)
	const [contractIncentives, setContractIncentives] = useState([])

	const [paymentInfoVisible, setPaymentInfoVisible] = useState(false)

	useEffect(() => {
		Promise.all([fetchContract(), fetchContractIncentives(contractId)]).then()
	}, [auth])

	const fetchContract = async () => {
		await fetch(`${API_ENDPOINT}/contract/detail/${contractId}`)
			.then(async (response) => {
				const result = await response.json()

				if (result?.success === false) throw result

				setContract(result)
			})
			.catch((errors) => {
				console.log(errors)
			})
	}

	const fetchContractIncentives = async (contractId) => {
		await fetch(`${API_ENDPOINT}/contract/contract/detail/incentive/${contractId}`)
			.then(async (response) => {
				const result = await response.json()

				setContractIncentives(result)
			})
			.catch((errors) => {
				console.log(errors)
			})
	}

	const matchContractUserType = () => {
		const myId = auth?.user?.id

		if (contract?.user?.id === myId) return 'USER'
		else if (contract?.targetUser?.id === myId) return 'TARGET'
	}

	const handlePaymentInfoModalModalOpen = () => {
		setPaymentInfoVisible(true)
	}
	const handlePaymentInfoModalModalClose = () => {
		setPaymentInfoVisible(false)
	}

	return (
		<div className="wrap form_page">
			<div className="content_area">
				<div className="container mainWrap">
					<div className="row align-items-end cmb-50">
						<div className="col-12 col-md-6">
							<div className="fontSize-20 font-R00 color-greyish-two">
								{matchContractUserType() === 'TARGET' &&
									getMatchTypeOptions(userTypes, contract?.user?.user_type)}
								{matchContractUserType() === 'USER' &&
									getMatchTypeOptions(userTypes, contract?.targetUser?.user_type)}
							</div>
							<div className="fontSize-30 font-SB00 cmb-10">
								{matchContractUserType() === 'USER' &&
									`${contract?.targetUser?.name} (${
										contract?.targetUser?.user_type !== 'agency' &&
										`${contract?.targetUser?.last_name} ${contract?.targetUser?.first_name}`
									})`}
								{matchContractUserType() === 'TARGET' &&
									`${contract?.user?.name} (${
										contract?.user?.user_type !== 'agency' &&
										`${contract?.user?.last_name} ${contract?.user?.first_name}`
									})`}
							</div>
							<div className="fontSize-20 font-R00 color-greyish-two">
								{dayjs(contract?.contract?.created_at).format('YYYY-MM-DD')} /{' '}
								{getMatchTypeOptions(
									contractTypes,
									contract?.contract?.contract_type,
								)}
							</div>
						</div>

						<div className="col-12 col-md-4 offset-md-2">
							{['PROCEEDING', 'CANCEL', 'NOTPAY', 'END'].includes(
								contract?.contract?.contract_status,
							) && (
								<Link to={`/mypages/contract/docs/${contractId}`} target="_blank">
									<button className="btn btn-dark pb-3 pt-3 w-100">
										계약서 보기
									</button>
								</Link>
							)}
						</div>
					</div>

					<p className="form_title cmt-10 cmb-10">기본정보</p>
					<div className="card cmb-30">
						<div className="card-body">
							<div className="row cmt-10">
								<div className="col-12 col-md-auto fontSize-18 font-R00 color-greyish-two">
									계약유형
								</div>
								<div className="col-12 col-md-auto fontSize-18 font-R00">
									{getMatchTypeOptions(
										contractTypes,
										contract?.contract?.contract_type,
									)}
								</div>
							</div>
							{['single', 'long_term'].includes(
								contract?.contract?.contract_type,
							) && (
								<div className="row cmt-10">
									<div className="col-12 col-md-auto fontSize-18 font-R00 color-greyish-two">
										계약기간
									</div>
									<div className="col-12 col-md-auto fontSize-18 font-R00 me-2">
										{dayjs(contract?.tour?.started_at).format('YYYY-MM-DD')} ~{' '}
										{dayjs(contract?.tour?.ended_at).format('YYYY-MM-DD')}
										(총 1경기) {contract?.tour?.name}
									</div>
								</div>
							)}

							<div className="row cmt-10">
								<div className="col-12 col-md-auto fontSize-18 font-R00 color-greyish-two">
									내용
								</div>
								<div
									className="col-12 col-md-auto fontSize-18 font-R00"
									dangerouslySetInnerHTML={{ __html: contract?.contract?.etc }}
									style={{ whiteSpace: 'pre' }}
								/>
							</div>
						</div>
					</div>

					<ContractInfo contract={contract?.contract} incentives={contractIncentives} />

					{contract?.contract_status === 'NOTPAY' && (
						<div className="row cmt-30">
							<div className="col-12">
								<button
									className="btn btn-success nextPageButton"
									onClick={handlePaymentInfoModalModalOpen}>
									결제 하기
								</button>
							</div>
						</div>
					)}
				</div>
			</div>

			<ModalPayServiceFeeInfo
				modalVisible={paymentInfoVisible}
				onClose={handlePaymentInfoModalModalClose}
			/>
		</div>
	)
}
