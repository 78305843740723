import numeral from 'numeral'

export const formattingPrice = (price) => (!!price ? numeral(price).format('0,0') : 0)
export const parsePriceToNumber = (value) => {
	if (!!!value) return 0

	if (typeof value === 'number') {
		return value
	} else {
		return parseInt(value.replace(/\$\s?|(,*)/g, ''))
	}
}
