import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStateMachine } from 'little-state-machine'
import {
	FETCH_HEADER_OPTIONS,
	initFormState,
	updateFormInput,
	parseStringToBool,
	parsePriceToNumber,
	useAuthStatus,
} from '../../utils'
import { AnnualContractInfoForm } from '../../components'

export const AnnualRecruitInfoFormView = ({ API_ENDPOINT, ...props }) => {
	const [auth, onChangeAuth] = useAuthStatus()

	const navigate = useNavigate()

	const { actions, state } = useStateMachine({ initFormState, updateFormInput })

	const [serviceConfig, setServiceConfig] = useState({})

	useEffect(() => {
		fetchSettingContract()
	}, [])

	const onSubmit = async (data) => {
		const { isTaxIncluded, weeklySalary, ...others } = data

		const { tours, ...offerInput } = state?.offerInput

		const input = {
			...offerInput,
			...others,
			userId: auth?.user?.id,
			isTaxIncluded: parseStringToBool(isTaxIncluded),
			weeklySalary: parsePriceToNumber(weeklySalary),
			tour: [],
		}

		await createOffer(input)
	}

	const createOffer = async (input) => {
		await fetch(`${API_ENDPOINT}/contract/offer`, {
			method: 'POST',
			headers: FETCH_HEADER_OPTIONS,
			body: JSON.stringify(input),
		})
			.then(async (response) => {
				actions.initFormState()
				navigate(`/mypages/offers`)
			})
			.catch((error) => {
				// TODO : 에러메세지 관련 안내창 출력
				console.log(error)
			})
	}

	const fetchSettingContract = async () => {
		await fetch(`${API_ENDPOINT}/admin/payment-manage/admin/setting`)
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) throw result

				setServiceConfig(result)
			})
			.catch((errors) => console.log(errors))
	}

	console.log(serviceConfig)

	return (
		<div className="wrap register_page form_page">
			<div className="content_area">
				<div className="bgWrap">
					<div className="container form_mw-662">
						<p className="form_topTitle fw-bold">
							계약 조건을{' '}
							<span className="text-nowrap" style={{ fontWeight: 'normal' }}>
								입력해 주세요.
							</span>
						</p>
						<p className="form_progressTitle cmt-30">
							항목을 선택하시면 제안서가 작성됩니다. 3/3
						</p>

						<AnnualContractInfoForm serviceConfig={serviceConfig} onSubmit={onSubmit} />
					</div>
				</div>
			</div>
		</div>
	)
}
