import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { ContractInfo, ModalSuggestionForm, ModalContractTourList } from '../../components'
import {
	contractTypes,
	FETCH_HEADER_OPTIONS,
	getMatchTypeOptions,
	parseFields,
	parseStringToBool,
	useAuthStatus,
} from '../../utils'
import dayjs from 'dayjs'
import Modal from 'react-bootstrap/Modal'

export const RecruitDetailView = ({ API_ENDPOINT, ...props }) => {
	const [auth, onChangeAuth] = useAuthStatus()

	const navigate = useNavigate()
	const { offerId } = useParams()

	const [serviceFeePercentages, setServiceFeePercentages] = useState([])
	const [offer, setOffer] = useState([])
	const [supportable, setSupportable] = useState(false)
	const [tourListVisible, setTourListVisible] = useState(false)
	const [applyOfferVisible, setApplyOfferVisible] = useState(false)
	const [suggestionModalVisible, setSuggestionModalVisible] = useState(false)
	const [suggestionFields, setSuggestionFields] = useState({})

	useEffect(() => {
		Promise.all([fetchOffer(), fetchApplies(), fetchServiceFeePercentages()]).then(
			([offer, applies, percentages]) => {
				// 작성자와 같은 사람인지
				const isSameUser = offer?.user?.id !== auth?.user?.id
				// 지원 이력이 있는지
				const isApplied =
					applies?.filter((item) => item?.user?.id === auth?.user?.id)?.length > 0
				// 작성자가 모집하는 유저 타입인지
				const matched = matchedUserType(offer?.user?.user_type, auth?.user?.userType)

				setOffer(offer)
				setSupportable(isSameUser && !isApplied && matched)
				setServiceFeePercentages(percentages)
			},
		)
	}, [auth])

	const fetchOffer = async () => {
		return await fetch(`${API_ENDPOINT}/contract/offer/${offerId}`)
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) throw new Error(result.massage)

				return result
			})
			.catch((errors) => setOffer({}))
	}

	const fetchApplies = async () => {
		return await fetch(`${API_ENDPOINT}/contract/contract/offer/apply/${offerId}`)
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) throw new Error(result.massage)

				return result
			})
			.catch((errors) => setOffer({}))
	}

	const fetchServiceFeePercentages = async () => {
		return await fetch(`${API_ENDPOINT}/admin/payment-manage/pro/incentive`)
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) throw result

				return result
			})
			.catch((errors) => {
				console.log(errors)
			})
	}

	const createContract = async ({ incentives, ...input }) => {
		await fetch(`${API_ENDPOINT}/contract/contract`, {
			method: 'POST',
			headers: FETCH_HEADER_OPTIONS,
			body: JSON.stringify(input),
		})
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) throw result

				if (incentives?.length > 0) {
					const incentivesInput = incentives?.map((item) => {
						return {
							...item,
							contractId: result?.id,
							contractGroupId: result?.contract_group_id,
						}
					})

					await createContractIncentives(incentivesInput)
				}
			})
			.catch((error) => {
				// TODO : 에러메세지 관련 안내창 출력
				console.log(error)
			})
	}

	const createContractIncentives = async (input) => {
		await fetch(`${API_ENDPOINT}/contract/contract/incentive`, {
			method: 'POST',
			headers: FETCH_HEADER_OPTIONS,
			body: JSON.stringify(input),
		})
			.then(async (response) => {})
			.catch((error) => {
				// TODO : 에러메세지 관련 안내창 출력
				console.log(error)
			})
	}

	const createContractList = async (input) => {
		const inputList = ['year_first_half', 'year_second_half'].includes(
			offer?.offer?.contract_type,
		)
			? [{ ...input, percentage: 0, postId: offer?.offer?.id, isOffer: true }]
			: offer?.tour?.map((item) => {
					if (offer?.offer?.contract_type === 'long_term') {
						return {
							...input,
							tourId: item?.id,
							contractGroupId: `${input?.userId}${Date.now()}`,
							percentage: matchedServiceFeePercentage(),
							postId: offer?.offer?.id,
							isOffer: true,
						}
					} else if (offer?.offer?.contract_type === 'single') {
						return {
							...input,
							tourId: item?.id,
							percentage: 0,
							postId: offer?.offer?.id,
							isOffer: true,
						}
					}
			  })

		await Promise.all(inputList?.map((contractInput) => createContract(contractInput)))
			.then(() => {
				navigate('/mypages/proposals?tab=SENT')
			})
			.catch((error) => {
				// TODO : 에러메세지 관련 안내창 출력
				console.log(error)
			})
	}

	const applyOffer = async () => {
		const {
			id,
			user_id,
			post_title,
			post_detail,
			created_at,
			is_incentive_tax_included,
			is_tax_included,
			wear_sponsored_hat,
			...offerInfo
		} = offer?.offer
		const contractInput = parseFields(offerInfo)

		const input = {
			...contractInput,
			userId: auth?.user?.id,
			targetUserId: offer?.user?.id,
			isIncentiveTaxIncluded: parseStringToBool(is_incentive_tax_included),
			isTaxIncluded: parseStringToBool(is_tax_included),
			wearSponsoredHat: parseStringToBool(wear_sponsored_hat),
			tourId: '',
			etc: '',
		}

		await createContractList(input).then(() => {
			// TODO : 지원 성공 안내
			handleApplyModalModalClose()
		})
	}

	const matchedUserType = (offerUserType, authUserType) => {
		if (['tourPro', 'agency'].includes(offerUserType)) {
			return ['tourCaddie', 'houseCaddie'].includes(authUserType)
		} else if (['tourCaddie', 'houseCaddie'].includes(offerUserType)) {
			return ['tourPro', 'agency'].includes(authUserType)
		}
	}

	const matchedServiceFeePercentage = () => {
		const tourLength = offer?.tour?.length

		const percentageItem = serviceFeePercentages?.find(
			(item) => item?.startRank <= tourLength && item?.endRank >= tourLength,
		)

		return percentageItem?.percentage
	}

	const handleTourListModalModalOpen = () => setTourListVisible(true)
	const handleTourListModalModalClose = () => setTourListVisible(false)

	const handleSuggestionModalModalOpen = () => {
		const { id, user_id, post_title, post_detail, created_at, ...offerInfo } = offer?.offer
		const incentives = offer?.incentive
			?.map((item) => {
				const { id, contract_post_id, ...info } = item

				return info
			})
			.sort((a, b) => {
				return a?.startRank - b?.startRank
			})

		const fields = parseFields({
			...offerInfo,
			incentives,
			userId: auth?.user?.id,
			targetUserId: user_id,
			postId: id,
		})

		setSuggestionFields(fields)
		setSuggestionModalVisible(true)
	}
	const handleSuggestionModalModalClose = () => {
		setSuggestionFields({})
		setSuggestionModalVisible(false)
	}

	const handleApplyModalModalOpen = () => setApplyOfferVisible(true)
	const handleApplyModalModalClose = () => setApplyOfferVisible(false)

	return (
		<div className="wrap notice_page form_page">
			<div className="content_area">
				<div className="container mainWrap">
					<div className="fontSize-20 font-R00 color-greyish-two">{}</div>
					<div className="fontSize-30 font-SB00 cmb-10">{offer?.offer?.post_title}</div>
					<div className="fontSize-20 font-R00 color-greyish-two cmb-30">
						{dayjs(offer?.offer?.created_at).format('YYYY-MM-DD')} / {offer?.user?.name}{' '}
						{offer?.user?.user_type !== 'agency' &&
							`(${offer?.user?.first_name} ${offer?.user?.last_name})`}
					</div>
					<p className="form_title cmt-10">기본정보</p>
					<Card className="cmb-30">
						<Card.Body>
							<div className="row cmt-10">
								<div className="col-12 col-md-auto color-greyish-two">계약유형</div>
								<div className="col-12 col-md-auto">
									{getMatchTypeOptions(
										contractTypes,
										offer?.offer?.contract_type,
									)}
								</div>
							</div>
							{['single', 'long_term'].includes(offer?.offer?.contract_type) && (
								<div className="row cmt-10">
									<div className="col-12 col-md-auto color-greyish-two">
										계약기간
									</div>
									<div className="col-12 col-md-auto">
										{dayjs(offer?.tour[0]?.started_at).format('YYYY-MM-DD')} ~{' '}
										{dayjs(offer?.tour?.at(-1)?.ended_at).format('YYYY-MM-DD')}{' '}
										총 {offer?.tour?.length}경기
										<button
											className="btn btn-light form_titleSubButton text-nowrap"
											type="button"
											onClick={handleTourListModalModalOpen}>
											대회목록보기
										</button>
									</div>
								</div>
							)}
							<div className="row cmt-10">
								<div className="col-12 col-md-auto color-greyish-two">공고내용</div>
								<div
									className="col-12 col-md-auto"
									dangerouslySetInnerHTML={{
										__html: offer?.offer?.post_detail,
									}}
									style={{ whiteSpace: 'pre' }}
								/>
							</div>
						</Card.Body>
					</Card>

					<ContractInfo contract={offer?.offer} incentives={offer?.incentive} />

					{supportable && (
						<div className="row">
							<div
								className={
									auth?.user?.userType !== 'houseCaddie' ? 'col-6' : 'col-12'
								}>
								<button
									disabled={offer?.offer?.contract_status !== 'PRE'}
									className="btn btn-success nextPageButton"
									onClick={handleApplyModalModalOpen}>
									현재 조건으로 지원하기
								</button>
							</div>
							{auth?.user?.userType !== 'houseCaddie' && (
								<div className="col-6">
									<button
										disabled={offer?.offer?.contract_status !== 'PRE'}
										className="btn btn-primary nextPageButton"
										onClick={handleSuggestionModalModalOpen}>
										다른 조건으로 지원하기
									</button>
								</div>
							)}
						</div>
					)}
				</div>
			</div>

			<ModalContractTourList
				modalVisible={tourListVisible}
				tours={offer?.tour}
				onClose={handleTourListModalModalClose}
			/>

			<ModalApplyOffer
				modalVisible={applyOfferVisible}
				applyOffer={applyOffer}
				onClose={handleApplyModalModalClose}
			/>

			<ModalSuggestionForm
				modalVisible={suggestionModalVisible}
				modalTitle="다른 조건으로 지원하기"
				fieldValues={suggestionFields}
				contractType={offer?.offer?.contract_type}
				createContractList={createContractList}
				onClose={handleSuggestionModalModalClose}
			/>
		</div>
	)
}

const ModalApplyOffer = ({ modalVisible, applyOffer, onClose, ...props }) => {
	const [agreeChecked, setAgreeChecked] = useState(false)

	useEffect(() => {
		setAgreeChecked(false)
	}, [modalVisible])

	const onClickAgreeCheck = (event) => {
		setAgreeChecked(event.target.checked)
	}

	return (
		<Modal
			id="applyContract"
			show={modalVisible}
			size="lg"
			backdrop="static"
			tabIndex={-1}
			centered
			aria-labelledby="applyContract"
			aria-hidden="true"
			className="wrap register_page form_page re_form">
			<Modal.Header class="border-0">
				<p className="modal-title w-100 text-center font-bold" id="staticBackdropLabel">
					현재 조건으로 지원하기
				</p>
				<button type="button" className="btn-close" aria-label="Close" onClick={onClose} />
			</Modal.Header>
			<Modal.Body>
				<div className="request_checkbox_outer">
					<input
						type="checkbox"
						id="request_agree"
						checked={agreeChecked}
						onClick={onClickAgreeCheck}
						className="d-none request_checkbox"
					/>
					<label htmlFor="request_agree" className="w-100">
						<div className="row align-items-center cmb-20">
							<div className="col cmb-10">
								<p
									className="fontSize-28 font-L00 color-greyish-brown me-2"
									style={{ wordBreak: 'keep-all' }}>
									상대 정보와 구인 제안서를 모두 확인했으며,
								</p>
								<p className="fontSize-28 font-M00 color-greyish-brown">
									구인 내용에 동의합니다.
								</p>
							</div>
							<div className="col-auto cmb-10">
								<div className="request_checkbox_img" />
							</div>
						</div>
					</label>

					<div className="fontSize-20 font-L00 color-greyish-brown cmb-30">
						<p>구인자에게 제안서가 발송됩니다.</p>
						<p>정말 진행하시겠습니까?</p>
					</div>

					<div className="row">
						<div className="col-12 col-md-6">
							<button
								type="button"
								className="btn btn-danger pb-4 pt-4 w-100 cmb-10"
								onClick={onClose}>
								취소
							</button>
						</div>
						<div className="col-12 col-md-6">
							<button
								id="noticeaccept"
								className="btn btn-dark pb-4 pt-4 w-100 cmb-10"
								disabled={!agreeChecked}
								onClick={applyOffer}>
								확인
							</button>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
}
