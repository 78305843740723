import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useStateMachine } from 'little-state-machine'
import {
	FETCH_HEADER_OPTIONS,
	initFormState,
	parsePriceToNumber,
	parseStringToBool,
	updateFormInput,
	useAuthStatus,
} from '../../utils'
import { BasicContractInfoForm } from '../../components'

export const TourRecruitInfoFormView = ({ API_ENDPOINT, ...props }) => {
	const [auth, onChangeAuth] = useAuthStatus()

	const navigate = useNavigate()

	const { actions, state } = useStateMachine({ initFormState, updateFormInput })

	const onSubmit = async (data) => {
		const {
			isTaxIncluded,
			isIncentiveTaxIncluded,
			wearSponsoredHat,
			weeklySalary,
			weeklySalaryF,
			expenseReimbursementJeju,
			...others
		} = data

		const { tours, ...offerInput } = state?.offerInput

		const tour = tours?.map((item) => {
			return {
				tourId: item.id,
			}
		})

		const input = {
			...offerInput,
			...others,
			userId: auth?.user?.id,
			isTaxIncluded: parseStringToBool(isTaxIncluded),
			isIncentiveTaxIncluded: parseStringToBool(isIncentiveTaxIncluded),
			wearSponsoredHat: parseStringToBool(wearSponsoredHat),
			weeklySalary: parsePriceToNumber(weeklySalary),
			weeklySalaryF: parsePriceToNumber(weeklySalaryF),
			expenseReimbursementJeju: parsePriceToNumber(expenseReimbursementJeju),
			tour,
		}

		await createOffer(input)
	}

	const createOffer = async (input) => {
		await fetch(`${API_ENDPOINT}/contract/offer`, {
			method: 'POST',
			headers: FETCH_HEADER_OPTIONS,
			body: JSON.stringify(input),
		})
			.then(async (response) => {
				actions.initFormState()
				navigate(`/mypages/offers`)
			})
			.catch((error) => {
				// TODO : 에러메세지 관련 안내창 출력
				console.log(error)
			})
	}

	return (
		<div className="wrap register_page form_page">
			<div className="content_area">
				<div className="bgWrap">
					<div className="container form_mw-662">
						<p className="form_topTitle">구인 내용 작성</p>
						<p className="form_progressTitle cmt-30">
							다음 내용으로 공고가 등록됩니다. 3/3
						</p>
						<div className="progress">
							<div
								className="progress-bar"
								role="progressbar"
								style={{ width: '100%' }}
								aria-valuenow="100"
								aria-valuemin="0"
								aria-valuemax="100"></div>
						</div>

						<BasicContractInfoForm onSubmit={onSubmit} />
					</div>
				</div>
			</div>
		</div>
	)
}
