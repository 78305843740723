import React, { useState, useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import {
	changeStyleColor,
	FETCH_HEADER_OPTIONS,
	formattingPrice,
	parseFields,
	parsePriceToNumber,
	parseStringToBool,
	rangeYears,
	useAuthStatus,
} from '../../utils'
import { MessageModal } from '../../components'

export const MyContractInfoView = ({ API_ENDPOINT, ...props }) => {
	const [auth, onChangeAuthStatus] = useAuthStatus()

	const [fieldValues, setFieldValues] = useState({})
	const [clubs, setClubs] = useState([])
	const [expenseReimbursementJejuVisible, setExpenseReimbursementJejuVisible] = useState(false)

	const {
		control,
		register,
		getValues,
		setValue,
		watch,
		setFocus,
		handleSubmit,
		formState: { errors },
	} = useForm({
		defaultValues: {
			isSalaryTaxIncluded: 'true',
			isWeekly: 'true',
			preferredTour: ['KPGA', 'KLPGA'],
			isIncentiveTaxIncluded: 'true',
			incentives: [
				{ startRank: 1, endRank: 1 },
				{ endRank: 0, percentage: 0 },
			],
			wearSponsoredHat: 'true',
			club: '',
		},
		values: fieldValues,
		mode: 'onBlur',
	})
	const { fields, append, insert, remove } = useFieldArray({
		control,
		name: 'incentives',
		rules: { maxLength: 10 },
	})

	useEffect(() => {
		if (auth?.isAuthentication) {
			Promise.all([
				fetchUserInfo(auth?.user?.id),
				fetchTourCaddieIncentives(auth?.user?.id),
				fetchClubs(),
			])
				.then(([caddieInfo, caddieIncentives, clubs]) => {
					const incentives = caddieIncentives
						?.map((item) => {
							const { id, user_id, ...incentiveInfo } = item

							return incentiveInfo
						})
						?.sort((a, b) => {
							return a?.startRank - b?.startRank < 0
						})

					const {
						preferred_tour,
						weekly_salary_for_KPGA,
						weekly_salary_for_KLPGA,
						weekly_salary_f_for_KPGA,
						weekly_salary_f_for_KLPGA,
						expense_reimbursement_jeju,
						...info
					} = caddieInfo

					const fields = parseFields({
						...info,
						weeklySalaryForKPGA: formattingPrice(weekly_salary_for_KPGA),
						weeklySalaryForKLPGA: formattingPrice(weekly_salary_for_KLPGA),
						weeklySalaryFailForKPGA: formattingPrice(weekly_salary_f_for_KPGA),
						weeklySalaryFailForKLPGA: formattingPrice(weekly_salary_f_for_KLPGA),
						expenseReimbursementJeju: formattingPrice(expense_reimbursement_jeju),
						incentives,
					})
					fields['preferredTour'] =
						preferred_tour === 'both' ? ['KPGA', 'KLPGA'] : [preferred_tour]

					if (expense_reimbursement_jeju > 0) {
						setExpenseReimbursementJejuVisible(true)
					}
					setFieldValues(fields)
					setClubs(clubs)
				})
				.catch((errors) => {
					setFieldValues({})
					setClubs([])
				})
		}
	}, [auth])

	const onSubmit = async ({ incentives, ...data }) => {
		// TODO: update contract info
		const fields = Object.keys(data).reduce((acc, cv) => {
			acc[cv] = parseStringToBool(data[cv])

			return acc
		}, {})

		const weeklySalaryForKPGA = parsePriceToNumber(fields.weeklySalaryForKPGA)
		const weeklySalaryForKLPGA = parsePriceToNumber(fields.weeklySalaryForKLPGA)
		const weeklySalaryFailForKPGA = parsePriceToNumber(fields.weeklySalaryFailForKPGA)
		const weeklySalaryFailForKLPGA = parsePriceToNumber(fields.weeklySalaryFailForKLPGA)
		const expenseReimbursementJeju = parsePriceToNumber(fields.expenseReimbursementJeju)
		const preferredTour = fields.preferredTour

		const input = {
			...fields,
			weeklySalaryForKPGA,
			weeklySalaryForKLPGA,
			weeklySalaryFailForKPGA,
			weeklySalaryFailForKLPGA,
			expenseReimbursementJeju,
			preferredTour: preferredTour?.length > 1 ? 'both' : preferredTour[0],
		}

		await fetch(`${API_ENDPOINT}/users/caddie/updateContract/${auth?.user?.id}`, {
			method: 'PATCH',
			headers: FETCH_HEADER_OPTIONS,
			body: JSON.stringify(input),
		})
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) throw result

				await createTourCaddieIncentives(auth?.user?.id, incentives)

				handleMessageModalOpen('정보 수정이 완료되었습니다.')
			})
			.catch((errors) => console.log(errors))
	}

	const onInvalid = (errors) => {
		const firstError = Object.keys(errors).reduce((field, a) => {
			return !!errors[field] ? field : a
		}, null)
		setFocus(firstError)

		if (
			[
				'weeklySalaryForKPGA',
				'weeklySalaryForKLPGA',
				'weeklySalaryFailForKPGA',
				'weeklySalaryFailForKLPGA',
			].includes(firstError)
		) {
			handleMessageModalOpen('금액을 입력해주세요.')
		} else if (firstError === 'incentives') {
			handleMessageModalOpen('인센티브를 입력해주세요.')
		}
	}

	const fetchUserInfo = async (caddieId) => {
		return await fetch(`${API_ENDPOINT}/users/my-page/${caddieId}`)
			.then(async (response) => {
				const result = await response.json()

				return result?.user_tour_caddie
			})
			.catch((errors) => {
				return {}
			})
	}

	const fetchTourCaddieIncentives = async (caddieId) => {
		return await fetch(`${API_ENDPOINT}/users/tourcaddie/incentive/${caddieId}`)
			.then(async (response) => {
				return await response.json()
			})
			.catch((errors) => {
				return []
			})
	}

	const fetchClubs = async () => {
		return await fetch(`${API_ENDPOINT}/admin/db-manage/club`)
			.then(async (response) => {
				return await response.json()
			})
			.catch((errors) => {
				return []
			})
	}

	const createTourCaddieIncentives = async (tourCaddieId, incentives) => {
		const input = incentives?.map((item) => {
			return { ...item, userId: tourCaddieId }
		})

		await fetch(`${API_ENDPOINT}/users/tourcaddie/incentive`, {
			method: 'POST',
			headers: FETCH_HEADER_OPTIONS,
			body: JSON.stringify(input),
		})
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) {
					throw new Error('회원가입 실패')
				}
			})
			.catch((errors) => {
				console.log(errors)
			})
	}

	const addIncentive = (index) => (event) => {
		event.preventDefault()

		const prevIncentive = getValues('incentives')[index - 1]
		insert(index, { startRank: prevIncentive?.endRank + 1, percentage: 1 })
	}
	const removeIncentive = (index) => (event) => {
		event.preventDefault()
		remove(index)
	}

	const onChangePreferredTour = (event) => {
		if (!watch('preferredTour')?.includes('KPGA')) {
			setValue('weeklySalaryForKPGA', 0)
			setValue('weeklySalaryFailForKPGA', 0)
		} else if (!watch('preferredTour')?.includes('KLPGA')) {
			setValue('weeklySalaryForKLPGA', 0)
			setValue('weeklySalaryFailForKLPGA', 0)
		}
	}

	const onClickExpenseReimbursementJeju = (value) => (event) => {
		setValue('expenseReimbursementJeju', '0')
		setExpenseReimbursementJejuVisible(value)
	}

	const onKeyupPrice = (key) => (event) => {
		const price = formattingPrice(event.target.value)

		setValue(key, price)
	}

	const years = rangeYears()

	// modal
	const [messageModalVisible, setMessageModalVisible] = useState(false)
	const [messageModalContent, setMessageModalContent] = useState('')

	const handleMessageModalClose = () => {
		setMessageModalContent('')
		setMessageModalVisible(false)
	}
	const handleMessageModalOpen = (message) => {
		setMessageModalContent(message)
		setMessageModalVisible(true)
	}

	return (
		<div className="wrap register_page re_form form_page">
			<div className="content_area">
				<div className="bgWrap">
					<div className="container form_mw-662">
						<form
							id="form_matchingPro"
							className="needs-validation"
							noValidate
							onSubmit={handleSubmit(onSubmit, onInvalid)}>
							<p className="form_topTitle">계약 정보 수정</p>
							<div className="card">
								<div className="card-body">
									<input type="hidden" name="userType" value="tourcaddie" />
									<p className="form_title cmb-20">
										임금 세금 포함 여부{' '}
										<span
											style={{
												marginLeft: '0.625rem',
												fontSize: '0.75rem',
												color: '#aeaeae',
											}}>
											원천징수 3.3%
										</span>
									</p>
									<div className="row cmt-20 cmb-20">
										<div className="col cmb-10">
											<input
												id="rgTour_paymentTax_1"
												type="radio"
												className="btn-check"
												autoComplete="off"
												value="true"
												{...register('isSalaryTaxIncluded', {
													required: true,
												})}
											/>
											<label
												className="btn btn-light btn-whiteShadow"
												htmlFor="rgTour_paymentTax_1">
												세전
											</label>
										</div>
										<div className="col cmb-10">
											<input
												id="rgTour_paymentTax_2"
												type="radio"
												className="btn-check"
												autoComplete="off"
												value="false"
												{...register('isSalaryTaxIncluded', {
													required: true,
												})}
											/>
											<label
												className="btn btn-light btn-whiteShadow"
												htmlFor="rgTour_paymentTax_2">
												세후
											</label>
										</div>
									</div>

									<p className="form_title">임금 지급 주기</p>
									<div className="row cmt-20 cmb-20">
										<div className="col cmb-10">
											<input
												id="is_weekly_1"
												type="radio"
												className="btn-check"
												autoComplete="off"
												value="true"
												{...register('isWeekly', {
													required: true,
												})}
											/>
											<label
												className="btn btn-light btn-whiteShadow"
												htmlFor="is_weekly_1">
												주급
											</label>
										</div>
										<div className="col cmb-10">
											<input
												id="is_weekly_2"
												type="radio"
												className="btn-check"
												autoComplete="off"
												value="false"
												{...register('isWeekly', {
													required: true,
												})}
											/>
											<label
												className="btn btn-light btn-whiteShadow"
												htmlFor="is_weekly_2">
												연간계약
											</label>
										</div>
									</div>

									<p className="form_title">
										근무 희망 투어
										<span className="regTaxText">복수선택가능</span>
									</p>
									<div className="row align-items-center cmb-30">
										<div className="col">
											<input
												id="rgTour_kpgaChk"
												type="checkbox"
												className="btn-check"
												autoComplete="off"
												value="KPGA"
												{...register('preferredTour', {
													required: true,
													onChange: onChangePreferredTour,
												})}
											/>
											<label
												className="btn btn-light btn-whiteShadow"
												htmlFor="rgTour_kpgaChk">
												KPGA
											</label>
										</div>
										<div className="col">
											<input
												id="rgTour_klpgaChk"
												type="checkbox"
												className="btn-check"
												value="KLPGA"
												{...register('preferredTour', {
													required: true,
													onChange: onChangePreferredTour,
												})}
											/>
											<label
												className="btn btn-light btn-whiteShadow"
												htmlFor="rgTour_klpgaChk">
												KLPGA
											</label>
										</div>
									</div>

									<div className="row align-items-end cmb-30">
										{watch('preferredTour')?.includes('KPGA') && (
											<div className="col-12 col-md-6 cmb-10">
												<p className="form_title">KPGA</p>

												<div className="row align-items-center cmb-10">
													{watch('isWeekly') === 'true' && (
														<div className="col-auto reg_subTitle text-end">
															컷통과
														</div>
													)}
													{watch('isWeekly') === 'false' && (
														<div className="col-auto reg_subTitle text-end">
															상반기
														</div>
													)}
													<div className="col">
														<div className="d-flex justify-content-end align-items-center underbarInput">
															<input
																id="weeklySalaryForKPGA"
																className="border-0 w-100 form-control no-focus form_inputText regUnderbarInput"
																placeholder="금액을 입력해 주세요."
																{...register(
																	'weeklySalaryForKPGA',
																	{
																		required: true,
																		onChange:
																			onKeyupPrice(
																				'weeklySalaryForKPGA',
																			),
																	},
																)}
															/>
															원
														</div>
													</div>
												</div>

												<div className="row align-items-center">
													{watch('isWeekly') === 'true' && (
														<div className="col-auto reg_subTitle text-end">
															컷탈락
														</div>
													)}
													{watch('isWeekly') === 'false' && (
														<div className="col-auto reg_subTitle text-end">
															하반기
														</div>
													)}
													<div className="col">
														<div className="d-flex justify-content-end align-items-center underbarInput">
															<input
																id="weeklySalaryFailForKPGA"
																className="border-0 w-100 form-control no-focus form_inputText regUnderbarInput"
																placeholder="금액을 입력해 주세요."
																{...register(
																	'weeklySalaryFailForKPGA',
																	{
																		required: true,
																		onChange:
																			onKeyupPrice(
																				'weeklySalaryFailForKPGA',
																			),
																	},
																)}
															/>
															원
														</div>
													</div>
												</div>
											</div>
										)}

										{watch('preferredTour')?.includes('KLPGA') && (
											<div className="col-12 col-md-6 cmb-10">
												<p className="form_title">KLPGA</p>

												<div className="row align-items-center cmb-10">
													{watch('isWeekly') === 'true' && (
														<div className="col-auto reg_subTitle text-end">
															컷통과
														</div>
													)}
													{watch('isWeekly') === 'false' && (
														<div className="col-auto reg_subTitle text-end">
															상반기
														</div>
													)}
													<div className="col">
														<div className="d-flex justify-content-end align-items-center underbarInput">
															<input
																id="weeklySalaryForKLPGA"
																className="border-0 w-100 form-control no-focus form_inputText regUnderbarInput"
																placeholder="금액을 입력해 주세요."
																{...register(
																	'weeklySalaryForKLPGA',
																	{
																		required: true,
																		onChange:
																			onKeyupPrice(
																				'weeklySalaryForKLPGA',
																			),
																	},
																)}
															/>
															원
														</div>
													</div>
												</div>

												<div className="row align-items-center">
													{watch('isWeekly') === 'true' && (
														<div className="col-auto reg_subTitle text-end">
															컷탈락
														</div>
													)}
													{watch('isWeekly') === 'false' && (
														<div className="col-auto reg_subTitle text-end">
															하반기
														</div>
													)}
													<div className="col">
														<div className="d-flex justify-content-end align-items-center underbarInput">
															<input
																id="weeklySalaryFailForKLPGA"
																className="border-0 w-100 form-control no-focus form_inputText regUnderbarInput"
																placeholder="금액을 입력해 주세요."
																{...register(
																	'weeklySalaryFailForKLPGA',
																	{
																		required: true,
																		onChange: onKeyupPrice(
																			'weeklySalaryFailForKLPGA',
																		),
																	},
																)}
															/>
															원
														</div>
													</div>
												</div>
											</div>
										)}

										{watch('isWeekly') === 'true' && (
											<p className="regInfoText">
												※ 컷통과, 컷탈락에 같은 금액을 설정하고 싶으면
												동일한 금액을 입력해 주세요.
											</p>
										)}
									</div>

									<p className="form_title">인센티브 방식</p>
									<div className="row cmb-30">
										<div className="col">
											<input
												type="radio"
												className="btn-check"
												name="radioTax2"
												id="rgTour_incentiveTax_1"
												autoComplete="off"
												value="true"
												{...register('isIncentiveTaxIncluded')}
											/>
											<label
												className="btn btn-light btn-whiteShadow"
												htmlFor="rgTour_incentiveTax_1">
												상금 표시액
											</label>
										</div>
										<div className="col">
											<input
												type="radio"
												className="btn-check"
												name="radioTax2"
												id="rgTour_incentiveTax_2"
												autoComplete="off"
												value="false"
												{...register('isIncentiveTaxIncluded')}
											/>
											<label
												className="btn btn-light btn-whiteShadow"
												htmlFor="rgTour_incentiveTax_2">
												상금 실수령액
											</label>
										</div>
									</div>

									<p className="form_title">인센티브</p>
									<div className="row" id="incenBox">
										{fields.map((field, index) => {
											return (
												<div className="col-12 cmb-10" key={field?.id}>
													<div className="row flex-nowrap align-items-center">
														<div className="col-1 fontSize-16 font-SB00 text-end incenNum">
															{index + 1}
														</div>
														<div className="col fontSize-16 font-SB00 text-end">
															<div
																className="row justify-content-end pb-2"
																style={{
																	display:
																		index === 0
																			? 'none'
																			: 'auto',
																	flexWrap: 'nowrap',
																}}>
																<input
																	type="number"
																	className="border-0 border-bottom no-focus text-end p-0"
																	placeholder="등수"
																	style={{
																		width: '40px',
																	}}
																	{...register(
																		`incentives.${index}.startRank`,
																		{
																			required: true,
																			valueAsNumber: true,
																			min: 0,
																		},
																	)}
																/>
																~
																<input
																	type="number"
																	className="border-0 border-bottom no-focus text-end p-0"
																	placeholder="등수"
																	style={{
																		display:
																			field?.endRank === 0
																				? 'none'
																				: 'auto',
																		width: '40px',
																	}}
																	{...register(
																		`incentives.${index}.endRank`,
																		{
																			required: true,
																			valueAsNumber: true,
																			min: 0,
																		},
																	)}
																/>
																{field?.endRank === 0 && ' CUT'}
															</div>
															{index === 0 && '우승'}
														</div>
														<div className="col-md-4 col-3 d-flex">
															<div className="incentiveItemInput underbarInput">
																<input
																	type="number"
																	id="incen_win"
																	className="rgPro003_noBorderInput rgPro003_incentiveInput p-right"
																	key={field?.id}
																	placeholder="0"
																	{...register(
																		`incentives.${index}.percentage`,
																		{
																			required: true,
																			valueAsNumber: true,
																			min:
																				field?.endRank === 0
																					? 0
																					: 1,
																			max: 100,
																		},
																	)}
																/>
																%
															</div>
														</div>

														<div className="col-auto ps-0">
															<button
																type="button"
																className="btn btn-danger mb-2 onlyPc"
																style={{
																	visibility:
																		index === 0 ||
																		index === fields?.length - 1
																			? 'hidden'
																			: 'auto',
																}}
																onClick={removeIncentive(index)}>
																삭제
															</button>
															<button
																type="button"
																className="btn btn-danger mb-2 onlyMobile"
																style={{
																	padding: '0.4rem',
																	lineHeight: '0.8',
																	visibility:
																		index === 0 ||
																		index === fields?.length - 1
																			? 'hidden'
																			: 'auto',
																}}
																onClick={removeIncentive(index)}>
																X
															</button>
														</div>
													</div>
												</div>
											)
										})}
									</div>

									<button
										type="button"
										className="btn btn-light rgPro003_addIncentive"
										disabled={fields?.length >= 9}
										onClick={addIncentive(fields?.length - 1)}>
										+ 추가하기
									</button>

									<p className="form_title">업무 외 비용</p>
									<div className="row align-items-center cmb-10">
										<div className="col-auto regNWExpensesText text-end">
											제주대회 출전 시
										</div>
										{expenseReimbursementJejuVisible && (
											<div className="col">
												<div className="d-flex justify-content-end">
													<div className="col justify-content-end regUnderbarInput">
														<input
															className="border-0 no-focus text-end"
															id="expenseReimbursementJeju"
															placeholder="희망 금액을 입력해 주세요."
															{...register(
																'expenseReimbursementJeju',
																{
																	required:
																		expenseReimbursementJejuVisible,
																	onChange: onKeyupPrice(
																		'expenseReimbursementJeju',
																	),
																},
															)}
														/>
														원
													</div>
												</div>
											</div>
										)}
									</div>
									<div className="row cmb-30">
										<div className="col">
											<input
												type="radio"
												className="btn-check"
												id="rgTour_jeju_1"
												autoComplete="off"
												checked={expenseReimbursementJejuVisible}
												onClick={onClickExpenseReimbursementJeju(true)}
											/>
											<label
												className="btn btn-light btn-whiteShadow"
												htmlFor="rgTour_jeju_1">
												지원
											</label>
										</div>
										<div className="col">
											<input
												type="radio"
												className="btn-check"
												id="rgTour_jeju_2"
												autoComplete="off"
												checked={!expenseReimbursementJejuVisible}
												onClick={onClickExpenseReimbursementJeju(false)}
											/>
											<label
												className="btn btn-light btn-whiteShadow"
												htmlFor="rgTour_jeju_2">
												미지원
											</label>
										</div>
									</div>

									<p className="form_title">구단</p>
									<div className="row cmb-30">
										<div className="col">
											<div className="d-flex justify-content-between align-items-center border-bottom">
												<select
													id="rgTour_clubName"
													className="form-select underbarSelect"
													placeholder="구단에 가입되어 있으면 추가해 주세요."
													{...register('club', {
														onChange:
															changeStyleColor(
																'var(--greyish-brown)',
															),
													})}>
													{clubs?.map((club, index) => (
														<option
															key={club?.id}
															value={club?.id}
															className="text-greyish-brown">
															{club?.name}
														</option>
													))}
												</select>
											</div>
										</div>
									</div>

									<p className="form_title">선수 스폰서 모자 착용 가능 여부</p>
									<div className="row cmb-30">
										<div className="col">
											<input
												id="rgTour_wearing"
												type="radio"
												className="btn-check"
												autoComplete="off"
												value="true"
												{...register('wearSponsoredHat')}
											/>
											<label
												className="btn btn-light btn-whiteShadow"
												htmlFor="rgTour_wearing">
												착용
											</label>
										</div>
										<div className="col">
											<input
												id="rgTour_notWorn"
												type="radio"
												className="btn-check"
												autoComplete="off"
												value="false"
												{...register('wearSponsoredHat')}
											/>
											<label
												className="btn btn-light btn-whiteShadow"
												htmlFor="rgTour_notWorn">
												미착용
											</label>
										</div>
									</div>

									<div className="row align-items-center cmb-30">
										<div className="col-auto w-100" style={{ height: '35px' }}>
											<span className="form_title">근무 시작 년도</span>
										</div>
										<select
											name="year"
											id="yearStartWork"
											className="form-control underbarInput"
											style={{ paddingTop: '11px' }}
											{...register('employmentYear')}>
											{years.map((year) => (
												<option value={year.toString()}>{year}</option>
											))}
										</select>
									</div>

									<button
										type="submit"
										className="btn btn-dark nextPageButton"
										id="rgTour_submitBtn">
										저장하기
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>

			<MessageModal
				modalVisible={messageModalVisible}
				content={messageModalContent}
				onClose={handleMessageModalClose}
			/>
		</div>
	)
}
