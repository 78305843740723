import { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { downloadPDF, useAuthStatus } from '../../../utils'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { SingleContractDocs, LongTermContractDocs, YearContractDocs } from '../../../components'

export const ContractDetailView = ({ API_ENDPOINT, ...props }) => {
	const [auth, onChangeAuthStatus] = useAuthStatus()

	const { contractId } = useParams()

	const [contract, setContract] = useState(null)
	const [contractIncentives, setContractIncentives] = useState([])

	const [serviceConfig, setServiceConfig] = useState({})

	useEffect(() => {
		Promise.all([
			fetchContract(),
			fetchContractIncentives(contractId),
			fetchProServiceFees(),
			fetchSettingContract(),
		]).then(([contractInfo, incentives, proServiceFees, settingConfig]) => {
			const { contract, targetUser, user, tour } = contractInfo

			const users = [targetUser, user]
			const pro = users?.find((user) => ['tourPro', 'agency'].includes(user?.user_type))
			const caddie = users?.find((user) =>
				['tourCaddie', 'houseCaddie'].includes(user?.user_type),
			)

			setContract({ ...contract, pro, caddie, tour })
			setContractIncentives(incentives)
			setServiceConfig({ proServiceFees, ...settingConfig })
		})
	}, [auth])

	const fetchContract = async () => {
		return await fetch(`${API_ENDPOINT}/contract/detail/${contractId}`)
			.then(async (response) => {
				const result = await response.json()

				if (result?.success === false) throw result

				return result
			})
			.catch((errors) => {
				console.log(errors)
			})
	}

	const fetchContractIncentives = async (contractId) => {
		return await fetch(`${API_ENDPOINT}/contract/contract/detail/incentive/${contractId}`)
			.then(async (response) => {
				const result = await response.json()

				if (result?.success === false) throw result

				result?.sort((a, b) => {
					return a?.startRank - b?.startRank
				})

				return result
			})
			.catch((errors) => {
				console.log(errors)
			})
	}

	const fetchProServiceFees = async () => {
		return await fetch(`${API_ENDPOINT}/admin/payment-manage/pro/incentive`)
			.then(async (response) => {
				const result = await response.json()

				if (result?.success === false) throw result

				result?.sort((a, b) => {
					return a?.startRank - b?.startRank
				})

				return result
			})
			.catch((errors) => console.log(errors))
	}

	const fetchSettingContract = async () => {
		return await fetch(`${API_ENDPOINT}/admin/payment-manage/admin/setting`)
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) throw result

				return result
			})
			.catch((errors) => console.log(errors))
	}

	const onClickDownloadBtn = async (event) => {
		event.preventDefault()

		await downloadPDF(contractId, document)
	}

	return (
		<div className="wrap">
			<div className="content_area contract_page">
				<div className="container">
					<Card>
						<Card.Body>
							<p className="mainTitle">용역제공계약서</p>

							{contract?.contract_type === 'single' && (
								<SingleContractDocs
									contract={contract}
									contractIncentives={contractIncentives}
									serviceConfig={serviceConfig}
								/>
							)}

							{contract?.contract_type === 'long_term' && (
								<LongTermContractDocs
									contract={contract}
									contractIncentives={contractIncentives}
									serviceConfig={serviceConfig}
								/>
							)}

							{['year_first_half', 'year_second_half'].includes(
								contract?.contract_type,
							) && (
								<YearContractDocs
									contract={contract}
									contractIncentives={contractIncentives}
									serviceConfig={serviceConfig}
								/>
							)}

							<p className="text-end mb-5">
								{dayjs(contract?.contracted_at).format('YYYY년 MM월 DD일')}
							</p>

							<p className="title">'선수'</p>
							<div style={{ position: 'relative', width: 'fit-content' }}>
								<p className="mb-3">
									이름: <span>{contract?.pro?.name}</span> (인)
								</p>
								<div
									style={{
										position: 'absolute',
										top: '-1.5rem',
										right: '-1.5rem',
										width: '4rem',
										height: '4rem',
									}}>
									<img src="/images/sign.png" style={{ width: '100%' }} />
								</div>
							</div>
							<p className="mb-3">주소:</p>
							<p className="mb-3">주민등록번호:</p>
							<p className="mb-5">
								연락처:{' '}
								{contract?.pro?.phone.replace(
									/^(\d{0,3})(\d{0,4})(\d{0,4})$/g,
									'$1-$2-$3',
								)}
							</p>

							<p className="title">'캐디'</p>
							<div style={{ position: 'relative', width: 'fit-content' }}>
								<p className="mb-3">
									이름: <span>{contract?.caddie?.name}</span> (인)
								</p>
								<div
									style={{
										position: 'absolute',
										top: '-1.5rem',
										right: '-1.5rem',
										width: '4rem',
										height: '4rem',
									}}>
									<img src="/images/sign.png" style={{ width: '100%' }} />
								</div>
							</div>
							<p className="mb-3">주소:</p>
							<p className="mb-3">주민등록번호:</p>
							<p className="mb-3">
								연락처:{' '}
								{contract?.caddie?.phone.replace(
									/^(\d{0,3})(\d{0,4})(\d{0,4})$/g,
									'$1-$2-$3',
								)}
							</p>
						</Card.Body>
					</Card>
				</div>
				<div
					style={{
						position: 'fixed',
						bottom: '0',
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
					}}
					data-html2canvas-ignore="true">
					<button
						className="btn btn-success"
						style={{ marginBottom: '1rem', fontSize: '1.5rem', padding: '1rem 5rem' }}
						onClick={onClickDownloadBtn}>
						저장
					</button>
				</div>
			</div>
		</div>
	)
}
