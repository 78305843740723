import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'

export const printPdf = (htmlDocument) => {
	const printWindow = window.open('', '', 'height=400,width=800')

	console.log(htmlDocument)
	printWindow?.document.write(htmlDocument)
	printWindow?.document.close()
	printWindow?.print()

	if (printWindow !== null) {
		// 인쇄 미리보기 창이 닫힌 후에  printwindow창 닫기
		printWindow.onload = function () {
			printWindow.close()
		}
	}
}

export const downloadPDF = async (contractId, document) => {
	// html to imageFile
	const paper = document.querySelector('.container > .card')

	const canvas = await html2canvas(paper, {
		allowTaint: true,
		useCORS: true,
		logging: false,
		scale: 2,
	})
	const imageFile = canvas.toDataURL('image/png', 1.0)
	const imgWidth = 210 // 이미지 가로 길이(mm) / A4 기준 210mm
	const imgHeight = (canvas.height * imgWidth) / canvas.width

	const doc = new jsPDF('p', 'mm', 'a4', true)
	const pageHeight = doc.internal.pageSize.getHeight()

	doc.addImage(imageFile, 'JPEG', 0, 0, imgWidth, imgHeight, undefined, 'FAST')

	let position = 0
	let leftHeight = imgHeight

	while (leftHeight >= 20) {
		position = leftHeight - imgHeight
		doc.addPage()
		doc.addImage(imageFile, 'JPEG', 0, position, imgWidth, imgHeight, undefined, 'FAST')
		leftHeight -= pageHeight
	}

	doc.save(`${contractId}.pdf`)
}
