import React, { useEffect, useState } from 'react'
import { Calendar, ScheduleInfo } from '../../components'
import {
	contractTypes,
	FETCH_HEADER_OPTIONS,
	getMatchTypeOptions,
	isEmpty,
	useAuthStatus,
} from '../../utils'
import dayjs from 'dayjs'

export const ScheduleCheckView = ({ API_ENDPOINT, ...props }) => {
	const [auth, onChangeAuthStatus] = useAuthStatus()

	const [contracts, setContracts] = useState([])
	const [selectedContract, setSelectedContract] = useState(null)
	const [contractIncentives, setContractIncentives] = useState([])
	const [contractEvents, setContractEvents] = useState([])
	const [serviceConfig, setServiceConfig] = useState({})

	useEffect(() => {
		fetchContracts()
		fetchSettingContract()
	}, [auth])

	const fetchContracts = async () => {
		await fetch(`${API_ENDPOINT}/contract/contract/${auth?.user?.id}`).then(
			async (response) => {
				const result = await response.json()

				if (result.success === false) {
					throw result
				}

				const color = (type) => {
					if (type === 'KPGA') return '#6495ed'
					else if (type === 'KLPGA') return '#ecdae3'
					else return '#aeaeae'
				}

				const events = result
					?.filter(
						(item) =>
							item?.contract?.contract_status === 'PROCEEDING' ||
							item?.contract?.contract_status === 'CANCEL_REQUEST',
					)
					?.map((item) => {
						if (['single', 'long_term'].includes(item?.contract?.contract_type)) {
							const startedAt = item?.tour?.started_at?.substring(0, 10)
							const endedAt = item?.tour?.ended_at?.substring(0, 10)

							return {
								id: item?.contract?.id,
								seq: item?.contract?.id,
								title: item?.tour?.name,
								start: dayjs(startedAt).format('YYYY-MM-DD'),
								end: dayjs(endedAt).add(1, 'days').format('YYYY-MM-DD'),
								purse: item?.purse,
								color: color(item?.tour?.type),
							}
						} else {
							return {
								id: item?.contract?.id,
								seq: item?.contract?.id,
								title: getMatchTypeOptions(
									contractTypes,
									item?.contract?.contract_type,
								),
								start: dayjs(item?.contracted_at).format('YYYY-MM-DD'),
								end:
									item?.contract?.contract_type === 'year_first_half'
										? dayjs(serviceConfig?.yearDate)
												.add(1, 'days')
												.format('YYYY-MM-DD')
										: dayjs(new Date(new Date().getFullYear(), 11, 31))
												.add(1, 'days')
												.format('YYYY년 MM월 DD일'),
								color: color(item?.tour?.type),
								allDay: true,
							}
						}
					})

				setContracts(result)
				setContractEvents(events)
			},
		)
	}

	const fetchContract = async (id) => {
		await fetch(`${API_ENDPOINT}/contract/detail/${id}`).then(async (response) => {
			const result = await response.json()

			if (result.success === false) {
				throw result
			}

			await fetchContractIncentives(id)

			setSelectedContract(result)
		})
	}

	const fetchContractIncentives = async (contractId) => {
		await fetch(`${API_ENDPOINT}/contract/contract/detail/incentive/${contractId}`)
			.then(async (response) => {
				const result = await response.json()

				setContractIncentives(result)
			})
			.catch((errors) => {
				console.log(errors)
			})
	}

	const cancelContract = async (contractId, updatedStatus) => {
		await fetch(
			`${API_ENDPOINT}/contract/contract/rejactResponse/${contractId}/${updatedStatus}`,
			{
				method: 'PATCH',
				headers: FETCH_HEADER_OPTIONS,
			},
		)
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) {
					throw new result()
				}

				await fetchContracts()

				setSelectedContract({})
			})
			.catch((errors) => {
				console.log(errors)
			})
	}

	const cancelRequestContract = async (contractId, updatedStatus) => {
		await fetch(
			`${API_ENDPOINT}/contract/contract/rejact/${contractId}/${updatedStatus}/${auth?.user?.id}`,
			{
				method: 'PATCH',
				headers: FETCH_HEADER_OPTIONS,
			},
		)
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) {
					throw new Error(result.message)
				}

				await fetchContract(result?.id)
			})
			.catch((errors) => {
				console.log(errors)
			})
	}

	const fetchSettingContract = async () => {
		return await fetch(`${API_ENDPOINT}/admin/payment-manage/admin/setting`)
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) throw result

				setServiceConfig(result)
			})
			.catch((errors) => console.log(errors))
	}

	const onClickEvent = async (info) => {
		const selectedEventId = info?.event?.id

		await fetchContract(selectedEventId)
	}

	return (
		<div className="wrap form_page">
			<div className="content_area">
				<div className="bgWrap">
					<div className="container">
						<div className="row cmb-20">
							<div className="col-auto col-md-6">
								<div className="col-auto fontSize-30 cmb-10">
									{auth?.user?.name || '-'} 님의 일정관리
								</div>
								<div className="col-auto fontSize-20 color-greyish-two cmb-10">
									{dayjs().format('YYYY-MM-DD')}
								</div>
							</div>
						</div>
						<div className="container indexScheduleBox">
							<div className="row indexScheduleCardRow">
								<div className="col-12 col-md-6">
									<div className="cmb-30 h-100">
										<div className="card card-boxShadow h-100">
											<div className="card-body">
												<Calendar
													id="scheduleCalendar"
													events={contractEvents}
													onClickEvent={onClickEvent}
												/>
											</div>
										</div>
									</div>

									{/*<div className="card ">*/}
									{/*    <div className="card-body">*/}
									{/*        <p className="color-greyish-two fontSize-25 font-L00">최근 나의 성적 | <span className="color-spruce text-nowrap">8차 카이도시리즈 카이도 투어챔피언십 with 솔모로 CC </span></p>*/}
									{/*    </div>*/}
									{/*</div>*/}
								</div>

								{/*대회 일정 박스 일정 눌렀을땐 감춰짐*/}
								{/*<div className="col-12 col-md-6"></div>*/}

								<div className="col-12 col-md-6" id="contractInfo">
									{isEmpty(selectedContract) ? (
										<div className="card">
											<div className="card-body">
												<p className="fontSize-20 color-greyish-brown cmd-30">
													계약된 대회가 없습니다.
												</p>
											</div>
										</div>
									) : (
										<ScheduleInfo
											serviceConfig={serviceConfig}
											userId={auth?.user?.id}
											userType={auth?.user?.userType}
											contract={selectedContract}
											incentives={contractIncentives}
											cancelRequestContract={cancelRequestContract}
											cancelContract={cancelContract}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
