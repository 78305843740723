import React from 'react'
import Modal from 'react-bootstrap/Modal'

export const ModalWithDrawMember = ({ modalVisible, destroyUser, onClose, ...props }) => {
	const onWithDrawUser = async (event) => {
		await destroyUser()
		onClose()
	}

	return (
		<Modal
			id="withdrawBtnModal"
			backdrop="static"
			centered={true}
			tabIndex="-1"
			aria-labelledby="withdrawBtnModalLabel"
			aria-hidden="true"
			show={modalVisible}
			onHide={onClose}>
			<Modal.Header className="border-0">
				<p
					className="modal-title w-100 text-center"
					id="staticBackdropLabel"
					style={{ fontWeight: 'bold' }}>
					회원 탈퇴
				</p>
				<button type="button" className="btn-close" aria-label="Close" onClick={onClose} />
			</Modal.Header>
			<Modal.Body>
				<div className="cmb-30">
					<p className="cmb-10 text-center">
						지금까지 스포이음을
						<span className="text-nowrap">이용해 주셔서 감사드립니다.</span>
					</p>
					<p className="cmb-10 text-center">
						스포이음은 고객님의
						<span className="text-nowrap">소중한 개인정보에 대해</span>
					</p>
					<p className="cmb-10 text-center">관계법령의 의거하여 처리하고 있으며,</p>
					<p className="cmb-10 text-center">
						탈퇴한 고객님의 정보 역시
						<span className="text-nowrap">이에 따라 조치하고 있습니다.</span>
					</p>
					<p className="cmb-10 text-center">
						탈퇴시 고객님의 정보는 삭제되며, 복구할 수 없습니다.
					</p>
				</div>

				<button
					type="button"
					className="btn btn-dark nextPageButton idDelBtn"
					onClick={onWithDrawUser}>
					탈퇴하기
				</button>
			</Modal.Body>
		</Modal>
	)
}
