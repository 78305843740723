import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'

export const ModalAcceptProposal = ({ modalVisible, acceptContract, onClose, ...props }) => {
	const [agreeChecked, setAgreeChecked] = useState(false)

	const onClickAgreeCheck = (event) => {
		setAgreeChecked(event.target.checked)
	}

	return (
		<Modal
			id="acceptProposal"
			show={modalVisible}
			size="lg"
			backdrop="static"
			tabIndex={-1}
			centered
			aria-labelledby="acceptProposal"
			aria-hidden="true"
			className="wrap register_page form_page re_form">
			<Modal.Header class="border-0">
				<p className="modal-title w-100 text-center font-bold" id="staticBackdropLabel">
					제안 수락
				</p>
				<button type="button" className="btn-close" aria-label="Close" onClick={onClose} />
			</Modal.Header>
			<Modal.Body>
				<div className="request_checkbox_outer">
					<input
						type="checkbox"
						id="accept_agree"
						checked={agreeChecked}
						onClick={onClickAgreeCheck}
						className="d-none request_checkbox"
					/>
					<label htmlFor="accept_agree" className="w-100">
						<div className="row align-items-center cmb-20">
							<div className="col cmb-10">
								<p
									className="fontSize-28 font-L00 color-greyish-brown me-2"
									style={{ wordBreak: 'keep-all' }}>
									상대 정보와 계약제안서를 모두 확인했으며,
								</p>
								<p className="fontSize-28 font-M00 color-greyish-brown">
									계약내용에 동의합니다.
								</p>
							</div>
							<div className="col-auto cmb-10">
								<div className="request_checkbox_img" />
							</div>
						</div>
					</label>

					<div className="fontSize-20 font-L00 color-greyish-brown cmb-30">
						<p>계약 완료 즉시 계약효과가 발휘 됩니다.</p>
						<p>
							이에 따라 추후 일방적 파기시 환불정책에 따른 위약금이 발생할 수
							있습니다.
						</p>
						<p>진행하시겠습니까?</p>
					</div>

					<div className="row">
						<div className="col-12 col-md-6">
							<button
								type="button"
								className="btn btn-danger pb-4 pt-4 w-100 cmb-10"
								onClick={onClose}>
								취소
							</button>
						</div>
						<div className="col-12 col-md-6">
							<button
								id="noticeaccept"
								className="btn btn-dark pb-4 pt-4 w-100 cmb-10"
								disabled={!agreeChecked}
								onClick={acceptContract('PROCEEDING')}>
								확인
							</button>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
}
