import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import dayjs from 'dayjs'
import { formattingPrice } from '../../utils'

export const ModalContractTourList = ({ modalVisible, tours = [], onClose, ...props }) => {
	tours?.sort((a, b) => {
		return new Date(a?.started_at) - new Date(b?.started_at)
	})

	return (
		<Modal
			id="tourListModal"
			backdrop="static"
			centered={true}
			tabIndex="-1"
			aria-labelledby="tourListModal"
			aria-hidden="true"
			className="modal-dialog-centered"
			show={modalVisible}
			onHide={onClose}>
			<Modal.Header className="border-0">
				<p
					id="staticBackdropLabel"
					className="modal-title w-100 fontSize-25 color-greyish-brown font-M00 text-center">
					대회 목록
				</p>

				<button type="button" className="btn-close" onClick={onClose} />
			</Modal.Header>

			<Modal.Body className="text-center">
				{tours?.map((item) => (
					<div className={`row cmb-10 challengecheckedBox kind_${item?.type}`}>
						<div className="col">
							<div className="d-flex">
								<div className="indexScheduleBoardDateLeftBar"></div>
								<p className="indexScheduleBoardDate">
									{dayjs(item?.started_at).format('YYYY-MM-DD')} ~
									{dayjs(item?.ended_at).format('YYYY-MM-DD')}
								</p>
							</div>
							<p className="indexScheduleBoardTitle">{item?.name}</p>
							<p className="indexScheduleSubtitle">
								총상금 {formattingPrice(item?.purse)} 원
							</p>
						</div>
					</div>
				))}

				<button
					type="button"
					className="btn btn-dark pb-2 pt-2 w-100 registerNextButton"
					onClick={onClose}>
					확인
				</button>
			</Modal.Body>
		</Modal>
	)
}
