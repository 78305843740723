import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Modal from 'react-bootstrap/Modal'
import {
	changeStyleColor,
	convertURLtoFile,
	FETCH_HEADER_OPTIONS,
	getFileExt,
	SignIn,
	uploadS3,
	useAuthStatus,
} from '../../utils'
import { MessageModal } from '../../components'
import { ModalLoginFail } from '../../components/modal'

export const RegisterTourProView = ({ API_ENDPOINT, ...props }) => {
	const [auth, onChangeAuthStatus] = useAuthStatus()

	const navigate = useNavigate()

	const [sponsors, setSponsors] = useState([])
	const [agencies, setAgencies] = useState([])

	const { register, getValues, setValue, watch, setFocus, handleSubmit } = useForm({
		mode: 'onBlur',
	})

	useEffect(() => {
		if (auth.isAuthentication) {
			navigate('/')
		}

		Promise.all([fetchSponsors(), fetchAgencies()])
	}, [auth])

	const onSubmit = async (data) => {
		// input format
		// {
		// 	"name": "string",
		// 	"firstName": "string",
		// 	"lastName": "string",
		// 	"email": "string",
		// 	"password": "string",
		// 	"phone": "string",
		// 	"notification": true,
		// 	"userType": "tourPro",
		// 	"tourType": "KPGA",
		// 	"fullMembershipNumber": "string",
		// 	"mainSponsor": "string",
		// 	"agencyName": "string",
		// 	"businessLicense": {}
		// }

		const { profileImg, ...basicFields } = JSON.parse(localStorage.getItem('basicInfo'))
		const { businessLicense, ...additionalFields } = data

		const convertedProfile = await convertURLtoFile(profileImg)
		!!businessLicense &&
			(await uploadS3(data?.fullMembershipNumber, 'business', businessLicense))

		const input = {
			userType: 'tourPro',
			profileType: !!convertedProfile ? getFileExt(convertedProfile) : null,
			businessLicence: data?.fullMembershipNumber + '.pdf',
			...basicFields,
			...additionalFields,
		}

		await fetch(`${API_ENDPOINT}/users/player`, {
			method: 'POST',
			headers: FETCH_HEADER_OPTIONS,
			body: JSON.stringify(input),
		})
			.then(async (response) => {
				const result = await response.json()

				await uploadS3(result?.user_id, 'profile', convertedProfile)

				localStorage.clear()

				await SignIn({ email: input?.email, password: input?.password })
					.then((userInfo) => {
						onChangeAuthStatus(JSON.stringify(userInfo))
						navigate('/matching')
					})
					.catch((errors) => {
						handleLoginFailModalOpen()
					})
			})
			.catch((errors) => {
				console.log(errors)
			})
	}

	const onInvalid = (errors) => {
		const firstError = Object.keys(errors).reduce((field, a) => {
			return !!errors[field] ? field : a
		}, null)
		setFocus(firstError)

		if (firstError === 'tourType') {
			handleMessageModalOpen('투어를 선택해주세요.')
		} else if (firstError === 'fullMembershipNumber') {
			handleMessageModalOpen('정회원 번호를 입력해주세요.')
		}
	}

	const fetchSponsors = async () => {
		await fetch(`${API_ENDPOINT}/admin/db-manage/sponsor`)
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) {
					throw new Error('Fail fetch')
				} else {
					setSponsors(result)
				}
			})
			.catch((errors) => {
				setSponsors([])
			})
	}

	const fetchAgencies = async () => {
		await fetch(`${API_ENDPOINT}/admin/db-manage/agency`)
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) {
					throw new Error('Fail fetch')
				} else {
					setAgencies(result)
				}
			})
			.catch((errors) => {
				setAgencies([])
			})
	}

	const onChangeTourType = (type) => {
		setValue('tourType', type)
		handleFullMembershipNumberModalOpen()
	}

	// modal
	const [messageModalVisible, setMessageModalVisible] = useState(false)
	const [messageModalContent, setMessageModalContent] = useState('')
	const [loginFailModalVisible, setLoginFailModal] = useState(false)

	const [fullMembershipNumberModalVisible, setFullMembershipNumberModalVisible] = useState(false)
	const [businessLicenseModalVisible, setBusinessLicenseModalVisible] = useState(false)

	const handleMessageModalOpen = (message) => {
		setMessageModalContent(message)
		setMessageModalVisible(true)
	}

	const handleMessageModalClose = () => {
		setMessageModalContent('')
		setMessageModalVisible(false)
	}

	const onClickMessageModalOk = () => navigate('/login')

	const handleLoginFailModalOpen = () => setLoginFailModal(true)

	const handleLoginFailModalClose = () => setLoginFailModal(false)

	const handleFullMembershipNumberModalOpen = () => setFullMembershipNumberModalVisible(true)
	const handleFullMembershipNumberModalClose = () => setFullMembershipNumberModalVisible(false)

	const handleBusinessLicenseModalOpen = () => setBusinessLicenseModalVisible(true)
	const handleBusinessLicenseModalClose = () => setBusinessLicenseModalVisible(false)

	return (
		<div className="wrap register_page form_page">
			<div className="content_area">
				<div className="bgWrap rg002_bgWrap">
					<form
						className="needs-validation"
						id="register002"
						noValidate
						onSubmit={handleSubmit(onSubmit, onInvalid)}>
						<div
							className="container register002 registerPro002"
							id="rgPro002Container"
							style={{ display: 'block' }}>
							<p className="form_topTitle">회원가입</p>
							<div className="card">
								<div className="card-body">
									<p className="rgPro002_title">*투어 선택</p>
									<div className="row rgProCheckTourBox">
										<div className="col">
											<input
												type="radio"
												className="btn-check"
												id="rgPro002_radio1"
												value="KPGA"
												autoComplete="off"
												{...register('tourType', {
													required: true,
												})}
											/>
											<label
												className="rgPro002_radio"
												htmlFor="rgPro002_radio1"
												style={{
													borderColor:
														watch('tourType') === 'KPGA'
															? 'var(--spruce)'
															: 'var(--white-four)',
												}}
												// onClick={() => onChangeTourType('KPGA')}
											>
												<img
													src={`/images/register-pro-002-kpgalogo.png`}
													alt="KPGA"
												/>
											</label>
										</div>
										<div className="col">
											<input
												type="radio"
												className="btn-check"
												id="rgPro002_radio2"
												value="KLPGA"
												autoComplete="off"
												{...register('tourType', {
													required: true,
												})}
											/>
											<label
												className="rgPro002_radio"
												htmlFor="rgPro002_radio2"
												style={{
													borderColor:
														watch('tourType') === 'KLPGA'
															? 'var(--spruce)'
															: 'var(--white-four)',
												}}
												// onClick={() => onChangeTourType('KLPGA')}
											>
												<img
													src={`/images/register-pro-002-klpgalogo.png`}
													alt="KLPGA"
												/>
											</label>
										</div>
									</div>
									<p className="rgPro002_title">*정회원 번호</p>
									<input
										id="rgPro002_userNumber"
										type="text"
										className="form-control underbarInput mb-4"
										placeholder="정회원 번호를 입력해주세요."
										{...register('fullMembershipNumber', { required: true })}
									/>

									<div className="row mb-4">
										<div className="col-12 col-md-12">
											<p>메인스폰서</p>
											<select
												className="form-select underbarSelect"
												id="rgPro002_mainSponsor"
												name="mainSponsor"
												{...register('mainSponsor', {
													onChange:
														changeStyleColor('var(--greyish-brown)'),
												})}>
												<option value={''} disabled hidden selected>
													선택해 주세요.
												</option>
												{sponsors.map((sponsor) => (
													<option
														key={sponsor.id}
														value={sponsor.name}
														className="text-greyish-brown">
														{sponsor.name}
													</option>
												))}
											</select>
										</div>
									</div>
									<div className="row mb-4">
										<div className="col-12 col-md-12">
											<p>에이전시</p>
											<select
												className="form-select underbarSelect"
												id="rgPro002_agency"
												name="agency"
												placeholder=""
												{...register('agencyName')}
												onChange={changeStyleColor('var(--greyish-brown)')}>
												<option value={''} disabled hidden selected>
													선택해 주세요.
												</option>
												{agencies.map((agency) => (
													<option
														key={agency.id}
														value={agency.name}
														className="text-greyish-brown">
														{agency.name}
													</option>
												))}
											</select>
										</div>
									</div>
									<div className="rgPro002_title d-flex align-items-center cmb-30">
										사업자 등록증
										<button
											type="button"
											className="btn btn-light rgPro002_addCBPR"
											id="rgPro002_addCBPR"
											onClick={handleBusinessLicenseModalOpen}>
											등록하기
										</button>
										<input
											type="hidden"
											name="rgPro002_file_SBPR"
											{...register('businessLicense')}
										/>
									</div>
									<div className="col-12">
										<button
											type="submit"
											className="btn btn-dark registerNextButton"
											id="rgPro002_nextButton">
											다음 단계로
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>

			<ModalCheckUserNumber
				API_ENDPOINT={API_ENDPOINT}
				modalVisible={fullMembershipNumberModalVisible}
				tourType={getValues('tourType')}
				setUserNumber={setValue}
				onClose={handleFullMembershipNumberModalClose}
			/>

			<ModalAddSBPR
				API_ENDPOINT={API_ENDPOINT}
				modalVisible={businessLicenseModalVisible}
				setSBPR={setValue}
				onClose={handleBusinessLicenseModalClose}
			/>

			<MessageModal
				modalVisible={messageModalVisible}
				content={messageModalContent}
				onClose={handleMessageModalClose}
			/>

			<ModalLoginFail
				modalVisible={loginFailModalVisible}
				onClickOk={onClickMessageModalOk}
				onClose={handleLoginFailModalClose}
			/>
		</div>
	)
}

const ModalCheckUserNumber = ({
	API_ENDPOINT,
	modalVisible,
	tourType,
	setUserNumber,
	onClose,
	...props
}) => {
	const {
		register,
		getValues,
		setValue,
		reset,
		setError,
		clearErrors,
		handleSubmit,
		formState: { isSubmitting, isSubmitted, errors },
	} = useForm({ mode: 'onBlur' })

	const onSubmit = (data) => {
		setUserNumber('fullMembershipNumber', data.fullMembershipNumber)

		onCloseModal()
	}

	const onInvalid = (errors) => {
		if (!!errors.fullMembershipNumber) {
			handleMessageModalOpen(errors.fullMembershipNumber.message)
		} else if (!!errors.checkNum) {
			handleMessageModalOpen(errors.checkNum.message)
		}
	}

	const checkUserNumber = async () => {
		const fullMembershipNumber = getValues('fullMembershipNumber')

		const userName = JSON.parse(localStorage.getItem('basicInfo')).name
		console.log(userName)

		// TODO: check user number api
		await fetch(`http://54.180.165.138/common/auth/proMemberCheck.php`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: new URLSearchParams({
				memberArr: JSON.stringify([
					{ tour: tourType, name: userName, memnum: fullMembershipNumber },
				]),
			}),
		})
			.then(async (response) => {
				const result = await response.json()

				console.log(result)

				if (result.success === false) {
					throw new Error(result.message)
				}

				setValue('checkNum', true)
				clearErrors('fullMembershipNumber')
			})
			.catch((errors) => {
				console.log('errors', errors)

				setError('fullMembershipNumber', {
					type: 'custom',
					message: '해당하는 회원의 정보가 없습니다.',
				})
				handleMessageModalOpen('해당하는 회원의 정보가 없습니다.')
			})
	}

	const onCloseModal = () => {
		reset()
		onClose()
	}

	// modal
	const [messageModalVisible, setMessageModalVisible] = useState(false)
	const [messageModalContent, setMessageModalContent] = useState('')

	const handleMessageModalClose = () => {
		setMessageModalContent('')
		setMessageModalVisible(false)
	}
	const handleMessageModalOpen = (message) => {
		setMessageModalContent(message)
		setMessageModalVisible(true)
	}

	return (
		<React.Fragment>
			<Modal
				id="checkUserNumberModal"
				show={modalVisible}
				backdrop="static"
				tabIndex={-1}
				size="lg"
				aria-labelledby="checkUserNumberModalLabel"
				aria-hidden="true">
				<Modal.Header className="border-0">
					<p className="modal-title w-100" id="staticBackdropLabel">
						정회원 번호 확인
					</p>
					<button
						type="button"
						className="btn-close"
						data-bs-dismiss="modal"
						aria-label="Close"
						onClick={onCloseModal}
					/>
				</Modal.Header>
				<Modal.Body className="modal-body">
					<form onSubmit={handleSubmit(onSubmit, onInvalid)}>
						<p className="rgPro002_addSBPRLabel">정회원 번호</p>
						<div className="d-flex justify-content-between align-items-center mb-5">
							<input
								id="rgPro002_userNumberInput"
								type="number"
								className="form-control underbarInput"
								placeholder="정회원 번호를 입력해 주세요."
								{...register('fullMembershipNumber', {
									required: true,
								})}
							/>

							<input
								id="rg002_checkId"
								type="checkbox"
								className="d-none"
								{...register('checkNum', {
									value: false,
									validate: (checkNum) => {
										return checkNum || '정회원 번호를 확인해 주세요.'
									},
								})}
							/>

							<button
								type="button"
								className="btn btn-outline-dark rgPro002_checkUserNumberButton"
								onClick={checkUserNumber}>
								확인
							</button>
						</div>
						<button
							id="rgPro002_doneUserNumberButton"
							type="submit"
							className="btn btn-dark registerNextButton">
							적용하기
						</button>
					</form>
				</Modal.Body>
			</Modal>

			<MessageModal
				modalVisible={messageModalVisible}
				content={messageModalContent}
				onClose={handleMessageModalClose}
			/>
		</React.Fragment>
	)
}

const ModalAddSBPR = ({ API_ENDPOINT, modalVisible, setSBPR, onClose, ...props }) => {
	const inputFile = useRef(null)
	const [businessLicense, setBusinessLicense] = useState(null)

	const onSubmit = () => {
		// console.log(businessLicense)
		setSBPR('businessLicense', businessLicense)
		onClose()
	}

	const handleUploadedFile = (event) => {
		const file = event.target.files[0]

		setBusinessLicense(file)
	}

	return (
		<Modal
			id="addSBPRModal"
			show={modalVisible}
			backdrop="static"
			tabIndex={-1}
			size="lg"
			aria-labelledby="addSBPRModalLabel"
			aria-hidden="true">
			<Modal.Header className="border-0">
				<p className="modal-title w-100" id="staticBackdropLabel">
					사업자 등록증 등록하기
				</p>
				<button type="button" className="btn-close" aria-label="Close" onClick={onClose} />
			</Modal.Header>

			<Modal.Body className="modal-body">
				<p className="rgPro002_addSBPRLabel">사업자 등록증</p>
				<div className="d-flex justify-content-between align-items-center mb-5">
					<input
						type="file"
						className="form-control d-none"
						id="rgPro002_uploadFileInput"
						accept=".pdf"
						onChange={handleUploadedFile}
						ref={(el) => {
							inputFile.current = el
						}}
					/>
					<label className="form-label d-flex w-100" htmlFor="rgPro002_uploadFileInput">
						<input
							id="rgPro002_uploadTextInput"
							type="text"
							className="form-control underbarInput rgPro002_fileInput"
							placeholder="파일 찾아보기"
							readOnly
							value={!!businessLicense ? businessLicense.name : ''}
						/>
						<button
							type="button"
							className="btn btn-outline-dark"
							style={{ width: '8.125rem' }}
							onClick={() => {
								inputFile.current.click()
							}}>
							업로드
						</button>
					</label>
				</div>
				<button
					id="rgPro002_addCBPRButton"
					type="button"
					className="btn btn-dark registerNextButton"
					onClick={onSubmit}>
					등록하기
				</button>
			</Modal.Body>
		</Modal>
	)
}
