import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SponsorshipForm, MessageModal } from '../../components'
import { FETCH_HEADER_OPTIONS, uploadS3 } from '../../utils'

export const SponsorshipInquiryCreateView = ({ API_ENDPOINT, ...props }) => {
	const navigate = useNavigate()

	const [messageModalVisible, setMessageModalVisible] = useState(false)
	const [messageModalContent, setMessageModalContent] = useState('')

	const createSponsorship = async ({ business_license, ...data }) => {
		const businessLicenseFileName = business_license?.[0]?.name || ''

		const input = { ...data, business_license: businessLicenseFileName }

		await fetch(`${API_ENDPOINT}/admin/sponsor/create`, {
			method: 'POST',
			headers: FETCH_HEADER_OPTIONS,
			body: JSON.stringify(input),
		})
			.then(async (response) => {
				const result = await response.json()

				// await uploadS3(result?.id, 'business', business_license)

				handleMessageModalOpen('문의가 정상적으로 접수되었습니다.')
			})
			.catch((errors) => console.log(errors))
	}

	const onClickOk = (sponsorshipId) => {
		navigate(`/sponsorship/${sponsorshipId}`)
	}

	const handleMessageModalOpen = (message) => {
		setMessageModalContent(message)
		setMessageModalVisible(true)
	}

	const handleMessageModalClose = () => {
		setMessageModalContent('')
		setMessageModalVisible(false)
	}

	return (
		<div className="wrap traning_page form_page">
			<div className="content_area">
				<div className="container tpContainer">
					<p className="topTitleText">스폰서쉽 문의하기</p>
					<p className="topSubTitleText">
						스폰서십 소개글 입력영역 입니다. 적용될 내용을 알려주시면 반영하도록
						하겠습니다. 스폰서십 소개글 입력영역 입니다. 적용될 내용을 알려주 시면
						반영하도록 하겠습니다. 스폰서십 소개글 입력영역 입니다. 적용될 내용을
						알려주시면 반영하도록 하겠습니다.
					</p>

					<SponsorshipForm fields={{}} onSubmitHandle={createSponsorship} />
				</div>
			</div>

			<MessageModal
				modalVisible={messageModalVisible}
				content={messageModalContent}
				onClickOk={onClickOk}
				onClose={handleMessageModalClose}
			/>
		</div>
	)
}
