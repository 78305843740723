import { ModalPP, ModalTerm } from '../components/modal'

export const Footer = ({ ...props }) => {
	return (
		<footer className="footer">
			<section className="container">
				<div className="row footerRow1">
					<div className="col-12 col-md-10 order-2 order-md-2 onlyPc">
						<div className="d-flex align-items-center">
							<div className="footerLogoBox">
								<img src={`/images/main_001_bottomlogo.png`} alt="SPOEUM" />
							</div>
							<div>
								<p>
									경기도 성남시 분당구 대왕판교로 670, B동 1001호(삼평동,
									유스페이스2) (주)스포이음 | 대표이사 : 민관홍 | 개인정보보호
									책임자 : 현주영
								</p>
								<p>
									사업자등록번호 : 112-87-01910 | 통신판매업:
									2023-성남분당A-0811호
								</p>
								<p>Fax : 0504-024-6890 | E-Mail : master@spoeum.com</p>
								<p>Copyright ©Spoeum. 2021 ~ All rights reserved</p>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-6 order-2 order-md-2 onlyMobile">
						<div className="d-flex align-items-center">
							<div className="footerLogoBox">
								<img src={`/images/main_001_bottomlogo.png`} alt="SPOEUM" />
							</div>
							<div>
								<p className="fontSize-12">
									경기도 성남시 분당구 대왕판교로 670, B동 1001호(삼평동,
									유스페이스2) (주)스포이음 | 대표이사 : 민관홍 | 개인정보보호
									책임자 : 현주영
								</p>

								<p className="fontSize-12">사업자등록번호 : 112-87-01910</p>
								<p className="fontSize-12">통신판매업 : 2023-성남분당A-0811호</p>
								<p className="fontSize-12">Fax : 0504-323-6890</p>
								<p className="fontSize-12">E-Mail : master@spoeum.com</p>
								<p className="fontSize-12">
									Copyright ©Spoeum. 2021 ~ All rights reserved
								</p>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-2 order-1 order-md-2 mb-4">
						<div className="footerIconBox">
							<a
								target="_blank"
								rel="noreferrer"
								href="https://www.instagram.com/spoeum_official/">
								<img
									src={`/images/instagram_icn_1.png`}
									alt="instagram"
									className="footerIconInstagram"
								/>
							</a>
							<a
								target="_blank"
								rel="noreferrer"
								href="https://www.facebook.com/Spoeum-103402884963555">
								<img
									src={`/images/facebook_icn_1.png`}
									alt="facebook"
									className="footerIconFacebook"
								/>
							</a>
						</div>
					</div>
				</div>
				<div className="row footerRow2">
					<div className="col-auto">
						<button
							type="button"
							className="btn btn-link"
							data-bs-toggle="modal"
							data-bs-target="#termModal">
							<u>이용약관</u>
						</button>
					</div>
					<div className="col-auto">
						<button
							type="button"
							className="btn btn-link"
							data-bs-toggle="modal"
							data-bs-target="#ppModal">
							<u>개인정보처리방침</u>
						</button>
					</div>
				</div>
			</section>

			<ModalTerm />
			<ModalPP />
		</footer>
	)
}
