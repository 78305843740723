import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
	ModalAfterMove,
	ModalPasswordChange,
	ModalPhoneChange,
	ModalServiceAlert,
	ModalWithDrawMember,
} from '../../components/modal'
import {
	convertObjectPropertiesSnakeCaseToCamelCase,
	convertURLtoFile,
	DEFAULT_USER_SESSION_COOKIE_NAME,
	FETCH_HEADER_OPTIONS,
	getFileExt,
	removeCookie,
	uploadS3,
	useAuthStatus,
} from '../../utils'
import { MessageModal } from '../../components'

export const MyInfoView = ({ API_ENDPOINT, PROFILE_BUCKET, ...props }) => {
	const [auth, onChangeAuth] = useAuthStatus()

	const navigate = useNavigate()
	const { register, watch, getValues, setValue, handleSubmit } = useForm({ mode: 'onBlur' })

	const [previewProfileImg, setPreviewProfileImg] = useState(
		!!auth?.user?.profileType
			? PROFILE_BUCKET + auth?.user?.id + '.' + auth?.user?.profileType
			: null,
	)

	useEffect(() => {
		setValue('profileType', auth?.user?.profileType)
		setValue('name', auth?.user?.name)
		setValue('lastName', auth?.user?.lastName)
		setValue('firstName', auth?.user?.firstName)
		setValue('phone', auth?.user?.phone)

		setPreviewProfileImg(
			!!auth?.user?.profileType
				? PROFILE_BUCKET + auth?.user?.id + '.' + auth?.user?.profileType
				: null,
		)
	}, [auth])

	const fetchUserInfo = async () => {
		await fetch(`${API_ENDPOINT}/users/my-page/${auth?.user?.id}`)
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) throw result

				const userInfo = convertObjectPropertiesSnakeCaseToCamelCase(result)

				onChangeAuth(JSON.stringify(userInfo))
			})
			.catch((errors) => {
				console.log(errors)
				// setError('checkId', { type: 'custom', message: '사용중인 이메일 입니다.' })
			})
	}

	const onSubmit = async (data) => {
		const { profileImg, profileType, password, ...basicFields } = data

		const convertedProfile = profileImg?.length > 0 && (await convertURLtoFile(profileImg))

		const input = {
			...basicFields,
			profileType: profileImg?.length > 0 ? getFileExt(convertedProfile) : profileType,
		}

		if (password?.length > 0) {
			input['password'] = password
		}

		await fetch(`${API_ENDPOINT}/users/${auth.user.id}`, {
			method: 'PATCH',
			headers: FETCH_HEADER_OPTIONS,
			body: JSON.stringify(input),
		})
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) {
					throw result
				} else {
					profileImg?.length > 0 &&
						(await uploadS3(auth.user.id, 'profile', convertedProfile))

					handleMoveModalOpen('정보 수정이 완료되었습니다.')

					fetchUserInfo()
				}
			})
			.catch((errors) => {
				console.log(errors)
				handleMessageModalOpen('저장에 실패했습니다.')
				// setError('checkId', { type: 'custom', message: '사용중인 이메일 입니다.' })
			})
	}

	const destroyUser = async () => {
		await fetch(`${API_ENDPOINT}/users/${auth.user.id}/delete`, { method: 'PATCH' })
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) {
					new Error('회원 탈퇴 실패')
				} else {
					removeCookie(DEFAULT_USER_SESSION_COOKIE_NAME)
					onChangeAuth()
					navigate('/')
				}
			})
			.catch((errors) => {
				console.log(errors)
				handleMessageModalOpen('회원 탈퇴를 실패했습니다.')
			})
	}

	const onInvalid = (errors) => {
		console.log(errors)
	}

	const handleUploadedFile = (event) => {
		const file = event.target.files[0]
		const reader = new FileReader()
		reader.readAsDataURL(file)

		return new Promise((resolve) => {
			reader.onload = () => {
				setPreviewProfileImg(reader.result || null)
				setValue('profileImg', reader.result)
				resolve()
			}
		})
	}

	const changePassword = async (password) => {
		const response = await fetch(`${API_ENDPOINT}/auth/signin`, {
			method: 'POST',
			headers: FETCH_HEADER_OPTIONS,
			body: JSON.stringify({ email: auth?.user?.email, password: password }),
		})
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) {
					throw new Error('로그인 실패')
				} else {
					setValue('password', password)

					return { success: true }
				}
			})
			.catch((errors) => {
				console.log(errors)

				return { success: false }
			})

		return response
	}

	const onChangeEngName = (key) => (event) => {
		const engName = event.target.value
		const parseName = engName?.charAt(0).toUpperCase() + engName.slice(1).toLowerCase()

		setValue(key, parseName)
	}

	const onAgreeCheckboxClick = (fieldName) => (event) => {
		const checked = getValues(fieldName)

		setValue(fieldName, !checked)
	}

	// moodal
	const [passwordChangeModalVisible, setPasswordChangeModalVisible] = useState()
	const [phoneChangeModalVisible, setPhoneChangeModalVisible] = useState()

	const [withDrawModalVisible, setWithDrawModalVisible] = useState(false)

	const [messageModalVisible, setMessageModalVisible] = useState(false)
	const [messageModalContent, setMessageModalContent] = useState('')
	const [moveLinkModal, setMoveLinkModal] = useState(false)

	const handlePasswordChangeModalOpen = () => setPasswordChangeModalVisible(true)
	const handlePasswordChangeModalClose = () => setPasswordChangeModalVisible(false)

	const handlePhoneChangeModalOpen = () => setPhoneChangeModalVisible(true)
	const handlePhoneChangeModalClose = () => setPhoneChangeModalVisible(false)

	const handleWithDrawModalOpen = () => setWithDrawModalVisible(true)
	const handleWithDrawModalClose = () => setWithDrawModalVisible(false)

	const handleMessageModalClose = () => {
		setMessageModalContent('')
		setMessageModalVisible(false)
	}
	const handleMessageModalOpen = (message) => {
		setMessageModalContent(message)
		setMessageModalVisible(true)
	}

	const handleMoveModalClose = () => {
		setMessageModalContent('')
		setMoveLinkModal(false)
	}
	const handleMoveModalOpen = (message) => {
		setMessageModalContent(message)
		setMoveLinkModal(true)
	}

	const onClickMoveOk = () => navigate('/matching')

	return (
		<div className="wrap form_page pro_form register_page">
			<div className="content_area">
				<div className="bgWrap">
					<div className="form_mw-662">
						<form
							encType="multipart/form-data"
							className="needs-validation"
							id="form_matchingPro"
							onSubmit={handleSubmit(onSubmit, onInvalid)}>
							<p className="form_topTitle">정보수정</p>
							<div className="card">
								<div className="card-body">
									<div className="d-flex justify-content-center cmb-30 cmt-10">
										<div className="inner-left">
											<img
												src={
													!!previewProfileImg
														? previewProfileImg
														: `/images/register-pro-001-nopictur.png`
												}
												alt="register-pro-001-nopictur"
												id="userProfileIMG"
											/>
											<label
												className="floatbtn"
												htmlFor="userProfileImageChange">
												<img
													src={`/images/register-pro-001-editicn.png`}
													alt="register-pro-001-editicn"
												/>
												<input
													type="file"
													name="profileImg"
													accept=".jpeg, .jpg, .png, .gif"
													id="userProfileImageChange"
													style={{ display: 'none' }}
													{...register('profileImg', {
														onChange: handleUploadedFile,
													})}
												/>
											</label>
										</div>
									</div>

									<p className="form_title cmb-10">* 아이디(이메일)</p>

									<div className="d-flex justify-content-between align-items-end cmb-30 w-100">
										<div className="border-bottom w-100 me-2">
											<div className="d-flex align-items-center form-control border-0 inputText pe-0">
												<p>
													<span className="">{auth?.user?.email}</span>
												</p>
											</div>
										</div>
									</div>

									<div className="form-group custom-from-group2 cmb-30">
										<label className="form_title underline me-2">
											* 비밀번호
										</label>
										<button
											type="button"
											className="btn btn-light form_titleSubButton"
											onClick={handlePasswordChangeModalOpen}>
											변경하기
										</button>
										<input
											type="text"
											id="rg_pro_passwordd"
											hidden
											{...register('password')}
										/>
									</div>

									<div className="row cmb-30">
										<div className="col-12 col-md-4">
											<div className="reg_inputBox">
												<label htmlFor="name" className="form-label">
													* 이름
												</label>
												<div className="d-flex">
													<input
														type="text"
														className="form-control underbarInput"
														id="rg002_name"
														placeholder="이름을 입력해 주세요."
														data-message="이름을 입력해 주세요."
														{...register('name', {
															required: true,
														})}
													/>
												</div>
											</div>
										</div>
										<div className="col-12 col-md-8">
											<div className="reg_inputBox">
												<label htmlFor="englishName" className="form-label">
													* 영문 이름
												</label>
												<div className="d-flex">
													<input
														type="text"
														className="form-control underbarInput me-2"
														id="rg_pro_lastName"
														placeholder="성"
														data-message="영문 성을 입력해 주세요."
														{...register('lastName', {
															required: true,
															pattern: {
																value: /\b[A-Z][a-z+]*$/,
															},
															onChange: onChangeEngName('lastName'),
														})}
													/>
													<input
														type="text"
														className="form-control underbarInput ms-2"
														id="rg_pro_firstName"
														placeholder="이름"
														data-message="영문 이름을 입력해 주세요."
														{...register('firstName', {
															required: true,
															pattern: {
																value: /\b[A-Z][a-z+]*$/,
															},
															onChange: onChangeEngName('firstName'),
														})}
													/>
												</div>
											</div>
										</div>
									</div>

									<div className="form-group custom-from-group2 cmb-30">
										<label className="form_title underline me-2">
											* 전화번호
										</label>
										<button
											type="button"
											className="btn btn-light form_titleSubButton"
											onClick={handlePhoneChangeModalOpen}>
											변경하기
										</button>
									</div>

									{/*[e/D] 프로 계정일 경우만 보임*/}
									{auth?.user?.userType === 'tourPro' && (
										<div className="rg002_agreeBox">
											<button
												className="btn btn-link"
												type="button"
												data-bs-toggle="modal"
												data-bs-target="#serviceAlertModal">
												서비스 알림 동의
											</button>
											<div className="form-check form-switch d-flex align-items-center">
												<input
													type="checkbox"
													id="rg002_checkServiceAgreeInput"
													className="d-none"
													{...register('notification', {
														required: true,
													})}
												/>
												<img
													id="rg002_checkServiceAgreeImg"
													src={`/images/${
														watch('notification')
															? 'register-pro-001-checkbox-bk.png'
															: 'register-pro-001-checkbox-gray.png'
													}`}
													alt="checkAgree"
													onClick={onAgreeCheckboxClick('notification')}
												/>
											</div>
										</div>
									)}

									<div className="row justify-content-center align-items-center cmb-30 ms-0 me-0">
										<label
											className="col-auto del_label p-0"
											onClick={handleWithDrawModalOpen}>
											회원 탈퇴
										</label>
									</div>

									<button type="submit" className="btn btn-dark nextPageButton">
										저장하기
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>

			<ModalPasswordChange
				modalVisible={passwordChangeModalVisible}
				changePassword={changePassword}
				onClose={handlePasswordChangeModalClose}
			/>

			<ModalPhoneChange
				modalVisible={phoneChangeModalVisible}
				onClose={handlePhoneChangeModalClose}
			/>

			<ModalServiceAlert />

			<ModalWithDrawMember
				modalVisible={withDrawModalVisible}
				destroyUser={destroyUser}
				onClose={handleWithDrawModalClose}
			/>

			<MessageModal
				modalVisible={messageModalVisible}
				content={messageModalContent}
				onClose={handleMessageModalClose}
			/>

			<ModalAfterMove
				modalVisible={moveLinkModal}
				content={messageModalContent}
				onClickOk={onClickMoveOk}
				onClose={handleMoveModalClose}
			/>
		</div>
	)
}
