import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStateMachine } from 'little-state-machine'
import { SuggestionTourSelectForm } from '../../components'
import { getToday, updateFormInput } from '../../utils'
import dayjs from 'dayjs'

export const RecruitTourSelectView = ({ API_ENDPOINT, ...props }) => {
	const navigate = useNavigate()
	const { actions, state } = useStateMachine({ updateFormInput })

	const [tours, setTours] = useState([])

	const contractType = state?.offerInput?.contractType

	useEffect(() => {
		fetchTours()
	}, [])

	const onSubmit = (data) => {
		const tours = data.tours?.map((item) => JSON.parse(item))

		const payload = {
			offerInput: { ...state.offerInput, tours },
		}

		actions.updateFormInput(payload)

		navigate('/recruits/create/tour-info')
	}

	const fetchTours = async () =>
		await fetch(`${API_ENDPOINT}/admin/db-manage/tours`)
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) {
					throw new Error(result.message)
				}
				const today = getToday()
				const tours = result?.filter((item) => dayjs(item?.startedAt).isAfter(today))

				setTours(tours)
			})
			.catch((errors) => console.log(errors))

	return (
		<div className="wrap form_page">
			<div className="content_area">
				<div className="bgWrap">
					<SuggestionTourSelectForm
						contractType={contractType}
						tours={tours}
						onSubmit={onSubmit}
					/>
				</div>
			</div>
		</div>
	)
}
