import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { StateMachineProvider, createStore } from 'little-state-machine'
import { SpoeumLayout } from './layout'
import {
	HomeView,
	TraningProgramView,
	AboutView,
	LoginView,
	FindIdView,
	ResetPasswordView,
	RegisterHomeView,
	RegisterMainView,
	RegisterTourProView,
	RegisterTourCaddieView,
	RegisterHouseCaddieView,
	RegisterAgencyView,
	MyAccountView,
	MyInfoView,
	MyPaymentView,
	MyContractInfoView,
	FaqListView,
	NoticeListView,
	ScheduleCheckView,
	RequesterDetailView,
	OfferListView,
	OfferDetailView,
	ProposalListView,
	ProposalDetailView,
	ProManagementView,
	OneInQuireListView,
	InterestedHumanResourcesView,
	AgencyManagementView,
	ContractProcessingDetailView,
	ContractProcessingView,
	ContractDetailView,
	TourRecruitInfoFormView,
	RecruitTourSelectView,
	RecruitTypeSelectView,
	RecruitListView,
	MatchingIndexView,
	MatchingHumanResourceDetailView,
	MatchingCompleteView,
	ProposalTourSelectView,
	ProposalTypeSelectView,
	ProposalAnnualTypeSelectView,
	TourProposalInfoFormView,
	AnnualProposalInfoFormView,
	PointChargeView,
	FixPaymentView,
	PaymentFailView,
	PaymentCompletedView,
	RecruitAnnualTypeSelectView,
	AnnualRecruitInfoFormView,
	SponsorshipListView,
	SponsorshipInquiryCreateView,
	SponsorshipInquiryDetailView,
	SponsorshipInquiryUpdateView,
	CompleteNiceView,
	PaymentView,
	OneInquiryDetailView,
} from './pages'
import { RecruitDetailView } from './pages/recruit/RecruitDetailView'
import { formInitState, RequireAuth, ScrollToTop } from './utils'
import { NiceTestView } from './pages/NiceTestView'

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
const CDN_ENDPOINT = process.env.REACT_APP_CDN_ENDPOINT
const PROFILE_BUCKET = process.env.REACT_APP_S3_PROFILE_BUCKET
const BUSINESS_BUCKET = process.env.REACT_APP_S3_BUSINESS_BUCKET

// form input state
createStore(formInitState)

function APP() {
	return (
		<SpoeumLayout API_ENDPOINT={API_ENDPOINT}>
			<StateMachineProvider>
				<ScrollToTop />
				<Routes>
					<Route
						path="/nice"
						element={
							<NiceTestView API_ENDPOINT={API_ENDPOINT} CDN_ENDPOINT={CDN_ENDPOINT} />
						}
					/>

					<Route path="/" element={<HomeView API_ENDPOINT={API_ENDPOINT} />} />
					<Route path="/about" element={<AboutView />} />
					<Route path="/training-program" element={<TraningProgramView />} />
					<Route
						path="/sponsorship"
						element={<SponsorshipListView API_ENDPOINT={API_ENDPOINT} />}
					/>
					<Route
						path="/sponsorship/:sponsorshipId"
						element={<SponsorshipInquiryDetailView API_ENDPOINT={API_ENDPOINT} />}
					/>
					<Route
						path="/sponsorship/create"
						element={<SponsorshipInquiryCreateView API_ENDPOINT={API_ENDPOINT} />}
					/>
					<Route
						path="/sponsorship/update/:sponsorshipId"
						element={<SponsorshipInquiryUpdateView API_ENDPOINT={API_ENDPOINT} />}
					/>

					<Route path="/login" element={<LoginView API_ENDPOINT={API_ENDPOINT} />} />
					<Route path="/find-id" element={<FindIdView API_ENDPOINT={API_ENDPOINT} />} />
					<Route
						path="/reset-password"
						element={<ResetPasswordView API_ENDPOINT={API_ENDPOINT} />}
					/>

					<Route
						path="/certify/complete"
						element={<CompleteNiceView CDN_ENDPOINT={CDN_ENDPOINT} />}
					/>
					<Route path="/register" element={<RegisterHomeView />} />
					<Route
						path="/register/main"
						element={
							<RegisterMainView
								API_ENDPOINT={API_ENDPOINT}
								CDN_ENDPOINT={CDN_ENDPOINT}
							/>
						}
					/>
					<Route
						path="/register/tour-pro"
						element={<RegisterTourProView API_ENDPOINT={API_ENDPOINT} />}
					/>
					<Route
						path="/register/tour-caddie"
						element={<RegisterTourCaddieView API_ENDPOINT={API_ENDPOINT} />}
					/>
					<Route
						path="/register/house-caddie"
						element={<RegisterHouseCaddieView API_ENDPOINT={API_ENDPOINT} />}
					/>
					<Route
						path="/register/agency"
						element={<RegisterAgencyView API_ENDPOINT={API_ENDPOINT} />}
					/>

					<Route
						path="/mypages/info"
						element={
							<RequireAuth>
								<MyInfoView
									API_ENDPOINT={API_ENDPOINT}
									PROFILE_BUCKET={PROFILE_BUCKET}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/mypages/contract-info"
						element={
							<RequireAuth>
								<MyContractInfoView API_ENDPOINT={API_ENDPOINT} />
							</RequireAuth>
						}
					/>
					<Route
						path="/mypages/account"
						element={
							<RequireAuth>
								<MyAccountView API_ENDPOINT={API_ENDPOINT} />
							</RequireAuth>
						}
					/>
					<Route
						path="/mypages/points"
						element={
							<RequireAuth>
								<PointChargeView API_ENDPOINT={API_ENDPOINT} />
							</RequireAuth>
						}
					/>
					<Route
						path="/mypages/payment"
						element={
							<RequireAuth>
								<MyPaymentView API_ENDPOINT={API_ENDPOINT} />
							</RequireAuth>
						}
					/>
					<Route
						path={'/mypages/interested-hr'}
						element={
							<RequireAuth>
								<InterestedHumanResourcesView API_ENDPOINT={API_ENDPOINT} />
							</RequireAuth>
						}
					/>

					<Route
						path={'/mypages/proposals'}
						element={
							<RequireAuth>
								<ProposalListView
									API_ENDPOINT={API_ENDPOINT}
									PROFILE_BUCKET={PROFILE_BUCKET}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path={'/mypages/proposals/:proposalId'}
						element={
							<RequireAuth>
								<ProposalDetailView API_ENDPOINT={API_ENDPOINT} />
							</RequireAuth>
						}
					/>

					<Route
						path={'/mypages/offers'}
						element={<OfferListView API_ENDPOINT={API_ENDPOINT} />}
					/>

					<Route
						path={'/mypages/offers/:offerId'}
						element={<OfferDetailView API_ENDPOINT={API_ENDPOINT} />}
					/>
					<Route
						path={'/mypages/offers/requester'}
						element={
							<RequireAuth>
								<RequesterDetailView API_ENDPOINT={API_ENDPOINT} />
							</RequireAuth>
						}
					/>

					<Route
						path={'/mypages/pro-management'}
						element={
							<RequireAuth>
								<ProManagementView API_ENDPOINT={API_ENDPOINT} />
							</RequireAuth>
						}
					/>
					<Route
						path={'/mypages/agency-management'}
						element={
							<RequireAuth>
								<AgencyManagementView API_ENDPOINT={API_ENDPOINT} />
							</RequireAuth>
						}
					/>
					{/*<Route path={'/mypages/spoeum-manage'} element={<SpoeumManagerListView />} />*/}

					<Route
						path={'/mypages/contract-process'}
						element={
							<RequireAuth>
								<ContractProcessingView API_ENDPOINT={API_ENDPOINT} />
							</RequireAuth>
						}
					/>
					<Route
						path={'/mypages/contract-process/:contractId'}
						element={
							<RequireAuth>
								<ContractProcessingDetailView API_ENDPOINT={API_ENDPOINT} />
							</RequireAuth>
						}
					/>
					<Route
						path={'/mypages/contract/docs/:contractId'}
						element={
							<RequireAuth>
								<ContractDetailView API_ENDPOINT={API_ENDPOINT} />
							</RequireAuth>
						}
					/>

					<Route
						path={'/mypages/schedule'}
						element={
							<RequireAuth>
								<ScheduleCheckView API_ENDPOINT={API_ENDPOINT} />
							</RequireAuth>
						}
					/>

					<Route
						path={'/mypages/one-in-quire'}
						element={
							<RequireAuth>
								<OneInQuireListView API_ENDPOINT={API_ENDPOINT} />
							</RequireAuth>
						}
					/>
					<Route
						path={'/mypages/one-in-quire/:oneInquireId'}
						element={
							<RequireAuth>
								<OneInquiryDetailView API_ENDPOINT={API_ENDPOINT} />
							</RequireAuth>
						}
					/>

					<Route
						path="/matching"
						element={
							<RequireAuth>
								<MatchingIndexView
									API_ENDPOINT={API_ENDPOINT}
									PROFILE_BUCKET={PROFILE_BUCKET}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/matching/:userId"
						element={
							<RequireAuth>
								<MatchingHumanResourceDetailView
									API_ENDPOINT={API_ENDPOINT}
									PROFILE_BUCKET={PROFILE_BUCKET}
								/>
							</RequireAuth>
						}
					/>

					<Route
						path="/matching/completed"
						element={
							<RequireAuth>
								<MatchingCompleteView API_ENDPOINT={API_ENDPOINT} />
							</RequireAuth>
						}
					/>

					<Route
						path="/proposal/create/type"
						element={
							<RequireAuth>
								<ProposalTypeSelectView API_ENDPOINT={API_ENDPOINT} />
							</RequireAuth>
						}
					/>
					<Route
						path="/proposal/create/tour"
						element={
							<RequireAuth>
								<ProposalTourSelectView API_ENDPOINT={API_ENDPOINT} />
							</RequireAuth>
						}
					/>
					<Route
						path="/proposal/create/annual-type"
						element={
							<RequireAuth>
								<ProposalAnnualTypeSelectView API_ENDPOINT={API_ENDPOINT} />
							</RequireAuth>
						}
					/>
					<Route
						path="/proposal/create/tour-info"
						element={
							<RequireAuth>
								<TourProposalInfoFormView API_ENDPOINT={API_ENDPOINT} />
							</RequireAuth>
						}
					/>
					<Route
						path="/proposal/create/annual-info"
						element={
							<RequireAuth>
								<AnnualProposalInfoFormView API_ENDPOINT={API_ENDPOINT} />
							</RequireAuth>
						}
					/>

					<Route
						path="/recruits"
						element={
							<RequireAuth>
								<RecruitListView API_ENDPOINT={API_ENDPOINT} />
							</RequireAuth>
						}
					/>

					<Route
						path="/recruits/:offerId"
						element={
							<RequireAuth>
								<RecruitDetailView API_ENDPOINT={API_ENDPOINT} />
							</RequireAuth>
						}
					/>

					<Route
						path="/recruits/create/contract-type"
						element={
							<RequireAuth>
								<RecruitTypeSelectView API_ENDPOINT={API_ENDPOINT} />
							</RequireAuth>
						}
					/>
					<Route
						path="/recruits/create/tour"
						element={
							<RequireAuth>
								<RecruitTourSelectView API_ENDPOINT={API_ENDPOINT} />
							</RequireAuth>
						}
					/>
					<Route
						path="/recruits/create/tour-info"
						element={
							<RequireAuth>
								<TourRecruitInfoFormView API_ENDPOINT={API_ENDPOINT} />
							</RequireAuth>
						}
					/>

					<Route
						path="/recruits/create/annual-type"
						element={
							<RequireAuth>
								<RecruitAnnualTypeSelectView API_ENDPOINT={API_ENDPOINT} />
							</RequireAuth>
						}
					/>

					<Route
						path="/recruits/create/annual-info"
						element={
							<RequireAuth>
								<AnnualRecruitInfoFormView API_ENDPOINT={API_ENDPOINT} />
							</RequireAuth>
						}
					/>
					{/*<Route*/}
					{/*	path="/recruits/apply"*/}
					{/*	element={<TourRecruitInfoFormView API_ENDPOINT={API_ENDPOINT} />}*/}
					{/*/>*/}

					<Route path="/faq" element={<FaqListView API_ENDPOINT={API_ENDPOINT} />} />
					<Route
						path="/notice"
						element={<NoticeListView API_ENDPOINT={API_ENDPOINT} />}
					/>

					<Route
						path="/payment"
						element={
							<PaymentView API_ENDPOINT={API_ENDPOINT} CDN_ENDPOINT={CDN_ENDPOINT} />
						}
					/>

					{/*<Route*/}
					{/*	path="/payment/completed"*/}
					{/*	element={*/}
					{/*		<PaymentCompletedView*/}
					{/*			API_ENDPOINT={API_ENDPOINT}*/}
					{/*			CDN_ENDPOINT={CDN_ENDPOINT}*/}
					{/*		/>*/}
					{/*	}*/}
					{/*/>*/}
					{/*<Route*/}
					{/*	path="/payment/fail"*/}
					{/*	element={*/}
					{/*		<PaymentFailView API_ENDPOINT={API_ENDPOINT} CDN_ENDPOINT={CDN_ENDPOINT} />*/}
					{/*	}*/}
					{/*/>*/}
				</Routes>
			</StateMachineProvider>
		</SpoeumLayout>
	)
}

export default APP
