import React from 'react'
import { Header } from './Header'
import { Footer } from './Footer'

export const SpoeumLayout = ({ API_ENDPOINT, children, ...props }) => {
	return (
		<React.Fragment>
			<Header API_ENDPOINT={API_ENDPOINT} />
			{children}
			<Footer />
		</React.Fragment>
	)
}
