import React from 'react'
import { Card } from 'react-bootstrap'
import { formattingPrice } from '../../utils'

export const DesiredContractInfo = ({ caddieInfo, incentives, ...props }) => {
	incentives?.sort((a, b) => {
		return a?.startRank - b?.startRank
	})

	return (
		<React.Fragment>
			<p className="form_title">계약정보</p>
			<Card className="cmb-30">
				<Card.Body>
					<div className="row">
						<div className="col-12 col-md-6 cm-border-right">
							<div className="row mbcmb-10 ">
								<div className="col-12 col-md-6 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 color-greyish-two">
									임금 세금포함 여부{' '}
									<span style={{ fontSize: '0.875rem' }}>(원천징수3.3%)</span>
								</div>
								<div className="col-12 col-md-6 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 text-lg-end text-sm-left">
									{caddieInfo?.is_tax_included ? '세전' : '세후'}
								</div>
							</div>

							<div className="row mbcmb-10">
								<div className="col-12 col-md-6 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 color-greyish-two">
									임금 지급 방식
								</div>
								<div className="col-12 col-md-6 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 text-lg-end text-sm-left">
									{caddieInfo?.is_weekly ? '주급' : '연봉'}
								</div>
							</div>

							<div className="row mbcmb-10">
								<div className="col-12 col-md-6 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 color-greyish-two">
									KPGA
								</div>
								<div className="col-12 col-md-6 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 text-lg-end text-sm-left">
									<p>
										{caddieInfo?.is_weekly ? '컷통과' : '상반기'}{' '}
										<span className="color-spruce">
											{formattingPrice(caddieInfo?.weekly_salary_for_KPGA)} 원
										</span>
									</p>
									<p>
										{caddieInfo?.is_weekly ? '컷탈락' : '하반기'}{' '}
										<span className="color-spruce">
											{formattingPrice(caddieInfo?.weekly_salary_f_for_KPGA)}{' '}
											원
										</span>
									</p>
								</div>
							</div>
							<div className="row mbcmb-10">
								<div className="col-12 col-md-6 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 color-greyish-two">
									KLPGA
								</div>
								<div className="col-12 col-md-6 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 text-lg-end text-sm-left">
									<p>
										{caddieInfo?.is_weekly ? '컷통과' : '상반기'}{' '}
										<span className="color-spruce">
											{formattingPrice(caddieInfo?.weekly_salary_for_KLPGA)}{' '}
											원
										</span>
									</p>
									<p>
										{caddieInfo?.is_weekly ? '컷탈락' : '하반기'}{' '}
										<span className="color-spruce">
											{formattingPrice(caddieInfo?.weekly_salary_f_for_KLPGA)}{' '}
											원
										</span>
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="row mbcmb-10">
								<div className="col-12 col-md-6 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 color-greyish-two">
									인센티브 방식
								</div>

								{caddieInfo?.is_weekly && (
									<div className="col-12 col-md-6 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 text-lg-end text-sm-left">
										{caddieInfo?.is_incentive_tax_included
											? '상금 표기액'
											: '상금 실수령액'}
									</div>
								)}

								{!caddieInfo?.is_weekly && (
									<div className="col-12 col-md-6 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 text-lg-end text-sm-left">
										해당없음
									</div>
								)}
							</div>

							<div className="row mbcmb-10">
								<div className="col-12 col-md-4 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 color-greyish-two">
									인센티브
								</div>

								{caddieInfo?.is_weekly && (
									<div className="col-12 col-md-8 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 text-lg-end text-sm-left">
										{incentives?.map((item, index) => {
											if (item?.endRank === 1) {
												return (
													<div
														key={item?.id}
														className="row flex-nowrap align-items-center">
														<div className="col text-end">우승</div>
														<div className="col-md-4 col-3 text-end">
															{item?.percentage}%
														</div>
													</div>
												)
											} else {
												return (
													<div
														key={item?.id}
														className="row flex-nowrap  align-items-center">
														<div className="col text-end">
															{item?.startRank}~
															{item?.endRank || 'CUT'}
														</div>
														<div className="col-md-4 col-3 text-end">
															{item?.percentage}%
														</div>
													</div>
												)
											}
										})}
									</div>
								)}

								{['year_first_half', 'year_second_half'].includes(
									caddieInfo?.caddieInfo_type,
								) && (
									<div className="col-12 col-md-8 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 text-lg-end text-sm-left">
										해당없음
									</div>
								)}
							</div>

							<div className="row mbcmb-10">
								<div className="col-12 col-md-4 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 color-greyish-two">
									제주도 지원금
								</div>

								{caddieInfo?.is_weekly && (
									<div className="col-12 col-md-8 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 text-lg-end text-sm-left">
										{caddieInfo?.expense_reimbursement_jeju > 0
											? `${formattingPrice(
													caddieInfo?.expense_reimbursement_jeju,
											  )}원`
											: '미지원'}
									</div>
								)}

								{!caddieInfo?.is_weekly && (
									<div className="col-12 col-md-8 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 text-lg-end text-sm-left">
										해당없음
									</div>
								)}
							</div>

							<div className="row mbcmb-10">
								<div className="col-12 col-md-8 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 color-greyish-two">
									선수 스폰서 모자 착용 가능 여부
								</div>

								{caddieInfo?.is_weekly && (
									<div className="col-12 col-md-4 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 text-lg-end text-sm-left">
										{caddieInfo?.wear_sponsored_hat ? '착용' : '미착용'}
									</div>
								)}

								{!caddieInfo?.is_weekly && (
									<div className="col-12 col-md-4 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 text-lg-end text-sm-left">
										해당없음
									</div>
								)}
							</div>
						</div>
					</div>
				</Card.Body>
			</Card>
		</React.Fragment>
	)
}
