import React, { useState, useEffect, useRef } from 'react'
import { useFetcher, useLoaderData, useNavigate, useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
	REG_EMAIL,
	REG_PASSWORD,
	FETCH_HEADER_OPTIONS,
	REG_PHONE,
	useAuthStatus,
	certificationNice,
} from '../../utils'
import { ModalPP, ModalServiceAlert, ModalTerm } from '../../components/modal'
import { MessageModal } from '../../components'

export const RegisterMainView = ({ API_ENDPOINT, CDN_ENDPOINT, ...props }) => {
	const [auth, onChangeAuthStatus] = useAuthStatus()

	const [searchParams, setSearchParams] = useSearchParams('enc_data')

	const navigate = useNavigate()
	const {
		register,
		watch,
		getValues,
		setValue,
		setError,
		clearErrors,
		handleSubmit,
		formState: { isSubmitting, isSubmitted, errors },
	} = useForm({
		defaultValues: {
			checkEmail: false,
			profileImg: null,
		},
		mode: 'onBlur',
	})

	const [userType, setUserType] = useState('')
	const [previewProfileImg, setPreviewProfileImg] = useState(null)
	const [isCertification, setIsCertification] = useState(false)

	useEffect(() => {
		if (auth.isAuthentication) {
			navigate('/')
		}
		const userType = localStorage.getItem('userType')

		setUserType(userType)
	}, [])

	useEffect(() => {
		if (userType === 'agency') {
			setIsCertification(true)
		} else {
			setIsCertification(JSON.parse(searchParams.get('status')))
		}
	}, [searchParams])

	const onSubmit = (data) => {
		const {
			checkEmail,
			checkedPassword,
			checkAgree,
			phone: formattedPhone,
			...basicInfo
		} = data

		if (userType !== 'agency' && !JSON.parse(searchParams.get('status'))) {
			handleMessageModalOpen('본인인증을 진행해주세요.')
		} else {
			const phone = formattedPhone.replace(/-/g, '')

			localStorage.setItem('basicInfo', JSON.stringify({ ...basicInfo, phone }))

			if (userType === 'tourPro') {
				navigate('/register/tour-pro')
			} else if (userType === 'tourCaddie') {
				navigate('/register/tour-caddie')
			} else if (userType === 'houseCaddie') {
				navigate('/register/house-caddie')
			} else if (userType === 'agency') {
				navigate('/register/agency')
			}
		}
	}

	const onInvalid = (errors) => {
		if (!!errors.email) {
			handleMessageModalOpen(errors.email.message)
		} else if (!!errors.checkEmail) {
			handleMessageModalOpen(errors.checkEmail.message)
		} else if (!!errors.password) {
			handleMessageModalOpen(errors.password.message)
		} else if (!!errors.checkedPassword) {
			handleMessageModalOpen(errors.checkedPassword.message)
		} else if (!!errors.name) {
			handleMessageModalOpen(errors.name.message)
		} else if (!!errors.lastName) {
			handleMessageModalOpen(errors.lastName.message)
		} else if (!!errors.firstName) {
			handleMessageModalOpen(errors.firstName.message)
		} else if (!!errors.corporateName) {
			handleMessageModalOpen(errors.corporateName.message)
		} else if (!!errors.phone) {
			handleMessageModalOpen(errors.phone.message)
		} else if (!!errors.notification) {
			handleMessageModalOpen(errors.notification.message)
		} else if (!!errors.checkAgree) {
			handleMessageModalOpen(errors.checkAgree.message)
		}
	}

	const handleUploadedFile = (event) => {
		const file = event.target.files[0]
		const reader = new FileReader()
		reader.readAsDataURL(file)

		return new Promise((resolve) => {
			reader.onload = () => {
				setPreviewProfileImg(reader.result || null)
				setValue('profileImg', reader.result)
				resolve()
			}
		})
	}

	const checkEmail = async (event) => {
		if (!!!errors['email']) {
			const email = getValues('email')

			await fetch(`${API_ENDPOINT}/users/duplicatecheck`, {
				method: 'POST',
				headers: FETCH_HEADER_OPTIONS,
				body: JSON.stringify({ email }),
			})
				.then(async (response) => {
					const result = await response.json()

					if (result.success) {
						setValue('checkEmail', true)
						handleMessageModalOpen('사용할 수 있는 아이디 입니다.')
						clearErrors(['email', 'checkEmail'])
					} else {
						setError('checkEmail', {
							type: 'custom',
							message: '사용할 수 없는 아이디 입니다.',
						})
						handleMessageModalOpen('사용할 수 없는 아이디 입니다.')
					}
				})
				.catch((errors) => {
					console.log(errors)
					setError('checkEmail', {
						type: 'custom',
						message: '네트워크 오류',
					})
				})
		}
	}

	const onChangeEngName = (key) => (event) => {
		const engName = event.target.value
		const parseName = engName?.charAt(0).toUpperCase() + engName.slice(1).toLowerCase()

		setValue(key, parseName)
	}

	const onClickCertification = async () => {
		const returnUrl = `${CDN_ENDPOINT}/certify/complete`

		await certificationNice(returnUrl)
	}

	const onAgreeCheckboxClick = (fieldName) => (event) => {
		const checked = getValues(fieldName)

		setValue(fieldName, !checked)
	}

	// modal
	const [messageModalVisible, setMessageModalVisible] = useState(false)
	const [messageModalContent, setMessageModalContent] = useState('')

	const handleMessageModalClose = () => {
		setMessageModalContent('')
		setMessageModalVisible(false)
	}
	const handleMessageModalOpen = (message) => {
		setMessageModalContent(message)
		setMessageModalVisible(true)
	}

	return (
		<div className="wrap register_page form_page">
			<div className="content_area">
				<div className="bgWrap rg002_bgWrap">
					<div className="container register002" id="rg002Container">
						<form
							className="needs-validation"
							id="register002"
							noValidate
							onSubmit={handleSubmit(onSubmit, onInvalid)}>
							<p className="form_topTitle">회원가입</p>
							<div className="card">
								<div className="card-body">
									{userType !== 'agency' && !isCertification ? (
										<div className="rg002_inputBox">
											<button
												type="button"
												id="rg002_sendCerificationNumber"
												className="btn btn-dark registerNextButton"
												onClick={onClickCertification}>
												본인인증
											</button>
										</div>
									) : (
										<React.Fragment>
											<div className="d-flex justify-content-center cmb-30 cmt-10">
												<div className="inner-left">
													<img
														src={
															!!previewProfileImg
																? previewProfileImg
																: `/images/register-pro-001-nopictur.png`
														}
														alt="userProfileIMG"
														id="userProfileIMG"
													/>
													<label
														className="floatbtn"
														htmlFor="userProfileImageChange">
														<img
															src={`/images/register-pro-001-editicn.png`}
															alt="userProfileImage"
														/>
														<input
															id="userProfileImageChange"
															type="file"
															accept=".jpeg, .jpg, .png, .gif"
															style={{ display: 'none' }}
															{...register('profileImg', {
																onChange: handleUploadedFile,
															})}
														/>
													</label>
												</div>
											</div>
											<div className="rg002_inputBox">
												<label htmlFor="rg002_id" className="form-label">
													* 아이디 (이메일)
												</label>
												<div className="d-flex">
													<input
														id="rg002_checkId"
														type="checkbox"
														className="d-none"
														{...register('checkEmail', {
															value: false,
															validate: (checkEmail) => {
																return (
																	checkEmail ||
																	'아이디 중복확인 후 다음단계로 넘어갈 수 있습니다.'
																)
															},
														})}
													/>
													<input
														id="rg002_id"
														type="email"
														placeholder="아이디를 입력해 주세요."
														className={`form-control underbarInput ${
															!!errors['email']
																? errors['email']
																	? 'is-invalid'
																	: 'is-valid'
																: ''
														}`}
														{...register('email', {
															required: '이메일을 정확히 입력하세요.',
															pattern: {
																value: REG_EMAIL,
																message:
																	'이메일을 정확히 입력하세요.',
															},
														})}
													/>
													<button
														type="button"
														className="btn btn-outline-dark rg002_buttons"
														id="rg002_checkIdButton"
														// disabled={
														// 	(!!!getValues('email') &&
														// 		getValues('email')?.length === 0) ||
														// 	!!errors['email']
														// }
														onClick={checkEmail}>
														중복확인
													</button>
												</div>
											</div>
											<div className="rg002_inputBox">
												<label htmlFor="rg002_id" className="form-label">
													* 비밀번호
												</label>
												<input
													id="rg002_password"
													type="password"
													placeholder="6~16자 영문 대/소문자, 숫자, 특수문자(!,@,#,$,%,^,&,*,?,_)를 조합해서 사용하세요."
													className={`form-control underbarInput mb-3 ${
														!!errors['password']
															? errors['password']
																? 'is-invalid'
																: 'is-valid'
															: ''
													}`}
													{...register('password', {
														required:
															'6~16자 영문 대/소문자, 숫자, 특수문자(!,@,#,$,%,^,&,*,?,_)를 조합해서 사용하세요.',
														pattern: {
															value: REG_PASSWORD,
															message:
																'6~16자 영문 대/소문자, 숫자, 특수문자(!,@,#,$,%,^,&,*,?,_)를 조합해서 사용하세요.',
														},
													})}
												/>
												<input
													id="rg002_checkPassword"
													type="password"
													placeholder="비밀번호를 한번 더 입력해 주세요."
													className={`form-control underbarInput ${
														!!errors['checkedPassword']
															? errors['checkedPassword']
																? 'is-invalid'
																: 'is-valid'
															: ''
													}`}
													{...register('checkedPassword', {
														required:
															'6~16자 영문 대/소문자, 숫자, 특수문자(!,@,#,$,%,^,&,*,?,_)를 조합해서 사용하세요.',
														pattern: {
															value: REG_PASSWORD,
															message:
																'6~16자 영문 대/소문자, 숫자, 특수문자(!,@,#,$,%,^,&,*,?,_)를 조합해서 사용하세요.',
														},
														validate: (checkedPassword) => {
															if (!getValues('password')) return
															return (
																getValues('password') ===
																	checkedPassword ||
																'비밀번호가 일치하지 않습니다.'
															)
														},
														onBlur: (event) =>
															getValues('password') !==
																event.target.value &&
															handleMessageModalOpen(
																'비밀번호가 일치하지 않습니다.',
															),
													})}
												/>
											</div>
											{userType !== 'agency' && (
												<div className="basic">
													<div className="row">
														<div className="col-12 col-md-4">
															<div className="rg002_inputBox">
																<label
																	htmlFor="rg002_name"
																	className="form-label">
																	* 이름
																</label>
																<div className="d-flex">
																	<input
																		id="rg002_name"
																		type="text"
																		placeholder="이름을 입력해 주세요."
																		className={`form-control underbarInput ${
																			!!errors['name']
																				? errors['name']
																					? 'is-invalid'
																					: 'is-valid'
																				: ''
																		}
																`}
																		{...register('name', {
																			required:
																				'이름을 입력해 주세요.',
																		})}
																	/>
																</div>
															</div>
														</div>
														<div className="col-12 col-md-8">
															<div className="rg002_inputBox">
																<label
																	htmlFor="rg002_englishName"
																	className="form-label">
																	* 영문 이름
																</label>
																<div className="d-flex">
																	<input
																		id="rg_pro_lastName"
																		type="text"
																		placeholder="성"
																		className={`form-control underbarInput me-2 ${
																			!!errors['lastName']
																				? errors['lastName']
																					? 'is-invalid'
																					: 'is-valid'
																				: ''
																		}
																`}
																		{...register('lastName', {
																			required:
																				'영문 성을 입력해 주세요.',
																			pattern: {
																				value: /\b[A-Z][a-z+]*$/,
																				message:
																					'영문으로 입력해주세요.',
																			},
																			onChange:
																				onChangeEngName(
																					'lastName',
																				),
																		})}
																	/>
																	<input
																		id="rg_pro_firstName"
																		type="text"
																		name="firstName"
																		placeholder="이름"
																		className={`form-control underbarInput me-2 ${
																			!!errors['firstName']
																				? errors[
																						'firstName'
																				  ]
																					? 'is-invalid'
																					: 'is-valid'
																				: ''
																		}
																`}
																		{...register('firstName', {
																			required:
																				'영문 이름을 입력해 주세요.',
																			pattern: {
																				value: /\b[A-Z][a-z+]*$/,
																				message:
																					'영문으로 입력해주세요.',
																			},
																			onChange:
																				onChangeEngName(
																					'firstName',
																				),
																		})}
																	/>
																</div>
															</div>
														</div>
													</div>
													<div className="rg002_inputBox">
														<label
															htmlFor="rg002_phoneNumber"
															className="form-label">
															* 휴대폰 번호
														</label>
														<div className="d-flex w-100 mb-3">
															<input type="hidden" id="cNum2" />
															<input
																id="rg002_phoneNumber"
																type="tel"
																placeholder="휴대폰 번호를 입력해 주세요."
																className={`form-control underbarInput ${
																	!!errors['phone']
																		? errors['phone']
																			? 'is-invalid'
																			: 'is-valid'
																		: ''
																}
																`}
																{...register('phone', {
																	required: true,
																	pattern: {
																		value: REG_PHONE,
																		message:
																			'휴대폰 번호를 정확히 입력하세요.',
																	},
																	onChange: (event) => {
																		event.target.value =
																			event.target.value
																				.replace(
																					/[^0-9]/g,
																					'',
																				)
																				.replace(
																					/^(\d{0,3})(\d{0,4})(\d{0,4})$/g,
																					'$1-$2-$3',
																				)
																				.replace(
																					/(\-{1,2})$/g,
																					'',
																				)
																	},
																})}
															/>
														</div>
													</div>
												</div>
											)}

											{/*[D] 에이전시 일 경우 basic_class display: none;*/}
											{userType === 'agency' && (
												<div className="agency">
													<div className="rg002_inputBox">
														<label
															htmlFor="rg002_agency"
															className="form-label cmb-10">
															* 에이전시명
														</label>
														<div className="d-flex w-100">
															<input
																id="rg002_agency"
																type="text"
																placeholder="에이전시명을 입력해 주세요."
																className={`form-control underbarInput ${
																	!!errors['name']
																		? errors['name']
																			? 'is-invalid'
																			: 'is-valid'
																		: ''
																}
																`}
																{...register('name', {
																	required: true,
																})}
															/>
														</div>
													</div>
													<div className="rg002_inputBox">
														<label
															htmlFor="rg002_repPhoneNumber"
															className="form-label cmb-10">
															* 대표번호
														</label>
														<div className="d-flex w-100 mb-3">
															<input
																id="rg002_repPhoneNumber"
																type="tel"
																placeholder="대표 번호를 입력해 주세요."
																className={`form-control underbarInput ${
																	!!errors['phone']
																		? errors['phone']
																			? 'is-invalid'
																			: 'is-valid'
																		: ''
																}
																`}
																{...register('phone', {
																	required: true,
																	onChange: (event) => {
																		event.target.value =
																			event.target.value
																				.replace(
																					/[^0-9]/g,
																					'',
																				)
																				.replace(
																					/^(\d{2,3})(\d{3,4})(\d{4})$/,
																					`$1-$2-$3`,
																				)
																	},
																})}
															/>
														</div>
													</div>
												</div>
											)}

											<div className="rg002_agreeBox">
												<div>
													<button
														className="btn btn-link"
														type="button"
														data-bs-toggle="modal"
														data-bs-target="#serviceAlertModal">
														서비스 알림 동의
													</button>
													<p className="regInfoText">
														프로 및 캐디의 다양한 정보 및 스케줄을
														받으시기 위해 추가를 추천드립니다.
													</p>
												</div>

												<div className="form-check form-switch d-flex align-items-center">
													<input
														id="rg002_checkServiceAgreeInput"
														type="checkbox"
														value="Y"
														className="d-none"
														{...register('notification', {
															required:
																'서비스 알림에 동의해 주세요.',
														})}
													/>
													<img
														src={`/images/${
															watch('notification')
																? 'register-pro-001-checkbox-bk.png'
																: 'register-pro-001-checkbox-gray.png'
														}`}
														alt="serviceAgreement"
														id="rg002_checkServiceAgreeImg"
														onClick={onAgreeCheckboxClick(
															'notification',
														)}
													/>
												</div>
											</div>
											<div className="rg002_agreeBox2">
												<p>
													스포이음의{' '}
													<u
														data-bs-toggle="modal"
														data-bs-target="#termModal">
														이용약관
													</u>{' '}
													및{' '}
													<u
														data-bs-toggle="modal"
														data-bs-target="#ppModal">
														개인정보취급방침
													</u>
													에 동의합니다.
												</p>
												<input
													id="rg002_checkAgreeInput"
													type="checkbox"
													value="Y"
													className="d-none"
													{...register('checkAgree', {
														required:
															'서비스 이용약관에 동의해 주세요.',
													})}
												/>
												<img
													src={`/images/${
														watch('checkAgree')
															? 'register-pro-001-checkbox-bk.png'
															: 'register-pro-001-checkbox-gray.png'
													}`}
													alt="checkAgree"
													id="rg002_checkAgreeImg"
													onClick={onAgreeCheckboxClick('checkAgree')}
												/>
											</div>
											<div className="col-12">
												<button
													type="submit"
													// disabled={!!errors}
													className="btn btn-dark registerNextButton"
													id="rg002_nextButton">
													다음 단계로
												</button>
											</div>
										</React.Fragment>
									)}
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>

			<ModalServiceAlert />
			<ModalTerm />
			<ModalPP />

			<MessageModal
				modalVisible={messageModalVisible}
				content={messageModalContent}
				onClose={handleMessageModalClose}
			/>

			<div>
				{/*nice 서버에 submit할 form*/}
				<form
					name="form"
					id="form"
					action="https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb">
					<input type="hidden" id="m" name="m" value="service" />
					<input type="hidden" id="token_version_id" name="token_version_id" value="" />
					<input type="hidden" id="enc_data" name="enc_data" />
					<input type="hidden" id="integrity_value" name="integrity_value" />
				</form>
			</div>
		</div>
	)
}
