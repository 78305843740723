import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import { REG_EMAIL, REG_PHONE, uploadS3 } from '../../utils'
import { ModalServiceAlert, ModalTerm } from '../modal'
import { MessageModal } from '../MessageModal'

export const SponsorshipForm = ({ fields, createSponsorship, onSubmitHandle, ...props }) => {
	const {
		register,
		setFocus,
		handleSubmit,
		formState: { errors },
	} = useForm({
		values: fields,
		mode: 'onBlur',
	})

	const [messageModalVisible, setMessageModalVisible] = useState(false)
	const [messageModalContent, setMessageModalContent] = useState('')

	const onSubmit = ({ check_agree, ...input }) => {
		onSubmitHandle(input)
	}

	const onInvalid = (errors) => {
		const firstError = Object.keys(errors).reduce((field, a) => {
			return !!errors[field] ? field : a
		}, null)

		const fieldValidMessage = (
			<span>
				필수 입력 항목을 입력 또는 선택하지 않으셨습니다.
				<br />
				필수 입력 항목을 모두 입력 후, 글을 등록해 주세요.
			</span>
		)

		const checkAgreeMessage = (
			<span>
				서비스 이용약관 및 개인정보 이용 동의를 진행하셔야 문의 글에 대한 정상적인 접수가
				가능합니다.
			</span>
		)

		setFocus(firstError)

		if (firstError === 'check_agree') {
			handleMessageModalOpen(checkAgreeMessage)
		} else {
			handleMessageModalOpen(fieldValidMessage)
		}
	}

	const handleMessageModalOpen = (message) => {
		setMessageModalContent(message)
		setMessageModalVisible(true)
	}

	const handleMessageModalClose = () => {
		setMessageModalVisible(false)
	}

	return (
		<React.Fragment>
			<form onSubmit={handleSubmit(onSubmit, onInvalid)}>
				<Card className="mb-5">
					<Card.Body>
						<div className="row align-items-center cmb-20">
							<div className="col-12 col-md-6">
								<div className="col-auto w-100 form_subTitle cmb-10">
									<span className="me-2 fontSize-18 color-greyish-brown-two">
										* 작성자
									</span>
								</div>
								<div className="d-flex justify-content-end align-items-center border-bottom">
									<input
										type="text"
										id="writter"
										className="border-0 w-100 form-control no-focus form_inputText"
										placeholder="작성자를 입력해주세요."
										{...register('name', {
											required: true,
										})}
									/>
								</div>
							</div>
							<div className="col-12 col-md-6">
								<div className="col-auto w-100 form_subTitle cmb-10">
									<span className="me-2 fontSize-18 color-greyish-brown-two">
										* 연락처
									</span>
								</div>

								<div className="d-flex justify-content-end align-items-center border-bottom">
									<input
										type="text"
										id="phone"
										className="border-0 w-100 form-control no-focus form_inputText"
										placeholder="연락처를 입력해 주세요"
										{...register('phone', {
											required: true,
											pattern: {
												value: REG_PHONE,
												message: '연락처를 정확히 입력하세요.',
											},
										})}
									/>
								</div>
							</div>
						</div>

						<div className="row align-items-center cmb-20">
							<div className="col-12">
								<div className="col-auto w-100 form_subTitle cmb-10">
									<span className="me-2 fontSize-18 color-greyish-brown-two">
										* 이메일
									</span>
								</div>

								<div className="d-flex justify-content-end align-items-center border-bottom">
									<input
										type="text"
										id="email"
										className="border-0 w-100 form-control no-focus form_inputText"
										placeholder="답변 받을 이메일 주소를 입력해 주세요."
										{...register('email', {
											required: true,
											pattern: {
												value: REG_EMAIL,
												message: '이메일을 정확히 입력하세요.',
											},
										})}
									/>
								</div>
							</div>
						</div>

						<div className="row align-items-center cmb-20">
							<div className="col-12 col-md-6">
								<div className="col-auto w-100 form_subTitle cmb-10">
									<span className="me-2 fontSize-18 color-greyish-brown-two">
										스폰서쉽 문의대상
									</span>
								</div>
								<div className="d-flex justify-content-end align-items-center border-bottom">
									<input
										type="text"
										id="targetPro"
										className="border-0 w-100 form-control no-focus form_inputText"
										placeholder="문의 대상 프로의 이름을 입력해주세요."
										{...register('proName')}
									/>
								</div>
							</div>
							<div className="col-12 col-md-6">
								<div className="col-auto w-100 form_subTitle cmb-10">
									<span className="me-2 fontSize-18 color-greyish-brown-two">
										* 스폰서쉽 유형
									</span>
								</div>

								<div className="d-flex justify-content-end align-items-center border-bottom">
									<select
										id="typeSelect"
										aria-label="Default select example"
										className="form-select"
										placeholder="유형을 선택하세요."
										{...register('type', {
											required: true,
										})}>
										<option value="title">타이틀 스폰서</option>
										<option value="sub">서브 스폰서</option>
									</select>
								</div>
							</div>
						</div>

						<div className="row align-items-center cmb-20">
							<div className="col-12">
								<div className="col-auto w-100 form_subTitle cmb-10">
									<span className="me-2 fontSize-18 color-greyish-brown-two">
										사업자 등록증
									</span>
								</div>
								<div className="d-flex justify-content-end align-items-center border-bottom">
									<input
										type="file"
										id="uploadFile"
										className="border-0 w-100 form-control no-focus form_inputText"
										placeholder="파일을 직접 등록해주세요."
										{...register('business_license')}
									/>
								</div>
							</div>
						</div>

						<div className="row align-items-center cmb-20">
							<div className="col-12">
								<div className="col-auto w-100 form_subTitle cmb-10">
									<span className="me-2 fontSize-18 color-greyish-brown-two">
										* 제목
									</span>
								</div>
								<div className="d-flex justify-content-end align-items-center border-bottom">
									<input
										type="text"
										id="title"
										className="border-0 w-100 form-control no-focus form_inputText"
										placeholder="문의 글의 제목을 입력해주세요.(30자 이내)"
										{...register('title', {
											required: true,
											maxLength: 30,
										})}
									/>
								</div>
							</div>
						</div>

						<div className="row align-items-center cmb-20">
							<div className="col-12">
								<div className="col-auto w-100 form_subTitle cmb-10">
									<span className="me-2 fontSize-18 color-greyish-brown-two">
										* 문의 내용 입력
									</span>
								</div>

								<div className="d-flex justify-content-end align-items-center border-bottom">
									<textarea
										id="detail"
										rows={10}
										className="w-100 form-control no-focus form_inputText"
										placeholder="문의 내용을 입력하세요."
										{...register('detail', {
											required: true,
										})}
									/>
								</div>
							</div>
						</div>

						<div className="row align-items-center">
							<div className="col-12 col-md-6">
								<div className="col-auto w-100 form_subTitle">
									<span className="me-2 fontSize-18 color-greyish-brown-two">
										<u data-bs-toggle="modal" data-bs-target="#termModal">
											서비스 이용약관 동의
										</u>{' '}
										및{' '}
										<u data-bs-toggle="modal" data-bs-target="#ppModal">
											개인정보 이용 동의
										</u>
										에 동의합니다.
									</span>
									<input
										type="checkbox"
										className="d-none"
										id="check_agree"
										{...register('check_agree', {
											required: true,
										})}
									/>
									<label htmlFor="check_agree" className="checkCustom" />
								</div>
							</div>
						</div>
					</Card.Body>
				</Card>

				<div className="row justify-content-end align-items-center cmb-20">
					<div className="col-6 col-md-3 d-flex align-items-center cmb-10 mb_btn_size">
						<Link
							to={'/sponsorship'}
							className="btn btn-white-three pb-3 pt-3"
							style={{ width: '100%' }}>
							이전
						</Link>
					</div>
					<div className="col-6 col-md-3 d-flex align-items-center cmb-10 mb_btn_size">
						<button
							type="submit"
							className="btn btn-spruce pb-3 pt-3"
							style={{ width: '100%' }}>
							등록
						</button>
					</div>
				</div>
			</form>

			<ModalServiceAlert />
			<ModalTerm />

			<MessageModal
				modalVisible={messageModalVisible}
				content={messageModalContent}
				onClose={handleMessageModalClose}
			/>
		</React.Fragment>
	)
}
