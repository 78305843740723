import React from 'react'
import { Link } from 'react-router-dom'
import { Accordion, Card } from 'react-bootstrap'
import { formattingPrice, getCurrentYear, isEmpty } from '../../utils'
import dayjs from 'dayjs'

export const MatchingListMyInfo = ({ PROFILE_BUCKET, user, contract, selectedTour, ...props }) => {
	const currentYear = getCurrentYear()

	const infoColumns = (userType) => {
		switch (userType) {
			case 'tourPro':
				return (
					<React.Fragment>
						<div className="d-flex align-items-start cmb-15">
							<p className="neoL00_16_greyish-two">메인스폰서</p>
							<p className="neoM00_16_greyish-brown ms-4">
								{user?.user_tour_pro?.main_sponsor || '-'}
							</p>
						</div>
						<div className="d-flex align-items-start cmb-15">
							<p className="neoL00_16_greyish-two">에이전시</p>
							<p className="neoM00_16_greyish-brown ms-4">
								{user?.user_tour_pro?.agency_name || '-'}
							</p>
						</div>
					</React.Fragment>
				)
			case 'tourCaddie':
				return (
					<React.Fragment>
						<div className="d-flex align-items-start cmb-15">
							<p className="neoL00_16_greyish-two">경력</p>
							<p className="neoM00_16_greyish-brown ms-4">
								{currentYear - user?.user_tour_caddie?.employment_year + 1 || 0}{' '}
								년차
							</p>
						</div>
						<div className="d-flex align-items-start cmb-15">
							<p className="neoL00_16_greyish-two">소속</p>
							<p className="neoM00_16_greyish-brown ms-4">{user?.club_name || '-'}</p>
						</div>
					</React.Fragment>
				)
			case 'houseCaddie':
				return (
					<React.Fragment>
						<div className="d-flex align-items-start cmb-15">
							<p className="neoL00_16_greyish-two">소속</p>
							<p className="neoM00_16_greyish-brown ms-4">{user?.club_name || '-'}</p>
						</div>
					</React.Fragment>
				)
			case 'agency':
				return (
					<React.Fragment>
						<div className="d-flex align-items-start cmb-15">
							<p className="neoL00_16_greyish-two">법인명</p>
							<p className="neoM00_16_greyish-brown ms-4">
								{user?.user_agency?.corporate_name}
							</p>
						</div>
						{/*<div className="d-flex align-items-start cmb-15">*/}
						{/*	<p className="neoL00_16_greyish-two">현재 소속 프로</p>*/}
						{/*	<p className="neoM00_16_greyish-brown ms-4">*/}
						{/*		{user?.user_agency?.players?.length}명*/}
						{/*	</p>*/}
						{/*</div>*/}
					</React.Fragment>
				)
			default:
				return
		}
	}

	const contractInfo = (userTypes) => {
		const titles = {
			tourPro: '계약된 캐디 정보',
			tourCaddie: '계약된 프로 정보',
			houseCaddie: '계약된 프로 정보',
			agency: '등록된 선수 계약 정보',
		}

		return (
			<Accordion id="accordion_contractedcaddie" defaultActiveKey={'0'}>
				<Accordion.Item eventKey="0" className="mb-3">
					<Accordion.Header id="head_accordion_contractedcaddie_1">
						{titles[userTypes]}
					</Accordion.Header>

					<Accordion.Body id="collapse_contractedcaddie_1" className="border-0">
						{isEmpty(contract) ? (
							<p className="neoM00_16_greyish-brown text-center">
								계약 정보가 없습니다.
							</p>
						) : (
							<div className="row">
								<div
									className="col-12 col-md-auto cmb-10"
									onClick="modalOpen(this,'mycaddie')">
									<div className="pfBox_imageOuter">
										<div className="pfBox_imageCircle">
											<img
												src={
													!!contract?.targetUser?.profile_type
														? PROFILE_BUCKET +
														  contract?.targetUser?.id +
														  '.' +
														  contract?.targetUser?.profile_type
														: `/images/noImg.png`
												}
												alt="pfImage"
											/>
										</div>
									</div>
								</div>
								<div className="col-12 col-md cmb-10">
									<p
										className="neoM00_20_greyish-brown mb-4"
										onClick="modalOpen(this,'mycaddie')">
										{contract?.targetUser?.name}{' '}
										{contract?.targetUser?.user_type !== 'agency' && (
											<span className="neoL00_16_greyish-two">
												({contract?.targetUser?.first_name}{' '}
												{contract?.targetUser?.last_name})
											</span>
										)}
									</p>
									<div className="d-flex align-items-start cmb-15">
										<p className="neoL00_16_greyish-two text-nowrap">
											계약정보
										</p>
										<p className="neoM00_16_greyish-brown ms-4">
											{selectedTour?.title}
											<br />
											{dayjs(selectedTour?.start).format('YYYY. MM. DD')}~
											{dayjs(selectedTour?.end)
												.subtract('day', 1)
												.format('YYYY. MM. DD')}
										</p>
									</div>
									<div className="d-flex align-items-center">
										<p className="neoL00_16_greyish-two">계약 임금</p>
										<a className="neoM00_16_greyish-brown color-camel text-decoration-underline ms-4">
											{formattingPrice(contract?.weekly_salary)} 원
										</a>
									</div>
								</div>
							</div>
						)}
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		)
	}

	return (
		<Card className="h-100">
			<Card.Body>
				<div className="row">
					<div className="col-auto cmb-30">
						<div className="pfBox_imageOuter">
							<div className="pfBox_imageSquare mb-3">
								<img
									src={
										!!user?.profile_type
											? PROFILE_BUCKET + user?.id + '.' + user?.profile_type
											: `/images/noImg.png`
									}
									alt="pfImage"
								/>
							</div>
						</div>
						<Link to="/mypages/info">
							<button className="btn btn-sm btn-light w-100 border neoR00_14_greyish-brown-two">
								내정보 수정
							</button>
						</Link>
					</div>

					{/*에이전시 일 경우*/}
					<div className="col cmb-30">
						<p className="neoM00_20_greyish-brown mb-4">
							{user?.name}{' '}
							{user?.user_type !== 'agency' &&
								`(${user?.first_name} ${user?.last_name})`}
						</p>

						{infoColumns(user?.user_type)}
					</div>
				</div>

				<div className="w-100 border-bottom cmb-30" />

				{contractInfo(user?.user_type)}
			</Card.Body>
		</Card>
	)
}
