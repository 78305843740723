import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { contractTypes, formattingPrice, getMatchTypeOptions, useAuthStatus } from '../../utils'

export const RecruitListView = ({ API_ENDPOINT, ...props }) => {
	const [auth, onChangeAuth] = useAuthStatus()

	const [offers, setOffers] = useState([])

	useEffect(() => {
		fetchOffers()
	}, [])

	const fetchOffers = async (contractType = 'ALL') => {
		await fetch(`${API_ENDPOINT}/contract/offer/list`)
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) throw new Error(result.massage)

				const offers =
					contractType === 'ALL'
						? result
						: result?.filter((item) => item?.offer?.contract_type === contractType)

				setOffers(offers)
			})
			.catch((errors) => {
				console.log(errors)
				setOffers([])
			})
	}

	const handleTypeSelect = async (event) => {
		await fetchOffers(event.target.value)
	}

	return (
		<div className="wrap form_page">
			<div className="content_area">
				<div className="bgWrap">
					<div className="container">
						<div className="row cmb-20">
							<div className="col-auto col-md-6">
								<div className="col-auto display-5 cmb-10 contract_topTitleText">
									구인공고 전체 목록
								</div>
								<div className="col-auto contract_topSubTitleText">
									{offers?.length}건의 공고가 당신을 찾고 있습니다.
								</div>
							</div>
							{auth?.user?.userType !== 'houseCaddie' && (
								<div className="col-12 col-md-6 align-self-end cmb-10">
									<Link
										to={'/recruits/create/contract-type'}
										className="btn btn-spruce fontSize-20 font-L00 w-100 pb-4 pt-4">
										공고 등록 바로가기 &gt;
									</Link>
								</div>
							)}
						</div>
						<div className="col-auto cmb-30">
							<select
								className="form-select rounded-pill"
								style={{ width: '7rem', height: '45px' }}
								aria-label="Default select example"
								defaultValue="ALL"
								onChange={handleTypeSelect}>
								<option selected value="ALL">
									전체
								</option>
								{contractTypes?.map((item) => (
									<option value={item?.value}>{item?.label}</option>
								))}
							</select>
						</div>
						<div className="card">
							<div className="card-body">
								{offers?.length > 0
									? offers?.map((offer) => <OfferListCard offer={offer} />)
									: '공고가 없습니다.'}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

const OfferListCard = ({ offer, ...props }) => {
	const { offer: offerInfo, tour, user } = offer

	tour?.sort((a, b) => {
		return new Date(a?.started_at) - new Date(b?.started_at)
	})

	return (
		<Link to={`/recruits/${offerInfo?.id}`}>
			<div className="row cmb-10">
				<div className="col-12 col-md-8 cmb-10">
					<p className="fontSize-14 font-R00 color-greyish-two">
						{dayjs(offerInfo?.created_at).format('YYYY-MM-DD')}
					</p>
					<p className="fontSize-14 font-R00 color-greyish-two">{user?.name}</p>
					<p className="fontSize-20 font-M00">{offerInfo?.post_title}</p>
					<p className="fontSize-16 font-R00 color-greyish-two">
						{dayjs(tour?.at(0)?.started_at).format('YYYY-MM-DD')} ~{' '}
						{dayjs(tour?.at(-1)?.ended_at).format('YYYY-MM-DD')}
						{['single', 'long_term'].includes(offerInfo?.contract_type) && (
							<span style={{ marginLeft: '4px' }}>
								(총 {tour?.length || 0}
								경기)
							</span>
						)}
					</p>
				</div>
				<div className="col-12 col-md-4 d-flex justify-content-end align-items-center cmb-10 cumbjc">
					<p className="fontSize-20 font-M00 color-greyish-two">
						{getMatchTypeOptions(contractTypes, offerInfo?.contract_type)}
						<br />
						<span className="fontSize-20 font-SB00 color-warm-pink">
							{['single', 'long_term'].includes(offerInfo?.contract_type) && (
								<React.Fragment>
									컷통과 {formattingPrice(offerInfo?.weekly_salary)} 원
									<br />
									컷탈락 {formattingPrice(offerInfo?.weekly_salary_f)} 원
								</React.Fragment>
							)}

							{['year_first_half', 'year_second_half'].includes(
								offerInfo?.contract_type,
							) && `${formattingPrice(offerInfo?.weekly_salary)} 원`}
						</span>
					</p>
				</div>
				<div className="w-100 border-bottom cmb-10" />
			</div>
		</Link>
	)
}
