import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import Modal from 'react-bootstrap/Modal'

export const RequesterDetailView = ({ ...props }) => {
	const [tabKey, setTabKey] = useState('CONTRACT')
	const [selectModalVisible, setSelectModalVisible] = useState()

	const selectRequester = () => {
		// TODO : acceptRequseter
	}

	const handleRequesterTabSelect = (key) => {
		setTabKey(key)
	}

	const handleSelectModalOpen = () => setSelectModalVisible(true)
	const handleSelectModalClose = () => setSelectModalVisible(false)

	return (
		<div className="wrap form_page">
			<div className="content_area">
				<div className="container mainWrap">
					<div className="row cmb-30">
						<div className="col-12 col-md-6 cmb-10">
							<div className="card">
								<div className="d-flex">
									<div className="cardImage_caddie">
										<img src={`/images/noImg02.png`} alt="profileImage" />
									</div>
									<div className="card-body">
										<div className="d-flex justify-content-between">
											<div>
												<p className="font-R00 fontSize-13 color-greyish-two mb-1">
													투어캐디
												</p>
												<p className="font-SB00 fontSize-17 color-black mb-2">
													테스트
												</p>
											</div>
											<div className="pe-none">
												<img
													src={`/images/pro-main-001-like-01.png`}
													alt="thumbup"
													className="button_thumup"
												/>
											</div>
										</div>

										<p className="font-L00 fontSize-15 color-greyish-two">
											경력
											<span className="color-greyish-brown ms-2 me-3">
												0년
											</span>{' '}
											소속
											<span className="color-greyish-brown ms-2">없음</span>
										</p>
										{/*<p class="font-L00 fontSize-15 color-greyish-two">경력<span class="color-greyish-brown ms-2 me-3">0년</span>소속<span class="color-greyish-brown ms-2"></span></p>*/}
										<p className="font-L00 fontSize-15 color-greyish-two mb-2">
											CUT통과<span className="color-greyish-brown">95%</span>
										</p>
									</div>
								</div>
								{/*<a href="javascript:void(0)" className="stretched-link"></a>*/}
							</div>
						</div>

						<div className="col-12 col-md-6 cmb-10">
							<div className="row h-100 align-items-end">
								<div className="col">
									<button
										className="btn pb-4 pt-4 btn-dark w-100"
										onClick={handleSelectModalOpen}>
										수락하기
									</button>
								</div>
							</div>
						</div>
					</div>

					<Tab.Container defaultActiveKey={tabKey} onSelect={handleRequesterTabSelect}>
						<Nav className="nav-tabs nav-tabs-camel" id="myTab" role="tablist">
							<Nav.Item className="w-50" role="presentation">
								<Nav.Link eventKey={'CONTRACT'} className="cumbw-100 tab-camel">
									계약 정보
								</Nav.Link>
							</Nav.Item>

							<Nav.Item className="w-50" role="presentation">
								<Nav.Link eventKey={'GRADE'} className="cumbw-100 tab-camel">
									회신받은 제안
								</Nav.Link>
							</Nav.Item>
						</Nav>

						<Tab.Content className="tab-content" id="myTabContent">
							<Tab.Pane eventKey={'CONTRACT'} className="py-3">
								<p className="form_title">계약정보</p>
								<div className="card cmb-30">
									<div className="card-body">
										<div className="row">
											<div className="col-12 col-md-6">
												<div className="row mbcmb-10 cm-border-right">
													<div className="col-12 col-md-6 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 color-greyish-two">
														임금 세금포함 여부{' '}
														<span style={{ fontSize: '0.875rem' }}>
															원천징수3.3%
														</span>
													</div>
													<div className="col-12 col-md-6 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 text-lg-end text-sm-left">
														세전
													</div>
												</div>
											</div>
											<div className="col-12 col-md-6">
												<div className="row mbcmb-10">
													<div className="col-12 col-md-10 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 color-greyish-two">
														인센티브 방식
													</div>
													<div className="col-12 col-md-2 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 text-lg-end text-sm-left">
														세후
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-12 col-md-6">
												<div className="row mbcmb-10 cm-border-right">
													<div className="col-12 col-md-6 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 color-greyish-two">
														임금 지급 방식
													</div>
													<div className="col-12 col-md-6 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 text-lg-end text-sm-left">
														경기당
													</div>
												</div>
											</div>
											<div className="col-12 col-md-6">
												<div className="row mbcmb-10">
													<div className="col-12 col-md-6 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 color-greyish-two">
														주급
													</div>
													<div className="col-12 col-md-6 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 color-spruce text-lg-end text-sm-left">
														900,000원
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-12 col-md-6">
												<div className="row mbcmb-10 cm-border-right">
													<div className="col-12 col-md-2 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 color-greyish-two">
														인센티브
													</div>
													<div className="col-12 col-md-10 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 text-lg-end text-sm-left">
														우승: 10%, TOP2~10: 5%, CUT 통과: 0%
													</div>
												</div>
											</div>
											<div className="col-12 col-md-6">
												<div className="row mbcmb-10">
													<div className="col-12 col-md-4 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 color-greyish-two">
														업무 외 비용
													</div>
													<div className="col-12 col-md-8 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 text-lg-end text-sm-left">
														제주 대회 출전시: 미지원
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-12 col-md-6">
												<div className="row mbcmb-10 cm-border-right">
													<div className="col-12 col-md-6 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 color-greyish-two">
														입회년도
													</div>
													<div className="col-12 col-md-6 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 text-lg-end text-sm-left"></div>
												</div>
											</div>
											<div className="col-12 col-md-6">
												<div className="row mbcmb-10">
													<div className="col-12 col-md-8 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 color-greyish-two">
														선수 스폰서 모자 착용 가능 여부
													</div>
													<div className="col-12 col-md-4 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 text-lg-end text-sm-left">
														미착용
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-12 col-md-6 cm-border-right">
												<div className="row mbcmb-10">
													<div className="col-12 col-md-6 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 color-greyish-two">
														미팅 희망 시간{' '}
														<span style={{ fontSize: '0.875rem' }}>
															티타임 전 기준
														</span>
													</div>
													<div className="col-12 col-md-6 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 text-lg-end text-sm-left">
														90분
													</div>
												</div>
											</div>
											<div className="col-12 col-md-6">
												<div className="row mbcmb-10">
													<div className="col-12 col-md-8 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 color-greyish-two">
														미팅 장소
													</div>
													<div className="col-12 col-md-4 pt-lg-2 pt-sm-0 pb-lg-2 pb-sm-0 text-lg-end text-sm-left">
														골프연습장
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Tab.Pane>

							<Tab.Pane eventKey={'GRADE'} className="py-3">
								<p className="form_title cmb-10">주요 이력</p>
								<div className="card cmb-30">
									<div className="card-body">
										<div className="indexScheduleBoard">
											<p className="indexScheduleBoardTitle">
												현대해상 제네시스 인비테이셔널
												<br />
											</p>
											<p className="indexScheduleSubtitle">
												2016.10.06 ~ 2016.10.09{' '}
												<span className="ms-2 me-2">전체성적:206</span>
												<span className="ms-2 me-2">순위:T5</span>
											</p>
										</div>

										<div className="indexScheduleBoard">
											<p className="indexScheduleBoardTitle">
												현대해상 제네시스 인비테이셔널
												<br />
											</p>
											<p className="indexScheduleSubtitle">
												2016.10.06 ~ 2016.10.09{' '}
												<span className="ms-2 me-2">전체성적:206</span>
												<span className="ms-2 me-2">순위:T5</span>
											</p>
										</div>
									</div>
								</div>
							</Tab.Pane>
						</Tab.Content>
					</Tab.Container>
				</div>
			</div>

			<ModalSelectRequester
				modalVisible={selectModalVisible}
				selectRequester={selectRequester}
				onClose={handleSelectModalClose}
			/>
		</div>
	)
}

const ModalSelectRequester = ({ modalVisible, selectRequester, onClose, ...props }) => {
	return (
		<Modal
			id="selectModal"
			backdrop="static"
			centered={true}
			tabIndex="-1"
			aria-labelledby="selectModalLabel"
			aria-hidden="true"
			show={modalVisible}
			onHide={onClose}>
			<Modal.Header className="border-0">
				<button type="button" className="btn-close" aria-label="Close" onClick={onClose} />
			</Modal.Header>
			<Modal.Body>
				<p className="fontSize-20 font-L00 color-greyish-brown text-center">
					[테스트] 님을 선택 하셨습니다.
				</p>
				<p className="fontSize-20 font-L00 color-greyish-brown text-center cmb-30">
					진행하시겠습니까?
				</p>

				<div className="row cmb-20">
					<div className="col-6">
						<button
							type="button"
							aria-label="Close"
							className="btn btn-danger registerNextButton w-100 pb-3 pt-3 cmb-10"
							onClick={onClose}>
							취소
						</button>
					</div>
					<div className="col-6">
						<button
							type="button"
							className="btn btn-dark registerNextButton w-100 pb-3 pt-3 cmb-10"
							onClick={selectRequester}>
							확인
						</button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
}
