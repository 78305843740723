import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import dayjs from 'dayjs'
import {
	contractStatusTypes,
	contractTypes,
	getMatchTypeOptions,
	useAuthStatus,
} from '../../../utils'

export const ContractProcessingView = ({ API_ENDPOINT, ...props }) => {
	const [auth, onChangeAuthStatus] = useAuthStatus()

	const navigate = useNavigate()

	const [groupedContracts, setGroupedContracts] = useState({})
	const [contractTypeFilter, setContractTypeFilter] = useState('ALL')
	const [infoModalVisible, setInfoModalVisible] = useState(false)

	useEffect(() => {
		fetchContracts()
	}, [auth])

	const fetchContracts = async () => {
		await fetch(`${API_ENDPOINT}/contract/contract/${auth?.user?.id}`)
			.then(async (response) => {
				const result = await response.json()

				const groupedContracts = contractStatusTypes?.reduce((acc, data, index) => {
					acc[data?.value] = result?.filter(
						(item) => item?.contract?.contract_status === data?.value,
					)

					return acc
				}, {})
				groupedContracts['ALL'] = result

				setGroupedContracts(groupedContracts)
			})
			.catch((errors) => {
				console.log(errors)
			})
	}

	const onClickContract = (contractId) => (event) => {
		navigate(`/mypages/contract-process/${contractId}`)
	}

	const onClickFilter = async (event) => {
		setContractTypeFilter(event?.target?.value)
		await fetchContracts()
	}

	const handleInfoModalOpen = () => setInfoModalVisible(true)
	const handleInfoModalClose = () => setInfoModalVisible(false)

	return (
		<div className="wrap form_page pro_form">
			<div className="content_area">
				<div className="container mainWrap">
					<div className="row cmb-30">
						<div className="col-auto col-md-8">
							<div className="col-auto contract_topTitleText">계약 진행 상황</div>
							<div className="col-auto contract_topSubTitleText">
								전체 계약의 진행 상황을 확인해 보세요.
							</div>
						</div>

						<div
							className="col-auto col-md-4 align-items-center cmb-10 mb_btn_size form_termBox"
							style={{ paddingTop: '21px' }}>
							<div className="d-flex justify-content-between cmb-10">
								<div className="form_termText">
									임의 계약기간 지정 서비스
									<img
										src={`/images/contracting_001.png`}
										alt="contracting_001"
										className="contracting_001_qes"
										onClick={handleInfoModalOpen}
									/>
								</div>

								<div className="form-check form-switch">
									<input
										className="form-check-input"
										type="checkbox"
										id="reg_house_checkServiceAgreeInput"
										name="checkServiceAgree"
									/>
								</div>
							</div>
							<div
								className="d-flex justify-content-start"
								style={{ paddingBottom: '14px' }}>
								<div className="col-auto pe-2">
									<button
										type="button"
										// onClick="ss()"
										className="form_settingBtn form_settingBtnText">
										날짜설정
									</button>
								</div>
								<div className="col-auto col-md-10 border-bottom">
									<input
										type="text"
										className="border-0"
										placeholder="사용중이 아닙니다."
										id="cd"
										value=""
									/>
								</div>
							</div>
						</div>
					</div>

					<div className="row cmb-30 align-items-end" id="filterRadio">
						<div className="col-auto cmb-10">
							<input
								type="radio"
								id="contractcheckAll"
								className="btn-check"
								autoComplete="off"
								value="ALL"
								checked={contractTypeFilter === 'ALL'}
								onClick={onClickFilter}
							/>
							<label
								className="d-flex justify-content-center btn btn-light btn-whiteShadow rounded-pill"
								style={{
									width: '7rem',
									height: '38px',
									alignItems: 'center',
									padding: 'unset',
								}}
								htmlFor="contractcheckAll">
								전체 ({groupedContracts['ALL']?.length})
							</label>
						</div>

						{contractStatusTypes?.map((item) => (
							<div className="col-auto cmb-10">
								<input
									type="radio"
									id={`contractcheck${item?.value}`}
									className="btn-check"
									autoComplete="off"
									value={item?.value}
									checked={contractTypeFilter === item?.value}
									onClick={onClickFilter}
								/>
								<label
									className="d-flex justify-content-center btn btn-light btn-whiteShadow rounded-pill"
									style={{
										width: '7rem',
										height: '38px',
										alignItems: 'center',
										padding: 'unset',
									}}
									htmlFor={`contractcheck${item?.value}`}>
									{item?.label} ({groupedContracts[item?.value]?.length})
								</label>
							</div>
						))}
					</div>

					<div className="row align-items-center cmb-30">
						<table
							className="table table-hover text-center"
							style={{ overflowX: 'scroll' }}>
							<thead>
								<tr>
									<th>상태</th>
									<th>날짜</th>
									<th>계약종류</th>
									<th>계약상대</th>
									<th>계약대회</th>
									<th>계약 승인일</th>
								</tr>
							</thead>
							<tbody className="fontSize-16 font-M00" id="hrefTrs">
								{groupedContracts[contractTypeFilter]?.map((contract) => (
									<tr
										className="padding-top"
										onClick={onClickContract(contract?.contract?.id)}>
										<td>
											{getMatchTypeOptions(
												contractStatusTypes,
												contract?.contract?.contract_status,
											)}
										</td>
										<td>
											{dayjs(contract?.contract?.created_at).format(
												'YYYY.MM.DD HH:mm:ss',
											)}
										</td>
										<td>
											{getMatchTypeOptions(
												contractTypes,
												contract?.contract?.contract_type,
											)}
										</td>
										<td>
											{auth?.user?.id === contract?.contract?.user_id &&
												contract?.targetUser?.name}
											{auth?.user?.id ===
												contract?.contract?.target_user_id &&
												contract?.user?.name}
										</td>
										<td>{contract?.tour?.name || '-'}</td>
										<td>
											{!!contract?.contract?.contracted_at
												? dayjs(contract?.contract?.contracted_at).format(
														'YYYY.MM.DD HH:mm:ss',
												  )
												: '-'}
										</td>
									</tr>
								))}
							</tbody>
						</table>

						{groupedContracts[contractTypeFilter]?.length === 0 && (
							<React.Fragment>
								<div className="cmt-30 cmb-30">
									<p className="contract_contentText">새로운 알림이 없습니다.</p>
								</div>
								<div className="border-bottom w-100" />
							</React.Fragment>
						)}
					</div>
				</div>
			</div>

			<ModalInfo modalVisible={infoModalVisible} onClose={handleInfoModalClose} />
		</div>
	)
}

const ModalInfo = ({ modalVisible, onClose, ...props }) => {
	return (
		<Modal
			id="infoModal"
			backdrop="static"
			centered={true}
			tabIndex="-1"
			aria-labelledby="refusalModalLabel"
			aria-hidden="true"
			show={modalVisible}
			onHide={onClose}>
			<Modal.Header className="border-0">
				<button type="button" className="btn-close" aria-label="Close" onClick={onClose} />
			</Modal.Header>
			<Modal.Body>
				<p class="fontSize-25 font-M00 color-greyish-brown text-center cmb-30">
					임의 계약기간 지정이란?
				</p>
				<p class="fontSize-20 font-R00 color-greyish-brown text-center cmb-10">
					외부 계약이 있으실 경우 사용하여,
				</p>
				<p class="fontSize-20 font-R00 color-greyish-brown text-center cmb-10">
					지정 기간 동안 스포이음 매칭 서비스에서도
				</p>
				<p class="fontSize-20 font-R00 color-greyish-brown text-center cmb-30">
					계약중으로 나타나 혼선을 막습니다
				</p>
				<button
					type="button"
					className="btn btn-dark pb-4 pt-4 registerNextButton w-100"
					onClick={onClose}>
					확인
				</button>
			</Modal.Body>
		</Modal>
	)
}
