import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStateMachine } from 'little-state-machine'
import { initFormState, updateFormInput } from '../../utils'
import { SuggestionTypeForm } from '../../components'

export const RecruitTypeSelectView = ({ ...props }) => {
	const navigate = useNavigate()

	const { actions, state } = useStateMachine({ initFormState, updateFormInput })

	useEffect(() => {
		actions.initFormState()
	}, [])

	const onSubmit = (data) => {
		const payload = {
			offerInput: { ...state.offerInput, ...data },
		}

		actions.updateFormInput(payload)

		if (data?.contractType === 'year') {
			navigate('/recruits/create/annual-type')
		} else {
			navigate('/recruits/create/tour')
		}
	}

	return (
		<div className="wrap form_page">
			<div className="content_area">
				<div className="bgWrap">
					<SuggestionTypeForm onSubmit={onSubmit} />
				</div>
			</div>
		</div>
	)
}
