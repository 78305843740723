export const getToday = () => new Date()
export const getCurrentYear = () => {
	return new Date().getFullYear()
}

export const formattedDate = (date) => {
	const parseYear = date.getFullYear(),
		parseMonth = date.getMonth() + 1,
		parseDay = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()

	return parseYear + '-' + parseMonth + '-' + parseDay
}

export const rangeYears = () => {
	const currentYear = getCurrentYear()
	const result = []

	for (let i = currentYear; i > currentYear - 51; --i) {
		result.push(i)
	}

	return result
}
