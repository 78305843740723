import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { formattingPrice, useAuthStatus } from '../../utils'
import { Calendar, Collapse } from '../../components'

import Modal from 'react-bootstrap/Modal'
import dayjs from 'dayjs'

export const HomeView = ({ API_ENDPOINT, ...props }) => {
	const [auth, onChangeAuthStatus] = useAuthStatus()
	const navigate = useNavigate()

	// const [popUpVisible, setPopUpVisible] = useState(true)

	const [paymentTotal, setPaymentTotal] = useState('0')
	const [tours, setTours] = useState([])
	const [noticeList, setNoticeList] = useState([])

	const [paymentPopupVisible, setPaymentPopupVisible] = useState(false)

	useEffect(() => {
		Promise.all([fetchTours(), fetchNotices()])

		if (auth?.isAuthentication) {
			fetchPaymentTotal()
		}
	}, [auth])

	const fetchPaymentTotal = async () => {
		await fetch(`${API_ENDPOINT}/payment/tour/payment/${auth?.user?.id}`)
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) throw result

				const total = result?.reduce((total, item, index) => {
					total += item.amount

					return total
				}, 0)

				if (total > 0) setPaymentPopupVisible(true)

				setPaymentTotal(total.toString())
			})
			.catch((errors) => setPaymentTotal('0'))
	}

	const fetchTours = async () =>
		await fetch(`${API_ENDPOINT}/admin/db-manage/tours`)
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) {
					throw new Error(result.message)
				}

				const color = (type) => {
					if (type === 'KPGA') return '#6495ed'
					else if (type === 'KLPGA') return '#ecdae3'
				}

				const tours = result.map((item) => {
					return {
						id: item.id,
						seq: item.id,
						title: item.name,
						start: dayjs(item.startedAt).format('YYYY-MM-DD'),
						end: dayjs(item.endedAt).add(1, 'days').format('YYYY-MM-DD'),
						color: color(item.type),
					}
				})
				setTours(tours)
			})
			.catch((errors) => console.log(errors))

	const fetchNotices = async () =>
		await fetch(`${API_ENDPOINT}/boards/notice`).then(async (response) => {
			const result = await response.json()
			setNoticeList(result)
		})

	const handleCarouselBtn = () => {
		navigate('/register')
	}

	// const handleClosePopup = () => setPopUpVisible(false)

	const handleClosePaymentPopup = () => setPaymentPopupVisible(false)

	return (
		<div className="wrap index_page notice_page">
			<div className="visual_area">
				<div
					id="carousel_1"
					className="carousel slide carousel-fade"
					data-bs-ride="carousel">
					<div className="carousel-inner">
						<div className="carousel-item" id="carousel_1_1"></div>
						<div className="carousel-item active" id="carousel_1_2"></div>
						<div className="carousel-item" id="carousel_1_3"></div>
					</div>
					<div className="carousel_1Container">
						<div className="container">
							<div>
								<ol className="carousel-indicators">
									<div data-bs-target="#carousel_1" data-bs-slide-to="0">
										<div
											className="carousel-caption"
											data-bs-target="#carousel_1"
											data-bs-slide-to="0">
											<p className="carousel_1Caption_1">
												스포이음은 대한민국 단 하나의
											</p>
											<p className="carousel_1Caption_2">
												프로골프 투어에 필요한 전문인력 매칭 플랫폼입니다.
											</p>
											{!auth.isAuthentication && (
												<button
													className="btn btn-dark"
													onClick={handleCarouselBtn}>
													스포이음 가입 바로가기
												</button>
											)}
										</div>
									</div>
									<div
										data-bs-target="#carousel_1"
										data-bs-slide-to="1"
										className="active">
										<div
											className="carousel-caption"
											data-bs-target="#carousel_1"
											data-bs-slide-to="1">
											<p className="carousel_1Caption_1">
												구인 · 구직 ONE-STOP
											</p>
											<p className="carousel_1Caption_2">
												서비스를 제공합니다.
											</p>
											{!auth.isAuthentication && (
												<button
													className="btn btn-dark"
													onClick={handleCarouselBtn}>
													스포이음 가입 바로가기
												</button>
											)}
										</div>
									</div>
									<div data-bs-target="#carousel_1" data-bs-slide-to="2">
										<div
											className="carousel-caption"
											data-bs-target="#carousel_1"
											data-bs-slide-to="2">
											<p className="carousel_1Caption_1">
												국내 유일 투어 캐디
											</p>
											<p className="carousel_1Caption_2">양성 프로그램</p>
											{!auth.isAuthentication && (
												<button
													className="btn btn-dark"
													onClick={handleCarouselBtn}>
													스포이음 가입 바로가기
												</button>
											)}
										</div>
									</div>
								</ol>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="content_area">
				<section className="container indexScheduleBox">
					<div className="row indexScheduleCardRow">
						<div className="col-12 col-md-6">
							<p className="indexScheduleTitle">KLPGA / KPGA 일정</p>
							<div className="card card-boxShadow h-100">
								<div className="card-body">
									<Calendar events={tours} />
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<p className="indexScheduleTitle">공지사항</p>
							<div className="col" style={{ display: 'none' }}>
								<input
									type="text"
									name=""
									id="pm001_input_searchcaddie"
									className="border-0 p-0 w-100"
									placeholder="검색어를 입력해 주세요."
								/>
							</div>
							<div
								className="col-auto"
								onClick="getBoardPc(1);"
								style={{ display: 'none' }}>
								<i
									className="fas fa-search color-white-three"
									aria-hidden="true"></i>
							</div>
							<p className="fontSize-16 color-greyish-two font-M00 cmb-30">
								공지사항 <span id="noticecnt">{noticeList?.length || 0}</span>개
							</p>

							<Collapse
								isAuthentication={auth.isAuthentication}
								dataSource={noticeList}
							/>

							<div className="pboardcount" id="pboardcount">
								<a href="javascript: void(0)" className="selected">
									1
								</a>
							</div>
						</div>
					</div>
				</section>
				<section className="container">
					{/*광고*/}
					<div
						id="slidebanner"
						className="carousel slide cmb-50 onlyPc"
						data-bs-ride="carousel">
						<div className="carousel-inner">
							<div className="carousel-item">
								<img src={`/images/ti.png`} className="d-block w-100" alt="img01" />
							</div>
							<div className="carousel-item active">
								<img
									src={`/images/ti02.jpg`}
									className="d-block w-100"
									style={{ height: '162px' }}
									alt="img02"
								/>
							</div>
						</div>
					</div>

					<div
						id="slidebanner"
						className="carousel slide cmb-30 onlyMobile"
						data-bs-ride="carousel">
						<div className="carousel-inner">
							<div className="carousel-item active">
								<img
									src={`/images/ti.png`}
									className="d-block w-100"
									style={{ height: '110px' }}
									alt="img01"
								/>
							</div>
							<div className="carousel-item">
								<img
									src={`/images/ti02.jpg`}
									className="d-block w-100"
									style={{ height: '110px' }}
									alt="img02"
								/>
							</div>
						</div>
					</div>
					{/*광고끝*/}
				</section>
				<section className="indexBannerBox">
					<section className="container">
						<div className="d-flex justify-content-between flex-wrap">
							<div className="indexBannerItemBox">
								<div className="indexBannerItem">
									<img src={`/images/comp_info01.png`} alt="comp_info01" />
								</div>
							</div>
							<div className="indexBannerItemBox">
								<div className="indexBannerItem">
									<img src={`/images/comp_info02.png`} alt="comp_info02" />
								</div>
							</div>
							<div className="indexBannerItemBox">
								<div className="indexBannerItem">
									<img src={`/images/comp_info03.png`} alt="comp_info03" />
								</div>
							</div>
						</div>
					</section>
				</section>
				<section className="indexAppStoreBox">
					<section className="container">
						<div className="row">
							<div className="col-12 col-md-6 order-md-2">
								<img
									src={`/images/main_mockup_01.png`}
									alt="img"
									className="indexAppStoreMockupImg"
								/>
							</div>
							<div className="col-12 col-md-6 order-md-1">
								<div className="d-flex align-items-center w-100 h-100">
									<div>
										<div className="row indexDownloadButtonBox">
											<div className="col">
												<a
													href="https://apps.apple.com/kr/app/%EC%8A%A4%ED%8F%AC%EC%9D%B4%EC%9D%8C/id1570740626"
													className="btn btn-light indexDownloadButton">
													<img
														src={`/images/main_001_appstore.png`}
														alt="appstore"
													/>
												</a>
											</div>
											<div className="col">
												<a
													href="https://play.google.com/store/apps/details?id=com.spoeum"
													className="btn btn-light indexDownloadButton">
													<img
														src={`/images/main_001_googleplay.png`}
														alt="googleplay"
													/>
												</a>
											</div>
										</div>
										<p className="indexAppStoreTitle">
											골퍼를 위한 국내 유일 플랫폼
										</p>
										<p className="indexAppStoreSubtitle">
											선수들의 캐디 예약 부터 에이전시 까지,
											<br />
											골프 전문 매칭서비스 스포이음 입니다.
										</p>
									</div>
								</div>
							</div>
						</div>
					</section>
				</section>
			</div>

			<ModalPaymentInfo
				modalVisible={paymentPopupVisible}
				paymentTotal={paymentTotal}
				onClose={handleClosePaymentPopup}
			/>

			{/*<ModalPopup modalVisible={popUpVisible} onClose={handleClosePopup} />*/}
		</div>
	)
}

const ModalPaymentInfo = ({ modalVisible, paymentTotal, onClose, ...props }) => {
	const navigate = useNavigate()

	return (
		<Modal
			id="messageModal"
			backdrop="static"
			centered={true}
			tabIndex="-1"
			show={modalVisible}
			onHide={onClose}
			size="md"
			className="wrap register_page form_page re_form">
			<Modal.Header className="modal-title">
				<p className="w-100 text-center" id="staticBackdropLabel">
					미결제 안내
				</p>
				<button type="button" className="btn-close" aria-label="Close" onClick={onClose} />
			</Modal.Header>
			<Modal.Body>
				<div className="text-center">
					<div className="cmb-30">
						<p className="fontSize-20 cmb-30">
							대회는 잘 마치셨나요?
							<br />
							캐디와의 용역 비용과 서비스 이용료를 지불해 주세요.
						</p>
						<p className="fontSize-20">
							총 결제 필요 금액 :{' '}
							<span className="font-B00">{formattingPrice(paymentTotal)}</span> 원
						</p>
					</div>

					<div className="row">
						<div className="col-12">
							<button
								className="btn btn-dark w-100 cmb-10"
								onClick={() => navigate(`/payment`)}>
								<span className="fontSize-20">결제 바로가기</span>
							</button>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
}

const ModalPopup = ({ modalVisible, onClose, ...props }) => {
	return (
		<Modal
			id="messageModal"
			className="messageModal"
			backdrop="static"
			centered={true}
			tabIndex="-1"
			show={modalVisible}
			onHide={onClose}
			size="md">
			<Modal.Body>
				<div style={{ marginBottom: '16px', textAlign: 'right' }}>
					<button
						type="button"
						className="btn-close"
						aria-label="Close"
						onClick={onClose}
					/>
				</div>
				<div style={{ textAlign: 'center' }}>
					<img src={`/images/applyEducation.jpeg`} alt="img" className="w-100 mb-5rem" />
					<a
						href="https://docs.google.com/forms/d/1zNOdYkDf6Mw7jV7-oIeKermZN7Zop4vkhuZRX7edvN4/edit "
						target="_blank"
						className="btn btn-lg btn-secondary btnDownload"
						style={{ marginTop: '16px' }}>
						참가 신청하기
					</a>
				</div>
			</Modal.Body>
		</Modal>
	)
}
