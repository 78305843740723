import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useStateMachine } from 'little-state-machine'
import { contractTypes, formattingPrice, getMatchTypeOptions, updateFormInput } from '../../utils'
import dayjs from 'dayjs'

export const AnnualContractInfoForm = ({ serviceConfig, onSubmit, ...props }) => {
	const { state } = useStateMachine({ updateFormInput })

	const { register, getValues, setValue, resetField, watch, handleSubmit } = useForm({
		defaultValues: {
			isTaxIncluded: 'true',
		},
		mode: 'onBlur',
	})

	const onKeyupPrice = (key) => (event) => {
		const price = formattingPrice(event.target.value)

		setValue(key, price)
	}

	return (
		<form
			id="form_matchingPro"
			noValidate
			className="needs-validation"
			onSubmit={handleSubmit(onSubmit)}>
			<div className="progress">
				<div
					className="progress-bar"
					role="progressbar"
					style={{ width: '100%' }}
					aria-valuenow="100"
					aria-valuemin="0"
					aria-valuemax="100"></div>
			</div>
			<div className="card">
				<div className="card-body">
					<p className="form_title cmb-10">계약 기간</p>
					<div className="d-flex justify-content-between align-items-end cmb-30 w-100">
						<div className="border-bottom w-100 me-2">
							<div
								id="contractTotal"
								className="d-flex align-items-center form_inputText pe-0">
								<p style={{ wordBreak: 'keep-all' }}>
									{getMatchTypeOptions(
										contractTypes,
										state?.offerInput?.contractType,
									)}
								</p>
								<p>
									<span className="text-nowrap me-1">
										(계약일 ~{' '}
										{state?.offerInput?.contractType === 'year_first_half' &&
											dayjs(serviceConfig?.yearDate).format('YYYY.MM.DD')}
										{state?.offerInput?.contractType === 'year_second_half' &&
											dayjs(
												new Date(new Date().getFullYear(), 11, 31),
											).format('YYYY.MM.DD')}
										)
									</span>
								</p>
							</div>
						</div>
					</div>

					<p className="form_title">
						* 임금 세금 포함 여부
						<span
							style={{
								marginLeft: '0.625rem',
								fontSize: '0.75rem',
								color: '#aeaeae',
							}}>
							원천징수 3.3%
						</span>
					</p>
					<div className="row cmb-30">
						<div className="col">
							<input
								type="radio"
								className="btn-check requiredc"
								id="mPro_paymentTax_1"
								autoComplete="off"
								value="true"
								{...register('isTaxIncluded', {
									required: true,
								})}
							/>
							<label
								className="btn btn-light btn-whiteShadow input-group-target"
								htmlFor="mPro_paymentTax_1">
								세전
							</label>
						</div>
						<div className="col">
							<input
								type="radio"
								className="btn-check"
								name="paymentTax"
								id="mPro_paymentTax_2"
								autoComplete="off"
								value="false"
								{...register('isTaxIncluded', {
									required: true,
								})}
							/>
							<label
								className="btn btn-light btn-whiteShadow input-group-target"
								htmlFor="mPro_paymentTax_2">
								세후
							</label>
						</div>
					</div>

					<p className="form_title">임금 지급 방식</p>
					<div className="row cmb-30">
						<div className="col-6">
							<input
								type="radio"
								className="btn-check requiredc"
								id="mPro_paymentTypeAnnual"
								autoComplete="off"
								checked
							/>
							<label
								className="btn btn-light btn-whiteShadow input-group-target"
								htmlFor="mPro_paymentTypeAnnual">
								연봉(
								{getMatchTypeOptions(
									contractTypes,
									state?.offerInput?.contractType,
								)}
								)
							</label>
						</div>
					</div>

					<p className="form_title">* 연봉</p>
					<div className="row cmb-30 align-items-center">
						<div className="col">
							<div className="d-flex justify-content-end align-items-center underbarInput">
								<input
									id="weeklySalary"
									className="border-0 w-100 form-control no-focus form_inputText regUnderbarInput"
									placeholder="금액을 입력해 주세요."
									{...register('weeklySalary', {
										required: true,
										onChange: onKeyupPrice('weeklySalary'),
									})}
								/>
								원
							</div>
						</div>
					</div>

					<p className="form_title">글 내용</p>
					<input
						id="mPro_noticeTitle"
						className="w-100 cmb-10"
						placeholder="제목을 입력해주세요"
						{...register('postTitle', {
							required: true,
						})}
					/>
					<textarea
						id="mPro_noticeComment"
						className="w-100 cmb-30"
						rows="7"
						placeholder="본문 내용을 입력해주세요"
						{...register('postDetail', {
							required: true,
						})}
					/>

					<button type="submit" className="btn btn-dark nextPageButton">
						제안하기
					</button>
				</div>
			</div>
		</form>
	)
}
