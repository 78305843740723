import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAuthStatus } from './auth'

export const RequireAuth = ({ children, ...props }) => {
	const [auth, onChangeAuthStatus] = useAuthStatus()
	const navigate = useNavigate()
	const location = useLocation()

	if (!auth.isAuthentication) {
		navigate('/login', { state: { from: location } })
	}

	return children
}
