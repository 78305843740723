import React, { useState, useEffect } from 'react'
import { Collapse } from '../../components'
import { useAuthStatus } from '../../utils'

export const FaqListView = ({ API_ENDPOINT, ...props }) => {
	const auth = useAuthStatus()

	const [faqList, setFaqList] = useState([])
	const [searchKeyword, setSearchKeyword] = useState('')

	useEffect(() => {
		fetchFaqs()
	}, [])

	const fetchFaqs = async () =>
		await fetch(`${API_ENDPOINT}/boards/faq`).then(async (response) => {
			const result = await response.json()
			setFaqList(result)
		})

	const onChangeSearchKeyword = (event) => {
		setSearchKeyword(event.target.value)
	}

	const searchFaq = async (event) => {
		await fetch(`${API_ENDPOINT}/boards/faq/search?keyword=${searchKeyword}`).then(
			async (response) => {
				const result = await response.json()

				if (result.success) {
					setFaqList(result.data || [])
				}
			},
		)
	}

	return (
		<div className="wrap notice_page">
			<div className="content_area">
				<div className="container mainWrap">
					<div className="cmb-30" style={{ fontSize: '3rem' }}>
						FAQ
					</div>

					<div className="row cmb-30">
						<div className="col-12">
							<div className="card py-0">
								<div className="card-body" style={{ padding: '1rem' }}>
									<div className="row align-items-center">
										<div className="col">
											<input
												type="text"
												name=""
												id="pm001_input_searchcaddie"
												className="border-0 p-0 w-100"
												placeholder="검색어를 입력해 주세요."
												onChange={onChangeSearchKeyword}
											/>
										</div>
										<div className="col-auto" onClick={searchFaq}>
											<i
												className="fas fa-search color-white-three"
												aria-hidden="true"></i>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<p className="fontSize-16 color-greyish-two font-M00 cmb-30">
						FAQ <span id="faqcnt">{faqList?.length || 0}</span>개
					</p>

					<Collapse isAuthentication={auth.isAuthentication} dataSource={faqList} />

					<div className="pboardcount" id="pboardcount">
						<a href="javascript: void(0)" className="selected">
							1
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}
