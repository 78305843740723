import { useEffect, useRef, useState } from 'react'

import { Link, useLocation, useNavigate } from 'react-router-dom'
import { DEFAULT_USER_SESSION_COOKIE_NAME, removeCookie, useAuthStatus } from '../utils'

export const Header = ({ ...props }) => {
	const [auth, onChangeAuth] = useAuthStatus()

	const navigate = useNavigate()
	const { pathname } = useLocation()

	const mobileSideMenuOuterRef = useRef()
	const mobileSideMenuRef = useRef()
	const [mobileSideMenuOuter, setMobileSideMenuOuter] = useState(null)
	const [mobileSideMenu, setMobileSideMenu] = useState(null)

	const [isTopAlertEsc, setTopAlertEsc] = useState(true)

	useEffect(() => {
		setMobileSideMenuOuter(mobileSideMenuOuterRef.current)
		setMobileSideMenu(mobileSideMenuRef.current)
	}, [])

	useEffect(() => {
		if (mobileSideMenu?.classList?.contains('actived')) {
			closeMSM()
		}
	}, [pathname])

	const showMSM = () => {
		document.querySelector('body').classList.add('stopScrolling')
		mobileSideMenuOuter.classList.add('actived')
		mobileSideMenu.classList.add('actived')

		if (mobileSideMenu.classList.contains('actived')) {
			mobileSideMenuOuter.addEventListener('onClick', (e) => {
				e.preventDefault()
				e.stopPropagation()
			})
		}
	}

	const closeMSM = () => {
		document.querySelector('body').classList.remove('stopScrolling')
		mobileSideMenuOuter.classList.remove('actived')
		mobileSideMenu.classList.remove('actived')
	}

	const onClickTopAlertEsc = () => setTopAlertEsc(true)

	const onClickLogout = () => {
		removeCookie(DEFAULT_USER_SESSION_COOKIE_NAME)
		onChangeAuth()
		navigate('/')
	}

	return (
		<header>
			{/*TOP menu*/}
			<nav className="navbar navbar-expand-lg navbar-light bg-light">
				<div className="container-fluid">
					<Link to="/" className="navbar-brand">
						<img
							src={`/images/main_001_toplogo.png`}
							alt="SPOEUM"
							className="main_001_toplogo"
						/>
					</Link>

					{/*<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">*/}
					<button className="navbar-toggler" type="button" onClick={showMSM}>
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" id="navbarNavAltMarkup">
						<div className="navbar-nav">
							<Link to="/about" className="nav-link">
								회사소개
							</Link>
							<Link to="/matching" className="nav-link">
								매칭서비스
							</Link>
							<Link to="/training-program" className="nav-link">
								투어캐디 아카데미
							</Link>
							{/*<Link to="/sponsorship" className="nav-link">*/}
							{/*	스폰서쉽*/}
							{/*</Link>*/}
							<Link to="/recruits" className="nav-link">
								구인 / 구직
							</Link>
							<Link to="/faq" className="nav-link">
								자주 묻는 질문
							</Link>
							<Link to="/notice" className="nav-link">
								공지사항
							</Link>
						</div>
						{auth.isAuthentication ? (
							<div className="nav-buttonBox">
								<Link to="/mypages/points">
									<p className="text-nowrap">
										<span>나의 잔여 포인트 : </span>
										<span>{auth.user.point} P</span>
									</p>
								</Link>
								<button
									className="btn btn-lg btn-outline-warning"
									onClick={onClickLogout}>
									로그아웃
								</button>
							</div>
						) : (
							<div className="nav-buttonBox">
								<Link to="/register" className="btn btn-lg btn-outline-warning">
									회원가입
								</Link>
								<Link to="/login" className="btn btn-lg btn-warning">
									로그인
								</Link>
							</div>
						)}
					</div>
				</div>
			</nav>

			{/*top alert*/}
			{auth.isAuthentication && (
				<div className={`topAlert ${isTopAlertEsc ? 'closed' : ''}`}>
					<a href="contract_001.php">
						<div className="topAlertTextBox">
							<p className="text-white">미확인 계약 일정이 있습니다.</p>
							<div className="btn btn-light rounded-pill" id="topAlertCount">
								1
							</div>
						</div>
					</a>
					<img
						src={`/images/esc_white.png`}
						alt="esc"
						id="topAlertEscButton"
						onClick={onClickTopAlertEsc}
					/>
				</div>
			)}

			{/*right gnb*/}
			<div className="menu_area">
				<div className="pc_menu">
					{auth.isAuthentication ? (
						<div className="rightFloatingMenu">
							<div className="rfm_topBox">
								<div className="rfm_topMenuBox">
									<p className="text-center">
										M<br />E<br />N<br />U
									</p>
								</div>
								<div className="rfm_topWelcomingBox">
									<img src={`/images/login-welcomeimg.png`} alt="welcoming" />

									<p className="rfm_welcomingText">
										안녕하세요.
										<br />
										<span className="rfm_userName">{auth.user.name}</span>{' '}
										고객님!
									</p>
								</div>
							</div>
							<div className="rfm_menuBox">
								<div className="rfm_mainMenuBox">
									<div className="rfm_menuItem">
										<p>내 정보</p>
										<div className="rfm_subMenu">
											<Link to={'/mypages/info'}>
												<div className="rfm_subMenuItem">
													<p>정보 수정</p>
												</div>
											</Link>

											{auth?.user?.userType === 'tourCaddie' && (
												<Link to={'/mypages/contract-info'}>
													<div className="rfm_subMenuItem">
														<p>계약 정보 수정</p>
													</div>
												</Link>
											)}

											<Link to={'/mypages/account'}>
												<div className="rfm_subMenuItem">
													<p>결제 관리</p>
												</div>
											</Link>

											<Link to={'/mypages/payment'}>
												<div className="rfm_subMenuItem">
													<p>결제 내역</p>
												</div>
											</Link>

											<Link to={'/mypages/interested-hr'}>
												<div className="rfm_subMenuItem">
													<p className="border-0">관심 인재</p>
												</div>
											</Link>
										</div>
									</div>
									<div className="rfm_menuItem">
										<p>계약 관리</p>
										<div className="rfm_subMenu">
											<Link to={'/mypages/contract-process'}>
												<div className="rfm_subMenuItem">
													<p>계약 진행 상황</p>
												</div>
											</Link>

											<Link to={'/mypages/proposals'}>
												<div className="rfm_subMenuItem">
													<p>내 제안 / 회신 내역</p>
												</div>
											</Link>

											<Link to={'/mypages/offers'}>
												<div className="rfm_subMenuItem">
													<p>내 구인 / 신청 내역</p>
												</div>
											</Link>

											{auth.user.userType === 'agency' && (
												<Link to={'/mypages/pro-management'}>
													<div className="rfm_subMenuItem">
														<p>소속 프로 관리</p>
													</div>
												</Link>
											)}

											{auth.user.userType === 'tourPro' && (
												<Link to={'/mypages/agency-management'}>
													<div className="rfm_subMenuItem">
														<p>에이전시 계약관리</p>
													</div>
												</Link>
											)}

											<Link to={'/payment'}>
												<div className="rfm_subMenuItem">
													<p className="border-0">결제하기</p>
												</div>
											</Link>
										</div>
									</div>

									<Link to={'/mypages/schedule'}>
										<div className="rfm_menuItem">
											<p>일정 관리</p>
										</div>
									</Link>

									<Link to={'/mypages/one-in-quire'}>
										<div className="rfm_menuItem">
											<p>1:1 문의</p>
										</div>
									</Link>
								</div>
							</div>
						</div>
					) : (
						<div className="rightFloatingMenu">
							<div className="rfm_topBox">
								<div className="rfm_topMenuBox">
									<p className="text-center">
										M<br />E<br />N<br />U
									</p>
								</div>
								<div className="rfm_topWelcomingBox">
									<img src={`/images/login-welcomeimg.png`} alt="welcoming" />
									<Link to={'/login'}>
										<p className="rfm_welcomingText">로그인이 필요 합니다</p>
									</Link>
								</div>
							</div>
							<div className="rfm_menuBox"></div>
						</div>
					)}
				</div>
				<div className="m_menu">
					<div ref={mobileSideMenuOuterRef} className="mobileSideMenuOuter" />
					{auth.isAuthentication ? (
						<div ref={mobileSideMenuRef} className="mobileSideMenu">
							<div className="msm_closedButton" onClick={closeMSM}>
								<img src={`/images/esc_white.png`} alt="esc" />
							</div>
							<div className="msm_topBox">
								<div className="msm_topWelcomingBox">
									<img src={`/images/login-welcomeimg.png`} alt="welcoming" />
									<p className="msm_welcomingText">
										안녕하세요.
										<br />
										<span className="msm_userName">{auth.user.name}</span>{' '}
										고객님!
									</p>

									<Link to="/mypages/points">
										<p className="text-nowrap">
											<span>나의 잔여 포인트 : </span>
											<span>{auth.user.point} P</span>
										</p>
									</Link>

									<a onClick={onClickLogout}>
										<p className="onlyMobile mblogoutF">로그아웃</p>
									</a>
								</div>
							</div>
							<div className="msm_menuBox">
								<div className="msm_mainMenuBox">
									<div className="msm_menuItem">
										<a
											href="#msm_subMenu_001"
											data-bs-toggle="collapse"
											role="button"
											aria-expanded="false"
											aria_controls="msm_subMenu_001">
											내 정보
										</a>
										<div className="msm_subMenu collapse" id="msm_subMenu_001">
											<Link to={'/mypages/info'}>
												<div className="msm_subMenuItem">
													<p>정보 수정</p>
												</div>
											</Link>

											{auth?.user?.userType === 'tourCaddie' && (
												<Link to={'/mypages/contract-info'}>
													<div className="msm_subMenuItem">
														<p>계약 정보 수정</p>
													</div>
												</Link>
											)}

											<Link to={'/mypages/account'}>
												<div className="msm_subMenuItem">
													<p>결제 관리</p>
												</div>
											</Link>

											<Link to={'/mypages/payment'}>
												<div className="msm_subMenuItem">
													<p>결제 내역</p>
												</div>
											</Link>

											<Link to={'/mypages/interested-hr'}>
												<div className="msm_subMenuItem">
													<p>관심 인재</p>
												</div>
											</Link>
										</div>
									</div>
									<Link to={'/matching'}>
										<div className="msm_menuItem">
											<p>매칭서비스</p>
										</div>
									</Link>
									<div className="msm_menuItem">
										<a
											href="#msm_subMenu_003"
											data-bs-toggle="collapse"
											role="button"
											aria-expanded="false"
											aria_controls="msm_subMenu_003">
											계약 관리
										</a>
										<div className="msm_subMenu collapse" id="msm_subMenu_003">
											<Link to={'/mypages/contract-process'}>
												<div className="msm_subMenuItem">
													<p>계약 진행 상황</p>
												</div>
											</Link>

											<Link to={'/mypages/proposals'}>
												<div className="msm_subMenuItem">
													<p>내 제안 / 회신 내역</p>
												</div>
											</Link>

											<Link to={'/mypages/offers'}>
												<div className="msm_subMenuItem">
													<p>내 구인 / 신청 내역</p>
												</div>
											</Link>
											{auth.user.userType === 'agency' && (
												<Link to={'/mypages/pro-management'}>
													<div className="msm_subMenuItem">
														<p>소속 프로 관리</p>
													</div>
												</Link>
											)}

											{auth.user.userType === 'tourPro' && (
												<Link to={'/mypages/agency-management'}>
													<div className="msm_subMenuItem">
														<p>에이전시 계약관리</p>
													</div>
												</Link>
											)}

											<Link to={'/payment'}>
												<div className="msm_subMenuItem">
													<p className="border-0">결제하기</p>
												</div>
											</Link>
										</div>
									</div>
									<Link to={'/mypages/schedule'}>
										<div className="msm_menuItem">
											<p>일정 관리</p>
										</div>
									</Link>
									<Link to={'/mypages/one-in-quire'}>
										<div className="msm_menuItem">
											<p>1:1 문의</p>
										</div>
									</Link>
								</div>
							</div>
						</div>
					) : (
						<div ref={mobileSideMenuRef} className="mobileSideMenu">
							<div className="msm_closedButton" onClick={closeMSM}>
								<img src={`/images/esc_white.png`} alt="esc" />
							</div>
							<div className="msm_topBox">
								<div className="msm_topWelcomingBox">
									<img src={`/images/login-welcomeimg.png`} alt="welcoming" />
								</div>
							</div>
							<div className="msm_menuBox">
								<Link to={'/login'}>
									<div className="msm_menuItem">
										<p className="rfm_welcomingText">로그인이 필요 합니다</p>
									</div>
								</Link>
							</div>
						</div>
					)}
				</div>
			</div>
		</header>
	)
}
