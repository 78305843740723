import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { DEFAULT_USER_SESSION_COOKIE_NAME, REG_PASSWORD, setCookie } from '../../utils'

export const ResetPasswordView = ({ ...props }) => {
	const navigate = useNavigate()
	const { register, getValues, handleSubmit } = useForm({ mode: 'onBlur' })

	const onSubmit = async (data) => {
		console.log(data)

		const input = { ...data }

		// TODO: resat password
		// await fetch(`${API_ENDPOINT}/auth/`, {
		// 	method: 'POST',
		// headers: {
		// 	'Content-Type': 'application/json',
		// },
		// 	body: JSON.stringify(input),
		// })
		// 	.then(async (response) => {
		// 		const result = await response.json()
		//
		// 		if (result.success) {
		// 			setCookie(DEFAULT_USER_SESSION_COOKIE_NAME, '')
		//
		// 			navigate('/')
		// 		}
		// 	})
		// 	.catch((errors) => {})
	}

	return (
		<div className="wrap find_info form_page">
			<div className="content_area">
				<div className="bgWrap">
					<div className="container">
						<form onSubmit={handleSubmit(onSubmit)}>
							<p className="form_topTitle">비밀번호 재설정</p>

							<div className="card">
								<div className="card-body">
									<input type="hidden" name="userType" value="" />
									<div className="row">
										<div className="col cmt-30">
											<div className="findId_inputBox cmb-30">
												<label
													htmlFor="findId_name"
													className="form-label cmb-10">
													비밀번호를 입력해 주세요
												</label>

												<input
													id="newPw1"
													type="password"
													className="form-control underbarInput cmb-10"
													placeholder="새 비밀번호를 입력해 주세요."
													{...register('password', {
														required: true,
														pattern: {
															value: REG_PASSWORD,
														},
													})}
												/>

												<input
													id="newPw2"
													type="password"
													className="form-control underbarInput"
													placeholder="새 비밀번호를 한번 더 입력해 주세요."
													{...register('checkedPassword', {
														required: true,
														pattern: {
															value: REG_PASSWORD,
														},
														validate: (checkedPassword) =>
															getValues('password') ===
															checkedPassword,
													})}
												/>
											</div>
										</div>
									</div>
									<div>
										{/*<button type="button" className="btn btn-dark cmt-20 findAccountButton" id="findId_nextButton" style="margin-bottom: 2.19rem;" data-bs-toggle="modal" data-bs-target="#findIdModal">비밀번호 설정</button>*/}
										<button
											type="submit"
											className="btn btn-dark cmt-20 findAccountButton">
											비밀번호 변경하기
										</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}
