export const ModalServiceAlert = () => {
	return (
		<div
			className="modal fade"
			id="serviceAlertModal"
			data-bs-backdrop="static"
			tabIndex="-1"
			aria-labelledby="serviceAlertModalLabel"
			aria-hidden="true">
			<div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">스포이음 서비스 알림 동의</h5>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"></button>
					</div>
					<div className="modal-body">
						사용자께서는 서비스 알림 동의를 거부할 수 있으나, 스포이음에서 수집하는
						필수항목 정보는 문자 및 기타 SNS를 통해 서비스를 제공하기 위한 필수 정보로써
						수집·이용에 동의하지 않을 경우 서비스를 제공받을 수 없습니다.
					</div>
					<div className="modal-footer py-1">
						<button
							type="button"
							className="btn btn-warning btn-sm"
							data-bs-dismiss="modal">
							확인
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}
