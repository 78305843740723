import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FETCH_HEADER_OPTIONS, formattingPrice, isEmpty, useAuthStatus } from '../../utils'
import { useDownloadExcel } from 'react-export-table-to-excel'
import dayjs from 'dayjs'

export const MyPaymentView = ({ API_ENDPOINT, ...props }) => {
	const [auth, onChangeAuthStatus] = useAuthStatus()

	const tableRef = useRef(null)

	const { register, watch, setValue, resetField, handleSubmit } = useForm({
		mode: 'onBlur',
	})

	const { onDownload } = useDownloadExcel({
		currentTableRef: tableRef.current,
		filename: `스포이음 결제내역_${auth?.user?.name}`,
		sheet: '결제내역',
	})

	const [payments, setPayments] = useState([])

	useEffect(() => {
		fetchPayments()
	}, [auth])

	const fetchPayments = async () => {
		await fetch(`${API_ENDPOINT}/payment/paymentList/${auth.user?.id}`)
			.then(async (response) => {
				const result = await response.json()

				setPayments(result)
			})
			.catch((errors) => console.log(errors))
	}

	const searchPayment = async (data) => {
		const input = {
			startedAt: dayjs(data?.startedAt).format('YYYY-MM-DDT00:00:00.00') + '9Z',
			endedAt: dayjs(data?.endedAt).format('YYYY-MM-DDT23:59:59.59') + '9Z',
		}

		await fetch(`${API_ENDPOINT}/payment/paymentList/search/${auth.user?.id}`, {
			method: 'PATCH',
			headers: FETCH_HEADER_OPTIONS,
			body: JSON.stringify(input),
		})
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) throw result

				setPayments(result)
			})
			.catch((errors) => console.log(errors))
	}

	return (
		<div className="wrap pro_form form_page">
			<div className="content_area">
				<div className="container mainWrap">
					<div className="cmb-30 pro_my_title_text">결제 내역</div>

					<div className="row cmb-30 align-items-end">
						{/*<div className="col-auto cmb-10">*/}
						{/*	<select*/}
						{/*		className="form-select rounded-pill"*/}
						{/*		style={{ width: '7rem', textAlignLast: 'center' }}*/}
						{/*		aria-label="Default select example"*/}
						{/*		id="filter1"*/}
						{/*		{...register('type')}>*/}
						{/*		<option value="0">전체</option>*/}
						{/*		<option value="1">공고수수료</option>*/}
						{/*		<option value="2">포인트구매</option>*/}
						{/*		<option value="3">포인트소모</option>*/}
						{/*	</select>*/}
						{/*</div>*/}

						<div className="col-auto cmb-10" style={{ paddingBottom: '0.3rem' }}>
							<input
								id="start"
								type="date"
								className="underbarInput"
								min="2021-01-01"
								{...register('startedAt')}
							/>
							<span>~</span>
							<input
								id="end"
								type="date"
								className="underbarInput"
								min="2021-01-01"
								{...register('endedAt')}
							/>
						</div>

						<div className="col-auto cmb-10">
							<button
								className="d-flex justify-content-center btn btn-light btn-whiteShadow rounded-pill"
								style={{
									width: '7rem',
									height: '38px',
									alignItems: 'center',
									padding: 'unset',
								}}
								name="point"
								id="pointcheck"
								onClick={handleSubmit(searchPayment)}>
								기간설정
							</button>
						</div>

						<div className="col-auto cmb-10">
							<button
								id="exceldownload"
								name="exceldownload"
								className="d-flex justify-content-center btn btn-light btn-whiteShadow rounded-pill"
								style={{
									width: '7rem',
									height: '38px',
									alignItems: 'center',
									padding: 'unset',
								}}
								onClick={onDownload}>
								엑셀 다운로드
							</button>
						</div>
					</div>

					<div style={{ width: '100%', float: 'right', display: 'block' }}></div>
					<div className="row align-items-center cmb-30">
						<table
							className="table table-hover text-center"
							id="zero-config"
							ref={tableRef}>
							<thead>
								<tr>
									<th>유형</th>
									<th>결제일</th>
									<th>계약내용</th>
									<th>사유</th>
									<th>금액</th>
								</tr>
							</thead>
							<tbody id="pointTableBody">
								{payments?.map((item) => (
									<tr>
										<th>수수료 결제</th>
										<th>
											{isEmpty(item?.payment?.payed_at)
												? '-'
												: dayjs(item?.payment?.payed_at).format(
														'YYYY-MM-DD HH:mm:ss',
												  )}
										</th>
										<th>{item?.tour?.name}</th>
										<th>서비스 이용료</th>
										<th>{formattingPrice(item?.payment?.amount)} 원</th>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	)
}
