import React from 'react'
import { getEncryptionToken } from '../utils'
import url from 'url'

// http://localhost:3000/nice?
// token_version_id=20240328213832EE-NC66CC624-EF85H-FCGFG789BD
// enc_data=3Ugbb6aGPBt/6iCTGYP7narZZXRTIqnmSvFEDX7LdaKput1cWVKfH1hCndCsR4ZyXs8wnCUBJl6wWuPuuW0UPhXFuK5VugypEnMEQyqqQRmm/dn8jS4pttz18Zl5Mvf2nObmE3LH/MxsvG0ceB6pavN4JOIuhMZu%2B7L/1iIsWTfMFJEtGRhEl8woPYsp/xDYV4UTh4tKSGxBhfj2ZI983%2Bh%2BE39AZh4VU9XLzNSHGHWY9Gy9qotLcC5WtKq2jOotktcOIb71Lew67jmq2Mk6oV8tZVFyOUSzeB99Doju8/qkVWinI0glOuWHHGSHSwXWlFT3O74thDJlZj4YIUDek%2BKZ4aLkhW6GqYaxzr1CBQpd04joomZl20fLazf9S18CTSmf3Nn00VEOfjcsi593HqL4ka9qpe8jREtfwwYBNwuZ1D5WAKNe95PIz1Ww7V4CvUvKlEIOb%2BBLQMN/53nekLZcVsZ8bFMFvlwZlQDmkjPTV/JtlSgR%2B2bC9qMwubE6U9pQ0HziyZ4i35zY8GPD/mgkoMTf5QzCs/sj/9Fzgnk=
// integrity_value=8965pyD4LwGV%2BfHTMV1qqfBzHnzM4zteq5ehUOxC7zw=

export const NiceComponent = ({ API_ENDPOINT, CDN_ENDPOINT, ...props }) => {
	// 표준창 호출에 필요 : token_version_id, enc_data, integrity_value

	const onClickCertify = async () => {
		const { form } = document
		const option = `width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no`
		const returnUrl = encodeURIComponent(`${CDN_ENDPOINT}/nice`) // 본인인증 결과를 전달받을 api url

		// token api가 요청 데이터를 암호화한 후 표준창 호출에 필요한 데이터를 응답해준다.
		await fetch(`https://www.spoeum.com:3095/api/users/niceapi/token/${returnUrl}`)
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) throw result

				if (form && result) {
					const { tokenVersionId, encData, integrityValue } = result

					form.enc_data.value = encData
					form.token_version_id.value = tokenVersionId
					form.integrity_value.value = integrityValue
					form.submit()
				}
			})
			.catch((errors) => console.log(errors))
	}

	return (
		<div>
			<button onClick={onClickCertify} className="btn btn-sm btn-primary mt-5">
				본인인증
			</button>

			{/*nice 서버에 submit할 form*/}
			<form
				name="form"
				id="form"
				action="https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb">
				<input type="hidden" id="m" name="m" value="service" />
				<input type="hidden" id="token_version_id" name="token_version_id" value="" />
				<input type="hidden" id="enc_data" name="enc_data" />
				<input type="hidden" id="integrity_value" name="integrity_value" />
			</form>
		</div>
	)
}
