import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Modal, Card } from 'react-bootstrap'
import {
	contractTypes,
	FETCH_HEADER_OPTIONS,
	formattingPrice,
	getMatchTypeOptions,
	parseFields,
	useAuthStatus,
	userTypes,
} from '../../../utils'
import {
	ContractInfo,
	ModalAcceptProposal,
	ModalCompleteAcceptProposal,
	ModalSuggestionForm,
} from '../../../components'
import dayjs from 'dayjs'

export const ProposalDetailView = ({ API_ENDPOINT, ...props }) => {
	const [auth, onChangeAuth] = useAuthStatus()

	const navigate = useNavigate()

	const { proposalId } = useParams()

	const [proposal, setProposal] = useState(null)
	const [proposalIncentives, setProposalIncentives] = useState([])

	const [refusalModalVisible, setRefusalModalVisible] = useState(false)
	const [suggestionModalVisible, setSuggestionModalVisible] = useState(false)
	const [suggestionFields, setSuggestionFields] = useState({})
	const [acceptModalVisible, setAcceptModalVisible] = useState(false)
	const [acceptCompleteModalVisible, setAcceptCompleteModalVisible] = useState(false)

	useEffect(() => {
		Promise.all([fetchProposal(), fetchProposalIncentives(proposalId)]).then()
	}, [auth])

	const fetchProposal = async () => {
		await fetch(`${API_ENDPOINT}/contract/detail/${proposalId}`)
			.then(async (response) => {
				const result = await response.json()

				setProposal(result)
			})
			.catch((errors) => {
				console.log(errors)
			})
	}

	const fetchProposalList = async () => {
		return !!proposal?.contract?.contract_group_id
			? await fetch(`${API_ENDPOINT}/contract/group/${proposal?.contract?.contract_group_id}`)
					.then(async (response) => {
						return await response.json()
					})
					.catch((errors) => {
						console.log(errors)
					})
			: []
	}

	const fetchProposalIncentives = async () => {
		await fetch(`${API_ENDPOINT}/contract/contract/detail/incentive/${proposalId}`)
			.then(async (response) => {
				const result = await response.json()

				setProposalIncentives(result)
			})
			.catch((errors) => {
				console.log(errors)
			})
	}

	const updateProposal = (status) => async (event) => {
		await fetch(
			`${API_ENDPOINT}/contract/contract/update/${proposal?.contract?.id}/${status}`,
			{
				method: 'PATCH',
				headers: FETCH_HEADER_OPTIONS,
			},
		)
			.then(async (response) => {
				const result = await response.json()

				if (result?.success === false) throw result

				if (status === 'REFUSE') {
					navigate(`/mypages/contract-process/${result?.id}`)
				} else if (status === 'PROCEEDING') {
					handleAcceptModalModalClose()
					handleAcceptCompleteModalModalOpen()
				}
			})
			.catch((error) => {
				// TODO : 에러메세지 관련 안내창 출력
				console.log(error)
			})
	}

	const createContract = async ({ incentives, ...input }) => {
		await fetch(`${API_ENDPOINT}/contract/contract`, {
			method: 'POST',
			headers: FETCH_HEADER_OPTIONS,
			body: JSON.stringify({ ...input, isOffer: false, postId: '' }),
		})
			.then(async (response) => {
				const result = await response.json()

				if (incentives?.length > 0) {
					const incentivesInput = incentives?.map((item) => {
						return {
							...item,
							contractId: result?.id,
							contractGroupId: result?.contract_group_id,
						}
					})

					await createContractIncentives(incentivesInput)
				}
			})
			.catch((error) => {
				// TODO : 에러메세지 관련 안내창 출력
				console.log(error)
			})
	}

	const createContractIncentives = async (input) => {
		await fetch(`${API_ENDPOINT}/contract/contract/incentive`, {
			method: 'POST',
			headers: FETCH_HEADER_OPTIONS,
			body: JSON.stringify(input),
		})
			.then(async (response) => {})
			.catch((error) => {
				// TODO : 에러메세지 관련 안내창 출력
				console.log(error)
			})
	}

	const createContractList = async (input) => {
		const inputList = []

		if (proposal?.contract?.contract_type === 'long_term') {
			const proposalList = await fetchProposalList()

			proposalList?.map((item) => {
				inputList.push({
					...input,
					tourId: item?.tour_id,
					percentage: proposal?.contract?.percentage,
					contractGroupId: `${input?.userId}${Date.now()}`,
				})
			})
		} else if (proposal?.contract?.contract_type === 'single') {
			inputList.push({ ...input, tourId: proposal?.tour?.id })
		} else {
			inputList.push({ ...input })
		}

		await Promise.all(inputList?.map((input) => createContract(input)))
			.then(async () => {
				await fetch(
					`${API_ENDPOINT}/contract/contract/update/${proposal?.contract?.id}/CANCEL`,
					{
						method: 'PATCH',
						headers: FETCH_HEADER_OPTIONS,
						body: JSON.stringify(input),
					},
				)
					.then(() => {
						navigate('/mypages/proposals')
					})
					.catch((error) => {
						// TODO : 에러메세지 관련 안내창 출력
						console.log(error)
					})
			})
			.catch((error) => {
				// TODO : 에러메세지 관련 안내창 출력
				console.log(error)
			})
	}

	const matchContractUserType = () => {
		const myId = auth?.user?.id

		if (proposal?.user?.id === myId) return 'USER'
		else if (proposal?.targetUser?.id === myId) return 'TARGET'
	}

	const handleSuggestionModalModalOpen = () => {
		const { id, contract_group_id, post_id, etc, created_at, ...proposalInput } =
			proposal?.contract

		const incentives = proposalIncentives
			?.map((item) => {
				const { id, contract_id, contract_group_id, ...info } = item

				return info
			})
			.sort((a, b) => {
				return a?.startRank - b?.startRank
			})

		const fields = parseFields({
			...proposalInput,
			incentives,
			userId: auth?.user?.id,
			targetUserId: proposal?.user?.id,
			weeklySalary: formattingPrice(proposalInput?.weekly_salary),
			weeklySalaryF: formattingPrice(proposalInput?.weekly_salary_f),
			expenseReimbursementJeju: formattingPrice(proposalInput?.expense_reimbursement_jeju),
		})

		setSuggestionFields(fields)
		setSuggestionModalVisible(true)
	}
	const handleSuggestionModalModalClose = () => {
		setSuggestionFields({})
		setSuggestionModalVisible(false)
	}

	const handleRefuseModalOpen = () => setRefusalModalVisible(true)

	const handleRefuseModalClose = () => setRefusalModalVisible(false)

	const handleAcceptModalModalOpen = () => setAcceptModalVisible(true)

	const handleAcceptModalModalClose = () => setAcceptModalVisible(false)

	const handleAcceptCompleteModalModalOpen = () => setAcceptCompleteModalVisible(true)

	const handleAcceptCompleteModalModalClose = () => setAcceptCompleteModalVisible(false)

	return (
		<div className="wrap notice_page form_page">
			<div className="content_area">
				<div className="container mainWrap">
					<div className="row cmb-30">
						<div className="fontSize-20 font-R00 color-greyish-two">
							{matchContractUserType() === 'TARGET' &&
								getMatchTypeOptions(userTypes, proposal?.user?.user_type)}
							{matchContractUserType() === 'USER' &&
								getMatchTypeOptions(userTypes, proposal?.targetUser?.user_type)}
						</div>
						<div className="fontSize-30 font-SB00 cmb-10">
							{matchContractUserType() === 'USER' &&
								`${proposal?.targetUser?.name} (${
									proposal?.targetUser?.user_type !== 'agency' &&
									`${proposal?.targetUser?.last_name} ${proposal?.targetUser?.first_name}`
								})`}
							{matchContractUserType() === 'TARGET' &&
								`${proposal?.user?.name} (${
									proposal?.user?.user_type !== 'agency' &&
									`${proposal?.user?.last_name} ${proposal?.user?.first_name}`
								})`}
						</div>
						<div className="fontSize-20 font-R00 color-greyish-two cmb-30">
							{dayjs(proposal?.contract?.created_at).format('YYYY-MM-DD')}
						</div>
					</div>

					<p className="form_title cmt-10 cmb-10">기본 정보</p>
					<Card className="cmb-30">
						<Card.Body>
							<div className="row cmt-10">
								<div className="col-12 col-md-auto color-greyish-two">계약유형</div>
								<div className="col-12 col-md-auto">
									{getMatchTypeOptions(
										contractTypes,
										proposal?.contract?.contract_type,
									)}
								</div>
							</div>
							{['single', 'long_term'].includes(
								proposal?.contract?.contract_type,
							) && (
								<div className="row cmt-10">
									<div className="col-12 col-md-auto color-greyish-two">
										계약기간
									</div>
									<div className="col-12 col-md-auto">
										{dayjs(proposal?.tour?.started_at).format('YYYY-MM-DD')} ~{' '}
										{dayjs(proposal?.tour?.ended_at).format('YYYY-MM-DD')} 총
										1경기
										<br />
										{proposal?.tour?.name}
									</div>
								</div>
							)}
							<div className="row cmt-10">
								<div className="col-12 col-md-auto color-greyish-two">내용</div>
								<div
									className="col-12 col-md-auto"
									dangerouslySetInnerHTML={{
										__html: proposal?.contract?.etc,
									}}
									style={{ whiteSpace: 'pre' }}
								/>
							</div>
						</Card.Body>
					</Card>

					<ContractInfo contract={proposal?.contract} incentives={proposalIncentives} />

					{matchContractUserType() === 'TARGET' && (
						<React.Fragment>
							<div className="row">
								<div
									className={`${
										auth?.user?.userType === 'houseCaddie' ? 'col-12' : 'col-6'
									}`}>
									<button
										disabled={proposal?.contract?.contract_status !== 'PRE'}
										className="btn btn-danger nextPageButton"
										onClick={handleRefuseModalOpen}>
										제안 거절하기
									</button>
								</div>
								{auth?.user?.userType !== 'houseCaddie' && (
									<div className="col-6">
										<button
											disabled={proposal?.contract?.contract_status !== 'PRE'}
											className="btn btn-success nextPageButton"
											onClick={handleSuggestionModalModalOpen}>
											다른 조건으로 제안하기
										</button>
									</div>
								)}

								<div
									className={`${
										auth?.user?.userType === 'houseCaddie' ? 'col-6' : 'col-12'
									}`}>
									<button
										disabled={proposal?.contract?.contract_status !== 'PRE'}
										className="btn btn-dark nextPageButton"
										onClick={handleAcceptModalModalOpen}>
										현재 조건으로 수락하기
									</button>
								</div>
							</div>
						</React.Fragment>
					)}
				</div>
			</div>

			<ModalRefusalProposal
				modalVisible={refusalModalVisible}
				refusalContract={updateProposal}
				onClose={handleRefuseModalClose}
			/>

			<ModalSuggestionForm
				modalVisible={suggestionModalVisible}
				modalTitle="다른 조건으로 제안하기"
				fieldValues={suggestionFields}
				contractType={proposal?.contract?.contract_type}
				createContractList={createContractList}
				onClose={handleSuggestionModalModalClose}
			/>

			<ModalAcceptProposal
				modalVisible={acceptModalVisible}
				acceptContract={updateProposal}
				onClose={handleAcceptModalModalClose}
			/>

			<ModalCompleteAcceptProposal
				modalVisible={acceptCompleteModalVisible}
				onClose={handleAcceptCompleteModalModalClose}
			/>
		</div>
	)
}

const CancelProposalModal = ({ modalVisible, cancelProposal, onClose, ...props }) => {
	return (
		<Modal
			id="cancelModal"
			backdrop="static"
			centered={true}
			tabIndex="-1"
			aria-labelledby="cancelModalLabel"
			aria-hidden="true"
			show={modalVisible}
			onHide={onClose}>
			<Modal.Header className="border-0">
				<button type="button" className="btn-close" aria-label="Close" onClick={onClose} />
			</Modal.Header>
			<Modal.Body>
				<p className="fontSize-20 font-L00 color-greyish-brown text-center">
					제안이 취소됩니다.
				</p>
				<p className="fontSize-20 font-L00 color-greyish-brown text-center cmb-30">
					진행하시겠습니까?
				</p>

				<div className="row cmb-20">
					<div className="col-6">
						<button
							type="button"
							aria-label="Close"
							className="btn btn-danger registerNextButton w-100 pb-3 pt-3 cmb-10"
							onClick={onClose}>
							취소
						</button>
					</div>
					<div className="col-6">
						<button
							type="button"
							className="btn btn-dark registerNextButton w-100 pb-3 pt-3 cmb-10"
							onClick={cancelProposal}>
							확인
						</button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
}

const ModalRefusalProposal = ({ modalVisible, refusalContract, onClose, ...props }) => {
	// const { register, getValues, watch, reset, handleSubmit } = useForm({
	// 	mode: 'onBlur',
	// })
	//
	// useEffect(() => {
	// 	reset()
	// }, [modalVisible])
	//
	// const onSubmit = (data) => refusalProposal(data)

	return (
		<Modal
			id="refusalModal"
			show={modalVisible}
			size="lg"
			backdrop="static"
			tabIndex="-1"
			centered
			aria-labelledby="refusalModalLabel"
			aria-hidden="true"
			className="wrap register_page form_page re_form">
			<Modal.Header className="border-0">
				<p className="modal-title w-100 text-center font-bold" id="staticBackdropLabel">
					제안 거절
				</p>
				<button type="button" className="btn-close" aria-label="Close" onClick={onClose} />
			</Modal.Header>
			<Modal.Body>
				<p className="fontSize-20 font-L00 color-greyish-brown">
					받은 제안서를 거절하시겠습니까?
				</p>
				<p className="fontSize-20 font-L00 color-greyish-brown cmb-30">
					거절 결과는 상대방에게 전달되며, 취소할 수 없습니다.
				</p>

				<div className="row">
					<div className="col-12 col-md-6">
						<button
							type="button"
							className="btn btn-dark pb-4 pt-4 w-100 cmb-10"
							onClick={onClose}>
							취소
						</button>
					</div>
					<div className="col-12 col-md-6">
						<button
							id="noticeaccept"
							className="btn btn-danger pb-4 pt-4 w-100 cmb-10"
							onClick={refusalContract('REFUSE')}>
							확인
						</button>
					</div>
				</div>

				{/*<form id="refusal-proposal" noValidate onSubmit={handleSubmit(onSubmit)}>*/}
				{/*	<div className="col-12 cmb-10">*/}
				{/*		<select*/}
				{/*			id="rejectSelect"*/}
				{/*			className="form-select"*/}
				{/*			aria-label="Default select example"*/}
				{/*			{...register('refusalReason', {*/}
				{/*				required: true,*/}
				{/*			})}>*/}
				{/*			<option selected disabled>*/}
				{/*				거절 사유를 선택해주세요.*/}
				{/*			</option>*/}
				{/*			<option value="1">*/}
				{/*				제안 감사합니다. 다만 다른분과 먼저 계약하였습니다.*/}
				{/*			</option>*/}
				{/*			<option value="2">*/}
				{/*				제안 감사하오나, 상황이 달라져 새로운 조건으로 작성할 예정입니다.*/}
				{/*			</option>*/}
				{/*			<option value="3">*/}
				{/*				개인 사정으로 구인 공고를 취소하게 되었습니다. 죄송합니다.*/}
				{/*			</option>*/}
				{/*			<option value="4">직접 입력</option>*/}
				{/*		</select>*/}
				{/*	</div>*/}
				{/*	{watch('refusalReason') === '4' && (*/}
				{/*		<div className="col-12 cmb-30">*/}
				{/*			<input*/}
				{/*				type="text"*/}
				{/*				id="rejectInput"*/}
				{/*				className="border w-100 pb-2 pt-2 d-none"*/}
				{/*				placeholder="내용을 입력해 주세요."*/}
				{/*				{...register('refusalReasonDetail', {*/}
				{/*					required: true,*/}
				{/*				})}*/}
				{/*			/>*/}
				{/*		</div>*/}
				{/*	)}*/}
				{/*	<button*/}
				{/*		type="submit"*/}
				{/*		id="offercaddiebtn"*/}
				{/*		className="btn btn-danger registerNextButton w-100 pb-3 pt-3">*/}
				{/*		거절하기*/}
				{/*	</button>*/}
				{/*</form>*/}
			</Modal.Body>
		</Modal>
	)
}
