import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
	convertURLtoFile,
	FETCH_HEADER_OPTIONS,
	getFileExt,
	parseStringToBool,
	SignIn,
	uploadS3,
	useAuthStatus,
} from '../../utils'
import { MessageModal } from '../../components'
import { ModalLoginFail } from '../../components/modal'

export const RegisterHouseCaddieView = ({ API_ENDPOINT, ...props }) => {
	const [auth, onChangeAuthStatus] = useAuthStatus()

	const navigate = useNavigate()

	const [golfZones, setGolfZones] = useState([])

	const { register, getValues, setValue, resetField, watch, handleSubmit } = useForm({
		defaultValues: {
			club: '',
		},
		mode: 'onBlur',
	})

	useEffect(() => {
		if (auth.isAuthentication) {
			navigate('/')
		}

		fetchGolfzones()
	}, [])

	const onSubmit = async (data) => {
		// input format
		// {
		// 	"name": "string",
		// 	"firstName": "string",
		// 	"lastName": "string",
		// 	"email": "string",
		// 	"password": "string",
		// 	"phone": "string",
		// 	"notification": true,
		// 	"userType": "tourPro",
		// 	"profileType": "string",
		// 	"club": "string",
		// 	"employmentYear": "string",
		// 	"currentContractEndedAt": "2024-02-19T08:48:08.023Z",
		// 	"career": [
		// 		null
		// 	]
		// }
		const { profileImg, ...basicFields } = JSON.parse(localStorage.getItem('basicInfo'))
		const additionalFields = Object.keys(data).reduce((acc, cv) => {
			acc[cv] = parseStringToBool(data[cv])

			return acc
		}, {})

		const convertedProfile = await convertURLtoFile(profileImg)

		const input = {
			userType: 'houseCaddie',
			profileType: !!convertedProfile ? getFileExt(convertedProfile) : null,
			...basicFields,
			...additionalFields,
		}

		await fetch(`${API_ENDPOINT}/users/housecaddie`, {
			method: 'POST',
			headers: FETCH_HEADER_OPTIONS,
			body: JSON.stringify(input),
		})
			.then(async (response) => {
				const result = await response.json()

				if (result.success === false) {
					throw new Error('회원가입 실패')
				} else {
					await uploadS3(result.user_id, 'profile', convertedProfile)

					localStorage.clear()

					await SignIn({ email: input?.email, password: input?.password })
						.then((userInfo) => {
							onChangeAuthStatus(JSON.stringify(userInfo))
							navigate('/matching')
						})
						.catch((errors) => {
							handleLoginFailModalOpen()
						})
				}
			})
			.catch((errors) => {
				console.log(errors)
			})
	}

	const oninvalid = (errors) => {
		console.log(errors)
	}

	const fetchGolfzones = async () => {
		const res = await fetch(`${API_ENDPOINT}/admin/db-manage/golf-course`)
		const golfzoneList = await res.json()

		setGolfZones(golfzoneList)
	}

	const onSearchGolfzone = async () => {
		const searchKeyword = getValues('golfzoneName')

		await fetch(`${API_ENDPOINT}/admin/db-manage/golf-course/search?keyword=${searchKeyword}`, {
			method: 'POST',
		}).then(async (response) => {
			const golfzoneList = await response.json()

			setValue('club', golfzoneList[0]?.id)
			setGolfZones(golfzoneList)
		})
	}

	// modal
	const [messageModalVisible, setMessageModalVisible] = useState(false)
	const [messageModalContent, setMessageModalContent] = useState('')
	const [loginFailModalVisible, setLoginFailModal] = useState(false)

	const handleMessageModalOpen = (message) => {
		setMessageModalContent(message)
		setMessageModalVisible(true)
	}

	const handleMessageModalClose = () => {
		setMessageModalContent('')
		setMessageModalVisible(false)
	}

	const onClickLoginFailOk = () => navigate('/login')

	const handleLoginFailModalOpen = () => setLoginFailModal(true)

	const handleLoginFailModalClose = () => setLoginFailModal(false)

	return (
		<div className="wrap form_page re_form">
			<div className="content_area">
				<div className="bgWrap">
					<div className="container form_mw-662">
						<form
							className="needs-validation"
							id="form_registorTour"
							noValidate
							onSubmit={handleSubmit(onSubmit, oninvalid)}>
							<p className="form_topTitle">회원가입</p>

							<div
								className="card"
								id="register_house2_card"
								style={{ display: 'flex' }}>
								<div className="card-body">
									<span className="regInfoText cmb-30">
										※ 매칭 서비스 이용에 필요한 부가 정보입니다.
										<br />
										바로 회원가입 버튼을 누르시면 생략 가능하며, 서비스 이용 때
										다시 입력할 수 있습니다.
									</span>

									{/*대회별 일급 및 인센티브가 아직 설정되지 않은 경우 의 표시처리*/}
									<div className="row cmt-30">
										<div className="col-24 col-md-24 cmb-30">
											<div className="row align-items-center">
												<div className="col form_subTitle text-center">
													(주)스포이음에서 각 대회별 용역비 및 인센티브 설정 예정
												</div>
											</div>
										</div>
									</div>

									<div className="cmb-30">
										<div className="form_title cmb-10">소속 골프장</div>
										<div>
											<select
												className="form-select underbarSelect"
												id="golfzone"
												style={{ width: '100%' }}
												placeholder="소속 골프장이 있으면 추가해 주세요."
												{...register('club', { required: true })}>
												{golfZones.map((golfZone, index) => (
													<option
														key={golfZone.id}
														value={golfZone.id}
														className="text-greyish-brown">
														[{golfZone.name}] {golfZone.address}
													</option>
												))}
											</select>
										</div>
										<div className="row align-items-center cmt-10">
											<div className="col underbarInput">
												<input
													id="golfzoneName"
													className="border-0 w-100 form-control no-focus form_inputText regUnderbarInput"
													placeholder="골프장 이름을 검색해 보세요."
													{...register('golfzoneName')}
												/>
											</div>
											<div className="col-auto">
												<button
													type="button"
													className="btn btn-dark w-100"
													onClick={onSearchGolfzone}>
													검색
												</button>
											</div>
										</div>
									</div>
									<button
										type="submit"
										className="btn btn-dark nextPageButton"
										id="reg_house_submitBtn">
										회원가입
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>

			<MessageModal
				modalVisible={messageModalVisible}
				content={messageModalContent}
				onClose={handleMessageModalClose}
			/>

			<ModalLoginFail
				modalVisible={loginFailModalVisible}
				onClickOk={onClickLoginFailOk}
				onClose={handleLoginFailModalClose}
			/>
		</div>
	)
}
